import { AppLayout } from "components/app-layout";
import { RequireAuthRole } from "contexts/auth-provider";
import type { Route } from "lib/react-router-dom";
import { LeftNavigation } from "modules/vendor-portal/components/left-navigation";
import { VENDOR_PORTAL_PATHS } from "modules/vendor-portal/paths";
import { DocumentsScreen } from "modules/vendor-portal/screens/documents/documents-screen";
import { EventsScreen } from "modules/vendor-portal/screens/events/events-screen";
import { FeedbackScreen } from "modules/vendor-portal/screens/feedback/feedback-screen";
import { MarketingScreen } from "modules/vendor-portal/screens/marketing/marketing-screen";
import { OffersScreen } from "modules/vendor-portal/screens/offers/offers-screen";
import { OpenHomeDetailsScreen } from "modules/vendor-portal/screens/open-home-details/open-home-details-screen";
import { OverviewScreen } from "modules/vendor-portal/screens/overview/overview-screen";
import { PerformanceScreen } from "modules/vendor-portal/screens/performance/performance-screen";

export const VENDOR_PORTAL_ROUTES: Route[] = [
  {
    path: VENDOR_PORTAL_PATHS.DEFAULT,
    handle: {
      title: "Vendor",
    },
    element: (
      <RequireAuthRole anyOf={["vendor", "agent", "agent_manager"]}>
        <AppLayout leftNavigationMenu={<LeftNavigation />} />
      </RequireAuthRole>
    ),
    children: [
      {
        index: true,
        Component: OverviewScreen,
        handle: {
          title: "Overview",
        },
      },
      {
        path: VENDOR_PORTAL_PATHS.DOCUMENTS,
        Component: DocumentsScreen,
      },
      {
        path: VENDOR_PORTAL_PATHS.OFFERS,
        Component: OffersScreen,
      },
      {
        path: VENDOR_PORTAL_PATHS.MARKETING,
        Component: MarketingScreen,
      },
      {
        path: VENDOR_PORTAL_PATHS.EVENTS,
        Component: EventsScreen,
      },
      {
        path: VENDOR_PORTAL_PATHS.EVENT,
        Component: OpenHomeDetailsScreen,
      },
      {
        path: VENDOR_PORTAL_PATHS.PERFORMANCE,
        Component: PerformanceScreen,
      },
      {
        path: VENDOR_PORTAL_PATHS.FEEDBACK,
        Component: FeedbackScreen,
      },
    ],
  },
];
