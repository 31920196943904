import {
  Box,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Show,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import BackArrowIcon from "assets/icons/medium/back-arrow.svg?react";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";
import { OpenHomeDesktopLayout } from "modules/vendor-portal/screens/open-home-details/components/layout/open-home-desktop-layout";
import { OpenHomeMobileLayout } from "modules/vendor-portal/screens/open-home-details/components/layout/open-home-mobile-layout";
import { openHomeDetailsModel } from "modules/vendor-portal/screens/open-home-details/models/open-home";
import { formatDuration } from "modules/vendor-portal/screens/open-home-details/utils";
import { formatEventDateTimeTitle } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card";

export function OpenHomeDetailsScreen() {
  const { isLoading, data } = useQuery(openHomeDetailsModel.queries.read());

  const start = dayjs(`2000-01-01 ${data?.event.date_time_start}`);
  const finish = dayjs(`2000-01-01 ${data?.event.date_time_finish}`);
  const durationObject = dayjs.duration(finish.diff(start));
  const duration = formatDuration(durationObject);
  const navigate = useNavigate();

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>
      <Box p={{ base: 4, md: 0 }}>
        <VStack gap={3} mb={8} alignItems={"flex-start"}>
          <HStack gap={2}>
            <Show above={"sm"}>
              <IconButton
                aria-label={"Back"}
                variant={"ghost"}
                icon={<Icon as={BackArrowIcon} boxSize={"16px"} />}
                color={"#000"}
                cursor={"pointer"}
                onClick={() => navigate(-1)}
              />
            </Show>
            <Skeleton isLoaded={!isLoading}>
              <Heading
                fontSize={{ base: "4xl", md: "2xl" }}
                fontWeight={{ base: 800, md: 700 }}
                as={"h1"}
              >
                {`Open Home on ${
                  data?.event ? formatEventDateTimeTitle(data?.event) : ""
                }`}
              </Heading>
            </Skeleton>
          </HStack>
          {data?.event.agent.name ? (
            <Show above={"sm"}>
              <Skeleton isLoaded={!isLoading}>
                <Text
                  fontSize={"md"}
                  color={"gray.600"}
                >{`${duration} • Hosted by ${data?.event.agent.name}`}</Text>
              </Skeleton>
            </Show>
          ) : null}
        </VStack>

        <Show above={"sm"}>
          <OpenHomeDesktopLayout isLoading={isLoading} data={data} />
        </Show>

        <Show below={"sm"}>
          <OpenHomeMobileLayout isLoading={isLoading} data={data} />
        </Show>
      </Box>
    </Box>
  );
}
