import {
  mockMaintenanceTask,
  type MaintenanceTask,
} from "types/maintenance-task";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type MaintenanceData = {
  maintenanceTasks: MaintenanceTask[];
};

export const landlordMaintenanceModel = createModel("landlordMaintenance", {
  queries: {
    read: (searchParams: URLSearchParams) => ({
      queryKey: ["read", searchParams.toString()],
      queryFn: () => {
        return apiClient.get<MaintenanceData>(
          `/landlord/maintenance?${searchParams.toString()}`,
        );
      },
      keepPreviousData: true,
    }),
  },
});

export const mockLandlordMaintenanceResponse = () => {
  return {
    maintenanceTasks: Array.from({ length: 8 }).map(() =>
      mockMaintenanceTask(),
    ),
  };
};
