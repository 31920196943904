import CalendarIcon from "assets/icons/medium/calendar.svg?react";
import DisbursementsIcon from "assets/icons/medium/disbursements.svg?react";
import FileIcon from "assets/icons/medium/file.svg?react";
import HomeIcon from "assets/icons/medium/house.svg?react";
import SpannerIcon from "assets/icons/medium/spanner.svg?react";
import type { MenuItem } from "components/left-navigation-menu/components/left-navigation-menu";
import { LeftNavigationMenu } from "components/left-navigation-menu/components/left-navigation-menu";
import { LANDLORD_PORTAL_PATHS } from "modules/landlord-portal/paths";

const menuItems: MenuItem[] = [
  {
    label: "Overview",
    path: LANDLORD_PORTAL_PATHS.OVERVIEW,
    Icon: HomeIcon,
  },
  {
    label: "Financials",
    path: LANDLORD_PORTAL_PATHS.FINANCIALS,
    Icon: DisbursementsIcon,
  },
  {
    label: "Maintenance",
    path: LANDLORD_PORTAL_PATHS.MAINTENANCE,
    Icon: SpannerIcon,
  },
  {
    label: "Inspections",
    path: LANDLORD_PORTAL_PATHS.INSPECTIONS,
    Icon: CalendarIcon,
  },
  {
    label: "Documents",
    path: LANDLORD_PORTAL_PATHS.DOCUMENTS,
    Icon: FileIcon,
  },
];

export function LeftNavigation() {
  return <LeftNavigationMenu items={menuItems} />;
}
