import { Box, Button, Heading, Icon, Text } from "@chakra-ui/react";
import SpannerIcon from "assets/icons/medium/spanner.svg?react";

export function RequestMaintenanceBox() {
  return (
    <Box
      p={6}
      bg={"white"}
      gap={6}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      borderRadius={"lg"}
    >
      <Icon as={SpannerIcon} w={8} h={8} />
      <Box display={"flex"} flexDir={"column"} gap={1}>
        <Heading size={"lg"} fontWeight={"bold"}>
          Is there an issue with your home?
        </Heading>
        <Text color={"gray.600"} fontWeight={"medium"}>
          Request for maintenance here and we will be notified instantly
        </Text>
      </Box>
      <Button colorScheme={"gray"}>Request maintenance</Button>
    </Box>
  );
}
