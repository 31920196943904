import { useMemo, useState } from "react";
import { CalendarIcon } from "@chakra-ui/icons";
import { Flex, Heading, HStack, Icon, List, ListItem } from "@chakra-ui/react";
import CommentIcon from "assets/icons/medium/comment.svg?react";
import { CardRecord } from "components/cards/card-record";
import {
  MultiSelectInput,
  type MenuOption,
} from "components/inputs/multi-select-input";
import { ShowMoreLessButton } from "components/show-more-less-button";
import { TotalCountBadge } from "components/total-count-badge";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import { formatEventDateTimeTitle } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card";
import { EventFeedbackInset } from "modules/vendor-portal/screens/overview/components/open-home-events/event-feedback-inset";
import { OpenHomeEventFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/open-home-event-footer";
import { PrivateInspectionEventFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/private-inspection-event-footer";
import { LinkedEventCard } from "modules/vendor-portal/screens/overview/components/open-home-events/linked-event-card";
import type { EventsScreenData } from "modules/vendor-portal/types/events";
import type { OpenHomeFeedback } from "modules/vendor-portal/types/feedback";

const FEEDBACK_OPTIONS: MenuOption[] = [
  { value: "inspection", label: "Private inspection" },
  { value: "ofi", label: "Open home" },
];
const DEFAULT_FILTERS = FEEDBACK_OPTIONS.map((x) => x.value);

type EventDesktopLayoutProps = {
  listing?: EventsScreenData;
  isLoading: boolean;
};

export function EventDesktopLayout({
  listing,
  isLoading,
}: EventDesktopLayoutProps) {
  const [pastEventFilters, setPastEventFilters] =
    useState<string[]>(DEFAULT_FILTERS);
  const [upcomingEventFilters, setUpcomingEventFilters] =
    useState<string[]>(DEFAULT_FILTERS);

  const pastEvents = useMemo(() => {
    return listing?.events.past.filter((event) =>
      pastEventFilters.includes(event.feedback.type.id),
    );
  }, [listing, pastEventFilters]);

  const upcomingEvents = useMemo(() => {
    return listing?.events.upcoming.filter((event) =>
      upcomingEventFilters.includes(event.feedback.type.id),
    );
  }, [listing, upcomingEventFilters]);

  const [showAllPastEvents, setShowAllPastEvents] = useState(false);
  const pastEventsToShow = showAllPastEvents
    ? pastEvents
    : pastEvents?.slice(0, 3);

  const [showAllUpcomingEvents, setShowAllUpcomingEvents] = useState(false);
  const upcomingEventsToShow = showAllUpcomingEvents
    ? upcomingEvents
    : upcomingEvents?.slice(0, 3);

  return (
    <Flex direction={"column"} gap={8}>
      <Flex direction={"column"} gap={6}>
        <Flex direction={"row"} justify={"space-between"}>
          <HStack gap={3}>
            <Heading fontSize={"xl"} as={"h2"}>
              Past Viewings
            </Heading>
            <TotalCountBadge count={pastEvents?.length} />
          </HStack>
          <MultiSelectInput
            placeholder={"Event type"}
            options={FEEDBACK_OPTIONS}
            value={pastEventFilters}
            menuLabel={"Filter by"}
            onChange={setPastEventFilters}
          />
        </Flex>
        <SkeletonList isLoaded={!isLoading} listSize={4}>
          <List spacing={4}>
            {pastEventsToShow?.map((event) => (
              <ListItem key={event.id}>
                <LinkedEventCard event={event}>
                  {event.feedback.type.id === "inspection" ? (
                    <PrivateInspectionEventFooter event={event}>
                      <Flex dir={"row"} align={"center"} gap={1}>
                        <Icon as={CommentIcon} boxSize={"12px"} />
                        <FooterText text={`1`} />
                      </Flex>
                    </PrivateInspectionEventFooter>
                  ) : null}

                  {event.feedback.type.id === "ofi" ? (
                    <>
                      <OpenHomeEventFooter event={event}>
                        {(event.feedback as OpenHomeFeedback)
                          .individual_feedback.length > 0 ? (
                          <Flex dir={"row"} align={"center"} gap={1}>
                            <Icon as={CommentIcon} boxSize={"12px"} />

                            <FooterText
                              text={`${
                                (event.feedback as OpenHomeFeedback)
                                  .individual_feedback.length
                              }`}
                            />
                          </Flex>
                        ) : null}
                      </OpenHomeEventFooter>
                    </>
                  ) : null}

                  <EventFeedbackInset feedback={event.feedback} />
                </LinkedEventCard>
              </ListItem>
            ))}
            <ShowMoreLessButton
              showAll={showAllPastEvents}
              setShowAll={setShowAllPastEvents}
            />
          </List>
        </SkeletonList>
      </Flex>

      <Flex direction={"column"} gap={6}>
        <Flex direction={"row"} justify={"space-between"}>
          <HStack gap={3}>
            <Heading fontSize={"xl"} as={"h2"}>
              Upcoming Viewings
            </Heading>
            <TotalCountBadge count={upcomingEvents?.length} />
          </HStack>
          <MultiSelectInput
            placeholder={"Event type"}
            options={FEEDBACK_OPTIONS}
            value={upcomingEventFilters}
            menuLabel={"Filter by"}
            onChange={setUpcomingEventFilters}
          />
        </Flex>

        <SkeletonList isLoaded={!isLoading} listSize={4}>
          <List spacing={4}>
            {upcomingEventsToShow?.map((event) => (
              <ListItem key={event.id}>
                <CardRecord
                  heading={formatEventDateTimeTitle(event)}
                  leftElement={<FeedbackIcon icon={CalendarIcon} />}
                >
                  {event.feedback.type.id === "inspection" ? (
                    <PrivateInspectionEventFooter event={event} />
                  ) : null}
                  {event.feedback.type.id === "ofi" ? (
                    <OpenHomeEventFooter event={event} />
                  ) : null}
                </CardRecord>
              </ListItem>
            ))}
            <ShowMoreLessButton
              showAll={showAllUpcomingEvents}
              setShowAll={setShowAllUpcomingEvents}
            />
          </List>
        </SkeletonList>
      </Flex>
    </Flex>
  );
}
