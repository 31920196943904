import { faker } from "@faker-js/faker";
import { fakerDateString } from "lib/faker";
import { mockContact, type Contact } from "types/contact";

export enum OwnershipStatus {
  Active = "active",
  Incoming = "incoming",
  Draft = "draft",
  Archived = "archived",
  Outgoing = "outgoing",
}

export type Ownership = {
  id: string;
  display_name: string;
  status_id: OwnershipStatus;
  preferred_suppliers?: Contact[];
  agreement_start_date: string;
  agreement_end_date: string | null;

  // Rex Home specific
  agency_company_contact: Contact;
  agency_manager_contact: Contact;

  owners: { role_id: "primary" | null; contact: Contact }[];
};

// Mock ownership with faker to generate data
export function mockOwnership(): Ownership {
  return {
    id: faker.string.uuid(),
    display_name: faker.person.firstName() + " " + faker.person.lastName(),
    status_id: faker.helpers.arrayElement([
      OwnershipStatus.Active,
      OwnershipStatus.Incoming,
      OwnershipStatus.Draft,
      OwnershipStatus.Archived,
      OwnershipStatus.Outgoing,
    ]),
    preferred_suppliers: Array.from({
      length: faker.number.int({ min: 0, max: 3 }),
    }).map(() => mockContact()),
    agreement_start_date: fakerDateString(),
    agreement_end_date: fakerDateString(),
    agency_company_contact: mockContact(),
    agency_manager_contact: mockContact(),
    owners: [
      {
        role_id: "primary",
        contact: mockContact(),
      },
    ],
  };
}
