import type { ComponentType, ReactNode, SVGProps } from "react";
import { Button, Icon as ChakraIcon, Tooltip } from "@chakra-ui/react";
import { useMatch, useResolvedPath } from "react-router-dom";
import LockIcon from "assets/icons/medium/lock.svg?react";
import { LinkPreserveSearch } from "components/link-preserve-search";

const TOOLTIP_MAP: Record<string, string> = {
  feedback:
    "Once we start receiving enquiries and feedback, this section will become available for you to view everything in one spot.",
  offers:
    "Upon receiving your first offer, you'll be able to view them all here.",
  performance:
    "Once we launch your campaign and it’s live on the portals, you will be able to view campaign statistics here.",
  documents:
    "Documents will be available soon. Any documents shared with you can be viewed here.",
};

export function LeftNavigationMenuItem({
  label,
  path,
  Icon,
  property,
}: {
  label: ReactNode;
  path: string;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  property?: any;
}) {
  const resolved = useResolvedPath(path, { relative: "route" });
  const match = useMatch({ path: resolved.pathname });

  const isLocked =
    (path === "documents" && property?.totalDocuments === 0) ||
    (path === "feedback" && property?.totalFeedback === 0) ||
    (path === "offers" && property?.totalOffers === 0) ||
    (path === "performance" && !property?.performanceAvailable);

  return (
    <Tooltip
      label={TOOLTIP_MAP[path]}
      hasArrow
      arrowSize={15}
      isDisabled={!isLocked}
      placement={"right"}
    >
      <Button
        as={LinkPreserveSearch}
        to={isLocked ? undefined : path}
        keyWhitelist={["property_id"]}
        w={"100%"}
        pl={3}
        py={6}
        fontWeight={"medium"}
        lineHeight={"24px"}
        fontSize={"lg"}
        invert={1}
        overflow={"hidden"}
        _hover={{ backgroundColor: "indigo.500", color: "white" }}
        _active={{ backgroundColor: "indigo.500", color: "white" }}
        justifyContent={"flex-start"}
        leftIcon={
          Icon ? <ChakraIcon as={Icon} boxSize={5} ml={1} mr={2} /> : undefined
        }
        rightIcon={
          isLocked ? (
            <ChakraIcon
              as={LockIcon}
              boxSize={5}
              pos={"absolute"}
              top={3}
              right={2}
            />
          ) : undefined
        }
        variant={"ghost"}
        isActive={!!match}
        isDisabled={isLocked}
      >
        {label}
      </Button>
    </Tooltip>
  );
}
