import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Link, useSearchParams } from "react-router-dom";
import { Page } from "components/page";
import { Table, type TableColumn } from "components/table/table";
import { PropertySelector } from "modules/landlord-portal/components/property-selector";
import {
  landlordDocumentsModel,
  type HandbookData,
} from "modules/landlord-portal/models/documents";
import { ComplianceEntryStatus } from "types/compliance-entry";
import { dateFormats } from "utils/date";
import { titleCase, UNKNOWN_VALUE_MAP } from "utils/string";

const documentStatusColorMap = {
  [ComplianceEntryStatus.Archived]: "red",
  [ComplianceEntryStatus.ExpiresSoon]: "yellow",
  [ComplianceEntryStatus.Valid]: "green",
  [ComplianceEntryStatus.Expired]: "red",
};

const documentsColumns: TableColumn<HandbookData["documents"][number]>[] = [
  {
    label: "File name",
    id: "name",
  },
  { label: "Type", id: "type", formatValue: () => UNKNOWN_VALUE_MAP },
  {
    label: "Date uploaded",
    id: "created_at",
    formatValue: (v) => dayjs.unix(v).format(dateFormats.dayMonthYear),
  },
  {
    label: null,
    width: "200px",
    id: "view",
    Cell: (props) => (
      <Link to={props.row.url} target={"_blank"} rel={"noopener noreferrer"}>
        <Button variant={"outline"} w={"100%"} colorScheme={"gray"}>
          View
        </Button>
      </Link>
    ),
  },
];

const complianceEntriesColumns: TableColumn<
  HandbookData["compliance_entries"][number]
>[] = [
  {
    label: "Name",
    id: "details",
  },
  {
    label: "Status",
    id: "status_id",
    width: "150px",
    Cell: (props) => (
      <Tag colorScheme={documentStatusColorMap[props.row.status_id]}>
        {titleCase(props.row.status_id)}
      </Tag>
    ),
  },
  { label: "Provider", id: "supplier.display_name" },
  {
    label: "Date expiry",
    id: "expires_at",
    formatValue: (v) => dayjs(v).format(dateFormats.dayMonthYear),
  },
  {
    label: "Policy No.",
    id: "policy_no",
    formatValue: () => UNKNOWN_VALUE_MAP,
  },
];

const columns = {
  documents: documentsColumns,
  manuals: documentsColumns,
  compliance_entries: complianceEntriesColumns,
};
export function DocumentsScreen() {
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useQuery(
    landlordDocumentsModel.queries.read(searchParams),
  );

  const isMobile = useBreakpointValue({ base: true, md: false });

  function renderTableForType(key: keyof HandbookData) {
    return (
      <Box bg={"white"} borderRadius={"md"} overflow={"hidden"} borderWidth={1}>
        <Table<HandbookData[typeof key][number]>
          isLoading={isLoading}
          isFetching={isLoading}
          columns={
            columns[key] as TableColumn<HandbookData[typeof key][number]>[]
          }
          rows={data?.[key] ?? []}
          emptyProps={{
            emptyMessage: `No ${titleCase(key).toLowerCase()} found.`,
            emptyDesc: "Please check back later.",
          }}
        />
      </Box>
    );
  }

  return (
    <Page heading={"Documents"} rightElement={<PropertySelector />}>
      <Tabs variant={isMobile ? "soft-rounded" : undefined}>
        <TabList overflowX={isMobile ? "auto" : undefined} flex={1}>
          <Tab>Documents</Tab>
          <Tab>Certificates</Tab>
          <Tab>Manuals</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{renderTableForType("documents")}</TabPanel>
          <TabPanel>{renderTableForType("compliance_entries")}</TabPanel>
          <TabPanel>{renderTableForType("manuals")}</TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
}
