import { Flex, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import QuoteIcon from "assets/icons/medium/quote.svg?react";

type FeedbackCardProps = {
  feedbackNote: string;
  attendeeName: string;
  dateOfAttendance: string;
  count: number;
};
export function FeedbackCard({
  feedbackNote,
  attendeeName,
  dateOfAttendance,
  count,
}: FeedbackCardProps) {
  const widthClass = count < 2 ? "100%" : count === 2 ? "48%" : "30%";

  return (
    <Flex
      direction={"column"}
      align={"flex-start"}
      justify={"flex-start"}
      bgColor={"gray.50"}
      py={{ base: 12, md: 16 }}
      px={8}
      gap={{ base: 6, md: 10 }}
      borderRadius={"8px"}
      h={"100%"}
      w={{ base: "100%", lg: "48%", xl: widthClass }}
    >
      <Icon as={QuoteIcon} boxSize={"38px"} color={"gray.900"} />
      <Flex
        direction={"column"}
        align={"flex-start"}
        justify={"space-between"}
        h={"100%"}
        gap={{ base: 6, md: 10 }}
      >
        <Text
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight={400}
          h={{ base: "270px", sm: "auto", lg: "300px" }}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          display={"-webkit-box"}
          css={{
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 10,
          }}
        >
          {feedbackNote}
        </Text>
        <HStack gap={3}>
          <Flex
            borderRadius={"full"}
            boxSize={8}
            bgColor={"indigo.500"}
            color={"gray.50"}
            align={"center"}
            justify={"center"}
            fontSize={{ base: "xs", md: "sm" }}
            fontWeight={500}
          >
            {attendeeName.charAt(0)}
          </Flex>
          <VStack
            gap={{ base: 1, md: 2 }}
            align={"flex-start"}
            justify={"center"}
          >
            <Text
              fontSize={{ base: "md", md: "lg" }}
              fontWeight={500}
              lineHeight={{ base: "14px", md: "16px" }}
              color={"gray.700"}
            >
              {attendeeName}
            </Text>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight={400}
              lineHeight={{ base: "12px", md: "14px" }}
              color={"gray.600"}
            >
              {dateOfAttendance}
            </Text>
          </VStack>
        </HStack>
      </Flex>
    </Flex>
  );
}
