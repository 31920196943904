import { useState } from "react";
import { Box, chakra, HStack, Input } from "@chakra-ui/react";

type ColorPickerInputProps = {
  value: string;
  onChange?: (color: string) => void;
  disabled?: boolean;
};

const ColorPickerInput = ({
  value,
  onChange,
  disabled,
}: ColorPickerInputProps) => {
  const [hexInput, setHexInput] = useState(value);

  const isValidHex = (hex: string) => /^#?([0-9A-F]{3}){1,2}$/i.test(hex);

  const formatHex = (hex: string) => {
    // Remove non-hex characters and ensure # prefix
    let formatted = hex.replace(/[^0-9A-F]/gi, "").toUpperCase();
    if (formatted.length === 3) {
      formatted = formatted
        .split("")
        .map((char) => char + char)
        .join("");
    }
    return "#" + formatted;
  };

  const handleHexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHex = e.target.value;
    setHexInput(newHex);

    // Only trigger onChange if valid hex
    if (isValidHex(newHex)) {
      const formattedHex = formatHex(newHex);
      onChange?.(formattedHex);
    }
  };

  const handleHexBlur = () => {
    if (isValidHex(hexInput)) {
      const formattedHex = formatHex(hexInput);
      setHexInput(formattedHex);
      onChange?.(formattedHex);
    } else {
      // Reset to last valid value
      setHexInput(value);
    }
  };

  return (
    <HStack spacing={2}>
      <Box
        pos={"relative"}
        w={"40px"}
        h={"40px"}
        borderRadius={"md"}
        borderWidth={"1px"}
        borderColor={"gray.200"}
        overflow={"hidden"}
        cursor={disabled ? "not-allowed" : "pointer"}
        role={"button"}
        aria-label={"Pick color"}
      >
        <chakra.input
          type={"color"}
          value={value}
          onChange={(e) => {
            setHexInput(e.target.value.toUpperCase());
            onChange?.(e.target.value.toUpperCase());
          }}
          disabled={disabled}
          position={"absolute"}
          cursor={disabled ? "not-allowed" : "pointer"}
          border={"0"}
          padding={"0"}
          margin={"0"}
          w={"100%"}
          h={"calc(100% - 3px)"}
          sx={{
            background: "transparent",
            appearance: "none",
            "::-webkit-color-swatch-wrapper": {
              padding: "3px",
              paddingBottom: "0px",
            },
            "::-webkit-color-swatch": {
              border: "none",
              borderRadius: "4px",
              overflow: "hidden",
            },
            "::-moz-color-swatch": {
              border: "none",
            },
          }}
        />
      </Box>

      <Input
        value={hexInput}
        onChange={handleHexChange}
        onBlur={handleHexBlur}
        disabled={disabled}
        w={"100px"}
        fontFamily={"mono"}
        fontSize={"lg"}
        textTransform={"uppercase"}
        _placeholder={{ opacity: 0.5 }}
        isInvalid={!isValidHex(hexInput)}
        aria-label={"Hex color value"}
      />
    </HStack>
  );
};

export { ColorPickerInput };
