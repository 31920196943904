import { Box, Center, Flex, Icon, Show, useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";
import {
  ResponsiveCards,
  type ResponsiveCard,
} from "components/responsive-cards";
import { StatsGrid } from "components/stats-grid";
import { AgentUpdates } from "modules/vendor-portal/screens/overview/components/agent-updates";
import { VendorBannerImage } from "modules/vendor-portal/screens/overview/components/banner-image/vendor-banner-image";
import { RecentFeedback } from "modules/vendor-portal/screens/overview/components/feedback/recent-feedback";
import { ListingPerformance } from "modules/vendor-portal/screens/overview/components/listing-performance/listing-performance";
import { RecentEvents } from "modules/vendor-portal/screens/overview/components/open-home-events/recent-events";
import { UpcomingEvents } from "modules/vendor-portal/screens/overview/components/open-home-events/upcoming-events";
import { PriceIndication } from "modules/vendor-portal/screens/overview/components/price-indication/price-indication";
import { WelcomePresentationModal } from "modules/vendor-portal/screens/overview/components/welcome-presentation/welcome-presentation-modal";
import { overviewModel } from "modules/vendor-portal/screens/overview/models/overview";

export function OverviewScreen() {
  const { propertyId } = useParams();
  const { data, isLoading } = useQuery(overviewModel.queries.read(propertyId));
  const { isOpen, onOpen, onClose } = useDisclosure();

  const agentUpdates = data?.agent_updates?.last_3_entries ?? [];
  const recentFeedback = [...(data?.applicant_feedback?.entries ?? [])]
    .reverse()
    .slice(0, 3);
  const events = data?.events ?? [];
  const recentEvents = events.filter(
    (event) => new Date(event.starts_at) <= new Date(),
  );
  const upcomingEvents = events.filter(
    (event) => new Date(event.starts_at) > new Date(),
  );

  const summary = data?.summary;
  const priceAdvertiseAs = data?.price_advertise_as || "No price";
  const priceIndications = summary?.list_of_price_indications?.value || [];

  const cards: ResponsiveCard[] = [
    agentUpdates.length > 0 && {
      label: "Agents Update",
      element: <AgentUpdates isLoading={isLoading} updates={agentUpdates} />,
    },
    recentFeedback.length > 0 && {
      label: "Recent Feedback",
      element: (
        <RecentFeedback isLoading={isLoading} feedback={recentFeedback} />
      ),
    },
    recentEvents.length > 0 && {
      label: "Recent Events",
      width: "50%",
      element: <RecentEvents isLoading={isLoading} events={recentEvents} />,
    },
    upcomingEvents.length > 0 && {
      label: "Upcoming Events",
      width: "50%",
      element: <UpcomingEvents isLoading={isLoading} events={upcomingEvents} />,
    },
    summary && {
      label: "Performance",
      width: "50%",
      element: <ListingPerformance isLoading={isLoading} summary={summary} />,
    },
    priceIndications.length > 0 && {
      label: "Price Indication",
      width: "50%",
      element: (
        <PriceIndication
          priceAdvertiseAs={priceAdvertiseAs}
          priceIndications={priceIndications}
          isLoading={isLoading}
        />
      ),
    },
  ].filter(Boolean) as ResponsiveCard[];

  const summaryStats = summary
    ? [
        {
          id: "days_since_listing",
          label: "Days since listing",
          value: summary.days_since_listed?.value || 0,
        },
        {
          id: "total_enquiries",
          label: "Total enquiries",
          value: summary.count_of_enquiries?.value || 0,
        },
        {
          id: "total_inspection_open_homes",
          label: "Total inspection/open homes",
          value: summary.count_of_inspections_and_open_homes?.value || 0,
        },
        {
          id: "total_attendees",
          label: "Total attendees",
          value: summary.count_of_unique_attendees?.value || 0,
        },
        {
          id: "duplicate_inspections",
          label: "Duplicate inspections",
          value:
            summary.count_of_attendees_attending_more_than_once?.value || 0,
        },
        {
          id: "total_offers",
          label: "Total offers",
          value: summary.list_of_offer_amounts?.value?.length || 0,
        },
      ]
    : [];

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>
      <Flex direction={"column"} gap={8}>
        <VendorBannerImage
          isLoading={isLoading}
          listing={data}
          onOpen={onOpen}
        />
        <Flex
          direction={"column"}
          px={{ base: "16px", md: "0px" }}
          rowGap={"32px"}
        >
          <StatsGrid stats={summaryStats} isLoading={isLoading} />
          <ResponsiveCards cards={cards} />
        </Flex>
        <WelcomePresentationModal
          listing={data}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Flex>
    </Box>
  );
}
