import type { ReactNode } from "react";
import { Flex, type ButtonProps } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelectInput } from "components/inputs/select-input";
import { useAuth, type AuthContextType } from "contexts/auth-provider";
import { Portal } from "types/portal";

function createGroupByOptions(auth?: AuthContextType) {
  if (!auth?.user) return [];

  const groupByOptions = [];

  if (auth.isLandlord) {
    groupByOptions.push({
      label: { id: Portal.Landlord, value: "Manage" },
      options: [{ value: null, label: "My portfolio" }],
    });
  }

  if (auth.isVendor) {
    groupByOptions.push({
      label: { id: Portal.Vendor, value: "For Sale" },
      options: auth.user.related.listings.map((listing: any) => ({
        value: listing.id,
        label: listing.address.short_address,
      })),
    });
  }

  if (auth.isTenant) {
    groupByOptions.push({
      label: { id: Portal.Tenant, value: "My Home" },
      options: auth.user.related.tenant_properties.map((listing: any) => ({
        value: listing.id,
        label: listing.address.short_address,
      })),
    });
  }

  return groupByOptions;
}

type PortalSelectorProps = {
  menuButtonProps?: ButtonProps;
  overlineText?: string;
  prefixIcon?: ReactNode;
};

export function PortalSelector({
  menuButtonProps,
  overlineText,
  prefixIcon,
}: PortalSelectorProps) {
  const auth = useAuth();
  const groupByOptions = createGroupByOptions(auth);
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const location = useLocation();
  const isLandlordPath = location.pathname.startsWith("/landlord");

  const selectedGroup =
    auth.isLandlord && isLandlordPath
      ? groupByOptions[0]
      : groupByOptions.find(
          (option) =>
            option?.options?.find((option) => option?.value === propertyId),
        );

  const selectedGroupOption =
    selectedGroup?.label.id === "landlord"
      ? selectedGroup.options[0]
      : selectedGroup?.options.find((option) => option?.value === propertyId);

  return (
    <Flex h={12} align={"center"} justify={"center"} gap={2}>
      <SelectInput
        optionGroups={groupByOptions}
        value={selectedGroupOption?.value}
        onChange={(option, label) => {
          navigate(option ? `/${label}/${option}` : `/${label}`);
        }}
        menuProps={{ matchWidth: true, gutter: 0, autoSelect: false }}
        menuButtonProps={
          menuButtonProps ?? {
            variant: "outline",
            fontSize: "lg",
          }
        }
        overlineText={overlineText}
        prefixIcon={prefixIcon}
      />
    </Flex>
  );
}
