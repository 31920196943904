import { lazy, Suspense } from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  SkeletonCircle,
  Tooltip,
} from "@chakra-ui/react";
import { HelpTooltipTrigger } from "components/help-tooltip-trigger";
import {
  PIE_CHART_CONTAINER_HEIGHT,
  PIE_CHART_CONTAINER_WIDTH,
} from "modules/vendor-portal/screens/overview/components/listing-performance/constants";
import { PieChartLegend } from "modules/vendor-portal/screens/overview/components/listing-performance/pie-chart-legend";
import { PieChartSkeleton } from "modules/vendor-portal/screens/overview/components/listing-performance/pie-chart-skeleton";
import type { APIListing } from "modules/vendor-portal/types/api-listing";

const PieChart = lazy(
  () =>
    import(
      "modules/vendor-portal/screens/overview/components/listing-performance/pie-chart"
    ),
);

const TOOLTIP_LABEL =
  "The figures and graph below provide an indication of the type of people that are inspecting your property.\n" +
  "Hot / Warm leads are more likely to make quick decisions regarding the sale or lease of your property than cold ones.\n" +
  "Information is only shown for people that have been ranked in our system.";

type ListingPerformanceProps = {
  summary?: APIListing["summary"];
  isLoading: boolean;
};
export function ListingPerformance({
  summary,
  isLoading,
}: ListingPerformanceProps) {
  const interestLevelStats =
    summary?.count_of_feedback_by_interest_level?.value || [];
  const totalAttendees = interestLevelStats?.reduce(
    (acc, curr) => acc + curr.value,
    0,
  );

  return (
    <Card h={"100%"} variant={{ base: "unstyled", md: "outline" }}>
      <CardHeader mb={{ base: 6, md: 0 }}>
        <HStack gap={0}>
          <Heading size={"md"}>Interest Level</Heading>
          <Tooltip
            hasArrow
            placement={"top"}
            label={TOOLTIP_LABEL}
            bg={"gray.900"}
            color={"white"}
          >
            <HelpTooltipTrigger />
          </Tooltip>
        </HStack>
      </CardHeader>
      <CardBody>
        <PieChartSkeleton isLoaded={!isLoading}>
          <Flex
            direction={"row"}
            h={"100%"}
            wrap={"wrap"}
            align={"center"}
            justify={"space-evenly"}
            gap={4}
          >
            <Box
              w={`${PIE_CHART_CONTAINER_WIDTH}px`}
              h={`${PIE_CHART_CONTAINER_HEIGHT}px`}
            >
              <Suspense fallback={<SkeletonCircle w={"100%"} h={"100%"} />}>
                <PieChart interestLevelStats={interestLevelStats} />
              </Suspense>
            </Box>
            <PieChartLegend
              interestLevelStats={interestLevelStats}
              totalAttendees={totalAttendees}
            />
          </Flex>
        </PieChartSkeleton>
      </CardBody>
    </Card>
  );
}
