import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import AccentColorHelpImage from "assets/help-images/accent-color.png";
import NavigationColorHelpImage from "assets/help-images/navigation-color.png";
import PrimaryLogoHelpImage from "assets/help-images/primary-logo.png";
import SecondaryLogoHelpImage from "assets/help-images/secondary-logo.png";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { HelpPopover } from "components/help-popover";
import { ColorPickerInput } from "components/inputs/color-picker-input";
import { SelectInput } from "components/inputs/select-input";
import { SingleImageUploadInput } from "components/inputs/single-image-upload-input";
import type {
  AccountBranding,
  ImageUploadResponse,
} from "modules/agency-settings/models/account-branding-model";
import { accountBrandingModel } from "modules/agency-settings/models/account-branding-model";
import {
  FONT_WEIGHT_DESCRIPTORS,
  fontModel,
  type Font,
  type FontFamiliesResponse,
  type FontFamily,
  type FontsResponse,
} from "modules/agency-settings/models/font-model";
import { PageCardAgencySettings } from "modules/agency-settings/screens/appearance/components/page-card-agency-settings";
import { UploadFontModal } from "modules/agency-settings/screens/appearance/components/upload-font-modal";
import { UploadedFontCard } from "modules/agency-settings/screens/appearance/components/uploaded-font-card";

type AppearanceFormValues = AccountBranding;

export function AppearanceScreen() {
  const toast = useToast();
  const [isUploadFontModalOpen, setIsUploadFontModalOpen] = useState(false);

  const form = useForm<AppearanceFormValues>({
    defaultValues: {
      heading_font: null,
      body_font: null,
      navigation_color: "#708fde",
      accent_color: "#2d3748",
      primary_logo: null,
      secondary_logo: null,
    },
  });

  // Fetch branding data
  const { data: brandingData, isLoading } = useQuery(
    accountBrandingModel.queries.get(),
  );

  // Fetch uploaded fonts
  const { data: uploadedFontsData, isLoading: isLoadingFonts } =
    useQuery<FontsResponse>(fontModel.queries.list());

  // Fetch Google fonts
  const { data: fontFamiliesData, isLoading: isLoadingFontFamilies } =
    useQuery<FontFamiliesResponse>(fontModel.queries.listFontFamilies());

  // Upload image mutation
  const uploadImageMutation = useMutation({
    ...accountBrandingModel.mutations.uploadImage(),
  });

  // Update branding mutation
  const updateBrandingMutation = useMutation({
    ...accountBrandingModel.mutations.update(),
    onSuccess: () => {
      toast({
        title: "Appearance updated",
        description: "Your appearance settings have been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Update failed",
        description:
          "There was an error updating your appearance settings. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  // Populate form when data is loaded
  useEffect(() => {
    if (brandingData) {
      form.reset(brandingData);
    }
  }, [brandingData, form]);

  // Handle image upload
  const handleImageUpload = async (file: File) => {
    const result = (await uploadImageMutation.mutateAsync({
      file,
    })) as ImageUploadResponse;
    return result.data.url;
  };

  // Handle form submission
  const handleSubmit = async (values: AppearanceFormValues) => {
    await updateBrandingMutation.mutateAsync({
      ...values,
      primary_logo_id: values.primary_logo?.id,
      secondary_logo_id: values.secondary_logo?.id,
    });
  };

  // Prepare font options for select inputs
  const getFontOptions = () => {
    const options = [];

    // Add Google Fonts group
    if (fontFamiliesData?.data) {
      options.push({
        label: { id: "google_fonts", value: "Google Fonts" },
        options: fontFamiliesData.data.map((family: FontFamily) => ({
          value: family.name,
          label: family.name,
        })),
      });
    }

    // Add Uploaded Fonts group
    if (uploadedFontsData?.data && uploadedFontsData.data.length > 0) {
      options.push({
        label: { id: "uploaded_fonts", value: "Uploaded Fonts" },
        options: uploadedFontsData.data.map((font: Font) => ({
          value: font.name,
          label: font.name,
        })),
      });
    }

    return options;
  };

  // Get available font weights from the selected font family
  const getFontWeightOptions = (fontName: string | undefined) => {
    // Return empty array if no font name is selected
    if (!fontName)
      return [
        {
          label: { id: "weights", value: "Weights" },
          options: [],
        },
      ];

    // Find matching font family in the Google fonts
    const googleFontFamily = fontFamiliesData?.data?.find(
      (family) => family.name === fontName,
    );

    if (googleFontFamily) {
      // Return weights from Google font family
      return [
        {
          label: { id: "weights", value: "Weights" },
          options: googleFontFamily.fonts.map((font) => ({
            value: font.weight,
            label: `${
              FONT_WEIGHT_DESCRIPTORS[
                Number(font.weight) as keyof typeof FONT_WEIGHT_DESCRIPTORS
              ]
            } (${font.weight})`,
          })),
        },
      ];
    }

    const uploadedFont = uploadedFontsData?.data?.find(
      (font) => font.name === fontName,
    );

    if (uploadedFont) {
      const weightValue = String(uploadedFont.weight);
      const weightLabel =
        FONT_WEIGHT_DESCRIPTORS[
          Number(uploadedFont.weight) as keyof typeof FONT_WEIGHT_DESCRIPTORS
        ];

      return [
        {
          label: { id: "weights", value: "Weights" },
          options: [
            {
              value: weightValue,
              label: weightLabel,
            },
          ],
        },
      ];
    }

    return [
      {
        label: { id: "weights", value: "Weights" },
        options:
          fontFamiliesData?.data
            ?.find((family) => family.name === fontName)
            ?.fonts.filter((font) => font.style !== "italic")
            .map((font) => ({
              value: font.weight,
              label: font.weight,
            })) || [],
      },
    ];
  };

  if (isLoading || isLoadingFonts || isLoadingFontFamilies) {
    return (
      <Flex
        justify={"center"}
        align={"center"}
        minH={"400px"}
        direction={"column"}
        gap={4}
      >
        <Spinner size={"xl"} />
        <Text>Loading appearance settings...</Text>
      </Flex>
    );
  }

  return (
    <Box pb={24}>
      <Form
        id={"appearance"}
        onSubmit={handleSubmit}
        form={form}
        enableSaveCancelOverlay
      >
        <Flex direction={"column"} gap={8}>
          <Flex direction={"column"} gap={2}>
            <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700}>
              Appearance
            </Heading>
            <Text>Choose the look and feel of your Rex Home platforms</Text>
          </Flex>
          <PageCardAgencySettings
            heading={"Logos"}
            description={"Add a primary and secondary logo"}
          >
            <Flex direction={"column"} gap={12}>
              <Flex direction={"column"} gap={2}>
                <Flex direction={"row"} align={"center"}>
                  <Heading fontSize={"lg"} as={"h4"}>
                    Primary logo
                  </Heading>
                  <HelpPopover
                    title={"Primary logo"}
                    image={PrimaryLogoHelpImage}
                    description={
                      "The primary logo will appear in the top navigation, hero image, vendor presentations and notification emails. For the best outcome, we recommend using a transparent PNG logo that is 5:2 ratio with a minimum height of 120px."
                    }
                  />
                </Flex>
                <Text fontSize={"md"} color={"gray.600"}>
                  Your primary logo will be used for most of your company
                  branding on Rex Home platforms and notification emails.
                </Text>
                <Text fontSize={"md"} color={"gray.600"} mb={4}>
                  We recommend a transparent PNG with an approximate ratio of
                  5:2 and a minimum height of 120px.
                </Text>
                <FormField
                  name={"primary_logo.url"}
                  label={null}
                  Input={SingleImageUploadInput}
                  inputProps={{
                    onImageUpload: handleImageUpload,
                  }}
                />
              </Flex>
              <Flex direction={"column"} gap={2}>
                <Flex direction={"row"} align={"center"}>
                  <Heading fontSize={"lg"} as={"h4"}>
                    Secondary logo
                  </Heading>
                  <HelpPopover
                    title={"Secondary logo"}
                    image={SecondaryLogoHelpImage}
                    description={
                      "The secondary logo will appear as a thumbnail for agency contact details. For the best outcome, we recommend using a PNG logo that is 1:1 ratio and at least 240px x 240px."
                    }
                  />
                </Flex>
                <Text fontSize={"md"} color={"gray.600"}>
                  Your secondary logo will be used as a thumbnail image for
                  contact details. Agents without profile images will have this
                  logo displayed instead.
                </Text>
                <Text fontSize={"md"} color={"gray.600"} mb={4}>
                  We recommend using a PNG logo that is 1:1 ratio and at least
                  240px x 240px.
                </Text>
                <FormField
                  name={"secondary_logo.url"}
                  label={null}
                  Input={SingleImageUploadInput}
                  inputProps={{
                    onImageUpload: handleImageUpload,
                    boxProps: {
                      p: 4,
                      aspectRatio: undefined,
                      overflow: "hidden",
                      w: "150px",
                      height: "150px",
                      borderRadius: "100%",
                    },
                    placeholderProps: {
                      fontSize: "sm",
                    },
                    imageProps: {
                      borderRadius: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              </Flex>
            </Flex>
          </PageCardAgencySettings>

          <PageCardAgencySettings
            heading={"Brand colors"}
            description={"Choose your own brand colors"}
          >
            <Flex direction={"column"} gap={12}>
              <Box>
                <Flex>
                  <FormField
                    name={"accent_color"}
                    label={
                      <>
                        Accent color
                        <HelpPopover
                          title={"Accent color"}
                          image={AccentColorHelpImage}
                          description={
                            "The accent colour will be used for banner backgrounds when a property image is not available and statistic icons."
                          }
                        />
                      </>
                    }
                    required
                    Input={ColorPickerInput}
                  />
                  <FormField
                    name={"navigation_color"}
                    required
                    label={
                      <>
                        Navigation color
                        <HelpPopover
                          title={"Navigation color"}
                          image={NavigationColorHelpImage}
                          description={
                            "The navigation colour will be used for your sidebar navigation."
                          }
                        />
                      </>
                    }
                    Input={ColorPickerInput}
                  />
                </Flex>
              </Box>
            </Flex>
          </PageCardAgencySettings>

          <PageCardAgencySettings
            heading={"Brand fonts"}
            description={"Choose your own brand fonts"}
          >
            <Flex direction={"column"} gap={12}>
              <Box>
                <Tabs variant={"line"} colorScheme={"gray"}>
                  <TabList>
                    <Tab>Text styles</Tab>
                    <Tab>Uploaded fonts</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel display={"flex"} flexDir={"column"} gap={6}>
                      <Flex direction={"column"} gap={2} mt={2}>
                        <Heading fontSize={"lg"} as={"h4"}>
                          Heading
                        </Heading>
                        <Text fontSize={"md"} color={"gray.600"}>
                          Customise your header text used at the top of pages
                          and for subheadings in sections
                        </Text>
                        <Flex direction={"row"} justify={"flex-start"} gap={2}>
                          <Box>
                            <FormField
                              name={"heading_font.name"}
                              required
                              inputProps={{
                                menuButtonProps: {
                                  borderWidth: "1px",
                                  borderColor: "gray.200",
                                  fontWeight: "normal",
                                },
                                optionGroups: getFontOptions(),
                              }}
                              Input={SelectInput}
                            />
                          </Box>
                          <Box>
                            <FormField
                              name={"heading_font.weight"}
                              required
                              inputProps={{
                                menuButtonProps: {
                                  borderWidth: "1px",
                                  borderColor: "gray.200",
                                  fontWeight: "normal",
                                },
                                optionGroups: getFontWeightOptions(
                                  form.watch("heading_font.name"),
                                ),
                              }}
                              Input={SelectInput}
                            />
                          </Box>
                        </Flex>
                      </Flex>
                      <Flex direction={"column"} gap={2}>
                        <Heading fontSize={"lg"} as={"h4"}>
                          Body
                        </Heading>
                        <Text fontSize={"md"} color={"gray.600"}>
                          Customise your paragraph text
                        </Text>
                        <Flex direction={"row"} justify={"flex-start"} gap={2}>
                          <Box>
                            <FormField
                              name={"body_font.name"}
                              required
                              inputProps={{
                                menuButtonProps: {
                                  borderWidth: "1px",
                                  borderColor: "gray.200",
                                  fontWeight: "normal",
                                },
                                optionGroups: getFontOptions(),
                              }}
                              Input={SelectInput}
                            />
                          </Box>
                          <Box>
                            <FormField
                              name={"body_font.weight"}
                              required
                              inputProps={{
                                menuButtonProps: {
                                  borderWidth: "1px",
                                  borderColor: "gray.200",
                                  fontWeight: "normal",
                                },
                                optionGroups: getFontWeightOptions(
                                  form.watch("body_font.name"),
                                ),
                              }}
                              Input={SelectInput}
                            />
                          </Box>
                        </Flex>
                      </Flex>
                    </TabPanel>
                    <TabPanel>
                      <Flex direction={"column"} gap={6} mt={2}>
                        <Flex
                          direction={"row"}
                          justify={"space-between"}
                          align={"center"}
                        >
                          <Text fontSize={"md"} color={"gray.600"}>
                            Once uploaded, custom fonts are available for
                            selection in the &quot;Text styles&quot; tab
                          </Text>

                          <Button
                            colorScheme={"gray"}
                            onClick={() => setIsUploadFontModalOpen(true)}
                          >
                            Upload font
                          </Button>
                        </Flex>

                        {isLoadingFonts ? (
                          <Flex justify={"center"} py={8}>
                            <Spinner />
                          </Flex>
                        ) : uploadedFontsData?.data &&
                          uploadedFontsData.data.length > 0 ? (
                          <Flex direction={"column"} gap={3}>
                            {uploadedFontsData.data.map((font: Font) => (
                              <UploadedFontCard key={font.id} font={font} />
                            ))}
                          </Flex>
                        ) : (
                          <Flex
                            p={8}
                            borderWidth={"1px"}
                            borderRadius={"md"}
                            borderStyle={"dashed"}
                            justify={"center"}
                            align={"center"}
                            direction={"column"}
                            gap={4}
                          >
                            <Text color={"gray.500"}>
                              No custom fonts uploaded yet
                            </Text>
                            <Button
                              size={"sm"}
                              colorScheme={"gray"}
                              onClick={() => setIsUploadFontModalOpen(true)}
                            >
                              Upload your first font
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Flex>
          </PageCardAgencySettings>
        </Flex>
      </Form>
      <UploadFontModal
        isOpen={isUploadFontModalOpen}
        onClose={() => setIsUploadFontModalOpen(false)}
      />
    </Box>
  );
}
