import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteIcon from "assets/icons/medium/delete.svg?react";
import FileIcon from "assets/icons/medium/file.svg?react";
import type { Font } from "modules/agency-settings/models/font-model";
import { fontModel } from "modules/agency-settings/models/font-model";
import { formatBytes } from "utils/string";

type UploadedFontCardProps = {
  font: Font;
};

export function UploadedFontCard({ font }: UploadedFontCardProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const deleteFontMutation = useMutation({
    ...fontModel.mutations.delete(),
    onSuccess: () => {
      toast({
        title: "Font deleted",
        description: "The font has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();

      queryClient.invalidateQueries({
        queryKey: fontModel.queries.list().queryKey,
      });
    },
    onError: () => {
      toast({
        title: "Delete failed",
        description: "There was an error deleting the font. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleDelete = async () => {
    await deleteFontMutation.mutateAsync({ id: font.id });
  };

  return (
    <>
      <Card>
        <CardBody p={4}>
          <Flex direction={"row"} gap={4} align={"center"}>
            <Box
              borderRadius={"8px"}
              h={"40px"}
              w={"40px"}
              bg={"gray.100"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon as={FileIcon} boxSize={"16px"} />
            </Box>
            <Flex direction={"column"} gap={0.5}>
              <Heading fontSize={"lg"} as={"h5"}>
                {font.name}
              </Heading>
              <Text color={"gray.600"}>{formatBytes(font.size)}</Text>
            </Flex>
            <IconButton
              colorScheme={"gray"}
              variant={"ghost"}
              aria-label={"Delete"}
              ml={"auto"}
              onClick={onOpen}
              isDisabled={deleteFontMutation.isPending}
            >
              <Icon as={DeleteIcon} boxSize={"16px"} color={"red.600"} />
            </IconButton>
          </Flex>
        </CardBody>
      </Card>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"} fontWeight={"bold"}>
              Delete Font
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the font &quot;{font.name}&quot;?
              This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={"red"}
                onClick={handleDelete}
                ml={3}
                isLoading={deleteFontMutation.isPending}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
