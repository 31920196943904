import { accordionTheme } from "theme/components/accordion-theme";
import { alertTheme } from "theme/components/alert-theme";
import { badgeTheme } from "theme/components/badge-theme";
import { buttonTheme } from "theme/components/button";
import { cardTheme } from "theme/components/card-theme";
import { closeButtonTheme } from "theme/components/close-button-theme";
import { formLabelTheme } from "theme/components/form-label-theme";
import { headingTheme } from "theme/components/heading-theme";
import { inputTheme } from "theme/components/input-theme";
import { menuTheme } from "theme/components/menu-theme";
import { modalTheme } from "theme/components/modal-theme";
import { selectTheme } from "theme/components/select-theme";
import { tableTheme } from "theme/components/table-theme";
import { tabsTheme } from "theme/components/tabs-theme";
import { tagTheme } from "theme/components/tag-theme";
import { textareaTheme } from "theme/components/textarea-theme";
import { tooltipTheme } from "theme/components/tooltip-theme";

const components = {
  Alert: alertTheme,
  Accordion: accordionTheme,
  Button: buttonTheme,
  Badge: badgeTheme,
  Card: cardTheme,
  CloseButton: closeButtonTheme,
  FormLabel: formLabelTheme,
  Heading: headingTheme,
  Input: inputTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Select: selectTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme,
};
export { components };
