import { Box, Image } from "@chakra-ui/react";
import rexRealEstateLogoDark from "assets/rex-real-estate-logo-dark.png";
import {
  getGreeting,
  PropertyBannerDetails,
} from "components/property-banner-image/property-banner-details";
import { useAuth } from "contexts/auth-provider";
import type { OverviewData } from "modules/landlord-portal/models/overview";
import { FinancialSnapshotBox } from "modules/landlord-portal/screens/overview/components/banner-image/financial-snapshot-box";
import { PropertyCountNumbers } from "modules/landlord-portal/screens/overview/components/banner-image/property-count-numbers";

type LandlordBannerImageProps = {
  isLoading: boolean;
  properties?: OverviewData["properties"];
  summary?: OverviewData["summary"];
};
export function LandlordBannerImage({
  isLoading,
  properties,
  summary,
}: LandlordBannerImageProps) {
  const { user } = useAuth();
  const currentHour = new Date().getHours();
  const greeting = getGreeting(currentHour);

  return (
    <PropertyBannerDetails
      isLoading={isLoading}
      title={`${greeting}, ${user?.given_name}!`}
      topLeftElement={
        <Image
          src={rexRealEstateLogoDark}
          alt={"Rex Real Estate"}
          w={"168px"}
        />
      }
      leftElement={<PropertyCountNumbers properties={properties} />}
      rightElement={
        <Box alignSelf={"stretch"} flex={1} flexBasis={{ base: "100%", xl: 0 }}>
          <FinancialSnapshotBox isLoading={isLoading} summary={summary} />
        </Box>
      }
    />
  );
}
