import { Center, Text } from "@chakra-ui/react";

export function TotalCountBadge({ count }: { count?: number }) {
  if (!count) return null;
  return (
    <Center py={0} px={1} bgColor={"gray.200"} borderRadius={"2px"}>
      <Text fontSize={"sm"} fontWeight={700} color={"gray.600"}>
        {count}
      </Text>
    </Center>
  );
}
