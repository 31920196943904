import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import blobGroup from "assets/blob-group.png";
import ReachLogo from "assets/icons/medium/reach-logo.svg?react";

export function ReachMarketingCta() {
  // TODO: handleOnClick for agent contact
  const handleOnClick = () => {
    return null;
  };
  return (
    <Center h={{ base: "378px", md: "578px" }} p={{ base: "10px", md: "80px" }}>
      <VStack zIndex={99} gap={{ base: "36px", md: "56px" }}>
        <HStack gap={{ base: 2, md: 4 }}>
          <Heading
            size={{ base: "lg", md: "3xl" }}
            fontWeight={700}
            whiteSpace={"nowrap"}
          >
            Boost your listings with
          </Heading>

          <Flex align={"center"} justify={"center"} gap={2}>
            <Icon as={ReachLogo} boxSize={{ base: "20px", md: "40px" }} />
            <Heading size={{ base: "lg", md: "3xl" }} fontWeight={700}>
              Reach
            </Heading>
          </Flex>
        </HStack>

        <Text fontSize={"lg"} textAlign={"center"}>
          Reach thousands of buyers, sellers and promote your listing in a few
          clicks. Through the 26+ real estate ads formats Rex Reach creates for
          Facebook, Instagram and millions of websites through the Google
          display network.
        </Text>
        <Button variant={"solid"} colorScheme={"gray"} onClick={handleOnClick}>
          Contact agent
        </Button>
      </VStack>
      <Box pos={"absolute"} bottom={0} right={0} w={"100%"}>
        <Image
          src={blobGroup}
          alt={"reach campaign background image"}
          w={"100%"}
        />
      </Box>
    </Center>
  );
}
