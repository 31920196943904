import { Flex, HStack, Image, Text } from "@chakra-ui/react";
import { PropertyAttributes } from "components/property-banner-image/property-attributes";
import type { Property } from "types/property";
import type { Tenancy } from "types/tenancy";
import { UNKNOWN_VALUE_MAP } from "utils/string";

type PropertyCellProps = {
  row: Property<{ tenancies: Tenancy[] }>;
};

export function PropertyCell({ row: property }: PropertyCellProps) {
  return (
    <Flex
      direction={"row"}
      gap={{ base: 3, xl: 6 }}
      align={"center"}
      minW={"250px"}
      py={2}
    >
      <Image
        src={property.related.ownership.agency_company_contact.logo?.url}
        alt={"Property image"}
        objectFit={"cover"}
        w={{ base: "30px", xl: "50px" }}
        h={{ base: "30px", xl: "50px" }}
        flexShrink={0}
        borderRadius={"100%"}
        overflow={"hidden"}
      />
      <Flex direction={"column"} gap={1.5} flex={1}>
        <Text fontSize={"lg"} fontWeight={"medium"}>
          {property.short_address}
        </Text>
        <HStack
          gap={3}
          color={"gray.600"}
          alignItems={["flex-start", "center"]}
          wrap={"wrap"}
          fontWeight={"medium"}
          divider={<>{"•"}</>}
        >
          <Text>{UNKNOWN_VALUE_MAP}</Text>
          <PropertyAttributes
            propertyCategoryId={property.property_category_id}
            propertyDetails={property.details}
          />
        </HStack>
      </Flex>
    </Flex>
  );
}
