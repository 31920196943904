import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import DollarIcon from "assets/icons/medium/dollar.svg?react";
import PersonIcon from "assets/icons/medium/person.svg?react";
import { HelpTooltipTrigger } from "components/help-tooltip-trigger";
import { DetailedPriceIndication } from "modules/vendor-portal/screens/overview/components/price-indication/detailed-price-indication";
import { MinimalPriceIndication } from "modules/vendor-portal/screens/overview/components/price-indication/minimal-price-indication";
import {
  deriveMinMaxAverage,
  groupPriceIndications,
} from "modules/vendor-portal/screens/overview/components/price-indication/utils";

const TOOLTIP_LABEL =
  "This chart shows a price indication for each person who has given one for your listing during this period.";

type PriceIndicationProps = {
  priceAdvertiseAs: string;
  priceIndications: number[];
  isLoading: boolean;
};

export function PriceIndication({
  priceAdvertiseAs,
  priceIndications,
  isLoading,
}: PriceIndicationProps) {
  const priceRange = deriveMinMaxAverage(priceIndications);
  return (
    <Card h={"100%"} variant={{ base: "unstyled", md: "outline" }}>
      <CardHeader mb={{ base: 6, md: 0 }}>
        <HStack gap={0}>
          <Heading size={"md"}>Price Indication</Heading>
          <Tooltip
            hasArrow
            placement={"top"}
            label={TOOLTIP_LABEL}
            bg={"gray.900"}
            color={"white"}
          >
            <HelpTooltipTrigger />
          </Tooltip>
        </HStack>
      </CardHeader>
      <CardBody>
        <Flex direction={"column"} gap={5} h={"100%"} justify={"center"}>
          <Skeleton
            isLoaded={!isLoading}
            display={"flex"}
            h={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {priceIndications.length > 4 ? (
              <DetailedPriceIndication
                priceRange={priceRange}
                priceGroups={groupPriceIndications(
                  priceIndications,
                  priceRange,
                )}
              />
            ) : (
              <MinimalPriceIndication priceRange={priceRange} />
            )}
          </Skeleton>

          <Flex direction={"row"} justify={"space-between"} align={"center"}>
            <Flex direction={"row"} align={"center"} gap={1}>
              <Icon as={DollarIcon} color={"gray.600"} boxSize={"12px"} />
              <Skeleton isLoaded={!isLoading}>
                <Text fontSize={"sm"} color={"gray.600"}>
                  {`Asking price: ${priceAdvertiseAs}`}
                </Text>
              </Skeleton>
            </Flex>
            <Flex direction={"row"} align={"center"} gap={1}>
              <Icon as={PersonIcon} color={"gray.600"} boxSize={"12px"} />
              <Skeleton isLoaded={!isLoading}>
                <Text fontSize={"sm"} color={"gray.600"}>
                  {`Result from ${priceIndications.length} responses`}
                </Text>
              </Skeleton>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
