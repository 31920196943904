import { Box, VStack } from "@chakra-ui/react";
import PencilIcon from "assets/icons/medium/pencil.svg?react";
import { LeftNavigationMenuItem } from "components/left-navigation-menu/components/left-navigation-menu-item";
import { TOP_NAVIGATION_BAR_HEIGHT } from "components/top-navigation-bar";
import { AGENCY_SETTINGS_PATHS } from "modules/agency-settings/paths";

export function AgencySettingsNavigationMenu() {
  return (
    <>
      <Box
        w={{ base: "100%", lg: "320px" }}
        bg={"indigo.50"}
        pos={"fixed"}
        top={0}
        bottom={0}
        p={{ base: 4, md: 6 }}
        pt={{ base: 4, md: 10 }}
        mt={TOP_NAVIGATION_BAR_HEIGHT}
        display={"flex"}
        flexDir={"column"}
        alignItems={"space-between"}
        overflowY={{ base: "scroll", lg: "visible" }}
        zIndex={999}
        gap={6}
      >
        <Box>
          <VStack spacing={1}>
            <LeftNavigationMenuItem
              path={AGENCY_SETTINGS_PATHS.APPEARANCE}
              label={"Appearance"}
              Icon={PencilIcon}
            />
          </VStack>
        </Box>
      </Box>
    </>
  );
}
