import { mockContact, type Contact } from "types/contact";
import { mockProperty, type Property } from "types/property";
import { mockTenancy, type Tenancy } from "types/tenancy";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type OverviewData = {
  tenancy: Tenancy;
  property: Property;
  agency: Contact;
};

export const tenancyOverviewModel = createModel("tenancyOverview", {
  queries: {
    read: (propertyId?: string) => ({
      queryKey: ["read", propertyId],
      queryFn: () => {
        return apiClient.get<OverviewData>(`/tenant/${propertyId}/overview`);
      },
      enabled: !!propertyId,
      keepPreviousData: true,
    }),
  },
});

export const mockTenancyOverviewResponse = () => {
  return {
    tenancy: mockTenancy(),
    property: mockProperty(),
    agency: mockContact(),
  };
};
