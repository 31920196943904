import type { ComponentProps, ReactNode } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Card,
  Flex,
  Icon,
  Text,
  type Button,
  type CardProps,
} from "@chakra-ui/react";

type CardRecordProps = {
  heading?: string;
  leftElement?: ReactNode;
  renderCallToActionButton?: (
    props: Partial<ComponentProps<typeof Button>>,
  ) => ReactNode;
  children?: ReactNode;
} & CardProps;
export function CardRecord({
  heading,
  leftElement,
  renderCallToActionButton,
  children,
  ...rest
}: CardRecordProps) {
  return (
    <Card
      display={"flex"}
      direction={{ base: "column", sm: "row" }}
      gap={4}
      pt={leftElement ? 4 : 6}
      pr={{ base: 0, md: 6 }}
      pb={4}
      pl={{ base: 0, md: leftElement ? 3 : 6 }}
      variant={{ base: "unstyled", md: "outline" }}
      justifyContent={"space-between"}
      {...rest}
    >
      <Flex wrap={["wrap", "wrap", "nowrap"]} gap={4} flex={1}>
        {leftElement}

        <Flex direction={"column"} gap={2} flex={1}>
          {heading ? (
            <Text
              textStyle={"large"}
              fontWeight={"semibold"}
              color={"gray.700"}
            >
              {heading}
            </Text>
          ) : null}
          {children}
        </Flex>
      </Flex>

      {renderCallToActionButton ? (
        <Flex pt={{ md: 0.5 }} flex={[1, 1, 0]}>
          {renderCallToActionButton({
            variant: ["outline", "link"],
            colorScheme: "gray",
            color: ["unset", "gray.700"],
            alignSelf: ["auto", "flex-start"],
            flex: 1,
            width: "100%",
            rightIcon: (
              <Icon
                display={["none", "block"]}
                ml={-1}
                as={ChevronRightIcon}
                boxSize={4}
              />
            ),
          })}
        </Flex>
      ) : null}
    </Card>
  );
}
