import CalendarIcon from "assets/icons/medium/calendar.svg?react";
import DisbursementsIcon from "assets/icons/medium/disbursements.svg?react";
import HandbookIcon from "assets/icons/medium/handbook.svg?react";
import HomeIcon from "assets/icons/medium/house.svg?react";
import SpannerIcon from "assets/icons/medium/spanner.svg?react";
import type { MenuItem } from "components/left-navigation-menu/components/left-navigation-menu";
import { LeftNavigationMenu } from "components/left-navigation-menu/components/left-navigation-menu";
import { TENANT_PORTAL_PATHS } from "modules/tenant-portal/paths";

const menuItems: MenuItem[] = [
  {
    label: "Overview",
    path: TENANT_PORTAL_PATHS.OVERVIEW,
    Icon: HomeIcon,
  },
  {
    label: "Maintenance",
    path: TENANT_PORTAL_PATHS.MAINTENANCE,
    Icon: SpannerIcon,
  },
  {
    label: "Inspections",
    path: TENANT_PORTAL_PATHS.INSPECTIONS,
    Icon: CalendarIcon,
  },
  {
    label: "Payments",
    path: TENANT_PORTAL_PATHS.PAYMENTS,
    Icon: DisbursementsIcon,
  },
  {
    label: "Handbook",
    path: TENANT_PORTAL_PATHS.HANDBOOK,
    Icon: HandbookIcon,
  },
];

export function LeftNavigation() {
  return <LeftNavigationMenu items={menuItems} />;
}
