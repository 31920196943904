import { defineStyleConfig } from "@chakra-ui/styled-system";
import { cssVar } from "@chakra-ui/theme-tools";

const $arrowBg = cssVar("popper-arrow-bg");

export const tooltipTheme = defineStyleConfig({
  baseStyle: {
    bgColor: "gray.900",
    borderRadius: "base",
    p: 2,
    fontSize: "md",
    [$arrowBg.variable]: "colors.gray.900",
  },
});
