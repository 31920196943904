/*
 Takes a number and formats it as an ordinal number ie, 1st, 2nd, 3rd, 4th, etc.
 */
export function numberToOrdinal(number: number): string {
  const suffixes: Record<string, string> = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };

  const pluralRules = new Intl.PluralRules("en", { type: "ordinal" });
  const pluralRule = pluralRules.select(number);

  const suffix = suffixes[pluralRule] || suffixes["other"];

  return `${number}${suffix}`;
}
