import { faker } from "@faker-js/faker";
import { uniqueArray } from "lib/enforce-unique";
import type { Listing } from "modules/vendor-portal/types/listing";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type DocumentsScreenData = {
  listing: Listing;
};

export const documentsScreenModel = createModel("documents", {
  queries: {
    read: (page) => ({
      queryKey: ["read", page],
      queryFn: () =>
        apiClient.get<DocumentsScreenData>(`/documents?page=${page}`),
      keepPreviousData: true,
    }),
  },
});

export const mockDocumentsResponse = (
  params: URLSearchParams,
  pageSize: number = 10,
) => {
  const page = Number(params.get("page"));
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const documents = Array.from({ length: 5 }, () => ({
    id: faker.string.uuid(),
    description: uniqueArray([
      "VPA Receipt 241222",
      "Standard Listing Agreement",
      "What is Rex Reach?",
      "Body Corporate Disclosure Statement",
      "Building Report",
    ]),
    privacy: {
      id: "public",
      text: "Public",
    },
    type: {
      id: "other",
      text: "Other",
    },
    upload_date: faker.date.recent(),
    uri: faker.internet.url(),
    url: "https://au-crm.cdns.rexsoftware.com/app/livestore/accounts/77/listings/3909419/documents/dummy_15da-0c95-6eff-c866-f759-7e3e-fe1f-97ee_20240220050917.pdf",
  })).slice(startIndex, endIndex);

  return {
    listing: {
      id: faker.string.uuid(),
      _related: {
        listing_documents: {
          data: documents,
          meta: {
            current_page: page,
            first_page_url: "/documents?page=1",
            from: 1,
            last_page: 3,
            last_page_url: "/documents?page=3",
            next_page_url: page < 3 ? `/documents?page=${page + 1}` : "",
            prev_page_url: page > 1 ? `/documents?page=${page - 1}` : "",
            path: "",
            per_page: 10,
            to: 0,
            total: 30,
          },
        },
      },
    },
  };
};
