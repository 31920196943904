import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

export const cardTheme = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
).defineMultiStyleConfig({
  defaultProps: {
    variant: "outline",
  },
});
