import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

export const tagTheme = createMultiStyleConfigHelpers(
  tagAnatomy.keys,
).defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: "base",
    },
  },
});
