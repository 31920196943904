import type { ReactNode } from "react";
import { Flex, Icon } from "@chakra-ui/react";
import PersonIcon from "assets/icons/medium/person.svg?react";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { FeedbackTypeTag } from "modules/vendor-portal/components/feedback-type-tag";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type { APIListing } from "modules/vendor-portal/types/api-listing";

type PrivateInspectionEventFooterProps = {
  event: NonNullable<APIListing["events"]>[number];
  children?: ReactNode;
};
export function PrivateInspectionEventFooter({
  event,
  children,
}: PrivateInspectionEventFooterProps) {
  return (
    <CardRecordMeta>
      <FeedbackTypeTag feedbackType={event.type} />
      <FooterText
        whiteSpace={"normal"}
        text={`Hosted by ${event.feedback?.agent.name}`}
      />
      <Flex direction={"row"} align={"center"} gap={1}>
        <Icon as={PersonIcon} boxSize={"12px"} />
        <FooterText text={`${event.feedback?.attendee_count}`} />
      </Flex>
      {children}
    </CardRecordMeta>
  );
}
