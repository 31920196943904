import { Flex, Icon, Text } from "@chakra-ui/react";
import BedIcon from "assets/icons/medium/bed.svg?react";
import { VacancyStatus, type Property } from "types/property";
import type { Tenancy } from "types/tenancy";

type PropertyCountNumbersProps = {
  properties?: Property<{ tenancies: Tenancy[] }>[];
};

export function PropertyCountNumbers({
  properties = [],
}: PropertyCountNumbersProps) {
  const total = properties.length;
  const tenanted = properties.filter(
    (p) => p.vacancy_status_id === VacancyStatus.Occupied,
  ).length;
  const vacant = properties.filter(
    (p) => p.vacancy_status_id === VacancyStatus.Vacant,
  ).length;

  return (
    <Flex direction={["column", "row"]} gap={[2, 4]}>
      <Flex direction={"row"} align={"center"} gap={1.5}>
        <Icon as={BedIcon} />

        <Text fontSize={"md"} fontWeight={600}>
          {total} {total === 1 ? "property" : "properties"}
        </Text>
      </Flex>
      <Flex direction={"row"} align={"center"} gap={1.5}>
        <Icon as={BedIcon} />

        <Text fontSize={"md"} fontWeight={600}>
          {tenanted} tenanted
        </Text>
      </Flex>
      <Flex direction={"row"} align={"center"} gap={1.5}>
        <Icon as={BedIcon} />

        <Text fontSize={"md"} fontWeight={600}>
          {vacant} vacant
        </Text>
      </Flex>
    </Flex>
  );
}
