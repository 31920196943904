import { forwardRef } from "react";
import type { IconProps } from "@chakra-ui/icons";
import { chakra, Icon } from "@chakra-ui/react";
import InfoIcon from "assets/icons/medium/info.svg?react";

export const HelpTooltipTrigger = forwardRef((props: IconProps, ref: any) => {
  return (
    <chakra.span
      p={2}
      mb={-1}
      mt={-1}
      cursor={"pointer"}
      _hover={{ opacity: 0.7 }}
      ref={ref}
      {...(props as any)}
    >
      <Icon
        as={InfoIcon}
        color={"gray.600"}
        boxSize={"12px"}
        cursor={"pointer"}
      />
    </chakra.span>
  );
});

HelpTooltipTrigger.displayName = "HelpTooltipTrigger";
