import { HStack, Image, Text, VStack } from "@chakra-ui/react";

type AgentFeedbackSectionProps = {
  profileImage?: string;
  bodyText?: string;
  agentName?: string;
};

export function AgentFeedbackSection({
  profileImage,
  bodyText,
  agentName,
}: AgentFeedbackSectionProps) {
  return (
    <VStack gap={{ base: 10, sm: 12, md: 14 }}>
      <VStack gap={8}>
        <Image
          borderRadius={"full"}
          boxSize={"120px"}
          src={profileImage}
          alt={"Agent image"}
          objectFit={"cover"}
        />

        <Text fontSize={"2xl"} fontWeight={500}>
          Message from {agentName}
        </Text>
      </VStack>
      <HStack>
        <Text
          fontSize={{ base: "lg", md: "xl" }}
          fontWeight={400}
          maxW={"1200px"}
          px={{ base: 0, md: 10, lg: 20, xl: "200px", "2xl": "300px" }}
        >
          {bodyText}
        </Text>
      </HStack>
    </VStack>
  );
}
