import { faker } from "@faker-js/faker";
import { fakerDateUnix } from "lib/faker";
import type { Timestamps } from "types/api";

export type File = {
  id: string;
  name: string;
  url: string;
  download_url: string;
  type: string;
  size: number;
} & Timestamps;

export function mockFile(): File {
  return {
    id: faker.string.uuid(),
    name: faker.lorem.sentence(),
    url: faker.internet.url(),
    download_url:
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    type: faker.helpers.arrayElement(["image", "pdf", "docx"]),
    size: faker.number.int(1000),
    created_at: fakerDateUnix(),
    updated_at: fakerDateUnix(),
  };
}
