import { Flex, Text } from "@chakra-ui/react";
import CommentIcon from "assets/icons/medium/comment.svg?react";
import DollarIcon from "assets/icons/medium/dollar.svg?react";
import { FeedbackHeader } from "modules/vendor-portal/screens/overview/components/feedback/feedback-header";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import { FeedbackListItemFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/feedback-list-item-footer";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";

type FeedbackListItemProps = {
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry;
};

export function FeedbackListItem({ feedback }: FeedbackListItemProps) {
  return (
    <Flex direction={"row"} gap={2} justify={"flex-start"}>
      <FeedbackIcon
        icon={feedback.type.id === "price_reduction" ? DollarIcon : CommentIcon}
      />

      <Flex direction={"column"} gap={2}>
        <FeedbackHeader feedback={feedback} />
        <Flex direction={"column"} gap={3}>
          <Text textStyle={"large"} whiteSpace={"pre-line"} color={"gray.600"}>
            {feedback.note}
          </Text>
          <FeedbackListItemFooter feedback={feedback} />
        </Flex>
      </Flex>
    </Flex>
  );
}
