import dayjs from "dayjs";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";
import { dateFormats } from "utils/date";

type AgentVendorFooterProps = {
  feedback:
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry
    | APIListingApplicantFeedbackEntry;
};

export function AgentVendorFooter({ feedback }: AgentVendorFooterProps) {
  if (!("date_of" in feedback)) {
    return null;
  }

  return (
    <CardRecordMeta>
      <FooterText
        text={dayjs(feedback.date_of).format(dateFormats.dayMonthYear)}
      />
    </CardRecordMeta>
  );
}
