import type { ReactNode } from "react";
import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import {
  PIE_CHART_CONTAINER_HEIGHT,
  PIE_CHART_CONTAINER_WIDTH,
} from "modules/vendor-portal/screens/overview/components/listing-performance/constants";

type PieChartSkeletonProps = {
  isLoaded: boolean;
  children: ReactNode;
};
export function PieChartSkeleton({
  isLoaded,
  children,
}: PieChartSkeletonProps) {
  if (isLoaded) return children;
  return (
    <Flex
      direction={"row"}
      h={"100%"}
      wrap={"wrap"}
      align={"center"}
      justify={"space-around"}
      gap={4}
    >
      <SkeletonCircle
        w={`${PIE_CHART_CONTAINER_WIDTH}px`}
        h={`${PIE_CHART_CONTAINER_HEIGHT}px`}
      />
      <Flex direction={"column"} gap={4} w={"210px"} align={"flex-end"}>
        <Skeleton h={3} w={"30%"} />
        <Skeleton h={3} w={"100%"} />
        <Skeleton h={3} w={"100%"} />
        <Skeleton h={3} w={"100%"} />
        <Skeleton h={3} w={"100%"} />
      </Flex>
    </Flex>
  );
}
