import { Flex, Skeleton, SkeletonText } from "@chakra-ui/react";

export function StatsCardSkeleton({
  hasBorders,
  hasIcons,
}: {
  hasBorders?: boolean;
  hasIcons?: boolean;
}) {
  return (
    <Flex
      direction={"row"}
      align={"flex-start"}
      justify={"flex-start"}
      h={"100%"}
      columnGap={"12px"}
      p={hasBorders ? 4 : 0}
      m={hasBorders ? "1px" : 0}
    >
      {hasIcons ? (
        <Skeleton
          minW={{ base: "24px", sm: "40px" }}
          minH={{ base: "24px", sm: "40px" }}
          borderRadius={"8px"}
        />
      ) : null}

      <Flex direction={"column"} gap={2} flex={1}>
        <SkeletonText noOfLines={1} spacing={2} skeletonHeight={6} w={"50%"} />
        <SkeletonText noOfLines={1} skeletonHeight={3} w={"70%"} />
      </Flex>
    </Flex>
  );
}
