import { defineStyleConfig } from "@chakra-ui/styled-system";

export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: "semibold",
  },
  sizes: {
    md: {
      fontSize: "lg",
    },
  },
});
