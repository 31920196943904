import { Box, Flex, Icon, Text, type As } from "@chakra-ui/react";
import type { Stats } from "components/stats-grid";

type StatsCardProps = {
  stat: Stats;
  icon?: As;
  iconColor?: string;
  iconBgColor?: string;
};

export const generateSingularLabel = (pluralLabel: string, value: number) => {
  if (value !== 1) {
    return pluralLabel;
  }

  switch (pluralLabel) {
    case "Hot buyers":
      return "Hot buyer";
    case "Warm buyers":
      return "Warm buyer";
    case "Total attendees":
      return "Total attendee";
    case "Private inspections":
      return "Private inspection";
    case "Open homes":
      return "Open home";
    case "Who have attended twice":
      return "Has attended twice";
    case "Total ad clicks":
      return "Total ad click";
    case "Enquiries":
      return "Enquiry";
    case "Newsletters sent":
      return "Newsletter sent";
    case "Days since listing the property":
      return "Day since listing the property";
    default:
      return pluralLabel;
  }
};

export function StatsCard({
  stat,
  icon,
  iconColor = "gray.700",
  iconBgColor = "indigo.100",
}: StatsCardProps) {
  return (
    <Flex
      direction={"row"}
      align={"flex-start"}
      justify={"flex-start"}
      h={"100%"}
      columnGap={"12px"}
    >
      {icon ? (
        <Box
          minW={{ base: "24px", sm: "40px" }}
          minH={{ base: "24px", sm: "40px" }}
          borderRadius={"8px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={{ base: "none", sm: iconBgColor }}
        >
          <Icon as={icon} boxSize={"16px"} color={iconColor} />
        </Box>
      ) : null}
      <Flex direction={"column"}>
        <Text fontSize={"2xl"} fontWeight={700} lineHeight={"28px"}>
          {stat.value}
        </Text>
        <Text
          fontSize={"sm"}
          color={"gray.600"}
          fontWeight={400}
          lineHeight={"16px"}
        >
          {generateSingularLabel(stat.label, stat.value as number)}
        </Text>
      </Flex>
    </Flex>
  );
}
