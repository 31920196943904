export enum FeedbackTypeId {
  Enquiry = "enquiry",
  Inspection = "inspection",
  OFI = "ofi",
  FollowUp = "followup",
  AgentVendor = "agent_vendor",
  PriceReduction = "price_reduction",
}

export enum FeedbackTypeText {
  Enquiry = "Enquiry",
  Inspection = "Private Inspection",
  OFI = "OFI",
  FollowUp = "Follow-Up",
  AgentVendor = "Agent Vendor",
  PriceReduction = "Price Reduction",
}

export enum InterestLevelId {
  Cold = "cold",
  Warm = "warm",
  Hot = "hot",
}

export enum InterestLevelText {
  Cold = "Cold",
  Warm = "Warm",
  Hot = "Hot",
}

export type InterestLevel = { id: InterestLevelId; text: InterestLevelText };
export type FeedbackType = { id: FeedbackTypeId; text: FeedbackTypeText };
export type EnquirySource = { id: string; text: string };
export type OpenHomeEvent = { id: string; calendar_event_id: string | null };

export type DefaultFeedback = {
  id: string;
  type: FeedbackType;
  date_of: string;
  is_new: boolean;
  note: string;
  agent: { id: string; name: string; profile_image?: { url: string } | null };
};

export type AgentVendorFeedback = DefaultFeedback;

export type EnquiryFeedback = {
  enquiry_source: EnquirySource | null;
  price_indication: number | null;
  interest_level: InterestLevel | null;
  number_of_people: number;
  related_contacts: { id: string; name: string }[];
} & DefaultFeedback;

export type FollowUpFeedback = {
  enquiry_source: EnquirySource | null;
  price_indication: number | null;
  interest_level: InterestLevel | null;
  number_of_people: number;
  related_contacts: { id: string; name: string }[];
} & DefaultFeedback;

export type PrivateInspectionFeedback = {
  enquiry_source: EnquirySource | null;
  price_indication: number | null;
  interest_level: InterestLevel | null;
  number_of_people: number;
  attendees: { id: string; name: string; ofi_visits: number }[];
} & DefaultFeedback;

export type OpenHomeFeedback = {
  avg_price_indication: number | null;
  interest_level?: InterestLevel | null;
  date_time_start: string;
  date_time_finish: string;
  number_of_people: number;
  attendees: { id: string; name: string; ofi_visits: number }[];
  open_home_event: OpenHomeEvent;
  individual_feedback: IndividualAttendeeFeedback[];
} & DefaultFeedback;

export type IndividualOpenHomeFeedback = Omit<
  OpenHomeFeedback,
  "avg_price_indication"
> & {
  price_indication: number | null;
  interest_level?: InterestLevel | null;
};

export type PriceReductionFeedback = {
  price_previous_match: number;
  price_new_match: number;
  price_previous_advertising: number;
  price_new_advertising: number;
} & DefaultFeedback;

export type Feedback =
  | AgentVendorFeedback
  | PrivateInspectionFeedback
  | EnquiryFeedback
  | FollowUpFeedback
  | IndividualOpenHomeFeedback
  | PriceReductionFeedback;

export type IndividualAttendeeFeedback = {
  id: string;
  name: string;
  enquiry_source: EnquirySource | null;
  price_indication: number | null;
  interest_level: InterestLevel | null;
  note: string;
};
