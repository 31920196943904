import type { FinancialBarDataRow } from "modules/landlord-portal/screens/financial/components/financial-bar-chart/financial-bar-chart";

export const loadingSkeletonData: FinancialBarDataRow[] = [
  {
    date: "2023-01-01",
    money_in: 2000,
    money_out: 1100,
  },
  {
    date: "2023-02-01",
    money_in: 2200,
    money_out: 1300,
  },
  {
    date: "2023-03-01",
    money_in: 2500,
    money_out: 1700,
  },
  {
    date: "2023-04-01",
    money_in: 2500,
    money_out: 1400,
  },
  {
    date: "2023-05-01",
    money_in: 2500,
    money_out: 1400,
  },
  {
    date: "2023-06-01",
    money_in: 2500,
    money_out: 1400,
  },
];
