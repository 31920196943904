import { Flex, Heading, Icon, Image, Text } from "@chakra-ui/react";
import EmailIcon from "assets/icons/medium/email.svg?react";
import MapPinIcon from "assets/icons/medium/map-pin.svg?react";
import PhoneIcon from "assets/icons/medium/phone.svg?react";
import { PageCard } from "components/page-card";
import type { Contact } from "types/contact";

type AgencyDetailsProps = {
  agency?: Contact;
};

export function AgencyDetails({ agency }: AgencyDetailsProps) {
  return (
    <PageCard heading={"Agency details"}>
      <Flex direction={["column", "column", "row"]} columnGap={12} rowGap={8}>
        <Flex align={"center"} justify={["flex-start", "flex-start", "center"]}>
          {agency?.logo?.url ? (
            <Image
              src={agency?.logo?.url}
              w={"100%"}
              minW={"80px"}
              maxW={"140px"}
            />
          ) : null}
        </Flex>
        <Flex direction={"column"} gap={2} maxW={80}>
          <Heading size={"md"}>{agency?.display_name}</Heading>
          <Flex direction={"row"} align={"center"} gap={1}>
            <Icon as={MapPinIcon} boxSize={"12px"} />
            <Text fontSize={"lg"} color={"gray.600"}>
              {agency?.primary_address}
            </Text>
          </Flex>
        </Flex>
        <Flex direction={"column"} gap={2} maxW={80}>
          <Heading size={"md"}>Contact</Heading>
          <Flex direction={"row"} align={"center"} gap={1}>
            <Icon as={PhoneIcon} boxSize={"12px"} mr={1} />
            <Text fontSize={"lg"} color={"gray.600"}>
              {agency?.primary_phone}
            </Text>
          </Flex>
          <Flex direction={"row"} align={"center"} gap={1}>
            <Icon as={EmailIcon} boxSize={"12px"} />
            <Text
              fontSize={"lg"}
              color={"gray.600"}
              wordBreak={"break-all"}
              overflowWrap={"break-word"}
            >
              {agency?.primary_email}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </PageCard>
  );
}
