import { AppLayout } from "components/app-layout";
import { RequireAuthRole } from "contexts/auth-provider";
import type { Route } from "lib/react-router-dom";
import { LeftNavigation } from "modules/tenant-portal/components/left-navigation";
import { TENANT_PORTAL_PATHS } from "modules/tenant-portal/paths";
import { HandbookScreen } from "modules/tenant-portal/screens/handbook/handbook-screen";
import { InspectionsScreen } from "modules/tenant-portal/screens/inspections/inspections-screen";
import { MaintenanceScreen } from "modules/tenant-portal/screens/maintenance/maintenance-screen";
import { OverviewScreen } from "modules/tenant-portal/screens/overview/overview-screen";
import { PaymentsScreen } from "modules/tenant-portal/screens/payments/payments-screen";
import { RequestMaintenanceScreen } from "modules/tenant-portal/screens/request-maintenance/request-maintenance-screen";

export const TENANT_PORTAL_ROUTES: Route[] = [
  {
    path: TENANT_PORTAL_PATHS.DEFAULT,
    handle: {
      title: "Tenant",
    },
    element: (
      <RequireAuthRole anyOf={["tenant", "agent", "agent_manager"]}>
        <AppLayout leftNavigationMenu={<LeftNavigation />} />
      </RequireAuthRole>
    ),
    children: [
      {
        index: true,
        Component: OverviewScreen,
        handle: {
          title: "Overview",
        },
      },
      {
        path: TENANT_PORTAL_PATHS.MAINTENANCE,
        Component: MaintenanceScreen,
        handle: {
          title: "Maintenance",
        },
      },
      {
        path: TENANT_PORTAL_PATHS.REQUEST_MAINTENANCE,
        Component: RequestMaintenanceScreen,
        handle: {
          title: "Request Maintenance",
        },
      },
      {
        path: TENANT_PORTAL_PATHS.INSPECTIONS,
        Component: InspectionsScreen,
        handle: {
          title: "Inspections",
        },
      },
      {
        path: TENANT_PORTAL_PATHS.PAYMENTS,
        Component: PaymentsScreen,
        handle: {
          title: "Payments",
        },
      },
      {
        path: TENANT_PORTAL_PATHS.HANDBOOK,
        Component: HandbookScreen,
        handle: {
          title: "Handbook",
        },
      },
    ],
  },
];
