import { faker } from "@faker-js/faker";
import dayjs from "dayjs";
import { mockContact, type Contact } from "types/contact";
import type { LandlordFinancialsSummary } from "types/landlord-financials";
import { mockPropertyWithTenancies, type Property } from "types/property";
import { type Tenancy } from "types/tenancy";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type OverviewData = {
  properties: Property<{ tenancies: Tenancy[] }>[];
  summary: LandlordFinancialsSummary;
  agency: Contact;
};

export const landlordOverviewModel = createModel("landlordOverview", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: () => {
        return apiClient.get<OverviewData>(`/landlord/overview`);
      },
      keepPreviousData: true,
    }),
  },
});

export const mockLandlordOverviewResponse = () => {
  return {
    properties: Array.from({ length: 4 }).map(() =>
      mockPropertyWithTenancies(),
    ),
    agency: mockContact(),
    summary: {
      period_start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      period_end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      opening_balance: faker.number.int({ min: 2000, max: 10000 }),
      money_in: faker.number.int({ min: 100, max: 1000 }),
      money_out: faker.number.int({ min: 100, max: 1000 }),
      net_balance: faker.number.int({ min: 100, max: 1000 }),
      bills_outstanding: faker.number.int({ min: 100, max: 1000 }),
    },
  };
};
