import { mockInspectionTask, type InspectionTask } from "types/inspection-task";
import { mockProperty, type Property } from "types/property";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type InspectionsData = {
  inspectionTasks: InspectionTask[];
  property: Property;
};

export const tenancyInspectionsModel = createModel("tenancyInspections", {
  queries: {
    read: (propertyId?: string) => ({
      queryKey: ["read", propertyId],
      queryFn: () => {
        return apiClient.get<InspectionsData>(
          `/tenant/${propertyId}/inspections`,
        );
      },
      enabled: !!propertyId,
      keepPreviousData: true,
    }),
  },
});

export const mockInspectionsResponse = () => {
  return {
    inspectionTasks: Array.from({ length: 12 }).map(() => mockInspectionTask()),
    property: mockProperty(),
  };
};
