import type { BarCustomLayerProps } from "@nivo/bar";
import type { FinancialBarDataRow } from "modules/landlord-portal/screens/financial/components/financial-bar-chart/financial-bar-chart";

export function BarLabels({ bars }: BarCustomLayerProps<FinancialBarDataRow>) {
  const TOP_PADDING = 14;
  const LEFT_PADDING = 5;

  return (
    <g>
      {bars.map(({ width, x, y, data: { formattedValue } }) => {
        const finalX = x + width / 2 + LEFT_PADDING;
        const finalY = y + TOP_PADDING;
        return (
          <text
            key={`${finalX}.${finalY}`}
            x={finalX}
            y={finalY}
            textAnchor={"end"}
            style={{
              fontWeight: 500,
              rotate: "-90deg",
              transformOrigin: `${finalX}px ${finalY}px`,
            }}
          >
            {formattedValue}
          </text>
        );
      })}
    </g>
  );
}
