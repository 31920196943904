import { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Skeleton,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { HelpTooltipTrigger } from "components/help-tooltip-trigger";
import { SkeletonGrid } from "components/skeleton-grid";
import { PercentageBar } from "modules/vendor-portal/components/percentage-bar";
import type { EnquirySourceStat } from "modules/vendor-portal/screens/performance/models/performance";

const TOOLTIP_LABEL =
  "This shows a breakdown of the sources of enquiry for your property while it has been on the market.\n" +
  "\n" +
  "The specific sources of enquiry can give a helpful indication of where to invest in further, if appropriate for your marketing strategy.";

const COLUMNS = { base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" };
const ROWS = { base: "repeat(6, 1fr)", lg: "repeat(3, 1fr)" };

type EnquiryStatsProps = {
  stats?: EnquirySourceStat[];
  isLoading: boolean;
};
export function EnquiryStats({ isLoading, stats }: EnquiryStatsProps) {
  const [displayPercentage, setDisplayPercentage] = useState(true);
  const total = stats?.reduce((acc, curr) => acc + curr.value, 0) ?? 0;

  return (
    <Card h={"100%"} variant={{ base: "unstyled", md: "outline" }}>
      <CardHeader mb={{ base: 6, md: 0 }}>
        <Flex justify={"space-between"}>
          <HStack gap={0}>
            <Heading size={"md"}>Enquiry Source</Heading>
            <Tooltip
              hasArrow
              placement={"top"}
              label={TOOLTIP_LABEL}
              bg={"gray.900"}
              color={"white"}
            >
              <HelpTooltipTrigger />
            </Tooltip>
          </HStack>
          <HStack>
            <Text size={"sm"}>{"Qty"}</Text>
            <Switch
              colorScheme={"switch"}
              size={"sm"}
              isChecked={displayPercentage}
              onChange={() => setDisplayPercentage(!displayPercentage)}
            />
            <Text size={"sm"}>{"%"}</Text>
          </HStack>
        </Flex>
      </CardHeader>
      <CardBody>
        <SkeletonGrid
          isLoaded={!isLoading}
          gridSize={6}
          skeleton={<Skeleton h={"46px"} w={"100%"} />}
          templateColumns={COLUMNS}
          templateRows={ROWS}
        >
          <Grid templateColumns={COLUMNS} templateRows={ROWS} gap={6}>
            {stats?.map((stat) => (
              <GridItem key={stat.id} w={"100%"}>
                <PercentageBar
                  label={stat.label}
                  percentage={
                    stat.value > 0 ? Math.round((stat.value / total) * 100) : 0
                  }
                  count={displayPercentage ? null : stat.value}
                />
              </GridItem>
            ))}
          </Grid>
        </SkeletonGrid>
      </CardBody>
    </Card>
  );
}
