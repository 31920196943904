export const LANDLORD_PORTAL_PATHS = {
  DEFAULT: "/landlord",
  OVERVIEW: "",
  OVERVIEW_PROPERTY_DETAILS: ":propertyId",
  FINANCIALS: "financials",
  MAINTENANCE: "maintenance",
  INSPECTIONS: "inspections",
  INSPECTION_DETAILS: "inspections/:inspectionId",
  DOCUMENTS: "documents",
} as const;
