import { Navigate } from "react-router-dom";
import { AppLayout } from "components/app-layout";
import { RequireAuthRole } from "contexts/auth-provider";
import type { Route } from "lib/react-router-dom";
import { AgencySettingsNavigationMenu } from "modules/agency-settings/components/agency-settings-navigation-menu/agency-settings-navigation-menu";
import { AGENCY_SETTINGS_PATHS } from "modules/agency-settings/paths";
import { AppearanceScreen } from "modules/agency-settings/screens/appearance/appearance-screen";

export const AGENCY_SETTINGS_ROUTES: Route[] = [
  {
    path: AGENCY_SETTINGS_PATHS.DEFAULT,
    handle: {
      title: "Agency Settings",
    },
    element: (
      <RequireAuthRole anyOf={["agent_manager"]}>
        <AppLayout leftNavigationMenu={<AgencySettingsNavigationMenu />} />
      </RequireAuthRole>
    ),
    children: [
      {
        index: true,
        Component: () => <Navigate to={AGENCY_SETTINGS_PATHS.APPEARANCE} />,
      },
      {
        path: AGENCY_SETTINGS_PATHS.APPEARANCE,
        Component: AppearanceScreen,
        handle: {
          title: "Appearance",
        },
      },
      {
        path: AGENCY_SETTINGS_PATHS.FEATURE_PREFERENCES,
        Component: () => <div>Feature Preferences</div>,
        handle: {
          title: "Feature Preferences",
        },
      },
    ],
  },
];
