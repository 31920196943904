import { faker } from "@faker-js/faker";
import { fakerDateString } from "lib/faker";
import type { DateString } from "types/api";

export type TenancyPaymentHistoryItem = {
  id: string;
  amount: number;
  date: DateString;
  description: string;
  sub_description: string;
};

// mock using faker to generate data
export function mockTenancyPaymentHistoryItem(): TenancyPaymentHistoryItem {
  return {
    id: faker.string.uuid(),
    amount: faker.number.int({ min: 500, max: 1000 }),
    date: fakerDateString(),
    description: faker.lorem.sentence(),
    sub_description: "EFT",
  };
}
