import { Tag } from "@chakra-ui/react";
import { VacancyStatus, type Property } from "types/property";
import type { Tenancy } from "types/tenancy";
import { titleCase } from "utils/string";

export type StatusCellProps = {
  row: Property<{ tenancies: Tenancy[] }>;
};
export function StatusCell({ row }: StatusCellProps) {
  return (
    <Tag
      colorScheme={
        row.vacancy_status_id === VacancyStatus.Occupied ? "green" : "gray"
      }
      fontSize={"sm"}
      fontWeight={600}
    >
      {titleCase(row.vacancy_status_id)}
    </Tag>
  );
}
