import { Tag } from "@chakra-ui/react";
import { useWhichWordFactory } from "modules/vendor-portal/hooks/use-which-words";
import type { EnumValue } from "modules/vendor-portal/types/api-listing";
import type { OpenHomeEvent } from "modules/vendor-portal/types/feedback";

export const feedbackTypeLabels = {
  enquiry: "Enquiry",
  inspection: "inspection",
  ofi: "Open home",
  accompanied_viewing: "Accompanied viewing",
  followup: "Agent follow up",
  agent_vendor: "Agent update",
  price_reduction: "Price change",
  offer: "Offer",
};

export const eventTypeLabels = {
  open_home: "Open home",
  auction: "Auction",
};

export function getFeedbackTypeLabel(
  feedbackType: EnumValue,
  enquirySource?: EnumValue | null,
  openHomeEvent?: OpenHomeEvent,
) {
  if (feedbackType.id === "enquiry" && enquirySource) {
    return `${feedbackTypeLabels[feedbackType.id]} from ${enquirySource.label}`;
  }

  if (feedbackType.id === "ofi" && openHomeEvent?.calendar_event_id) {
    return feedbackTypeLabels["accompanied_viewing"];
  }

  return { ...feedbackTypeLabels, ...eventTypeLabels }[
    feedbackType.id as
      | keyof typeof feedbackTypeLabels
      | keyof typeof eventTypeLabels
  ];
}

type FeedbackTypeBadgeProps = {
  feedbackType: EnumValue;
  enquirySource?: EnumValue | null;
  openHomeEvent?: OpenHomeEvent;
};
export function FeedbackTypeTag({
  feedbackType,
  enquirySource,
  openHomeEvent,
}: FeedbackTypeBadgeProps) {
  const ww = useWhichWordFactory();

  return (
    <Tag
      colorScheme={"gray"}
      fontSize={"sm"}
      fontWeight={600}
      color={"gray.600"}
      lineHeight={"16px"}
      py={0.5}
      px={2}
      whiteSpace={"nowrap"}
    >
      {ww(getFeedbackTypeLabel(feedbackType, enquirySource, openHomeEvent))}
    </Tag>
  );
}
