import type { ReactNode } from "react";
import { Flex, Icon } from "@chakra-ui/react";
import PersonIcon from "assets/icons/medium/person.svg?react";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { FeedbackTypeTag } from "modules/vendor-portal/components/feedback-type-tag";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type { APIListing } from "modules/vendor-portal/types/api-listing";

type OpenHomeEventFooterProps = {
  event: NonNullable<APIListing["events"]>[number];
  children?: ReactNode;
};
export function OpenHomeEventFooter({
  event,
  children,
}: OpenHomeEventFooterProps) {
  const feedback = event.feedback;

  return (
    <CardRecordMeta>
      <FeedbackTypeTag feedbackType={event.type} />
      {feedback?.agent ? (
        <FooterText
          whiteSpace={"normal"}
          text={`Hosted by ${feedback?.agent?.name}`}
        />
      ) : null}
      {feedback?.attendee_count ? (
        <Flex direction={"row"} align={"center"} gap={1}>
          <Icon as={PersonIcon} boxSize={"12px"} />
          <FooterText text={`${feedback?.attendee_count}`} />
        </Flex>
      ) : null}
      {children}
    </CardRecordMeta>
  );
}
