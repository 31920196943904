import { CardRecordInsetDescription } from "components/cards/card-record-inset-description";
import { colorMap } from "modules/vendor-portal/components/interest-level-badge";
import { OpenHomeEventInsetFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/open-home-event-inset-footer";
import { PrivateInspectionEventInsetFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/private-inspection-event-inset-footer";
import type { EventFeedback } from "modules/vendor-portal/types/events";
import type {
  OpenHomeFeedback,
  PrivateInspectionFeedback,
} from "modules/vendor-portal/types/feedback";

type EventFeedbackInsetProps = {
  feedback: EventFeedback;
};
export function EventFeedbackInset({ feedback }: EventFeedbackInsetProps) {
  const feedbackType = feedback.type.id;
  const interestLevelId =
    (feedback as PrivateInspectionFeedback)?.interest_level?.id ?? "neutral";
  const color =
    feedbackType === "ofi"
      ? `${colorMap["neutral"]}.300`
      : `${colorMap[interestLevelId]}.100`;
  return (
    <CardRecordInsetDescription
      color={color}
      description={feedback.note}
      showMoreElement={({ isTruncated }) => {
        if (feedback.type.id === "ofi") {
          return (
            <OpenHomeEventInsetFooter
              feedback={feedback as OpenHomeFeedback}
              isTruncated={isTruncated}
            />
          );
        } else {
          return (
            <PrivateInspectionEventInsetFooter
              feedback={feedback as PrivateInspectionFeedback}
              isTruncated={isTruncated}
            />
          );
        }
      }}
    />
  );
}
