import { colors } from "theme/colors";

export const styles = {
  global: {
    html: {
      "--chakra-ui-focus-ring-color": colors.gray["100"],
    },
    body: {
      fontWeight: "normal",
      overflowY: "scroll",
      fontSize: "0.875rem",
    },
  },
};
