import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Text,
} from "@chakra-ui/react";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import { SimpleGridTable } from "components/simple-grid-table";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { PendingReachCampaignPlaceholder } from "modules/vendor-portal/screens/performance/components/pending-reach-campaign-placeholder";
import { ReachMarketingCta } from "modules/vendor-portal/screens/performance/components/reach-marketing-cta";
import {
  CampaignStatus,
  type MarketingCampaign,
} from "modules/vendor-portal/screens/performance/models/performance";

// const LineChart = lazy(() => import("components/line-chart/line-chart"));

export const FILTER_BUTTON_OPTIONS = [
  { id: "total", label: "Total campaign" },
  { id: "last7days", label: "Last 7 days" },
  { id: "last30days", label: "Last 30 days" },
];

type PeriodKey = "total" | "last7days" | "last30days";

type CampaignSnapshotProps = {
  campaign: MarketingCampaign;
  isLoading: boolean;
};

export function CampaignSnapshot({
  campaign,
  isLoading,
}: CampaignSnapshotProps) {
  const [selectedCampaignPeriod, setSelectedCampaignPeriod] = useState(
    FILTER_BUTTON_OPTIONS[0],
  );

  const [primaryStats, setPrimaryStats] = useState(
    campaign?.type === "summary"
      ? campaign.primary_stats.total
      : campaign.primary_stats?.[selectedCampaignPeriod.id as PeriodKey],
  );
  const [secondaryStats, setSecondaryStats] = useState(
    campaign?.type === "summary"
      ? campaign.secondary_stats.total
      : campaign.secondary_stats?.[selectedCampaignPeriod.id as PeriodKey],
  );

  useEffect(() => {
    if (campaign?.type === "summary") return;
    setPrimaryStats(
      campaign.primary_stats[selectedCampaignPeriod.id as PeriodKey],
    );
    setSecondaryStats(
      campaign.secondary_stats[selectedCampaignPeriod.id as PeriodKey],
    );
  }, [selectedCampaignPeriod, campaign]);

  // const chartLinePink = useToken("colors", "chartLinePink");
  // const chartLineBlue = useToken("colors", "chartLineBlue");

  if (campaign.id === "reach") {
    if (campaign.status === CampaignStatus.Inactive) {
      return <ReachMarketingCta />;
    } else if (campaign.status === CampaignStatus.Pending) {
      return <PendingReachCampaignPlaceholder />;
    }
  }

  return (
    <Flex direction={"column"} gap={10}>
      <Flex justify={"space-between"} wrap={"wrap"} gap={4}>
        <HStack gap={4}>
          <Text fontSize={"xl"} fontWeight={600} color={"gray.700"}>
            Campaign snapshot
          </Text>
          {/* <Link
            href={campaign.url}
            color={"blue.600"}
            fontSize={"md"}
            fontWeight={700}
            lineHeight={"20px"}
            isExternal
          >
            View portal
            <Icon as={ExternalLinkIcon} boxSize={"12px"} mx={2} />
          </Link> */}
        </HStack>
        {campaign?.type !== "summary" && (
          <Show above={"md"}>
            <ButtonGroup size={"sm"} isAttached variant={"group"}>
              {FILTER_BUTTON_OPTIONS.map((option) => (
                <Button
                  key={option.id}
                  isActive={option.id === selectedCampaignPeriod.id}
                  onClick={() => setSelectedCampaignPeriod(option)}
                >
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </Show>
        )}
      </Flex>

      {campaign?.type !== "summary" && (
        <Show below={"md"}>
          <Menu matchWidth={true}>
            <MenuButton
              as={Button}
              variant={"outline"}
              colorScheme={"gray"}
              rightIcon={<ChevronDownIcon />}
            >
              {selectedCampaignPeriod.label}
            </MenuButton>
            <MenuList>
              {FILTER_BUTTON_OPTIONS.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={() => setSelectedCampaignPeriod(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Show>
      )}

      <SkeletonGrid
        isLoaded={!isLoading}
        gridSize={4}
        skeleton={<StatsCardSkeleton />}
      >
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          templateRows={{
            base: "repeat(2, 1fr)",
            xl: "repeat(1, 1fr)",
          }}
          gap={6}
        >
          {primaryStats?.map((stat) => (
            <GridItem key={stat.id} w={"100%"}>
              <StatsCard
                stat={{
                  ...stat,
                  value: `${stat.value}${
                    stat.aggregateMethod === "percentage" ? "%" : ""
                  }`,
                }}
                icon={BarChartIcon}
              />
            </GridItem>
          ))}
        </Grid>
      </SkeletonGrid>

      {/* TODO: uncomment engagement stats - dependant on ability to sync from reach to CRM */}
      {/* {campaign.engagement_stats ? (
        <Flex direction={"column"} gap={6}>
          <Heading fontSize={"xl"} fontWeight={600}>
            Engagement
          </Heading>
          <Center h={"277px"}>
            <Suspense fallback={<Skeleton w={"100%"} h={"100%"} />}>
              <LineChart
                data={(campaign.engagement_stats ?? []).map((d) => ({
                  clicks: d.clicks,
                  impressions: d.impressions,
                  x: d?.time ? dayjs(d.time).format(dateFormats.dayMonth) : "",
                }))}
                lines={[
                  {
                    dataKey: "clicks",
                    label: "Link Clicks",
                    color: chartLinePink,
                  },
                  {
                    dataKey: "impressions",
                    label: "Impressions",
                    color: chartLineBlue,
                    format: (value) =>
                      value > 1000
                        ? `${Math.round((value / 1000) * 10) / 10}k`
                        : value,
                  },
                ]}
                width={"100%"}
                height={"100%"}
              />
            </Suspense>
          </Center>
          <HStack gap={6}>
            <HStack>
              <Box
                w={"12px"}
                h={"12px"}
                borderRadius={"100%"}
                bg={"chartLineBlue"}
              />
              <Text fontSize={"sm"} fontWeight={500}>
                Impressions (ads shown)
              </Text>
            </HStack>
            <HStack>
              <Box
                w={"12px"}
                h={"12px"}
                borderRadius={"100%"}
                bg={"chartLinePink"}
              />
              <Text fontSize={"sm"} fontWeight={500}>
                Clicks
              </Text>
            </HStack>
          </HStack>
        </Flex>
      ) : null} */}

      {secondaryStats?.map((stat) => (
        <Flex direction={"column"} gap={6} key={stat.id}>
          <Heading fontSize={"xl"} fontWeight={600}>
            {stat.title}
          </Heading>
          <SimpleGridTable
            items={stat.data.map((d) => ({
              label: d.label,
              value: `${d.value}${
                d.aggregateMethod === "percentage" ? "%" : ""
              }`,
            }))}
          />
        </Flex>
      ))}
    </Flex>
  );
}
