import type { ReactNode } from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

type RowSectionProps = {
  heading: ReactNode;
  children: ReactNode;
};

export function RowSection({ heading, children }: RowSectionProps) {
  return (
    <VStack h={"100%"} gap={{ base: 8, sm: 14, md: 20 }} justify={"center"}>
      <Text
        fontSize={{ base: "6xl", sm: "7xl", md: "48px" }}
        color={"gray.700"}
        fontWeight={400}
        textAlign={"center"}
      >
        {heading}
      </Text>
      <HStack
        justify={"center"}
        align={"start"}
        flexWrap={"wrap"}
        gap={4}
        w={"100%"}
      >
        {children}
      </HStack>
    </VStack>
  );
}
