import dayjs from "dayjs";
import type { Stats } from "components/stats-grid";
import type { Tenancy } from "types/tenancy";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";

const dateFormat = (date: string | null | undefined) =>
  date ? dayjs(date).format(dateFormats.dayMonth) : "–";
export function generateRentPositionStats(tenancy?: Tenancy) {
  const rentPosition = tenancy?.rent_position;
  if (!rentPosition) return [];
  return [
    {
      id: "effective_pay_to_date",
      label: "Effective pay to Date",
      value: dateFormat(rentPosition?.paid_to_date),
    },
    {
      id: "paid_to_period",
      label: "Paid to period",
      value: `${dateFormat(
        rentPosition?.fully_paid_period_start_date,
      )} - ${dateFormat(rentPosition?.fully_paid_period_end_date)}${
        rentPosition?.fully_paid_period_surplus
          ? ` + ${formatMoney(rentPosition?.fully_paid_period_surplus)}`
          : ""
      }`,
      dynamicSizing: true,
    },
    {
      id: "rent_arrears",
      label: "Rent arrears",
      value: formatMoney(tenancy?.rent_arrears?.amount),
    },
    {
      id: "next_payment",
      label: "Next payment",
      value: formatMoney(tenancy?.rent_amount),
    },
  ] as const satisfies Stats[];
}
