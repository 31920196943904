import { AppError } from "errors/index";
import type { ErrorResponseBody } from "types/api";

export class FetchClientError extends AppError {
  request: Request | undefined;
  response: Response | undefined;
  body: ErrorResponseBody | undefined;

  constructor(
    message: string,
    request: Request,
    response: Response,
    body: ErrorResponseBody,
  ) {
    super(message);
    this.name = "FetchClientError";
    if (request) this.request = request;
    if (response) this.response = response;
    if (body) this.body = body;
  }
}
