import { faker } from "@faker-js/faker";
import type { MutationOptions } from "@tanstack/react-query";
import type { Listing } from "modules/vendor-portal/types/listing";
import {
  ListingAdvertType,
  ListingLinkType,
} from "modules/vendor-portal/types/listing";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type MarketingScreenData = {
  listing: Listing;
};

export const marketingScreenModel = createModel("marketing", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: () => apiClient.get<MarketingScreenData>(`/marketing`),
    }),
  },
  mutations: {
    approveMarketingMaterial: () => ({
      mutationFn: ({ userId }) =>
        apiClient.post(`/marketing/approve/${userId}`, { userId }),
    }),
    sendFeedback: (): MutationOptions<any, any, any, any> => ({
      mutationFn: ({ userId, feedback }) => {
        return apiClient.post(`/marketing/send-feedback/${userId}`, {
          userId,
          feedback,
        });
      },
    }),
  },
});

const mockListingImages = [
  "https://rimh2.domainstatic.com.au/SrAL1orE8pVbUVq0IC345GsAp3A=/fit-in/1920x1080/filters:format(jpeg):quality(80):no_upscale()/2016411449_11_1_200731_123941-w5958-h3982",
  "https://rimh2.domainstatic.com.au/UvitH85ZRbmWZiqwHlTy1cpEj7Y=/fit-in/1920x1080/filters:format(jpeg):quality(80):no_upscale()/2016411449_12_1_200731_123941-w2000-h1336",
  "https://rimh2.domainstatic.com.au/Zfvak_ui1o3l6xAIV5rbcioRgRQ=/fit-in/1920x1080/filters:format(jpeg):quality(80):no_upscale()/2016411449_1_1_200731_123941-w2000-h1335",
];

const mockFloorplanImages = [
  "//au-crm.cdns.rexsoftware.com/app/livestore/accounts/77/listings/3909419/floorplans/floorplan1-4817-ca42_1afb-b05f-8710-9aed-2822-53ce-0366-2dc7_20240109050315",
  "//uk-crm.cdns.rexsoftware.com/app/livestore/accounts/1411/listings/41730/floorplans/Poppy-Cottage-WYDON-_dbdf-41a2-5c68-8531-d9f4-dcc0-bae8-e75b_20210224065554",
  "//au-crm.cdns.rexsoftware.com/app/livestore/accounts/77/listings/3909419/floorplans/floorplan1-4817-ca42_1afb-b05f-8710-9aed-2822-53ce-0366-2dc7_20240109050315",
];

export const mockMarketingResponse = {
  listing: {
    id: faker.string.uuid(),
    _related: {
      listing_adverts: Array.from({ length: 3 }).map((_i, idx) => ({
        advert_body:
          "Looking for a modern home that's perfect for entertaining? Look no further! \n\nThis stunning property features plantation blinds throughout, adding a touch of elegance to every room. With three spacious living areas, there's plenty of room for everyone to spread out and relax. \n\nThe huge kitchen boasts a butler pantry and all the modern appliances you need to whip up a feast for your family and friends\n\n• Bullet one\n• Bullet two\n• Bullet three",
        advert_heading: faker.lorem.sentence(),
        advert_type: Object.values(ListingAdvertType)[idx],
      })),
      listing_floorplans: Array.from({ length: 3 }).map((_i, idx) => ({
        inbound_index: null,
        inbound_last_update: null,
        inbound_original_src_url: null,
        priority: `${idx + 1}`,
        uri: "",
        _id: faker.string.uuid(),
        _thumbs: {
          "80x60": {
            uri: "",
            _url: `${mockFloorplanImages[idx]}_80x60.jpg`,
          },
          "200x150": {
            uri: "",
            _url: `${mockFloorplanImages[idx]}_200x150.jpg`,
          },
          "400x300": {
            uri: "",
            _url: `${mockFloorplanImages[idx]}_400x300.jpg`,
          },
          "800x600": {
            uri: "",
            _url: `${mockFloorplanImages[idx]}_800x600.jpg`,
          },
          original: {
            uri: "",
            _url: `${mockFloorplanImages[idx]}_original.jpg`,
          },
        },
        _url: `${mockFloorplanImages[idx]}.jpg`,
      })),
      listing_images: Array.from({ length: 3 }).map((_i, idx) => ({
        priority: `${idx + 1}`,
        id: faker.string.uuid(),
        thumbnails: {
          "800x600": {
            url: `${mockListingImages[idx]}`,
          },
        },
        url: `${mockListingImages[idx]}`,
      })),
      listing_links: Array.from({ length: 5 }).map((_i, idx) => ({
        link_type: Object.values(ListingLinkType)[idx],
        link_url: faker.internet.url(),
        _id: faker.string.uuid(),
      })),
      vpa_campaign: {
        budgeted_amount: null,
        listing_id: faker.string.uuid(),
        project_id: null,
        project_stage_id: null,
        property_id: null,
        system_campaign_status: "inactive",
        system_total_allocated: faker.number.float({
          min: 0,
          max: 3000,
          multipleOf: 0.02,
        }),
        system_total_booked: 500,
        system_total_invoiced: null,
        vpa_note: null,
        _id: faker.string.uuid(),
        _lineitems: Array.from({ length: 3 }, () => {
          const [providerName, item, price] = faker.helpers.arrayElement([
            ["REA", "Standard Ad", 2000],
            ["Domain", "Premium Listing Package", 1600],
            ["Rex Reach", "Standard Campaign - 14 Days", 250],
            ["JS Photography", "Daytime Photography", 300],
            ["Sunshine Staging", "Full Staging", 500],
          ]);
          return {
            booking_date: "2024-02-23",
            cost_inc_tax: faker.number.float({
              min: 0,
              max: 3000,
              multipleOf: 0.02,
            }),
            is_funds_requested: faker.datatype.boolean() ? "1" : "0",
            listing_id: faker.string.uuid(),
            location: {
              id: faker.string.uuid(),
              text: "Rex Support Test (AU)",
            },
            price_inc_tax: price,
            price_tax_free: null,
            product_category: {
              id: faker.string.uuid(),
              text: faker.commerce.department(),
            },
            product_name: item,
            project_id: null,
            project_stage_id: null,
            property_id: null,
            provider_name: providerName,
            supplier_invoice_ref: null,
            id: faker.string.uuid(),
            _invoice_id: faker.string.uuid(),
          };
        }),
      },
    },
  },
};
