import { Helmet } from "react-helmet-async";
import { useMatches } from "react-router-dom";
import type { Route } from "lib/react-router-dom";

export function DocumentTitle() {
  const matches = useMatches();

  const title = matches
    .filter((match) => match.handle)
    .map((match) => (match.handle as Route["handle"])?.title)
    .join(" – ");

  return (
    <Helmet>
      <title>Rex Home{title ? ` – ${title}` : ""}</title>
    </Helmet>
  );
}
