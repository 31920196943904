import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { PIE_CHART_COLOR_MAP } from "modules/vendor-portal/screens/overview/components/listing-performance/constants";
import type { InterestLevelStat } from "modules/vendor-portal/types/listing";

type PieChartLegendProps = {
  interestLevelStats: InterestLevelStat[];
  totalAttendees: number;
};
export function PieChartLegend({
  interestLevelStats,
  totalAttendees,
}: PieChartLegendProps) {
  return (
    <TableContainer>
      <Table size={"sm"} variant={"unstyled"}>
        <Thead bgColor={"none"}>
          <Tr>
            <Th></Th>
            <Th>
              <Text
                fontSize={"sm"}
                fontWeight={400}
                color={"gray.400"}
                textAlign={"end"}
              >
                %
              </Text>
            </Th>
            <Th>
              <Text
                fontSize={"sm"}
                fontWeight={400}
                color={"gray.400"}
                textAlign={"end"}
              >
                qty
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {interestLevelStats?.map(({ id, label, value }) => (
            <Tr key={id}>
              <Td>
                <Flex direction={"row"} align={"center"} gap={4} w={"145px"}>
                  <Box
                    w={"12px"}
                    h={"12px"}
                    borderRadius={"100%"}
                    bgColor={PIE_CHART_COLOR_MAP[id]}
                  />
                  <Text fontSize={"md"} fontWeight={500} color={"gray.600"}>
                    {label}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Text
                  fontSize={"md"}
                  fontWeight={500}
                  color={"gray.600"}
                  textAlign={"end"}
                >
                  {Math.round((value / totalAttendees) * 100)}%
                </Text>
              </Td>
              <Td>
                <Text
                  fontSize={"md"}
                  fontWeight={500}
                  color={"gray.600"}
                  textAlign={"end"}
                >
                  {value}
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
