import { Outlet } from "react-router-dom";
import { DocumentTitle } from "components/document-title";

export function RootComponent() {
  return (
    <>
      <DocumentTitle />
      <Outlet />
    </>
  );
}
