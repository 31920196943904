import type { Dispatch, SetStateAction } from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Text } from "@chakra-ui/react";
import type { PaginationMeta } from "types/pagination";

type PaginationProps = {
  setPage: Dispatch<SetStateAction<number>>;
} & PaginationMeta;

export function Pagination({
  current_page,
  last_page,
  total,
  per_page,
  setPage,
}: PaginationProps) {
  return (
    <HStack justify={"space-between"}>
      <Text fontSize={"sm"} color={"gray.600"}>
        Showing {(current_page - 1) * per_page + 1} to{" "}
        {current_page === last_page ? total : current_page * per_page} of{" "}
        {total} results
      </Text>
      <HStack spacing={2}>
        <IconButton
          isDisabled={current_page === 1}
          colorScheme={"gray"}
          variant={"outline"}
          aria-label={"previous page"}
          icon={<ArrowBackIcon />}
          onClick={() => setPage(current_page - 1)}
        />
        <IconButton
          isDisabled={current_page === last_page}
          colorScheme={"gray"}
          variant={"outline"}
          aria-label={"next page"}
          icon={<ArrowForwardIcon />}
          onClick={() => setPage(current_page + 1)}
        />
      </HStack>
    </HStack>
  );
}
