import { useState } from "react";
import {
  Box,
  Button,
  Image as ChakraImage,
  Grid,
  GridItem,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ImageGalleryModal } from "components/image-gallery/image-gallery-modal";
import { MobileImageGalleryModal } from "components/image-gallery/mobile-image-gallery-modal";
import type { Image } from "types/image";

type ImageGalleryProps = {
  images: Image[];
};
export function ImageGallery({ images }: ImageGalleryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentModalImage, setCurrentModalImage] = useState(0);
  const displayImages = images.slice(0, 5);

  const rows =
    displayImages.length >= 5 ? 4 : Math.ceil(displayImages.length / 2);

  const columns =
    displayImages.length >= 5
      ? 4
      : displayImages.length % 2 === 0
        ? 2
        : displayImages.length;

  return (
    <>
      <Grid
        templateColumns={{
          base: `repeat(${columns}, 1fr)`,
          lg: `repeat(${columns}, 1fr)`,
        }}
        templateRows={{
          base: `repeat(${rows}, 1fr)`,
          lg: `repeat(${rows}, 1fr)`,
        }}
        w={"100%"}
        h={"600px"}
        gap={"15px"}
        borderRadius={"8px"}
        overflow={"hidden"}
      >
        {displayImages.map((image, idx) => (
          <GridItem
            key={image.id}
            pos={"relative"}
            minW={0}
            minH={0}
            rowSpan={idx === 0 && displayImages.length % 2 === 1 ? rows : 1}
            colSpan={
              idx === 0 && displayImages.length % 2 === 1 ? columns - 1 : 1
            }
            bg={"gray.50"}
          >
            <ChakraImage
              key={image.id}
              w={"100%"}
              h={"100%"}
              fit={"cover"}
              src={image?.url}
            />

            {idx === displayImages.length - 1 ? (
              <Button
                pos={"absolute"}
                bottom={2}
                right={2}
                color={"gray.700"}
                _hover={{ bg: "gray.700", color: "gray.100" }}
                bgColor={"gray.100"}
                onClick={onOpen}
              >
                Show all photos
              </Button>
            ) : null}
          </GridItem>
        ))}
      </Grid>
      <ImageGalleryModal
        isOpen={isOpen}
        onClose={onClose}
        images={images}
        currentModalImage={currentModalImage}
        setCurrentModalImage={setCurrentModalImage}
      />
    </>
  );
}

export function MobileImageGallery({ images }: ImageGalleryProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box w={"100%"} pos={"relative"}>
        <HStack gap={3} w={"100%"}>
          <ChakraImage
            key={images[0]?.id}
            h={"100%"}
            fit={"cover"}
            src={images[0]?.thumbnails["800x600"].url}
          />
        </HStack>
        <Button
          pos={"absolute"}
          bottom={2}
          right={2}
          color={"gray.700"}
          _hover={{ bg: "gray.700", color: "gray.100" }}
          bgColor={"gray.100"}
          onClick={onOpen}
        >
          Show all photos
        </Button>
      </Box>
      <MobileImageGalleryModal
        isOpen={isOpen}
        onClose={onClose}
        images={images}
      />
    </>
  );
}
