import { useMemo, useState } from "react";
import { Flex, Heading, HStack, List } from "@chakra-ui/react";
import { ImageGalleryModal } from "components/image-gallery/image-gallery-modal";
import { MaintenanceListCardSkeleton } from "components/maintenance-list/maintenance-list-card-skeleton";
import { MaintenanceTaskListItem } from "components/maintenance-list/maintenance-task-list-item";
import { ShowMoreLessButton } from "components/show-more-less-button";
import { TotalCountBadge } from "components/total-count-badge";
import type { MaintenanceTask } from "types/maintenance-task";
import { TaskStatus } from "types/task";

export type MaintenanceListProps = {
  isLoading: boolean;
  maintenanceTasks: MaintenanceTask[];
};

export function MaintenanceList({
  isLoading,
  maintenanceTasks,
}: MaintenanceListProps) {
  const [showAllActive, setShowAllActive] = useState(false);
  const [showAllPast, setShowAllPast] = useState(false);

  const [currentOpenTaskId, setCurrentOpenTaskId] = useState<string | null>(
    null,
  );
  const [currentModalImage, setCurrentModalImage] = useState(0);

  const currentOpenTask = useMemo(
    () => maintenanceTasks?.find((task) => task.id === currentOpenTaskId),
    [currentOpenTaskId, maintenanceTasks],
  );

  const activeTasks: MaintenanceTask[] = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 1 })
        : maintenanceTasks?.filter(
            (task) => task.status_id !== TaskStatus.Completed,
          ),
    [maintenanceTasks, isLoading],
  );

  const pastTasks: MaintenanceTask[] = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 3 })
        : maintenanceTasks?.filter(
            (task) => task.status_id === TaskStatus.Completed,
          ),
    [maintenanceTasks, isLoading],
  );

  return (
    <>
      <Flex direction={"column"} gap={4}>
        <HStack gap={3}>
          <Heading fontSize={"xl"} as={"h2"}>
            Active requests
          </Heading>
          {isLoading ? null : <TotalCountBadge count={activeTasks?.length} />}
        </HStack>
        <List spacing={3}>
          {activeTasks
            ?.slice(0, showAllActive ? activeTasks?.length : 3)
            .map((task, index) =>
              isLoading ? (
                <MaintenanceListCardSkeleton key={index} />
              ) : (
                <MaintenanceTaskListItem
                  key={task.id}
                  task={task}
                  setCurrentOpenTaskId={setCurrentOpenTaskId}
                />
              ),
            )}
        </List>
        {(activeTasks?.length ?? 0) > 3 ? (
          <ShowMoreLessButton
            showAll={showAllActive}
            setShowAll={setShowAllActive}
          />
        ) : null}
      </Flex>

      <Flex direction={"column"} gap={4}>
        <HStack gap={3}>
          <Heading fontSize={"xl"} as={"h2"}>
            Past requests
          </Heading>
          {isLoading ? null : <TotalCountBadge count={pastTasks?.length} />}
        </HStack>
        <List spacing={3}>
          {pastTasks
            ?.slice(0, showAllPast ? pastTasks?.length : 3)
            ?.map((task, index) =>
              isLoading ? (
                <MaintenanceListCardSkeleton key={index} />
              ) : (
                <MaintenanceTaskListItem
                  key={task.id}
                  task={task}
                  setCurrentOpenTaskId={setCurrentOpenTaskId}
                />
              ),
            )}
        </List>
        {(pastTasks?.length ?? 0) > 3 ? (
          <ShowMoreLessButton
            showAll={showAllPast}
            setShowAll={setShowAllPast}
          />
        ) : null}
      </Flex>
      <ImageGalleryModal
        isOpen={!!currentOpenTaskId}
        onClose={() => setCurrentOpenTaskId(null)}
        images={currentOpenTask?.details?.images ?? []}
        currentModalImage={currentModalImage}
        setCurrentModalImage={setCurrentModalImage}
      />
    </>
  );
}
