import { faker } from "@faker-js/faker";
import dayjs from "dayjs";
import { fakerDateString } from "lib/faker";
import { mockContact } from "types/contact";
import type { Image } from "types/image";
import { mockProperty } from "types/property";
import { TaskPriority, TaskStatus, type Task } from "types/task";

export enum InspectionType {
  RoutineInspection = "routine_inspection",
  EntryInspection = "entry_inspection",
  ExitInspection = "exit_inspection",
}

export enum InspectionStatus {
  NotStarted = "not_started",
  NoticeIssued = "notice_issued",
  InspectionCompleted = "inspection_completed",
  ReportInProgress = "report_in_progress",
  ReportCompleted = "report_completed",
  ReportSent = "report_sent",
}

export type InspectionTask = Task<{
  status_id: InspectionStatus;
  type_id: InspectionType;
  inspection_report?: { url: string };
  next_inspection_date?: string;
  scheduled_at?: string;
  scheduled_duration?: number;
  scheduled_order?: number;
  inspection_run_id?: string;
  supporting_documents?: { id: string; url: string }[];
  cover_summary: string | null;
  images: Image[];
}>;

// Mock an inspection task using faker to generate data
export function mockInspectionTask(): InspectionTask {
  return {
    id: faker.string.uuid(),
    summary: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    status_id: faker.helpers.arrayElement([
      TaskStatus.NotStarted,
      TaskStatus.InProgress,
      TaskStatus.Completed,
    ]),
    priority_id: faker.helpers.arrayElement([
      TaskPriority.Preventative,
      TaskPriority.Normal,
      TaskPriority.Urgent,
      TaskPriority.Emergency,
    ]),
    due_date: fakerDateString(),
    follow_up_date: fakerDateString(),
    managed_by: mockContact(),
    closed_at: fakerDateString(),
    created_at: fakerDateString(),
    updated_at: fakerDateString(),
    property: mockProperty(),
    details: {
      images: Array.from({ length: 3 }).map((_i, idx) => ({
        priority: idx + 1,
        id: faker.string.uuid(),
        url: faker.image.urlPicsumPhotos({ width: 1440, height: 1080 }),
        thumbnails: {
          "800x600": {
            url: faker.image.urlPicsumPhotos({ width: 800 }),
          },
        },
      })),
      status_id: faker.helpers.arrayElement([
        InspectionStatus.NotStarted,
        InspectionStatus.NoticeIssued,
        InspectionStatus.InspectionCompleted,
        InspectionStatus.ReportInProgress,
        InspectionStatus.ReportCompleted,
        InspectionStatus.ReportSent,
      ]),
      type_id: faker.helpers.arrayElement([
        InspectionType.RoutineInspection,
        InspectionType.EntryInspection,
        InspectionType.ExitInspection,
      ]),
      inspection_report: {
        url: faker.internet.url(),
      },
      next_inspection_date: fakerDateString(),
      scheduled_at: fakerDateString(dayjs().subtract(3, "day")),
      scheduled_duration: faker.helpers.arrayElement([30, 45]),
      scheduled_order: faker.number.int(100),
      inspection_run_id: faker.string.uuid(),
      supporting_documents: [
        {
          id: faker.string.uuid(),
          url: faker.internet.url(),
        },
      ],
      cover_summary: faker.lorem.sentence(),
    },
  };
}
