import {
  Fragment,
  useCallback,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import {
  Box,
  Table as ChakraTable,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  type BoxProps,
  type MenuItemProps,
} from "@chakra-ui/react";
import { Link, type To } from "react-router-dom";
import { Empty, type EmptyProps } from "components/empty";
import { Pagination } from "components/table/pagination";
import type { PaginationMeta } from "types/pagination";
import { get } from "utils/collection";

type TableRow<T extends Record<string, unknown>> = T & {
  id: string;
};

export type CellProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: T;
  value: any;
};

export type TableColumn<T extends Record<string, unknown>> = {
  label: ReactNode;
  id?: (keyof T & string) | string;
  formatValue?: (value: any, row: T) => ReactNode;
  width?: BoxProps["w"];
  Cell?: (props: CellProps<T>) => JSX.Element;
};

export type TableAction<T extends Record<string, unknown>> = {
  label: ReactNode;
  id: string;
  onClick: ({ row }: { row: TableRow<T> }) => void;
  addBottomDivider?: boolean;
  isLoading?: boolean;
  menuItemProps?: MenuItemProps;
};

type TableProps<T extends Record<string, unknown>> = {
  columns: TableColumn<T>[];
  rows: TableRow<T>[];
  actions?: TableAction<T>[];
  customRowCell?: (row: TableRow<T>) => ReactNode;
  emptyProps?: EmptyProps;
  isLoading?: boolean;
  meta?: PaginationMeta;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  isFetching?: boolean;
  onRowClick?: (row: T) => void;
  getRowHref?: (row: T) => To;
};

export function Table<RowType extends Record<string, unknown>>({
  columns,
  rows,
  actions,
  customRowCell,
  isLoading,
  isFetching,
  emptyProps,
  meta,
  setPage,
  onRowClick,
  getRowHref,
}: TableProps<RowType>) {
  const getCell = useCallback(
    ({
      column,
      row,
    }: {
      column: TableColumn<RowType>;
      row: TableRow<RowType>;
    }): ReactNode => {
      const { Cell, id, formatValue } = column;

      const valueReadById = id ? get(row, id) : undefined;
      const value = formatValue
        ? formatValue(valueReadById, row)
        : valueReadById;
      if (!value && !Cell) return "–";
      if (Cell) {
        return <Cell row={row} value={value as string} />;
      } else {
        return value;
      }
    },
    [],
  );

  if (isLoading) {
    return (
      <Flex w={"full"} h={300} justify={"center"} align={"center"}>
        <Spinner size={"lg"} />
      </Flex>
    );
  }

  if (rows.length === 0 && emptyProps) {
    return <Empty {...emptyProps} />;
  }

  const showPagination = meta && setPage;

  return (
    <>
      <TableContainer>
        <ChakraTable
          variant={"simple"}
          {...(isFetching ? { opacity: 0.3, pointerEvents: "none" } : {})}
        >
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column.id}>{column.label}</Th>
              ))}
              {(actions || customRowCell) && <Th />}
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, idx) => {
              const rowElement = (
                <Tr
                  key={row.id}
                  onClick={onRowClick ? () => onRowClick(row) : undefined}
                  _hover={
                    onRowClick
                      ? { background: "gray.50", cursor: "pointer" }
                      : undefined
                  }
                >
                  {columns.map((column) => (
                    <Td
                      key={column.id}
                      py={2}
                      w={column.width}
                      borderBottom={
                        !showPagination && idx === rows.length - 1
                          ? "none"
                          : "1px solid"
                      }
                      borderColor={"gray.200"}
                    >
                      {getCell({ column, row })}
                    </Td>
                  ))}
                  {customRowCell ? (
                    <Td
                      textAlign={"right"}
                      py={2}
                      borderBottom={
                        !showPagination && idx === rows.length - 1
                          ? "none"
                          : "1px solid"
                      }
                      borderColor={"gray.200"}
                    >
                      {customRowCell(row)}
                    </Td>
                  ) : null}

                  {actions && (
                    <Td textAlign={"right"} py={2}>
                      <Menu isLazy={rows.length > 30}>
                        <MenuButton
                          as={IconButton}
                          aria-label={"options"}
                          icon={<PlusSquareIcon />}
                          variant={"ghost"}
                        />
                        <MenuList>
                          {actions.map((action) => (
                            <Fragment key={action.id}>
                              <MenuItem
                                isDisabled={action.isLoading}
                                onClick={() => action.onClick({ row })}
                                {...(action.menuItemProps ?? {})}
                              >
                                {action.isLoading && (
                                  <Spinner size={"sm"} mr={2} />
                                )}
                                {action.label}
                              </MenuItem>
                              {action.addBottomDivider && <MenuDivider />}
                            </Fragment>
                          ))}
                        </MenuList>
                      </Menu>
                    </Td>
                  )}
                </Tr>
              );

              return getRowHref ? (
                <Link
                  to={getRowHref(row)}
                  style={{ display: "contents", verticalAlign: "middle" }}
                >
                  {rowElement}
                </Link>
              ) : (
                rowElement
              );
            })}
          </Tbody>
        </ChakraTable>
      </TableContainer>
      {showPagination ? (
        <Box p={4}>
          <Pagination {...meta} setPage={setPage} />
        </Box>
      ) : null}
    </>
  );
}
