import type { ComponentProps } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import type { BarDatum } from "@nivo/bar";
import { ResponsiveBar } from "@nivo/bar";
import dayjs from "dayjs";
import { BarLabels } from "modules/landlord-portal/screens/financial/components/financial-bar-chart/financial-bar-chart-labels";
import { loadingSkeletonData } from "modules/landlord-portal/screens/financial/components/financial-bar-chart/utils/loading-skeleton-data";
import type { DateString } from "types/api";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { titleCase } from "utils/string";

const colorMap = {
  money_in: "#d3dffd",
  money_out: "#96afef",
} as const;

const colorMapLoading = {
  money_in: "hsl(214,32%,91%)",
  money_out: "hsl(211,25%,84%)",
} as const;

export type FinancialBarDataRow = {
  date: DateString;
  money_in: number;
  money_out: number;
};

type FinancialsBarChartProps = {
  chartData?: { money_in: number; money_out: number; date: DateString }[];
  isLoading: boolean;
};

const theme = ({
  isLoading: isLoading,
  isMobile,
}: {
  isLoading: boolean;
  isMobile: boolean;
}) => ({
  grid: {
    line: {
      stroke: "#eee",
    },
  },
  legends: {
    text: {
      fontWeight: 600,
    },
  },
  axis: {
    legend: {
      text: {
        fontWeight: 600,
      },
    },
    ticks: {
      text: {
        fontWeight: 550,
        opacity: isLoading ? 0 : 1,
        fontSize: isMobile ? "10px" : "12px",
      },
      line: {
        display: "none",
      },
    },
  },
  text: {
    fontFamily: "InterVariable",
    fontSize: "14px",
    fontWeight: 700,
  },
  labels: {
    text: {
      fontWeight: 600,
    },
  },
});

export function FinancialBarChart({
  chartData,
  isLoading,
}: FinancialsBarChartProps) {
  const isMobile = !!useBreakpointValue({ base: true, md: false });

  return (
    <Box w={"100%"}>
      <Box h={"500px"} flex={1}>
        <ResponsiveBar
          layers={
            [
              "grid",
              "axes",
              "bars",
              isLoading ? null : BarLabels,
              "markers",
              "legends",
            ].filter(Boolean) as ComponentProps<typeof ResponsiveBar>["layers"]
          }
          theme={theme({ isLoading, isMobile })}
          data={isLoading ? loadingSkeletonData : (chartData as BarDatum[])}
          enableLabel={false}
          keys={["money_in", "money_out"]}
          indexBy={"date"}
          margin={{ top: 50, right: 0, bottom: 70, left: isMobile ? 50 : 60 }}
          padding={isMobile ? 0.3 : 0.4}
          borderRadius={4}
          innerPadding={isMobile ? 2 : 4}
          groupMode={"grouped"}
          axisLeft={{
            format: (v) => formatMoney(v),
            tickSize: 5,
          }}
          valueScale={{ type: "linear", nice: true }}
          indexScale={{ type: "band", round: true }}
          colors={(datum) =>
            (isLoading ? colorMapLoading : colorMap)[
              datum.id as keyof typeof colorMap
            ]
          }
          axisTop={null}
          legendLabel={(datum) => titleCase(String(datum.id))}
          axisRight={null}
          tooltipLabel={(datum) => titleCase(String(datum.id))}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (v) =>
              dayjs(v).format(isMobile ? "MMM" : dateFormats.monthYear),
            truncateTickAt: 0,
          }}
          labelSkipWidth={12}
          motionConfig={{
            duration: 0,
          }}
          labelSkipHeight={12}
          labelTextColor={"#2d3748"}
          valueFormat={(v) => formatMoney(v)}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 68,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 20,
              symbolShape: "square",
            },
          ]}
          role={"application"}
          ariaLabel={"Financials bar chart"}
        />
      </Box>
    </Box>
  );
}
