import * as ReactDOM from "react-dom/client";
import "index.css";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import type { JsonBodyType } from "msw";
import { RouterProvider } from "react-router-dom";
import { mockAuthResponse } from "contexts/auth";
import { mockSessionResponse } from "contexts/session";
import { initialiseBugsnag } from "lib/bugsnag";
import { mockEndpoints, mockServiceWorker } from "lib/msw";
import { initialiseRouter } from "lib/react-router-dom";
import { mockLandlordDocumentsResponse } from "modules/landlord-portal/models/documents";
import {
  mockFinancialsResponse,
  mockFinancialsStatementDownloadResponse,
} from "modules/landlord-portal/models/financials";
import { mockLandlordInspectionsResponse } from "modules/landlord-portal/models/inspections";
import { mockLandlordMaintenanceResponse } from "modules/landlord-portal/models/maintenance";
import { mockLandlordOverviewResponse } from "modules/landlord-portal/models/overview";
import { mockHandbookResponse } from "modules/tenant-portal/models/handbook";
import { mockInspectionsResponse } from "modules/tenant-portal/models/inspections";
import { mockTenancyMaintenanceResponse } from "modules/tenant-portal/models/maintenance";
import { mockTenancyOverviewResponse } from "modules/tenant-portal/models/overview";
import { mockPaymentsResponse } from "modules/tenant-portal/models/payments";
import { mockDocumentsResponse } from "modules/vendor-portal/screens/documents/models/documents";
import { mockEventsResponse } from "modules/vendor-portal/screens/events/models/events";
import { mockFeedbackResponse } from "modules/vendor-portal/screens/feedback/models/feedback";
import { mockMarketingResponse } from "modules/vendor-portal/screens/marketing/models/marketing";
import { mockOffersResponse } from "modules/vendor-portal/screens/offers/models/offers";
import { mockOpenHomeDetailsResponse } from "modules/vendor-portal/screens/open-home-details/models/open-home";
import { mockOverviewResponse } from "modules/vendor-portal/screens/overview/models/overview";
import { mockListingPerformanceResponse } from "modules/vendor-portal/screens/performance/models/performance";
import { initialiseAuthToken } from "utils/auth";

dayjs.extend(duration);

const router = initialiseRouter();

async function initialise() {
  if (import.meta.env.MODE === "production") {
    initialiseBugsnag();
  }

  initialiseAuthToken();

  if (import.meta.env.VITE_ENABLE_MOCK_SERVICE_WORKER) {
    mockEndpoints([
      { endpoint: "/auth", response: mockAuthResponse as JsonBodyType },
      { endpoint: "/user", response: mockSessionResponse as JsonBodyType },
      { endpoint: "/overview", response: mockOverviewResponse as JsonBodyType },
      { endpoint: "/feedback", response: mockFeedbackResponse as JsonBodyType },
      { endpoint: "/events", response: mockEventsResponse as JsonBodyType },
      {
        endpoint: "/openhome",
        response: mockOpenHomeDetailsResponse as JsonBodyType,
      },
      {
        endpoint: "/performance",
        response: mockListingPerformanceResponse as JsonBodyType,
      },
      {
        endpoint: "/offers",
        response: mockOffersResponse as JsonBodyType,
      },
      {
        endpoint: "/documents",
        response: mockDocumentsResponse as JsonBodyType,
      },
      {
        endpoint: "/marketing",
        response: mockMarketingResponse as JsonBodyType,
      },
      {
        endpoint: "/tenant/:propertyId/overview",
        response: mockTenancyOverviewResponse as JsonBodyType,
      },
      {
        endpoint: "/tenant/:propertyId/maintenance",
        response: mockTenancyMaintenanceResponse as JsonBodyType,
      },
      {
        endpoint: "/tenant/:propertyId/inspections",
        response: mockInspectionsResponse as JsonBodyType,
      },
      {
        endpoint: "/tenant/:propertyId/inspections",
        response: mockInspectionsResponse as JsonBodyType,
      },
      {
        endpoint: "/tenant/:propertyId/payments",
        response: mockPaymentsResponse as JsonBodyType,
      },
      {
        endpoint: "/tenant/:propertyId/handbook",
        response: mockHandbookResponse as JsonBodyType,
      },
      {
        endpoint: "/landlord/overview",
        response: mockLandlordOverviewResponse as JsonBodyType,
      },
      {
        endpoint: "/landlord/financials",
        response: mockFinancialsResponse as JsonBodyType,
      },
      {
        endpoint: "/landlord/maintenance",
        response: mockLandlordMaintenanceResponse as JsonBodyType,
      },
      {
        endpoint: "/landlord/financials/statement-download/:statementId",
        response: mockFinancialsStatementDownloadResponse as JsonBodyType,
      },
      {
        endpoint: "/landlord/inspections",
        response: mockLandlordInspectionsResponse as JsonBodyType,
      },
      {
        endpoint: "/landlord/documents",
        response: mockLandlordDocumentsResponse as JsonBodyType,
      },
    ]);
    await mockServiceWorker.start({ onUnhandledRequest: "bypass" });
  }
}

initialise().then(() => {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <RouterProvider router={router} />,
  );
});
