import { useState } from "react";
import {
  Box,
  HStack,
  Icon,
  Image,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Show,
  Text,
  VStack,
} from "@chakra-ui/react";
import EmailIcon from "assets/icons/medium/email.svg?react";
import PhoneIcon from "assets/icons/medium/phone.svg?react";

type ContactItemProps = {
  agent: {
    name: string;
    role: string;
    phone: string;
    email: string;
    imageUrl?: string;
  };
};
export function ContactItem({ agent }: ContactItemProps) {
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <HStack
      w={"100%"}
      alignItems={"flex-start"}
      onClick={() => setIsContactOpen(!isContactOpen)}
      cursor={"pointer"}
    >
      <Image
        src={agent.imageUrl}
        alt={""}
        w={"40px"}
        h={"40px"}
        borderRadius={"100%"}
      />
      <VStack justify={"flex-start"} gap={3}>
        <Show above={"lg"}>
          <Popover placement={"right"}>
            <PopoverTrigger>
              <Box w={"100%"}>
                <Text fontSize={"14px"} fontWeight={600} color={"gray.900"}>
                  {agent.name}
                </Text>
                <Text fontSize={"14px"} fontWeight={400} color={"gray.600"}>
                  {agent.role}
                </Text>
              </Box>
            </PopoverTrigger>

            <PopoverContent>
              <PopoverHeader fontWeight={"semibold"}>
                {agent.name}
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Box w={"100%"}>
                  <HStack>
                    <Icon as={PhoneIcon} boxSize={"12px"} />
                    <Link href={`tel:${agent.phone}`}>
                      <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"gray.600"}
                      >
                        {agent.phone}
                      </Text>
                    </Link>
                  </HStack>
                  <HStack>
                    <Icon as={EmailIcon} boxSize={"12px"} />
                    <Link href={`mailto:${agent.email}`}>
                      <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"gray.600"}
                      >
                        {agent.email}
                      </Text>
                    </Link>
                  </HStack>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Show>

        <Show below={"lg"}>
          <Box w={"100%"}>
            <Text fontSize={"14px"} fontWeight={600} color={"gray.900"}>
              {agent.name}
            </Text>
            <Text fontSize={"14px"} fontWeight={400} color={"gray.600"}>
              {agent.role}
            </Text>
            <Box w={"100%"}>
              <HStack>
                <Icon as={PhoneIcon} boxSize={"12px"} />
                <Link href={`tel:${agent.phone}`}>
                  <Text fontSize={"14px"} fontWeight={400} color={"gray.600"}>
                    {agent.phone}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Icon as={EmailIcon} boxSize={"12px"} />
                <Link href={`mailto:${agent.email}`}>
                  <Text fontSize={"14px"} fontWeight={400} color={"gray.600"}>
                    {agent.email}
                  </Text>
                </Link>
              </HStack>
            </Box>
          </Box>
        </Show>
      </VStack>
    </HStack>
  );
}
