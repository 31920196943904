import type { ReactElement, ReactNode } from "react";
import { cloneElement } from "react";
import { Grid, type GridProps } from "@chakra-ui/react";

type SkeletonGridProps = {
  isLoaded: boolean;
  skeleton: ReactElement;
  gridSize?: number;
  children: ReactNode;
  gap?: number;
} & GridProps;

/*
    Desktop: 1x4 grid (default)
    Mobile: 2x2 grid (default)
 */
export function SkeletonGrid({
  isLoaded,
  skeleton,
  gridSize = 4,
  children,
  templateColumns,
  templateRows,
  gap = 6,
}: SkeletonGridProps) {
  if (isLoaded) return children;
  return (
    <Grid
      templateColumns={
        templateColumns ?? { base: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }
      }
      templateRows={
        templateRows ?? { base: "repeat(2, 1fr)", lg: "repeat(1, 1fr)" }
      }
      gap={gap}
    >
      {Array.from({ length: gridSize }).map((_, index) =>
        cloneElement(skeleton, { key: index }),
      )}
    </Grid>
  );
}
