import {
  Card,
  Grid,
  GridItem,
  List,
  ListItem,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import type { OpenHomeData } from "modules/vendor-portal/screens/open-home-details/models/open-home";
import {
  openHomeStatStyles,
  sortByInterestLevel,
} from "modules/vendor-portal/screens/open-home-details/utils";
import { FeedbackListItem } from "modules/vendor-portal/screens/overview/components/feedback/feedback-list-item";

type OpenHomeMobileLayoutProps = {
  isLoading: boolean;
  data?: OpenHomeData;
};
export function OpenHomeMobileLayout({
  data,
  isLoading,
}: OpenHomeMobileLayoutProps) {
  return (
    <Tabs variant={"soft-rounded"}>
      <TabList overflowX={"scroll"}>
        <Tab whiteSpace={"nowrap"}>Summary</Tab>
        <Tab whiteSpace={"nowrap"}>Feedback</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SkeletonText
            isLoaded={!isLoading}
            noOfLines={3}
            skeletonHeight={3}
            spacing={3}
            mt={5}
            mb={8}
          >
            <Text fontSize={"lg"} color={"gray.600"}>
              {data?.event.note}
            </Text>
          </SkeletonText>
          <SkeletonGrid
            isLoaded={!isLoading}
            gridSize={6}
            skeleton={<StatsCardSkeleton />}
            templateColumns={"repeat(2, 1fr)"}
            templateRows={"repeat(3, 1fr)"}
          >
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              templateRows={"repeat(3, 1fr)"}
              gap={6}
            >
              {data?.stats.map((stat) => (
                <GridItem key={stat.id} w={"100%"}>
                  <StatsCard
                    stat={stat}
                    icon={openHomeStatStyles[stat.id].icon}
                    iconBgColor={openHomeStatStyles[stat.id].bgColor}
                    iconColor={openHomeStatStyles[stat.id].color}
                  />
                </GridItem>
              ))}
            </Grid>
          </SkeletonGrid>
        </TabPanel>
        <TabPanel>
          <SkeletonList isLoaded={!isLoading} listSize={4}>
            <List gap={3}>
              {data?.event.individual_feedback
                ?.sort(sortByInterestLevel)
                .map((feedback: any) => (
                  <ListItem key={feedback.id} p={0} pb={3}>
                    <Card py={4} px={3} variant={"unstyled"}>
                      <FeedbackListItem
                        feedback={{
                          ...feedback,
                          type: data.event.type,
                          agent: data.event.agent,
                          date_of: data.event.date_of,
                        }}
                      />
                    </Card>
                  </ListItem>
                ))}
            </List>
          </SkeletonList>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
