import type { ChakraTheme } from "@chakra-ui/react";
import type { RouteObject } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import { RootComponent } from "root-component";
import {
  rootAgencySettingsRoutes,
  rootLandlordRoutes,
  rootTenantRoutes,
  rootVendorRoutes,
} from "root-routes";
import { NotFound404Screen } from "screens/not-found-404-screen";
import { AppProviders } from "components/app-providers";

export type Route = RouteObject & {
  handle?: { title?: string; theme?: ChakraTheme };
};

function initialiseRouter() {
  const routes: Route[] = [
    {
      path: "/",
      element: (
        <AppProviders>
          <RootComponent />
        </AppProviders>
      ),
      children: [
        {
          index: true,
          element: <NotFound404Screen />,
        },
        ...rootVendorRoutes,
        ...rootTenantRoutes,
        ...rootLandlordRoutes,
        ...rootAgencySettingsRoutes,
        {
          path: "*",
          element: <NotFound404Screen />,
        },
      ],
    },
  ];

  return createBrowserRouter(routes);
}

export { initialiseRouter };
