import { Center, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import campaignPending from "assets/campaign-pending.png";

export function PendingReachCampaignPlaceholder() {
  return (
    <Center p={{ base: "10px", md: "80px" }}>
      <VStack gap={4}>
        <Image
          src={campaignPending}
          alt={"pending reach campaign placeholder"}
          w={"112px"}
          h={"112px"}
        />

        <HStack
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"center"}
          fontWeight={700}
          color={"gray.600"}
          textAlign={"center"}
        >
          <Heading size={{ base: "lg", md: "2xl" }} whiteSpace={"nowrap"}>
            Sit tight!
          </Heading>
          <Heading size={{ base: "lg", md: "2xl" }}>
            The campaign has just launched
          </Heading>
        </HStack>

        <Text fontSize={"lg"} textAlign={"center"} color={"gray.600"}>
          As soon as we receive data from the campaigns, details of the
          performance will appear here
        </Text>
      </VStack>
    </Center>
  );
}
