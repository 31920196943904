import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const baseStyle = defineStyle({
  fontSize: "sm",
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: "0.6px",
  minW: "38px",
  height: "18px",
  px: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const solid = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: `${c}.800`,
    color: `white`,
  };
});

const subtle = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    bg: `${c}.100`,
    color: `${c}.800`,
  };
});

const outline = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    borderWidth: "1px",
    borderColor: `${c}.800`,
    color: `${c}.800`,
  };
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
  variants: {
    solid,
    subtle,
    outline,
  },
  defaultProps: {
    colorScheme: "gray",
    variant: "subtle",
  },
});
