import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

export function EventCardSkeleton() {
  return (
    <Box
      display={"flex"}
      flexDir={"row"}
      gap={4}
      pt={4}
      pr={{ base: 0, md: 10 }}
      pb={4}
      pl={{ base: 0, md: 6 }}
    >
      <SkeletonCircle size={"6"} />
      <SkeletonText
        w={"100%"}
        noOfLines={2}
        spacing={"4"}
        skeletonHeight={"4"}
      />
    </Box>
  );
}
