import type { JsonBodyType } from "msw";
import { delay, http, HttpResponse } from "msw";
import { setupWorker } from "msw/browser";
import { apiPrefix } from "lib/ky";

export const mockServiceWorker = setupWorker();

export function mockEndpoints<ResponseType extends JsonBodyType>(
  endpoints: Array<{
    endpoint: string;
    response: ResponseType;
  }>,
) {
  const handlers = endpoints.map((e) =>
    http.all(`${apiPrefix}${e.endpoint}`, async ({ request }) => {
      await delay(500);

      const url = new URL(request.url);
      return HttpResponse.json(
        typeof e.response === "function"
          ? e.response(url.searchParams)
          : e.response,
      );
    }),
  );
  mockServiceWorker.use(...handlers);
}
