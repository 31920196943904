import { faker } from "@faker-js/faker";
import { uniqueArray } from "lib/enforce-unique";
import { mockOwnership, type Ownership } from "types/ownership";
import { mockTenancy, type Tenancy } from "types/tenancy";

export type Property<R = NonNullable<unknown>> = {
  id: string;
  full_address: string;
  short_address: string;
  image_url: string | null;
  details: PropertyDetails;
  property_category_id: PropertyCategories;
  vacancy_status_id: VacancyStatus;
  related: {
    ownership: Ownership;
  } & R;
};

export enum VacancyStatus {
  Vacant = "vacant",
  Occupied = "occupied",
}

export enum BuildAreaUnit {
  SquareMeters = "m2",
  SquareFeet = "sqft",
  Square = "sq",
}

export enum LandAreaUnit {
  SquareMeters = "m2",
  SquareFeet = "sqft",
  Hectares = "ha",
  Acres = "ac",
}

export type PropertyDetails = {
  bedrooms: number | null;
  bathrooms: number | null;
  carports: number | null;
  build_area: number | null;
  land_area: number | null;
  build_area_unit: BuildAreaUnit | null;
  land_area_unit: LandAreaUnit | null;
};

export enum PropertyCategories {
  Residential = "residential",
  Commercial = "commercial",
}

export const mockListingImages = [
  "//au-crm.cdns.rexsoftware.com/app/livestore/accounts/740/listings/4029994/images/Photo-16-High-Res_86fb-aace-ad3f-89f9-bf0c-6d55-4913-dfad_20240425112634_800x600.jpg",
  "//au-crm.cdns.rexsoftware.com/app/livestore/accounts/740/listings/4029994/images/Photo-9-High-Res_6bb6-d947-e0f1-63d6-30c5-47a9-f956-d1ed_20240425112603_800x600.jpg",
  "//au-crm.cdns.rexsoftware.com/app/livestore/accounts/740/listings/4029994/images/Photo-21-High-Res_88af-0d75-ffdc-d39e-1769-9668-ae72-4174_20240425112654_800x600.jpg",
];

export function mockProperty(): Property {
  const [fullAddress, shortAddress] = uniqueArray([
    [
      "1184/12 Longland Street, Newstead, Queensland, 4006",
      "1184/12 Longland Street",
    ],
    ["29b Helen Street, Newstead, Queensland 4006", "29b Helen Street"],
    ["64 Woonga Drive, Ashgrove, Queensland 4060", "64 Woonga Drive"],
    ["124 Fernberg Road, Paddington, Queensland 4064", "124 Fernberg Road"],
  ]);

  return {
    id: faker.string.uuid(),
    full_address: fullAddress,
    short_address: shortAddress,
    image_url: mockListingImages[0],
    property_category_id: PropertyCategories.Residential,
    vacancy_status_id: faker.helpers.arrayElement([
      VacancyStatus.Vacant,
      VacancyStatus.Occupied,
    ]),
    related: {
      ownership: mockOwnership(),
    },
    details: {
      bedrooms: faker.number.int({ min: 2, max: 4 }),
      bathrooms: faker.number.int({ min: 1, max: 3 }),
      carports: faker.number.int({ min: 0, max: 2 }),
      build_area: faker.number.float({ min: 100, max: 2000, multipleOf: 0.01 }),
      build_area_unit: BuildAreaUnit.SquareMeters,
      land_area: faker.number.float({ min: 100, max: 2000, multipleOf: 0.01 }),
      land_area_unit: LandAreaUnit.Hectares,
    },
  };
}

export function mockPropertyWithTenancies(): Property<{
  tenancies: Tenancy[];
}> {
  return {
    ...mockProperty(),
    related: {
      ownership: mockOwnership(),
      tenancies: [mockTenancy()],
    },
  };
}
