export const textStyles = {
  body: {
    fontSize: "md",
    fontWeight: "medium",
  },
  paragraph: {},
  label: {
    fontSize: "sm",
    fontWeight: "semibold",
    color: "gray.700",
  },
  small: {
    fontSize: "sm",
    fontWeight: "medium",
  },
  medium: {
    fontSize: "md",
    fontWeight: "normal",
    lineHeight: "20px",
  },
  large: {
    fontSize: "lg",
    fontWeight: "normal",
    lineHeight: "24px",
  },
  xl: {
    fontSize: "xl",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  h1: {
    fontSize: "3xl",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "xl",
    fontWeight: "bold",
  },
  h3: {
    fontSize: "lg",
    fontWeight: "bold",
  },
  h4: {},
  h5: {},
  h6: {},
};
