/*
 Takes a numeric amount and formats it as a currency value in USD
  with zero decimal places. Eg. 100000 -> $100,000
 */
// TODO: handle localisation
export function formatMoney(amount: number | null | undefined): string {
  if (amount === null || amount === undefined) return "–";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(amount);
}
