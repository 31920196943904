import { AgentVendorFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/variants/agent-vendor-footer";
import { EnquiryFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/variants/enquiry-footer";
import { FollowUpFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/variants/follow-up-footer";
import { OpenHomeFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/variants/open-home-footer";
import { PriceReductionFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/variants/price-reduction-footer";
import { PrivateInspectionFooter } from "modules/vendor-portal/screens/overview/components/feedback/footer/variants/private-inspection-footer";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";

type FeedbackListItemFooterProps = {
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry;
};

export function FeedbackListItemFooter({
  feedback,
}: FeedbackListItemFooterProps) {
  switch (feedback.type?.id) {
    case "agent_vendor":
      return <AgentVendorFooter feedback={feedback} />;
    case "enquiry":
      return <EnquiryFooter feedback={feedback} />;
    case "followup":
      return <FollowUpFooter feedback={feedback} />;
    case "ofi":
      return <OpenHomeFooter feedback={feedback} />;
    case "inspection":
      return <PrivateInspectionFooter feedback={feedback} />;
    case "price_reduction":
      return <PriceReductionFooter feedback={feedback} />;
    default:
      return null;
  }
}
