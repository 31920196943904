import type { Stats } from "components/stats-grid";
import type { Contract } from "modules/vendor-portal/screens/offers/models/offers";
import type { MarketingCampaign } from "modules/vendor-portal/screens/performance/models/performance";
import type { ViewingEvent } from "modules/vendor-portal/types/events";
import type {
  DefaultFeedback,
  Feedback,
} from "modules/vendor-portal/types/feedback";
import type { Image } from "types/image";
import type { PaginationMeta } from "types/pagination";
import type { Property } from "types/property";

export enum ListingCategories {
  BusinessSale = "business_sale",
  CommercialSale = "commercial_sale",
  CommercialRental = "commercial_rental",
  HolidayRental = "holiday_rental",
  RuralSale = "rural_sale",
  ResidentialSale = "residential_sale",
  ResidentialRental = "residential_rental",
  LandSale = "land_sale",
  CommercialSaleRental = "commercial_sale_rental",
}

export type InterestLevelStat = {
  id: string;
  label: string;
  value: number;
};

export type Document = {
  id: string;
  description: string;
  privacy: {
    id: string;
    text: string;
  };
  type: {
    id: string;
    text: string;
  };
  upload_date: string;
  uri: string;
  url: string;
};

export type VpaLineItem = {
  id: string;
  booking_date: string;
  cost_inc_tax: number;
  is_funds_requested: string;
  listing_id: string;
  location: {
    id: string;
    text: string;
  };
  price_inc_tax: number;
  price_tax_free: number | null;
  product_category: {
    id: string;
    text: string;
  };
  product_name: string;
  project_id: string | null;
  project_stage_id: string | null;
  property_id: string | null;
  provider_name: string;
  supplier_invoice_ref: string | null;
  _id: string;
  _invoice_id: string;
};

export type VpaCampaign = {
  id: string;
  budgeted_amount: number | null;
  listing_id: string;
  project_id: string | null;
  project_stage_id: string | null;
  property_id: string | null;
  system_campaign_status: string;
  system_total_allocated: number | null;
  system_total_booked: number;
  system_total_invoiced: number | null;
  vpa_note: string | null;
  _lineitems: VpaLineItem[];
};

export enum ListingAdvertType {
  Internet = "internet",
  Brochure = "brochure",
  Stocklist = "stocklist",
}
export type ListingAdvert = {
  advert_body: string;
  advert_heading: string;
  advert_type: ListingAdvertType;
};

export enum ListingLinkType {
  VideoLink = "video_link",
  VirtualTour = "virtual_tour",
  InteractiveFloorPlan = "interactive_floorplan",
  MiniWeb = "mini_web",
  ExternalLink = "external_link",
}
export type ListingLink = {
  link_type: ListingLinkType;
  link_url: string;
  _id: number;
};

export type Listing = {
  id: string;
  listing_category_id: ListingCategories | null;
  price_advertise_as: number;
  property: Property;
  agent_updates: DefaultFeedback[];
  feedback: Feedback[];
  stats: Stats[];
  events: {
    recent: ViewingEvent[];
    upcoming: ViewingEvent[];
  };
  interest_level_stats: InterestLevelStat[];
  price_indications: number[];
  contracts?: Contract[];
  _related?: {
    listing_documents?: {
      data: Document[];
      meta: PaginationMeta;
    };
    listing_adverts?: ListingAdvert[];
    listing_floorplans?: Image[];
    listing_images?: Image[];
    listing_links?: ListingLink[];
    vpa_campaign?: VpaCampaign;
  };
  performance_stats: Stats[];
  campaigns?: MarketingCampaign[];
};
