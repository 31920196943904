import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  List,
  ListItem,
} from "@chakra-ui/react";
import { FeedbackListItem } from "modules/vendor-portal/screens/overview/components/feedback/feedback-list-item";
import type { APIListingAgentUpdateEntry } from "modules/vendor-portal/types/api-listing";

type AgentUpdatesProps = {
  isLoading: boolean;
  updates: APIListingAgentUpdateEntry[];
};
export function AgentUpdates({ updates }: AgentUpdatesProps) {
  return (
    <Card p={0}>
      <CardHeader p={6} pb={0}>
        <Heading fontSize={"xl"} fontWeight={600} lineHeight={"28px"}>
          Agent updates
        </Heading>
      </CardHeader>
      <CardBody pt={1} pb={2} px={0}>
        <List>
          {updates?.slice(0, 3).map((update) => (
            <ListItem key={update.id} pt={3} pr={10} pb={4} pl={3}>
              <FeedbackListItem feedback={update} />
            </ListItem>
          ))}
        </List>
      </CardBody>
    </Card>
  );
}
