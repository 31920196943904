import { useMemo } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import rexRealEstateLogo from "assets/a-logo.png";
import { GradientMaskedImage } from "components/gradient-masked-image";
import { generateSingularLabel } from "components/stats-card";
import { useAuth } from "contexts/auth-provider";
import { AgentFeedbackSection } from "modules/vendor-portal/screens/overview/components/welcome-presentation/agent-feedback-section";
import { FeedbackCard } from "modules/vendor-portal/screens/overview/components/welcome-presentation/feedback-card";
import { PresentationStat } from "modules/vendor-portal/screens/overview/components/welcome-presentation/presentation-stat";
import { RowSection } from "modules/vendor-portal/screens/overview/components/welcome-presentation/row-section";
import { getHighestResolutionImageSource } from "modules/vendor-portal/screens/overview/models/overview";
import type { APIListing } from "modules/vendor-portal/types/api-listing";

const INTEREST_LEVELS = {
  hot: {
    id: "hot",
    label: "Hot buyers",
    value: 3,
  },
  warm: {
    id: "warm",
    label: "Warm buyers",
    value: 2,
  },
  cold: {
    id: "cold",
    label: "Cold buyers",
    value: 1,
  },
};

type WelcomePresentationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  listing?: APIListing;
};

export function WelcomePresentationModal({
  isOpen,
  onClose,
  listing,
}: WelcomePresentationModalProps) {
  const { user } = useAuth();
  const name = user?.given_name;
  const title = `Welcome back, ${name}`;

  // For now we're setting this to a fixed value since we don't have campaign data
  const totalAdClicks = 0;

  // Create stats based on available data in APIListing
  const filteredPerformanceStats = useMemo(() => {
    const stats = [];

    // Add enquiries count if available
    if (listing?.summary?.count_of_enquiries?.value !== undefined) {
      stats.push({
        id: "total_enquiries",
        value: listing.summary.count_of_enquiries.value,
        label: "Total enquiries",
      });
    }

    // Add total ad clicks stat
    stats.unshift({
      id: "total_ad_clicks",
      value: totalAdClicks,
      label: "Total ad clicks",
    });

    return stats;
  }, [listing?.summary, totalAdClicks]);

  // Create interest level stats from summary if available
  const filteredInterestLevelStats = useMemo(() => {
    const stats = [];

    if (listing?.summary?.count_of_feedback_by_interest_level?.value) {
      for (const stat of listing.summary.count_of_feedback_by_interest_level
        .value) {
        if (["hot", "warm"].includes(stat.id)) {
          stats.push({
            id: stat.id,
            value: stat.value,
            label: INTEREST_LEVELS[stat.id as "hot" | "warm" | "cold"].label,
          });
        }
      }
    }

    return stats;
  }, [listing?.summary]);

  // Create event stats from available data in APIListing
  const filteredEventStats = useMemo(() => {
    const stats = [];

    // Add open homes stat if available
    if (
      listing?.summary?.count_of_inspections_and_open_homes?.value !== undefined
    ) {
      stats.push({
        id: "total_open_homes",
        value: listing.summary.count_of_inspections_and_open_homes.value,
        label: "Total open homes",
      });
    }

    // Add attendees stat if available
    if (listing?.summary?.count_of_unique_attendees?.value !== undefined) {
      stats.push({
        id: "total_attendees",
        value: listing.summary.count_of_unique_attendees.value,
        label: "Total attendees",
      });
    }

    // Add duplicate inspections stat if available
    if (
      listing?.summary?.count_of_attendees_attending_more_than_once?.value !==
      undefined
    ) {
      stats.push({
        id: "duplicate_inspections",
        value:
          listing.summary.count_of_attendees_attending_more_than_once.value,
        label: "Duplicate inspections",
      });
    }

    return stats;
  }, [listing?.summary]);

  const openHomeEventStats = [
    ...filteredEventStats,
    ...filteredInterestLevelStats,
  ];

  const sortedFeedbackNotes = useMemo(() => {
    return listing?.events
      ?.filter((event) => event.feedback?.note)
      ?.sort((a, b) => {
        const interestLevelValueA = a.feedback?.interest_level?.id
          ? INTEREST_LEVELS[
              a.feedback.interest_level.id as keyof typeof INTEREST_LEVELS
            ]?.value ?? 0
          : 0;
        const interestLevelValueB = b.feedback?.interest_level?.id
          ? INTEREST_LEVELS[
              b.feedback.interest_level.id as keyof typeof INTEREST_LEVELS
            ]?.value ?? 0
          : 0;

        return interestLevelValueB - interestLevelValueA;
      })
      .slice(0, 3);
  }, [listing?.events]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={false} size={"full"}>
      <ModalContent
        pos={"relative"}
        h={"100%"}
        borderRadius={"none"}
        overflowY={"scroll"}
        overflowX={"hidden"}
      >
        <ModalHeader p={0}>
          <Box
            pos={"relative"}
            h={"100vh"}
            w={"100vw"}
            bg={!listing?.marketing?.images ? "gray.50" : undefined}
          >
            <GradientMaskedImage
              imageUrl={
                listing?.marketing?.images.length
                  ? getHighestResolutionImageSource(
                      listing?.marketing?.images[0],
                    )?.url
                  : undefined
              }
              style={{
                borderRadius: "none",
                height: "100vh",
              }}
              bgGradient={listing?.marketing?.images.length ? true : undefined}
              gradientStyle={{ height: "100%" }}
            >
              <VStack
                h={"100%"}
                py={{ base: "40px", md: "100px" }}
                px={{ base: "10px", md: "100px" }}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Image
                  src={rexRealEstateLogo}
                  alt={"Rex Real Estate"}
                  w={"min-content"}
                  h={8}
                />
                <VStack gap={{ base: 4, md: 8, lg: 12 }} color={"white"}>
                  <Text
                    fontSize={{ base: "6xl", md: "56px" }}
                    fontWeight={{ base: 700 }}
                    lineHeight={{ base: "6xl", md: "56px" }}
                  >
                    {title}
                  </Text>
                  <Text fontSize={{ base: "3xl", md: "5xl" }} fontWeight={400}>
                    Here&apos;s what&apos;s been happening in the past week
                  </Text>
                </VStack>
                <VStack color={"white"}>
                  <Text fontSize={"14px"} fontWeight={400}>
                    Scroll to discover
                  </Text>
                  <ChevronDownIcon w={8} h={8} />
                </VStack>
              </VStack>
            </GradientMaskedImage>
          </Box>
        </ModalHeader>
        {/* You must have the ModalCloseButton to ensure the modal opens at the
        correct scroll position. The z-index/opacity here is a hack to hide the close button.
        Display and visibility won't work. Chakra doesn't like us doing that. */}
        <ModalCloseButton zIndex={"-999"} opacity={0} />
        <ModalBody pos={"relative"} h={"auto"} p={0}>
          <Box
            w={"100%"}
            pos={"sticky"}
            top={"0"}
            zIndex={"sticky"}
            p={6}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            bg={"gray.900"}
          >
            <Image
              src={rexRealEstateLogo}
              alt={"Rex Real Estate"}
              w={"auto"}
              h={8}
            />
            <Button
              variant={"solid"}
              bg={"gray.100"}
              color={"gray.700"}
              _hover={{ bg: "gray.200" }}
              onClick={onClose}
            >
              Exit
            </Button>
          </Box>

          <Box h={"auto"} w={"100%"}>
            {listing?.agent_updates?.last_3_entries?.[0] ? (
              <VStack
                minH={"100vh"}
                gap={14}
                p={{ base: 10, sm: 16, md: 20 }}
                justify={"center"}
              >
                <AgentFeedbackSection
                  profileImage={
                    // @ts-expect-error - profile_image isn't in AgentUser type
                    listing.agent_updates.last_3_entries[0].agent?.profile_image
                      ?.url ?? ""
                  }
                  bodyText={listing.agent_updates.last_3_entries[0].note ?? ""}
                  agentName={
                    listing.agent_updates.last_3_entries[0].agent?.name ?? ""
                  }
                />
              </VStack>
            ) : null}

            {(listing?.marketing?.images.length || 0) > 1 && (
              <Box minH={"100vh"}>
                <Image
                  src={
                    getHighestResolutionImageSource(
                      listing?.marketing?.images[1],
                    )?.url
                  }
                  alt={"Secondary listing image"}
                  style={{
                    height: "100vh",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </Box>
            )}

            <VStack
              minH={"100vh"}
              p={{ base: 10, sm: 16, md: 20 }}
              justify={"center"}
              align={"center"}
              bg={
                (listing?.marketing?.images.length || 0) < 1
                  ? "gray.50"
                  : undefined
              }
            >
              <RowSection heading={"Open Home Stats"}>
                {openHomeEventStats.map((stat, index) => (
                  <PresentationStat
                    key={index}
                    value={stat.value}
                    label={stat.label}
                    count={openHomeEventStats.length}
                  />
                ))}
              </RowSection>
            </VStack>

            {(listing?.marketing?.images.length || 0) > 2 && (
              <Box minH={"100vh"}>
                <Image
                  src={
                    getHighestResolutionImageSource(
                      listing?.marketing?.images[2],
                    )?.url
                  }
                  alt={"Tertiary listing image"}
                  style={{
                    height: "100vh",
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
              </Box>
            )}

            {sortedFeedbackNotes && sortedFeedbackNotes.length > 0 && (
              <VStack
                minH={"100vh"}
                p={{ base: 10, sm: 16, md: 20 }}
                justify={"center"}
                align={"center"}
              >
                <RowSection heading={"What have people been saying?"}>
                  {sortedFeedbackNotes.map((event, index) => (
                    <FeedbackCard
                      key={index}
                      feedbackNote={event.feedback.note}
                      attendeeName={
                        event.feedback.attendee_count > 0
                          ? `${event.feedback.attendee_count} attendees`
                          : "No attendees"
                      }
                      dateOfAttendance={event.starts_at}
                      count={sortedFeedbackNotes.length}
                    />
                  ))}
                </RowSection>
              </VStack>
            )}

            <VStack
              pos={"relative"}
              minH={"100vh"}
              pt={{ base: 10, sm: 16, md: 20 }}
              pb={0}
              px={{ base: 10, sm: 16, md: 20 }}
              justify={"center"}
              align={"center"}
              bg={
                (listing?.marketing?.images.length || 0) < 1
                  ? "gray.50"
                  : undefined
              }
            >
              <RowSection
                heading={
                  <>
                    Your home was clicked <strong>1000 times</strong> online
                    this week
                  </>
                }
              >
                {filteredPerformanceStats.map((stat, index) => (
                  <PresentationStat
                    key={index}
                    value={stat.value}
                    label={generateSingularLabel(
                      stat.label,
                      stat.value as number,
                    )}
                    count={filteredPerformanceStats.length}
                  />
                ))}
              </RowSection>
              <ModalFooter
                pos={"absolute"}
                bottom={"6"}
                border={"none"}
                justifyContent={"center"}
                p={{ base: 0, sm: 6 }}
              >
                <Button
                  variant={"solid"}
                  bg={"gray.100"}
                  color={"gray.700"}
                  _hover={{ bg: "gray.200" }}
                  onClick={onClose}
                >
                  Go to Overview
                </Button>
              </ModalFooter>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
