import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { MaintenanceList } from "components/maintenance-list/maintenance-list";
import { Page } from "components/page";
import { PropertySelector } from "modules/landlord-portal/components/property-selector";
import { landlordMaintenanceModel } from "modules/landlord-portal/models/maintenance";

export function MaintenanceScreen() {
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useQuery(
    landlordMaintenanceModel.queries.read(searchParams),
  );

  return (
    <Page heading={"Maintenance"} rightElement={<PropertySelector />}>
      <MaintenanceList
        isLoading={isLoading}
        maintenanceTasks={data?.maintenanceTasks ?? []}
      />
    </Page>
  );
}
