import { mockBankAccount, type BankAccount } from "types/bank-account";
import { mockProperty, type Property } from "types/property";
import { mockTenancy, type Tenancy } from "types/tenancy";
import {
  mockTenancyPaymentHistoryItem,
  type TenancyPaymentHistoryItem,
} from "types/tenancy-payment-history-item";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type PaymentsData = {
  payment_history_items: TenancyPaymentHistoryItem[];
  property: Property;
  tenancy: Tenancy;
  bank_account: BankAccount;
};

export const tenancyPaymentsModel = createModel("tenancyPayments", {
  queries: {
    read: (propertyId?: string) => ({
      queryKey: ["read", propertyId],
      queryFn: () => {
        return apiClient.get<PaymentsData>(`/tenant/${propertyId}/payments`);
      },
      enabled: !!propertyId,
      keepPreviousData: true,
    }),
  },
});

export const mockPaymentsResponse = () => {
  return {
    payment_history_items: Array.from({ length: 12 }).map(() =>
      mockTenancyPaymentHistoryItem(),
    ),
    property: mockProperty(),
    tenancy: mockTenancy(),
    bank_account: mockBankAccount(),
  };
};
