import { faker } from "@faker-js/faker";
import {
  EnforceLimitError,
  EnforceUniqueError,
  UniqueEnforcer,
  type UniqueComparable,
} from "enforce-unique";

const uniqueEnforcer = new UniqueEnforcer();

export function uniqueArray<T extends UniqueComparable>(array: T[]): T {
  try {
    return uniqueEnforcer.enforce<T>(() => {
      return faker.helpers.arrayElement(array);
    });
  } catch (e) {
    if (e instanceof EnforceUniqueError || e instanceof EnforceLimitError) {
      return faker.helpers.arrayElement(array);
    } else {
      throw e;
    }
  }
}
