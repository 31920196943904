import { faker } from "@faker-js/faker";
import { fakerDateString } from "lib/faker";
import type { DateString } from "types/api";
import { mockContact, type Contact } from "types/contact";
import type { Image } from "types/image";
import { mockProperty } from "types/property";
import { TaskPriority, TaskStatus, type Task } from "types/task";

export enum MaintenanceTaskStatus {
  JobLogged = "job_logged",
  QuotesRequested = "quotes_requested",
  QuotesReceived = "quotes_received",
  QuotesApproved = "quotes_approved",
  QuotesIssued = "quotes_issued",
  WorkOrderIssued = "work_order_issued",
  WorkOrderAssigned = "work_order_assigned",
  WorkFinished = "work_finished",
}

export type MaintenanceTask = Task<{
  notes?: string;
  work_assigned?: boolean;
  work_assigned_to?: Contact;
  access_provided_by?: Contact;
  completion_date?: DateString;
  reported_date?: DateString;
  status_id: MaintenanceTaskStatus;
  reported_by?: Contact;
  images?: Image[];
}>;

// Mock a maintenance task user the faker library for everything
export const mockMaintenanceTask = (): MaintenanceTask => {
  return {
    id: faker.string.uuid(),
    summary: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    status_id: faker.helpers.arrayElement([
      TaskStatus.NotStarted,
      TaskStatus.InProgress,
      TaskStatus.Completed,
    ]),
    priority_id: faker.helpers.arrayElement([
      TaskPriority.Preventative,
      TaskPriority.Normal,
      TaskPriority.Urgent,
      TaskPriority.Emergency,
    ]),
    due_date: fakerDateString(),
    follow_up_date: fakerDateString(),
    managed_by: mockContact(),
    closed_at: fakerDateString(),
    property: mockProperty(),
    created_at: fakerDateString(),
    updated_at: fakerDateString(),
    details: {
      notes: faker.lorem.paragraph(),
      work_assigned: faker.datatype.boolean(),
      work_assigned_to: mockContact(),
      access_provided_by: mockContact(),
      completion_date: fakerDateString(),
      reported_date: fakerDateString(),
      status_id: faker.helpers.arrayElement([
        MaintenanceTaskStatus.JobLogged,
        MaintenanceTaskStatus.QuotesRequested,
        MaintenanceTaskStatus.QuotesReceived,
        MaintenanceTaskStatus.QuotesApproved,
        MaintenanceTaskStatus.QuotesIssued,
        MaintenanceTaskStatus.WorkOrderIssued,
        MaintenanceTaskStatus.WorkOrderAssigned,
        MaintenanceTaskStatus.WorkFinished,
      ]),
      reported_by: mockContact(),
      images: Array.from({ length: 3 }).map((_i, idx) => ({
        priority: idx + 1,
        id: faker.string.uuid(),
        url: faker.image.urlPicsumPhotos({ width: 1440, height: 1080 }),
        thumbnails: {
          "800x600": {
            url: faker.image.urlPicsumPhotos({ width: 800 }),
          },
        },
      })),
    },
  };
};
