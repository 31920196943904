import { Badge, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { InterestLevelBadge } from "modules/vendor-portal/components/interest-level-badge";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type { PrivateInspectionFeedback } from "modules/vendor-portal/types/feedback";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";

type PrivateInspectionEventFooterProps = {
  feedback: PrivateInspectionFeedback;
  isTruncated?: boolean;
};
export function PrivateInspectionEventInsetFooter({
  feedback,
  isTruncated,
}: PrivateInspectionEventFooterProps) {
  return (
    <CardRecordMeta wrap={isTruncated ? "nowrap" : "wrap"}>
      <FooterText
        text={`${feedback.attendees[0]?.name ?? feedback.agent.name}`}
      />

      {feedback.interest_level ? (
        <InterestLevelBadge interestLevel={feedback.interest_level} />
      ) : null}

      {!feedback.price_indication ? null : (
        <FooterText
          text={`Price indication ${formatMoney(feedback.price_indication)}`}
        />
      )}

      <Flex direction={"row"} align={"center"} gap={2}>
        <FooterText
          text={dayjs(feedback.date_of).format(dateFormats.dayMonthYear)}
        />

        {feedback.is_new ? (
          <Badge colorScheme={"badgeLightBlue"}>NEW</Badge>
        ) : null}
      </Flex>
    </CardRecordMeta>
  );
}
