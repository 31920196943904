import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    bg: "none",
    _focus: {
      bg: "none",
    },
  },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
