import { Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import { CardRecord } from "components/cards/card-record";

export function MaintenanceListCardSkeleton() {
  return (
    <CardRecord
      leftElement={
        <Skeleton
          borderRadius={"lg"}
          w={["90px", "160px"]}
          aspectRatio={4 / 3}
          bg={"gray.100"}
          alignItems={"center"}
          justifyContent={"center"}
          overflow={"hidden"}
          flexBasis={["100%", "auto"]}
          display={"flex"}
        />
      }
    >
      <Flex direction={"column"} gap={[4, 2.5]}>
        <SkeletonText noOfLines={1} w={"40%"} skeletonHeight={6} />
        <Flex wrap={"wrap"} gap={2} direction={["column", "column", "row"]}>
          <SkeletonText noOfLines={1} w={"140px"} skeletonHeight={5} />
          <SkeletonText noOfLines={1} w={"140px"} skeletonHeight={5} />
          <SkeletonText noOfLines={1} w={"170px"} skeletonHeight={5} />
        </Flex>
        <SkeletonText noOfLines={2} w={"100%"} skeletonHeight={4} />
      </Flex>
    </CardRecord>
  );
}
