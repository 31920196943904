import { useEffect, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Image as ChakraImage,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import type { Image } from "types/image";

type ImageGalleryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  images: Image[];
  currentModalImage: number;
  setCurrentModalImage: (value: number) => void;
};
export function ImageGalleryModal({
  isOpen,
  onClose,
  images,
  currentModalImage,
  setCurrentModalImage,
}: ImageGalleryModalProps) {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      const selectedImage = scrollRef.current.children[currentModalImage];
      if (selectedImage) {
        selectedImage.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [currentModalImage]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ md: "3xl", lg: "4xl", xl: "6xl" }}
    >
      <ModalOverlay />
      <ModalContent pt={4}>
        <ModalHeader p={0} fontWeight={400}>
          <Center h={"40px"}>
            {`${currentModalImage + 1}/${images?.length}`}
          </Center>
        </ModalHeader>

        <ModalCloseButton top={4} right={6} size={"lg"} />

        <ModalBody p={6}>
          <VStack gap={6} w={"100%"}>
            <HStack>
              <IconButton
                variant={"ghost"}
                aria-label={"scroll left"}
                fontSize={"32px"}
                icon={<ChevronLeftIcon />}
                onClick={() =>
                  setCurrentModalImage(
                    currentModalImage === 0
                      ? images?.length - 1
                      : currentModalImage - 1,
                  )
                }
              />

              <Box bg={"black"}>
                <ChakraImage
                  w={"940px"}
                  aspectRatio={4 / 3}
                  fit={"contain"}
                  src={images?.[currentModalImage]?.url}
                  alt={`Listing image #${currentModalImage + 1}`}
                />
              </Box>

              <IconButton
                variant={"ghost"}
                aria-label={"scroll right"}
                fontSize={"32px"}
                icon={<ChevronRightIcon />}
                onClick={() =>
                  setCurrentModalImage(
                    currentModalImage === images?.length - 1
                      ? 0
                      : currentModalImage + 1,
                  )
                }
              />
            </HStack>
            <Box px={"48px"}>
              <HStack gap={6} overflowX={"scroll"} ref={scrollRef}>
                {images?.map((image, idx) => (
                  <ChakraImage
                    key={image.id}
                    w={"136px"}
                    h={"88px"}
                    fit={"cover"}
                    opacity={idx === currentModalImage ? 1 : 0.5}
                    src={image?.url}
                    alt={`Listing image #${idx + 1}`}
                    onClick={() => setCurrentModalImage(idx)}
                  />
                ))}
              </HStack>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
