import { faker } from "@faker-js/faker";
import type { Contact } from "types/contact";
import { mockFile, type File } from "types/file";

export type ComplianceEntry = {
  id: string;
  details: string;
  issued_at: string;
  expires_at: string;
  object_type: string;
  object_id: string;
  setting_compliance_type_id: string;
  compliance_type: ComplianceType;
  status_id: ComplianceEntryStatus;
  supplier?: Contact | null;
  file: File;
};

export enum ComplianceEntryStatus {
  Archived = "archived",
  ExpiresSoon = "expires_soon",
  Valid = "valid",
  Expired = "expired",
}

export type ComplianceType = {
  id: string;
  label: string;
  description: string;
  category_id: string;
};

export function mockComplianceEntry(): ComplianceEntry {
  return {
    id: faker.string.uuid(),
    details: faker.lorem.sentence(),
    issued_at: faker.date.recent().toDateString(),
    expires_at: faker.date.recent().toDateString(),
    object_type: faker.helpers.arrayElement(["property", "tenant"]),
    object_id: faker.string.uuid(),
    setting_compliance_type_id: faker.string.uuid(),
    compliance_type: {
      id: faker.string.uuid(),
      label: faker.lorem.sentence(),
      description: faker.lorem.sentence(),
      category_id: faker.string.uuid(),
    },
    status_id: faker.helpers.arrayElement([
      ComplianceEntryStatus.Archived,
      ComplianceEntryStatus.ExpiresSoon,
      ComplianceEntryStatus.Valid,
      ComplianceEntryStatus.Expired,
    ]),
    supplier: null,
    file: mockFile(),
  };
}
