import { useBreakpoint } from "@chakra-ui/react";
import dayjs from "dayjs";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { FeedbackTypeTag } from "modules/vendor-portal/components/feedback-type-tag";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";
import { dateFormats } from "utils/date";

type PriceReductionFooterProps = {
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry;
};

export function PriceReductionFooter({ feedback }: PriceReductionFooterProps) {
  const breakpoint = useBreakpoint({ ssr: false });
  const isMobile = breakpoint === "base" || breakpoint === "sm";

  return (
    <CardRecordMeta>
      <FeedbackTypeTag feedbackType={feedback.type} />

      {"date_of" in feedback && (
        <FooterText
          text={dayjs(feedback.date_of).format(dateFormats.dayMonthYear)}
        />
      )}

      {isMobile ? null : <FooterText text={`Agent ${feedback.agent.name}`} />}
    </CardRecordMeta>
  );
}
