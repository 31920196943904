import type { As } from "@chakra-ui/react";
import type { Duration } from "dayjs/plugin/duration";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import FlameIcon from "assets/icons/medium/flame.svg?react";
import type { IndividualAttendeeFeedback } from "modules/vendor-portal/types/feedback";

const interestLevelOrder: { [key: string]: number } = {
  hot: 1,
  warm: 2,
  cold: 3,
};

export function sortByInterestLevel(
  a: IndividualAttendeeFeedback,
  b: IndividualAttendeeFeedback,
) {
  const interestA = interestLevelOrder[a.interest_level?.id ?? ""];
  const interestB = interestLevelOrder[b.interest_level?.id ?? ""];

  // Handle cases where interestLevel is null
  if (interestA === undefined && interestB === undefined) return 0;
  if (interestA === undefined) return 1;
  if (interestB === undefined) return -1;

  return interestA - interestB;
}

/*
 * Format a dayjs duration object to an hours and minutes string
 *
 * Note: dayjs provides a humanize method that can also be used however
 * if the duration is between 45min and 59min it will return "an hour"
 * which is not accurate.
 */
export function formatDuration(duration: Duration) {
  const hours = duration.get("hours");
  const minutes = duration.get("minutes");

  if (hours > 0 || minutes > 0) {
    const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : "";
    const minutesString =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";

    return `${hoursString} ${minutesString}`.trim();
  }

  return "less than a minute";
}

export const openHomeStatStyles: {
  [key: string]: { bgColor: string; color: string; icon: As };
} = {
  attendees: { bgColor: "indigo.100", color: "gray.700", icon: BarChartIcon },
  "multiple-inspections": {
    bgColor: "indigo.100",
    color: "gray.700",
    icon: BarChartIcon,
  },
  "avg-price-indication": {
    bgColor: "indigo.100",
    color: "gray.700",
    icon: BarChartIcon,
  },
  "hot-buyers": { bgColor: "red.100", color: "red.500", icon: FlameIcon },
  "warm-buyers": {
    bgColor: "orange.100",
    color: "orange.500",
    icon: FlameIcon,
  },
  "cold-buyers": { bgColor: "blue.50", color: "blue.500", icon: FlameIcon },
};
