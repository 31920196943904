import { CopyIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FormField } from "components/form-field";
import { Table } from "components/table/table";
import { TENANT_PORTAL_PATHS } from "modules/tenant-portal/paths";
import { UNKNOWN_VALUE_MAP } from "utils/string";

export function EmergencyFields() {
  const { setValue } = useClipboard("");

  return (
    <Flex direction={"column"} gap={6} bg={"red.50"} p={6} borderRadius={"lg"}>
      <Heading size={"md"} fontWeight={"bold"}>
        For emergencies, contact us or our preferred tradesmen
      </Heading>
      <Text color={"gray.600"}>
        For emergency maintenance, contact us or our preferred tradesmen
        directly. You can also refer to your handbook for troubleshooting
        guides.{" "}
        <Link to={`../${TENANT_PORTAL_PATHS.HANDBOOK}`}>
          <Button size={"md"} variant={"link"} color={"blue.600"}>
            Go to handbook
          </Button>
        </Link>
      </Text>
      <Box>
        <FormLabel>Contact</FormLabel>
        <Flex direction={"row"} gap={1}>
          <Input disabled value={UNKNOWN_VALUE_MAP}></Input>
          <IconButton
            aria-label={"Copy"}
            variant={"ghost"}
            colorScheme={"gray"}
            icon={<CopyIcon boxSize={4} />}
            onClick={() => setValue(UNKNOWN_VALUE_MAP)}
          />
        </Flex>
      </Box>
      <Box bg={"white"} borderRadius={"md"} overflow={"hidden"} borderWidth={1}>
        <Table
          columns={[
            { label: "Name", id: "name" },
            { label: "Company", id: "company" },
            { label: "Phone number", id: "phone" },
          ]}
          rows={[
            {
              name: UNKNOWN_VALUE_MAP,
              company: UNKNOWN_VALUE_MAP,
              phone: UNKNOWN_VALUE_MAP,
              id: "1",
            },
          ]}
        />
      </Box>
      <Alert status={"error"} alignItems={"flex-start"} bg={"white"}>
        <AlertIcon mt={1} />
        <Flex direction={"column"}>
          <AlertTitle>For emergency services</AlertTitle>
          <AlertDescription>
            Call Triple Zero (000) if you are in critical need of emergency
            services (police, fire or ambulance).
          </AlertDescription>
        </Flex>
      </Alert>
      <FormField
        name={"send_request_as_well"}
        Input={Checkbox}
        inputProps={{
          children: "Send request as well",
        }}
      />
    </Flex>
  );
}
