import { useRef, useState } from "react";
import {
  Center,
  Image as ChakraImage,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";
import type { Image } from "types/image";

type MobileImageGalleryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  images: Image[];
};
export function MobileImageGalleryModal({
  isOpen,
  onClose,
  images,
}: MobileImageGalleryModalProps) {
  const [currentModalImage, setCurrentModalImage] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);

  function handleScroll() {
    const container = scrollRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    const scrollLeft = container.scrollLeft;

    const imagesWidth = container.scrollWidth;
    const imageWidth = imagesWidth / images.length;

    const currentIndex = Math.floor(
      (scrollLeft + containerWidth / 2) / imageWidth,
    );

    if (currentIndex !== currentModalImage) {
      setCurrentModalImage(currentIndex);
    }
  }

  function handleClose() {
    onClose();
    setCurrentModalImage(0);
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={"full"}>
      <ModalContent>
        <ModalHeader
          fontWeight={400}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
        >
          <Center h={"40px"}>
            {`${currentModalImage + 1}/${images?.length}`}
          </Center>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody
          h={"100%"}
          p={0}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <HStack
            ref={scrollRef}
            gap={6}
            w={"100%"}
            overflowX={"scroll"}
            onScroll={handleScroll}
            scrollSnapType={"x mandatory"}
          >
            {images?.map((image, idx) => (
              <ChakraImage
                key={image.id}
                w={"100%"}
                fit={"cover"}
                src={image?.thumbnails["800x600"].url}
                alt={`Listing image #${idx + 1}`}
                scrollSnapAlign={"start"}
              />
            ))}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
