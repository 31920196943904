import type { ComponentProps, ReactNode } from "react";
import { Card, CardBody, CardHeader, Heading } from "@chakra-ui/react";

type PageCardProps = {
  heading?: string;
  children: ReactNode;
  cardProps?: ComponentProps<typeof Card>;
};

export function PageCard({ children, heading, cardProps }: PageCardProps) {
  return (
    <Card
      h={"100%"}
      variant={{ base: "unstyled", md: "outline" }}
      {...cardProps}
    >
      {heading ? (
        <CardHeader p={6} pb={0} pt={8}>
          <Heading fontSize={"xl"}>{heading}</Heading>
        </CardHeader>
      ) : null}
      <CardBody p={6}>{children}</CardBody>
    </Card>
  );
}
