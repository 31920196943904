import type { ComponentProps } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";

type DateRangeInputProps = {
  value: Date[];
  onChange: (dates: Date[]) => void;
};

const theme: ComponentProps<typeof RangeDatepicker>["propsConfigs"] = {
  dateNavBtnProps: {
    color: "gray.600",
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      fontSize: "md",
      color: "gray.700",
      _hover: {
        backgroundColor: "indigo.500",
        color: "white",
      },
    },
    isInRangeBtnProps: {
      backgroundColor: "indigo.100",
      _hover: {
        backgroundColor: "indigo.500",
        color: "white",
      },
    },
    selectedBtnProps: {
      backgroundColor: "indigo.500",
      color: "white",
    },
    todayBtnProps: {},
  },
  inputProps: {
    _hover: { bg: "gray.200" },
    w: "220px",
    textAlign: "left",
    fontWeight: "medium",
    cursor: "pointer",
    style: {
      caretColor: "transparent",
      userSelect: "none",
    },
  },
  popoverCompProps: {
    popoverContentProps: {},
    popoverBodyProps: {},
  },
  calendarPanelProps: {
    wrapperProps: {
      borderWidth: 0,
    },
    contentProps: {
      borderRadius: 6,
    },
    headerProps: {},
    dividerProps: {},
  },
  weekdayLabelProps: {
    color: "gray.600",
    fontWeight: "medium",
  },
  dateHeadingProps: {},
};

export function DateRangeInput({ value, onChange }: DateRangeInputProps) {
  return (
    <Flex align={"center"} pos={"relative"}>
      <RangeDatepicker
        configs={{
          dateFormat: "d MMM yyyy",
        }}
        selectedDates={value}
        onDateChange={onChange}
        propsConfigs={theme}
      />
      <ChevronDownIcon
        boxSize={"18px"}
        pos={"absolute"}
        right={"8px"}
        zIndex={2}
      />
    </Flex>
  );
}
