import { Flex, Text, VStack } from "@chakra-ui/react";
import { PercentageBar } from "modules/vendor-portal/components/percentage-bar";
import type {
  PriceIndicationGroup,
  PriceIndicationRange,
} from "modules/vendor-portal/types/price-indication";
import { formatMoney } from "utils/format-money";

type DetailedPriceIndicationProps = {
  priceRange: PriceIndicationRange;
  priceGroups: PriceIndicationGroup[];
};
export function DetailedPriceIndication({
  priceRange,
  priceGroups,
}: DetailedPriceIndicationProps) {
  return (
    <Flex direction={"column"} gap={5} w={"100%"}>
      <Flex direction={"row"} justify={"space-between"} align={"center"}>
        <VStack gap={0}>
          <Text fontSize={"md"} fontWeight={700} color={"gray.700"}>
            {`${formatMoney(priceRange.minValue)}`}
          </Text>
          <Text
            fontSize={"sm"}
            color={"gray.600"}
            w={"100%"}
            textAlign={"start"}
          >
            Lowest
          </Text>
        </VStack>
        <VStack gap={0}>
          <Text fontSize={"md"} fontWeight={700} color={"gray.700"}>
            {`${formatMoney(priceRange.averageValue)}`}
          </Text>
          <Text fontSize={"sm"} color={"gray.600"} textAlign={"center"}>
            Most common
          </Text>
        </VStack>
        <VStack gap={0}>
          <Text fontSize={"md"} fontWeight={700} color={"gray.700"}>
            {`${formatMoney(priceRange.maxValue)}`}
          </Text>
          <Text fontSize={"sm"} color={"gray.600"} w={"100%"} textAlign={"end"}>
            Highest
          </Text>
        </VStack>
      </Flex>

      <Flex direction={"column"} justify={"center"} gap={3}>
        <PercentageBar
          label={`< ${formatMoney(priceGroups[0].end)}`}
          percentage={priceGroups[0].percentage}
        />

        <PercentageBar
          label={`${formatMoney(priceGroups[1].start)} - ${formatMoney(
            priceGroups[1].end,
          )}`}
          percentage={priceGroups[1].percentage}
        />

        <PercentageBar
          label={`${formatMoney(priceGroups[2].start)} - ${formatMoney(
            priceGroups[2].end,
          )}`}
          percentage={priceGroups[2].percentage}
        />

        <PercentageBar
          label={`${formatMoney(priceGroups[3].start)} +`}
          percentage={priceGroups[3].percentage}
        />
      </Flex>
    </Flex>
  );
}
