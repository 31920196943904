import { Box, Card, Flex, HStack, Icon, Tag, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import DollarIcon from "assets/icons/medium/dollar.svg?react";
import PinIcon from "assets/icons/medium/pin.svg?react";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { useAuth } from "contexts/auth-provider";
import type { Contract } from "modules/vendor-portal/screens/offers/models/offers";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { upperFirst } from "utils/string";

const colorMap: { [key: string]: string } = {
  accepted: "badgeBlue",
  unconditional: "badgeGreen",
  settled: "badgeGreen",
  offer: "gray",
  fallen: "badgeOrange",
};

const UkColorMap: { [key: string]: string } = {
  communicated: "badgeBlue",
  accepted: "badgeGreen",
  exchanged: "badgeGreen",
  completed: "badgeGreen",
  fallen: "badgeOrange",
  withdrawn: "badgeOrange",
  rejected: "badgeOrange",
};

type OfferListItemProps = {
  contract: Contract;
};
export function OfferListItem({ contract }: OfferListItemProps) {
  const { user } = useAuth();
  const regionalColorMap = user?.region === "uk" ? UkColorMap : colorMap;

  const isFirstBackup =
    !contract._is_primary_accepted && contract.is_primary_backup_offer;

  const isBackup =
    !contract._is_primary_accepted &&
    !contract.is_primary_backup_offer &&
    contract.is_backup_offer;

  return (
    <Card
      pt={3}
      pr={10}
      pb={4}
      pl={3}
      variant={{ base: "unstyled", md: "outline" }}
    >
      <Flex direction={"column"} gap={2} justify={"flex-start"}>
        {contract._is_primary_accepted ? (
          <HStack>
            <Box
              minW={{ base: "16px", md: "40px" }}
              h={"24px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon as={PinIcon} boxSize={"16px"} />
            </Box>
            <Text fontSize={"md"} fontWeight={400} color={"gray.400"}>
              Pinned to top
            </Text>
          </HStack>
        ) : null}

        <Flex direction={"row"} gap={2} justify={"flex-start"}>
          <FeedbackIcon icon={DollarIcon} />

          <Flex direction={"column"} gap={2} flexGrow={1}>
            <Flex
              direction={"row"}
              align={"center"}
              justify={"space-between"}
              gap={2}
            >
              <Text textStyle={"large"} fontWeight={500} color={"gray.700"}>
                {contract.purchtenant_legal_name}
              </Text>
              <Text
                textStyle={"large"}
                fontWeight={"semibold"}
                color={"gray.700"}
              >
                {formatMoney(contract.detail_sale_price_or_lease_pa ?? 0)}
              </Text>
            </Flex>
            <Flex direction={"column"} gap={3}>
              <CardRecordMeta>
                {contract._contract_status === "offer" ? null : (
                  <Tag
                    colorScheme={regionalColorMap[contract._contract_status]}
                    fontSize={"sm"}
                    fontWeight={600}
                    color={`${regionalColorMap[contract._contract_status]}.800`}
                    lineHeight={"16px"}
                    py={0.5}
                    px={2}
                    whiteSpace={"nowrap"}
                  >
                    {contract.fallen_reason?.text ??
                      upperFirst(contract._contract_status)}
                  </Tag>
                )}

                {contract._is_primary_accepted ? (
                  <Text fontSize={"md"} fontWeight={400}>
                    Active
                  </Text>
                ) : null}

                {isFirstBackup ? (
                  <Text fontSize={"md"} fontWeight={400}>
                    1st backup
                  </Text>
                ) : null}

                {isBackup ? (
                  <Text fontSize={"md"} fontWeight={400}>
                    Backup
                  </Text>
                ) : null}

                {contract._contract_status === "fallen" ? (
                  <Text fontSize={"md"} fontWeight={400}>
                    Fallen on{" "}
                    {dayjs(contract.date_actual_fallen).format(
                      dateFormats.dayMonthYear,
                    )}
                  </Text>
                ) : (
                  <Text fontSize={"md"} fontWeight={400}>
                    {dayjs(contract.date_actual_offer).format(
                      dateFormats.dayMonthYear,
                    )}
                  </Text>
                )}
              </CardRecordMeta>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
