import type {
  PriceIndicationGroup,
  PriceIndicationRange,
} from "modules/vendor-portal/types/price-indication";

export function deriveMinMaxAverage(numbers: number[]): {
  minValue: number;
  maxValue: number;
  averageValue: number;
} {
  if (!numbers.length) return { minValue: 0, maxValue: 0, averageValue: 0 };

  const minValue = Math.min(...numbers);
  const maxValue = Math.max(...numbers);
  const averageValue = Math.round(
    numbers.reduce((a, b) => a + b, 0) / numbers.length,
  );
  return { minValue, maxValue, averageValue };
}

/*
 * This function takes an array of numbers and returns an object containing
 * the minimum, maximum and average values in the array, as well as an array
 * of four groups of numbers, each representing a quarter of the dataset.
 */
export function groupPriceIndications(
  prices: number[],
  range: PriceIndicationRange,
): PriceIndicationGroup[] {
  // Find the minimum, maximum and average values in the array
  const { minValue, maxValue } = range;

  // Round the minimum and maximum values to the nearest hundred thousand
  const roundedMinValue = Math.floor(minValue / 100000) * 100000;
  const roundedMaxValue = Math.ceil(maxValue / 100000) * 100000;

  // Calculate the size of each group
  const groupSize = (roundedMaxValue - roundedMinValue) / 4;

  // Create four groups based on the rounded values
  const groups: Array<{
    start: number;
    end: number;
    values: number[];
    percentage: number;
  }> = Array.from({ length: 4 }, (_, index) => {
    const startRange = roundedMinValue + index * groupSize;
    const endRange = startRange + groupSize;
    return { start: startRange, end: endRange, values: [], percentage: 0 };
  });

  // Assign each number to its corresponding group
  prices.forEach((number) => {
    const group = groups.find((g) => number >= g.start && number < g.end);
    if (group) {
      group.values.push(number);
    }
  });

  // Calculate the percentage of the dataset in each group
  const totalValues = prices.length;
  groups.forEach((group) => {
    group.percentage = group.values.length
      ? Math.round((group.values.length / totalValues) * 100)
      : 0;
  });

  return groups;
}
