import { Box, Flex, Heading, SkeletonText, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { StatsGrid } from "components/stats-grid";
import type { OverviewData } from "modules/landlord-portal/models/overview";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";

type FinancialSnapshotBoxProps = {
  isLoading: boolean;
  summary?: OverviewData["summary"];
};
export function FinancialSnapshotBox({
  isLoading,
  summary,
}: FinancialSnapshotBoxProps) {
  return (
    <Box
      p={6}
      bg={"white"}
      gap={6}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
      borderRadius={"lg"}
    >
      <Flex direction={"column"} gap={1}>
        <Heading size={"md"}>Your financial snapshot</Heading>
        {isLoading ? (
          <SkeletonText noOfLines={1} w={"50%"} skeletonHeight={5} h={"21px"} />
        ) : (
          <Text size={"sm"} color={"gray.600"}>
            {dayjs(summary?.period_start_date).format(dateFormats.dayMonthYear)}{" "}
            – {dayjs(summary?.period_end_date).format(dateFormats.dayMonthYear)}
          </Text>
        )}
      </Flex>
      <StatsGrid
        isLoading={isLoading}
        stats={
          summary
            ? [
                {
                  id: "opening_balance",
                  label: "Opening balance",
                  value: formatMoney(summary.opening_balance),
                },
                {
                  id: "money_in",
                  label: "Money in",
                  value: formatMoney(summary.money_in),
                },
                {
                  id: "money_out",
                  label: "Money out",
                  value: formatMoney(summary.money_out),
                },
                {
                  id: "net_balance",
                  label: "Net balance",
                  value: formatMoney(summary.net_balance),
                },
                {
                  id: "bills_outstanding",
                  label: "Bills outstanding",
                  value: formatMoney(summary.bills_outstanding),
                },
              ]
            : []
        }
        hasIcons={false}
        columnsLarge={3}
        columnsSmall={3}
      />
    </Box>
  );
}
