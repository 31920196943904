import type { ReactNode } from "react";
import {
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpoint,
} from "@chakra-ui/react";

export type ResponsiveCard = {
  label: string;
  element: ReactNode;
  width?: "50%" | "100%";
};

type ResponsiveCardsProps = {
  cards: ResponsiveCard[];
};

export function ResponsiveCards({ cards }: ResponsiveCardsProps) {
  const breakpoint = useBreakpoint({ ssr: false });

  const isMobile = breakpoint === "base" || breakpoint === "sm";

  if (!isMobile) {
    return (
      <Grid templateColumns={"repeat(2, 1fr)"} gap={6}>
        {cards.map((card) => (
          <GridItem key={card.label} colSpan={card.width === "50%" ? 1 : 2}>
            {card.element}
          </GridItem>
        ))}
      </Grid>
    );
  }

  return (
    <Tabs variant={"soft-rounded"}>
      <TabList overflowX={"scroll"}>
        {cards.map((card) => (
          <Tab key={card.label} whiteSpace={"nowrap"}>
            {card.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {cards.map((card) => (
          <TabPanel key={card.label} py={8}>
            {card.element}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
