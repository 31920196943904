import { Box, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import { CardRecord } from "components/cards/card-record";

export function InspectionsListCardSkeleton() {
  return (
    <Box h={["235px", "195px", "auto"]}>
      <CardRecord leftElement={<Box w={12} />}>
        <Flex direction={"column"} gap={[3, 2.5]}>
          <SkeletonText noOfLines={1} w={"250px"} skeletonHeight={"23px"} />
          <Flex wrap={"wrap"} gap={2} direction={["column", "column", "row"]}>
            <SkeletonText noOfLines={1} w={"140px"} skeletonHeight={5} />
            <SkeletonText noOfLines={1} w={"140px"} skeletonHeight={5} />
            <SkeletonText noOfLines={1} w={"190px"} skeletonHeight={5} />
            <SkeletonText noOfLines={1} w={"150px"} skeletonHeight={5} />
          </Flex>
        </Flex>
      </CardRecord>
      <Skeleton h={[9, 0, 0]} />
    </Box>
  );
}
