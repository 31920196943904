import { faker } from "@faker-js/faker";
import { fakerDateString } from "lib/faker";
import { type DateString } from "types/api";
import type { Contact } from "types/contact";

export type RentPosition = {
  paid_to_date: DateString | null;
  paid_to_date_surplus: number | null;
  fully_paid_period_start_date: DateString | null;
  fully_paid_period_end_date: DateString | null;
  fully_paid_period_surplus: number | null;
  partially_paid_period_start_date: DateString | null;
  partially_paid_period_end_date: DateString | null;
  partially_paid_period_deficit: number | null;
};

export enum PaymentFrequency {
  Weekly = "weekly",
  Fortnightly = "fortnightly",
  Monthly = "monthly",
  Quarterly = "quarterly",
  Annually = "annually",
}

export type Tenancy = {
  id: string;
  agreement_start_date: DateString;
  agreement_end_date: DateString;
  agreement_vacate_date: DateString;
  agreement_signed_date: DateString;
  paid_to_date: DateString;
  surplus_amount: number;
  rent_arrears?: { days: number; amount: number };
  rent_position: RentPosition;
  rent_amount: number;
  rent_review_date: string | null;
  next_rent_due_date: DateString;
  frequency_id: PaymentFrequency;
  security_deposit_amount: number;
  tenants: { role_id: "primary" | null; contact: Contact }[];
};

export function mockTenancy(): Tenancy {
  return {
    id: faker.string.uuid(),
    agreement_start_date: fakerDateString(),
    agreement_end_date: fakerDateString(),
    agreement_vacate_date: fakerDateString(),
    agreement_signed_date: fakerDateString(),
    paid_to_date: fakerDateString(),
    surplus_amount: faker.number.int({ min: 0, max: 2000 }),
    rent_amount: faker.number.int({ min: 100, max: 1000 }),
    rent_arrears: {
      days: faker.number.int({ min: 0, max: 1 }),
      amount: faker.number.int({ min: 0, max: 5000 }),
    },
    frequency_id: faker.helpers.arrayElement([
      PaymentFrequency.Weekly,
      PaymentFrequency.Fortnightly,
      PaymentFrequency.Monthly,
      PaymentFrequency.Quarterly,
      PaymentFrequency.Annually,
    ]),
    rent_position: {
      paid_to_date: fakerDateString(),
      fully_paid_period_end_date: fakerDateString(),
      partially_paid_period_end_date: fakerDateString(),
      partially_paid_period_deficit: faker.number.int({ min: 0, max: 2000 }),
      paid_to_date_surplus: faker.number.int({ min: 0, max: 2000 }),
      fully_paid_period_start_date: fakerDateString(),
      fully_paid_period_surplus: faker.number.int({ min: 0, max: 2000 }),
      partially_paid_period_start_date: fakerDateString(),
    },
    next_rent_due_date: fakerDateString(),
    rent_review_date: fakerDateString(),
    security_deposit_amount: faker.number.int({ min: 100, max: 2000 }),
    tenants: [
      {
        role_id: "primary",
        contact: {
          id: faker.string.uuid(),
          display_name:
            faker.person.firstName() + " " + faker.person.lastName(),
          family_name: faker.person.lastName(),
          middle_name: null,
          given_name: faker.person.firstName(),
          primary_address: faker.location.streetAddress(),
          primary_email: faker.internet.email(),
          primary_phone: faker.phone.number(),
        },
      },
    ],
  };
}
