import { faker } from "@faker-js/faker";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

export const fakerDateString = (since?: Dayjs) =>
  dayjs(
    since
      ? faker.date.soon({ days: 10, refDate: since.toISOString() })
      : faker.date.recent(),
  )
    .set("minutes", 0)
    .set("hours", 0)
    .add(faker.helpers.arrayElement([10, 8, 14, 16]), "hours")
    .toISOString();

export const fakerDateUnix = () => dayjs(faker.date.recent()).unix();
