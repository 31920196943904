import type { ReactNode } from "react";
import { HStack, useBreakpoint, type StackProps } from "@chakra-ui/react";

type CardRecordMetaProps = {
  children: ReactNode;
} & StackProps;

export function CardRecordMeta({ children, ...rest }: CardRecordMetaProps) {
  const breakpoint = useBreakpoint({ ssr: false });

  const isMobile = breakpoint === "base" || breakpoint === "sm";
  return (
    <HStack
      gap={2}
      color={"gray.600"}
      mb={[2, 0]}
      flexDir={["column", "row"]}
      alignItems={["flex-start", "center"]}
      wrap={"wrap"}
      fontSize={"xs"}
      divider={isMobile ? undefined : <>{"•"}</>}
      {...rest}
    >
      {children}
    </HStack>
  );
}
