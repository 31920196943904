import { mockInspectionTask, type InspectionTask } from "types/inspection-task";
import { type Property } from "types/property";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type InspectionsListData = {
  inspectionTasks: InspectionTask[];
  property: Property;
};

export const landlordInspectionsModel = createModel("landlordInspections", {
  queries: {
    list: (searchParams: URLSearchParams) => ({
      queryKey: ["list", searchParams.toString()],
      queryFn: () => {
        return apiClient.get<InspectionsListData>(
          `/landlord/inspections?${searchParams.toString()}`,
        );
      },
      keepPreviousData: true,
    }),
  },
});

export const mockLandlordInspectionsResponse = () => {
  return {
    inspectionTasks: Array.from({ length: 12 }).map(() => mockInspectionTask()),
  };
};
