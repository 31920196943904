import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { cssVar } from "@chakra-ui/theme-tools";

const $color = cssVar("tabs-color");

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const closedPanel = definePartsStyle(() => {
  return {
    tab: {
      border: "1px solid",
      borderColor: "transparent",
      bg: "gray.100",
      color: "gray.700",
      borderTopRadius: "lg",
      borderBottom: "none",
      fontWeight: 700,
      fontSize: "lg",
      width: "264px",
      py: "16px",
      justifyContent: "flex-start",
      _selected: {
        bg: "#fff",
        borderColor: "inherit",
        borderBottom: "none",
        mb: "-2px",
      },
    },
    tablist: {
      borderBottom: "1x solid",
      borderColor: "inherit",
    },
    tabpanel: {
      px: "24px",
      py: "24px",
      border: "1px solid",
      borderColor: "inherit",
      borderBottomRadius: "lg",
      borderTopRightRadius: "lg",
    },
  };
});

export const tabsTheme = defineMultiStyleConfig({
  defaultProps: {
    size: "md",
  },
  baseStyle: {
    tab: {
      [$color.variable]: "colors.gray.600",
    },
    tabpanel: {
      px: 0,
      pb: 0,
    },
  },
  variants: {
    "soft-rounded": {
      tablist: {
        gap: 2,
      },
      tab: {
        _selected: {
          bg: "gray.700",
          color: "#FFF",
        },
        flexShrink: 0,
      },
    },
    line: {
      tablist: {
        borderBottom: "1px solid",
        width: "100%",
      },
      tab: {
        flex: 1,
      },
    },
    closedPanel: closedPanel,
  },
  sizes: {
    lg: {
      tab: {
        fontSize: "md",
      },
    },
    md: {
      tab: {
        fontSize: "lg",
      },
    },
  },
});
