import type { BaseSyntheticEvent, ReactNode } from "react";
import { AnimatePresence } from "framer-motion";
import type { FieldErrors, FieldValues, UseFormReturn } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { SaveCancelBar } from "components/save-cancel-bar";

type FormProps<Values extends FieldValues> = {
  id: string;
  onSubmit: (data: Values, event?: BaseSyntheticEvent) => void;
  form: UseFormReturn<Values>;
  children: ReactNode;
  enableSaveCancelOverlay?: boolean;
  submitLoading?: boolean;
};

export function Form<Values extends FieldValues>({
  id,
  form,
  onSubmit,
  children,
  submitLoading,
  enableSaveCancelOverlay,
}: FormProps<Values>) {
  const {
    formState: { isDirty },
  } = form;
  const onSubmitWrapper = (data: Values, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    return onSubmit(data, event);
  };
  const onErrorWrapper = (errors: FieldErrors<Values>) => {
    // eslint-disable-next-line no-console
    console.error(errors);
  };

  return (
    <FormProvider {...form}>
      <form
        id={id}
        onSubmit={form.handleSubmit(onSubmitWrapper, onErrorWrapper)}
        style={{ width: "100%" }}
      >
        {children}
        {enableSaveCancelOverlay && (
          <AnimatePresence>
            {isDirty && <SaveCancelBar form={form} isLoading={submitLoading} />}
          </AnimatePresence>
        )}
      </form>
    </FormProvider>
  );
}
