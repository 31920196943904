import * as React from "react";
import { Provider as ModalProvider } from "@ebay/nice-modal-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "contexts/auth-provider";
import { ThemeProvider } from "contexts/theme-provider";
import { BugsnagBoundary } from "lib/bugsnag";
import { queryClient } from "lib/react-query";

export function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <React.StrictMode>
      <BugsnagBoundary>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <ModalProvider>
                <AuthProvider>
                  <ErrorBoundary FallbackComponent={() => <h1>error</h1>}>
                    {children}
                  </ErrorBoundary>
                </AuthProvider>
              </ModalProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </BugsnagBoundary>
    </React.StrictMode>
  );
}
