import type { Route } from "lib/react-router-dom";
import { AGENCY_SETTINGS_ROUTES } from "modules/agency-settings/routes";
import { LANDLORD_PORTAL_ROUTES } from "modules/landlord-portal/routes";
import { TENANT_PORTAL_ROUTES } from "modules/tenant-portal/routes";
import { VENDOR_PORTAL_ROUTES } from "modules/vendor-portal/routes";

export const rootVendorRoutes: Route[] = [...VENDOR_PORTAL_ROUTES];

export const rootTenantRoutes: Route[] = [...TENANT_PORTAL_ROUTES];

export const rootLandlordRoutes: Route[] = [...LANDLORD_PORTAL_ROUTES];

export const rootAgencySettingsRoutes: Route[] = [...AGENCY_SETTINGS_ROUTES];
