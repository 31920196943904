import { faker } from "@faker-js/faker";

export type BankAccount = {
  account_name: string;
  branch_code: string;
  account_number: string;
};

export function mockBankAccount(): BankAccount {
  return {
    account_name: faker.finance.accountName(),
    branch_code: faker.finance.accountNumber(),
    account_number: faker.finance.accountNumber(),
  };
}
