import { useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Show,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";
import { Table } from "components/table/table";
import { documentsScreenModel } from "modules/vendor-portal/screens/documents/models/documents";
import { dateFormats } from "utils/date";
import { upperFirst } from "utils/string";

const COLUMNS = [
  {
    label: "File name",
    id: "description",
  },
  {
    label: "Type",
    id: "type.id",
    formatValue: (value: string) => upperFirst(value),
  },
  {
    label: "Date uploaded",
    id: "upload_date",
    formatValue: (value: string) =>
      dayjs(value).format(dateFormats.dayMonthYear),
  },
];

export function DocumentsScreen() {
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useQuery(
    documentsScreenModel.queries.read(page),
  );

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>
      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={8}
      >
        <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700} as={"h1"}>
          Documents
        </Heading>

        <Box
          p={0}
          border={"1px solid"}
          borderColor={"gray.200"}
          borderRadius={"8px"}
          overflow={"hidden"}
        >
          <Table
            isLoading={isLoading}
            isFetching={isFetching}
            columns={COLUMNS}
            rows={data?.listing._related?.listing_documents?.data ?? []}
            meta={data?.listing._related?.listing_documents?.meta}
            setPage={setPage}
            customRowCell={(row) => (
              <a href={row.url}>
                <Button colorScheme={"gray"} variant={"outline"}>
                  Download
                </Button>
              </a>
            )}
            emptyProps={{
              emptyMessage: "No documents found.",
              emptyDesc: "Please check back later.",
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
}
