import {
  mockMaintenanceTask,
  type MaintenanceTask,
} from "types/maintenance-task";
import { mockProperty, type Property } from "types/property";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type MaintenanceData = {
  maintenanceTasks: MaintenanceTask[];
  property: Property;
};

export const tenancyMaintenanceModel = createModel("tenancyMaintenance", {
  queries: {
    read: (propertyId?: string) => ({
      queryKey: ["read", propertyId],
      queryFn: () => {
        return apiClient.get<MaintenanceData>(
          `/tenant/${propertyId}/maintenance`,
        );
      },
      enabled: !!propertyId,
      keepPreviousData: true,
    }),
  },
});

export const mockTenancyMaintenanceResponse = () => {
  return {
    maintenanceTasks: Array.from({ length: 8 }).map(() =>
      mockMaintenanceTask(),
    ),
    property: mockProperty(),
  };
};
