import { faker } from "@faker-js/faker";
import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

export type AgencyContact = {
  id: string;
  name: string;
  role: string;
  phone: string;
  email: string;
  imageUrl?: string;
};

export type ListingAddress = {
  full_address: string;
  short_address: string;
};

export type UserListing = {
  id: string;
  address: ListingAddress;
};

export type Referrer = {
  product: string;
  account_id: string | null;
};

export type SessionData = {
  email: string;
  remote_id: string;
  given_name: string;
  family_name: string;
  roles: string[];
  region: string;
  referrer: Referrer;
  related: {
    listings: UserListing[];
    landlord_properties: any[];
    tenant_properties: any[];
  };
};

export type SessionResponse = {
  data: SessionData;
};

export const sessionModel = createModel("session", {
  queries: {
    read: (token) => ({
      queryKey: ["read"],
      queryFn: async () => {
        const response = await apiClient.get<SessionResponse>(
          `/user?token=${token}`,
        );
        return response.data;
      },
      enabled: Boolean(token),
    }),
  },
});

export const mockAgencyContacts = Array.from({ length: 2 }).map((_, i) => {
  const firstName = faker.person.firstName(i === 0 ? "male" : "female");
  const lastName = faker.person.lastName();
  return {
    id: faker.string.uuid(),
    name: `${firstName} ${lastName}`,
    role: "Agent",
    phone: "0432123123",
    email: faker.internet.email({
      firstName,
      lastName,
      provider: "example.com",
    }),
    imageUrl:
      i === 0
        ? "https://ca.slack-edge.com/T040WAEA4-U03GH7DEMCJ-5915945d8ca2-512"
        : "https://ca.slack-edge.com/T040WAEA4-U044N8RMN-20782af81385-512",
  };
});

export const mockSessionResponse = {
  data: {
    email: faker.internet.email(),
    remote_id: faker.string.uuid(),
    given_name: faker.person.firstName(),
    family_name: faker.person.lastName(),
    roles: ["agent", "tenant", "landlord", "vendor"],
    region: "au",
    referrer: {
      product: "self",
      account_id: faker.string.uuid(),
    },
    related: {
      landlord_properties: Array.from({
        length: faker.number.int({ min: 1, max: 3 }),
      }).map(() => ({
        id: faker.string.uuid(),
        address: {
          full_address: faker.location.streetAddress(true),
          short_address: faker.location.streetAddress(),
        },
      })),
      tenant_properties: Array.from({
        length: faker.number.int({ min: 1, max: 3 }),
      }).map(() => ({
        id: faker.string.uuid(),
        address: {
          full_address: faker.location.streetAddress(true),
          short_address: faker.location.streetAddress(),
        },
      })),
      listings: Array.from({
        length: faker.number.int({ min: 1, max: 3 }),
      }).map(() => ({
        id: faker.string.uuid(),
        address: {
          full_address: faker.location.streetAddress(true),
          short_address: faker.location.streetAddress(),
        },
      })),
    },
  },
};
