import { faker } from "@faker-js/faker";
import dayjs from "dayjs";
import type { Listing } from "modules/vendor-portal/types/listing";
import { apiClient } from "utils/api-client/index";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { createModel } from "utils/models";
import { upperFirst } from "utils/string";

export type OffersScreenData = {
  listing: Listing;
};

export const offersScreenModel = createModel("offers", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: () => apiClient.get<OffersScreenData>(`/offers`),
    }),
  },
});

enum ContractStatus {
  Accepted = "accepted",
  Offer = "offer",
  Unconditional = "unconditional",
  Settled = "settled",
  Fallen = "fallen",
}

enum FallenReasonId {
  Fallen = "fallen",
  Withdrawn = "withdrawn",
  Rejected = "rejected",
}

enum FallenReasonText {
  Fallen = "Fallen",
  Withdrawn = "Withdrawn",
  Rejected = "Rejected",
}

export type ListingContractCondition = {
  condition_notes: string | null;
  condition_type: string | null;
  priority: string;
  id: string;
};

export type Contract = {
  id: string;
  date_actual_accepted: string | null;
  date_actual_communicated: string | null;
  date_actual_deposit: string | null;
  date_actual_fallen: string | null;
  date_actual_offer: string;
  date_actual_settlement: string | null;
  date_actual_unconditional: string | null;
  date_expec_settlement: string | null;
  date_expec_unconditional: string | null;
  is_backup_offer: boolean | null;
  is_primary_backup_offer: boolean | null; // 1st backup
  detail_sale_price_or_lease_pa: number | null;
  notes: string;
  purchtenant_legal_name: string;
  fallen_reason: null | {
    id: FallenReasonId;
    text: FallenReasonText;
  };
  _contract_status: ContractStatus;
  _is_accepted: boolean;
  _is_primary_accepted: boolean;
  _related: {
    listing_contract_conditions: ListingContractCondition[];
  };
};

function mockContract(idx: number) {
  const isPrimaryAccepted = hasOffer && idx === 0;
  const isPrimaryBackupOffer = hasOffer && idx === 1;
  const isBackupOffer = isPrimaryBackupOffer
    ? true
    : hasOffer
      ? faker.datatype.boolean()
      : false;
  const isFallen =
    isPrimaryAccepted || isPrimaryBackupOffer || isBackupOffer
      ? false
      : faker.datatype.boolean();

  return {
    id: faker.string.uuid(),
    date_actual_accepted: faker.date.recent({ days: 10 }).toDateString(),
    date_actual_communicated: faker.date.recent({ days: 10 }).toDateString(),
    date_actual_deposit: faker.date.recent({ days: 10 }).toDateString(),
    date_actual_fallen: faker.date.recent({ days: 10 }).toDateString(),
    date_actual_offer: faker.date.recent({ days: 10 }).toDateString(),
    date_actual_settlement: faker.date.recent({ days: 10 }).toDateString(),
    date_actual_unconditional: faker.date.recent({ days: 10 }).toDateString(),
    date_expec_settlement: faker.date.recent({ days: 10 }).toDateString(),
    date_expec_unconditional: faker.date.recent({ days: 10 }).toDateString(),
    is_backup_offer: isBackupOffer,
    is_primary_backup_offer: isPrimaryBackupOffer,
    detail_sale_price_or_lease_pa: faker.number.int({
      min: 100000,
      max: 1000000,
    }),
    notes: faker.lorem.paragraphs({ min: 1, max: 3 }),
    purchtenant_legal_name:
      faker.person.firstName() + " " + faker.person.lastName(),
    fallen_reason: isFallen
      ? { id: FallenReasonId.Fallen, text: FallenReasonText.Fallen }
      : null,
    _contract_status: isFallen
      ? ContractStatus.Fallen
      : isPrimaryAccepted
        ? [ContractStatus.Unconditional, ContractStatus.Settled][
            faker.number.int({ min: 0, max: 1 })
          ]
        : isPrimaryBackupOffer || isBackupOffer
          ? [ContractStatus.Accepted, ContractStatus.Unconditional][
              faker.number.int({ min: 0, max: 1 })
            ]
          : ContractStatus.Offer,

    _is_accepted: isPrimaryAccepted || isPrimaryBackupOffer || isBackupOffer,
    _is_primary_accepted: isPrimaryAccepted,
    _related: {
      listing_contract_conditions: [
        {
          condition_notes: faker.lorem.paragraphs(1),
          condition_type: "Finance",
          priority: "1",
          _id: faker.string.uuid(),
        },
        {
          condition_notes: faker.lorem.paragraphs(1),
          condition_type: "Building & Pest",
          priority: "2",
          _id: faker.string.uuid(),
        },
      ],
    },
  };
}

const hasOffer = faker.datatype.boolean();

const mockOfferStats = [
  {
    id: faker.string.uuid(),
    value: formatMoney(faker.number.int({ min: 300000, max: 1000000 })),
    label: "Highest offer",
  },
  {
    id: faker.string.uuid(),
    value: formatMoney(faker.number.int({ min: 300000, max: 1000000 })),
    label: "Most common offer",
  },
  {
    id: faker.string.uuid(),
    value: formatMoney(faker.number.int({ min: 300000, max: 1000000 })),
    label: "Your asking price",
  },
];

const mockAcceptedOfferStats = [
  {
    id: faker.string.uuid(),
    value: formatMoney(faker.number.int({ min: 300000, max: 1000000 })),
    label: "Accepted offer",
  },
  {
    id: faker.string.uuid(),
    value: upperFirst(
      [
        ContractStatus.Unconditional,
        ContractStatus.Settled,
        ContractStatus.Accepted,
      ][faker.number.int({ min: 0, max: 2 })],
    ),
    label: "Current offer status",
  },
  {
    id: faker.string.uuid(),
    value: dayjs(faker.date.recent({ days: 10 })).format(
      dateFormats.dayMonthYear,
    ),
    label: "Expected unconditional date",
  },
  {
    id: faker.string.uuid(),
    value: dayjs(faker.date.recent({ days: 10 })).format(
      dateFormats.dayMonthYear,
    ),
    label: "Expected settlement date",
  },
];
export const mockOffersResponse = {
  listing: {
    id: faker.string.uuid(),
    price_advertise_as: faker.number.int({ min: 300000, max: 1000000 }),
    stats: hasOffer ? mockAcceptedOfferStats : mockOfferStats,
    contracts: Array.from({
      length: faker.number.int({ min: 1, max: 10 }),
    }).map((_i, idx: number) => mockContract(idx)),
  },
};
