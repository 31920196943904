import type { ComponentProps, ReactNode } from "react";
import { HStack, SimpleGrid, Text } from "@chakra-ui/react";

type SimpleGridTableProps = {
  items: { label: string; value: ReactNode }[];
  simpleGridProps?: ComponentProps<typeof SimpleGrid>;
};
export function SimpleGridTable({
  items,
  simpleGridProps,
}: SimpleGridTableProps) {
  return (
    <SimpleGrid columns={[1, 2]} spacing={4} {...simpleGridProps}>
      {items.map((item) => (
        <HStack
          key={item.label}
          w={"100%"}
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
          justifyContent={"space-between"}
          fontSize={"lg"}
          lineHeight={"24px"}
          px={6}
          py={2}
        >
          <Text fontWeight={500}>{item.label}</Text>
          <Text fontWeight={400} textAlign={"end"}>
            {item.value}
          </Text>
        </HStack>
      ))}
    </SimpleGrid>
  );
}
