import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Show,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { CampaignSnapshot } from "modules/vendor-portal/screens/performance/components/campaign-snapshot";
import { LOGO_MAP } from "modules/vendor-portal/screens/performance/components/campaign-tabs";
import { EnquiryStats } from "modules/vendor-portal/screens/performance/components/enquiry-stats";
import type { PerformanceData } from "modules/vendor-portal/screens/performance/models/performance";

type PerformanceDesktopLayoutProps = {
  isLoading: boolean;
  data?: PerformanceData;
};
export function PerformanceMobileLayout({
  isLoading,
  data,
}: PerformanceDesktopLayoutProps) {
  return (
    <Box p={0}>
      <Show below={"md"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>

      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={8}
      >
        <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700} as={"h1"}>
          Performance
        </Heading>

        <Tabs variant={"soft-rounded"} size={"md"}>
          <TabList overflowX={"scroll"} mb={8}>
            <Tab whiteSpace={"nowrap"}>Summary</Tab>
            <Tab whiteSpace={"nowrap"}>Campaigns</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex direction={"column"} gap={8}>
                <SkeletonGrid
                  isLoaded={!isLoading}
                  gridSize={4}
                  skeleton={<StatsCardSkeleton />}
                >
                  <Grid
                    templateColumns={{
                      base: "repeat(2, 1fr)",
                      lg: "repeat(4, 1fr)",
                    }}
                    templateRows={{
                      base: "repeat(2, 1fr)",
                      lg: "repeat(1, 1fr)",
                    }}
                    gap={6}
                  >
                    {data?.listing.performance_stats.map((stat) => (
                      <GridItem key={stat.id} w={"100%"}>
                        <StatsCard stat={stat} icon={BarChartIcon} />
                      </GridItem>
                    ))}
                  </Grid>
                </SkeletonGrid>

                <EnquiryStats
                  stats={data?.listing.enquiry_stats.sources}
                  isLoading={isLoading}
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Accordion defaultIndex={[0]} allowMultiple>
                {data?.listing.campaigns.map((campaign) => (
                  <AccordionItem key={campaign.id} border={"none"}>
                    <AccordionButton p={0}>
                      <HStack as={"span"} flex={1} gap={2} py={6}>
                        <Icon as={LOGO_MAP[campaign.id]} boxSize={"32px"} />
                        <Text
                          fontSize={"lg"}
                          fontWeight={700}
                          lineHeight={"28px"}
                        >
                          {campaign.source_title}
                        </Text>
                      </HStack>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel p={0} pos={"relative"} overflow={"hidden"}>
                      <CampaignSnapshot
                        campaign={campaign}
                        isLoading={isLoading}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
}
