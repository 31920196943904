import { Box, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { CampaignTabs } from "modules/vendor-portal/screens/performance/components/campaign-tabs";
import { EnquiryStats } from "modules/vendor-portal/screens/performance/components/enquiry-stats";
import type { PerformanceData } from "modules/vendor-portal/screens/performance/models/performance";

type PerformanceDesktopLayoutProps = {
  isLoading: boolean;
  data?: PerformanceData;
};

export function PerformanceDesktopLayout({
  isLoading,
  data,
}: PerformanceDesktopLayoutProps) {
  return (
    <Box p={0}>
      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={8}
      >
        <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700} as={"h1"}>
          Performance
        </Heading>

        <SkeletonGrid
          isLoaded={!isLoading}
          gridSize={4}
          skeleton={<StatsCardSkeleton />}
        >
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
            templateRows={{ base: "repeat(2, 1fr)", lg: "repeat(1, 1fr)" }}
            gap={6}
          >
            {data?.listing.performance_stats.map((stat) => (
              <GridItem key={stat.id} w={"100%"}>
                <StatsCard stat={stat} icon={BarChartIcon} />
              </GridItem>
            ))}
          </Grid>
        </SkeletonGrid>

        <EnquiryStats
          stats={data?.listing.enquiry_stats.sources}
          isLoading={isLoading}
        />

        <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700} as={"h1"}>
          Campaigns
        </Heading>

        <CampaignTabs
          campaigns={data?.listing.campaigns}
          isLoading={isLoading}
        />
      </Flex>
    </Box>
  );
}
