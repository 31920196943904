import * as React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import type { FetchClientError } from "errors/fetch-client-error";
import type { FetchServerError } from "errors/fetch-server-error";

// wrap app with bugsnag error boundary
function BugsnagBoundary({ children }: { children: JSX.Element }) {
  if (!import.meta.env.VITE_BUGSNAG_API_KEY) return children;
  const Boundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
  return Boundary ? <Boundary>{children}</Boundary> : children;
}

type ErrorEvent = {
  originalError: FetchClientError | FetchServerError;
  addMetadata: (
    title: string,
    meta: { requestUrl: string; message: string; response: string },
  ) => void;
};

function initialiseBugsnag() {
  if (import.meta.env.VITE_BUGSNAG_API_KEY) {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      redactedKeys: [/^password$/i], //regex of the w
      onError: (event: ErrorEvent) => {
        const error = event.originalError;
        if (error.name === "FetchClientError") return false;
        else {
          event.addMetadata("API Request", {
            requestUrl: (error.request as Request)?.url,
            message: error?.message,
            response: JSON.stringify(error.response),
          });
        }
      },
    });
  }
}

export { initialiseBugsnag, BugsnagBoundary, Bugsnag };
