import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  List,
  ListItem,
  SkeletonText,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FeedbackListItem } from "modules/vendor-portal/screens/overview/components/feedback/feedback-list-item";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";

type RecentFeedbackProps = {
  isLoading: boolean;
  feedback: (
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry
  )[];
};
export function RecentFeedback({ feedback, isLoading }: RecentFeedbackProps) {
  return (
    <Card p={0}>
      <CardHeader
        display={"flex"}
        flexDir={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={6}
        pb={0}
      >
        <Heading fontSize={"xl"} fontWeight={600} lineHeight={"28px"}>
          Recent Feedback
        </Heading>
        <Link to={"feedback"}>
          <Button variant={"solid"} colorScheme={"gray"}>
            View All
          </Button>
        </Link>
      </CardHeader>
      <CardBody pt={1} pb={2} px={0}>
        {isLoading ? (
          <SkeletonText
            noOfLines={4}
            spacing={4}
            skeletonHeight={2}
            p={3}
            pr={10}
          />
        ) : (
          <List>
            {feedback?.slice(0, 3).map((feedbackItem) => {
              return (
                <ListItem key={feedbackItem.id} pt={3} pr={10} pb={4} pl={3}>
                  <FeedbackListItem feedback={feedbackItem} />
                </ListItem>
              );
            })}
          </List>
        )}
      </CardBody>
    </Card>
  );
}
