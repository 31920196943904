import { useState, type ReactNode } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  List,
  ListItem,
  Tag,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { createEvent } from "ics";
import CalendarIcon from "assets/icons/medium/calendar.svg?react";
import { CardRecord } from "components/cards/card-record";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { InspectionsListCardSkeleton } from "components/inspections-list/inspections-list-card-skeleton";
import { LinkPreserveSearch } from "components/link-preserve-search";
import { ShowMoreLessButton } from "components/show-more-less-button";
import { TotalCountBadge } from "components/total-count-badge";
import type { InspectionTask } from "types/inspection-task";
import { dateFormats } from "utils/date";
import { titleCase, UNKNOWN_VALUE_MAP } from "utils/string";

const inspectionStatusColorMap = {
  not_started: "gray",
  notice_issued: "gray",
  inspection_completed: "green",
  report_in_progress: "gray",
  report_completed: "green",
  report_sent: "green",
};

export function getLongDateTimeStringForInspectionTask(task: InspectionTask) {
  return (
    [
      dayjs(task.details.scheduled_at).format("h:mma"),
      task.details.scheduled_duration
        ? dayjs(task.details.scheduled_at)
            .add(task.details.scheduled_duration, "minutes")
            .format("h:mma")
        : null,
    ]
      .filter(Boolean)
      .join(" - ") +
    `, ${dayjs(task.details.scheduled_at).format("dddd D MMM YYYY")}`
  );
}

export function triggerIcsDownload(task: InspectionTask) {
  const start = dayjs(task.details.scheduled_at);

  const startArray = start.format("YYYY M D H M").split(" ").map(Number) as [
    number,
    number,
    number,
    number,
    number,
  ];

  const [hours, minutes] = task.details.scheduled_duration
    ? [
        Math.floor(task.details.scheduled_duration / 60),
        task.details.scheduled_duration % 60,
      ]
    : [0, 0];

  createEvent(
    {
      start: startArray,
      duration: { hours, minutes },
      title: task.summary,
      location: task.property?.full_address,
      status: "CONFIRMED",
      busyStatus: "BUSY",
      organizer: {
        name: task.managed_by?.display_name,
        email: task.managed_by?.primary_email,
      },
      attendees: [
        {
          name: task.managed_by?.display_name,
          email: task.managed_by?.primary_email,
          rsvp: true,
          partstat: "ACCEPTED",
          role: "REQ-PARTICIPANT",
        },
      ],
    },
    (error, value) => {
      const blob = new Blob([value], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "inspection.ics", { autoBom: false });
    },
  );
}

export type InspectionsListProps = {
  isLoading: boolean;
  inspectionTasks: InspectionTask[];
  alerts?: ReactNode;
};

export function InspectionsList({
  isLoading,
  alerts,
  inspectionTasks,
}: InspectionsListProps) {
  const [showAllPast, setShowAllPast] = useState(false);

  const sortedInspections = [...(inspectionTasks ?? [])].sort((a, b) =>
    dayjs(b.details.scheduled_at).diff(dayjs(a.details.scheduled_at)),
  );

  const pastInspections: InspectionTask[] = isLoading
    ? Array.from({ length: 3 })
    : sortedInspections?.filter((task) =>
        dayjs(task.details.scheduled_at).isBefore(dayjs()),
      );

  return (
    <>
      {alerts}
      <HStack gap={3}>
        <Heading fontSize={"xl"} as={"h2"}>
          Past inspections
        </Heading>
        <TotalCountBadge count={pastInspections?.length} />
      </HStack>
      <List spacing={4}>
        {pastInspections
          ?.slice(0, showAllPast ? pastInspections?.length : 3)
          ?.map((task) => {
            if (isLoading) return <InspectionsListCardSkeleton />;
            const formattedDate = dayjs(task.details.scheduled_at).format(
              dateFormats.weekdayDayYear,
            );

            const formattedTime = dayjs(task.details.scheduled_at).format(
              "h:mma",
            );

            const formatedEndTime = task.details.scheduled_duration
              ? dayjs(task.details.scheduled_at)
                  .add(task.details.scheduled_duration, "minutes")
                  .format("h:mma")
              : null;

            const inspectionReportUrl = task.id;

            return (
              <ListItem key={task.id}>
                <CardRecord
                  renderCallToActionButton={
                    inspectionReportUrl
                      ? (props) => (
                          <Button
                            {...props}
                            as={LinkPreserveSearch}
                            to={inspectionReportUrl}
                          >
                            View report
                          </Button>
                        )
                      : undefined
                  }
                  leftElement={
                    <Box pl={4} pr={2} pt={1}>
                      <CalendarIcon />
                    </Box>
                  }
                  heading={`${formattedDate}, ${[formattedTime, formatedEndTime]
                    .filter(Boolean)
                    .join(" - ")}`}
                >
                  <CardRecordMeta>
                    <Tag
                      colorScheme={
                        inspectionStatusColorMap[task.details.status_id]
                      }
                      fontSize={"sm"}
                      fontWeight={600}
                      lineHeight={"16px"}
                      py={0.5}
                      minH={4}
                      px={2}
                      whiteSpace={"nowrap"}
                    >
                      {titleCase(task.details.status_id)}
                    </Tag>
                    <Text fontSize={"md"}>
                      {titleCase(task.details.type_id)}
                    </Text>
                    <Text fontSize={"md"}>
                      Inspected by {task.managed_by?.display_name}
                    </Text>
                    <Text fontSize={"md"}>Attended by {UNKNOWN_VALUE_MAP}</Text>
                  </CardRecordMeta>
                </CardRecord>
              </ListItem>
            );
          })}
      </List>
      {(pastInspections?.length ?? 0) > 3 ? (
        <ShowMoreLessButton showAll={showAllPast} setShowAll={setShowAllPast} />
      ) : null}
    </>
  );
}
