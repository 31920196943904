import { faker } from "@faker-js/faker";
import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

export type AuthData = {
  access_token: string;
  expires_in: number;
  token_type: string;
};

export const authModel = createModel("auth", {
  queries: {
    read: (token) => ({
      queryKey: ["read"],
      queryFn: () => apiClient.get<AuthData>(`/auth?token=${token}`),
      enabled: Boolean(token),
    }),
  },
});

export const mockAuthResponse = {
  access_token: faker.string.uuid(),
  expires_in: faker.number.int(),
  token_type: "Bearer",
};
