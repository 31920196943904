import {
  Card,
  Flex,
  Grid,
  GridItem,
  List,
  ListItem,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import type { OpenHomeData } from "modules/vendor-portal/screens/open-home-details/models/open-home";
import {
  openHomeStatStyles,
  sortByInterestLevel,
} from "modules/vendor-portal/screens/open-home-details/utils";
import { FeedbackListItem } from "modules/vendor-portal/screens/overview/components/feedback/feedback-list-item";

type OpenHomeDesktopLayoutProps = {
  isLoading: boolean;
  data?: OpenHomeData;
};
export function OpenHomeDesktopLayout({
  data,
  isLoading,
}: OpenHomeDesktopLayoutProps) {
  return (
    <Flex direction={"column"} gap={8}>
      <SkeletonGrid
        isLoaded={!isLoading}
        gridSize={6}
        skeleton={<StatsCardSkeleton />}
        templateColumns={"repeat(3, 1fr)"}
        templateRows={"repeat(2, 1fr)"}
      >
        <Grid
          templateColumns={"repeat(3, 1fr)"}
          templateRows={"repeat(2, 1fr)"}
          gap={6}
        >
          {data?.stats.map((stat) => (
            <GridItem key={stat.id} w={"100%"}>
              <StatsCard
                stat={stat}
                icon={openHomeStatStyles[stat.id].icon}
                iconBgColor={openHomeStatStyles[stat.id].bgColor}
                iconColor={openHomeStatStyles[stat.id].color}
              />
            </GridItem>
          ))}
        </Grid>
      </SkeletonGrid>

      <Text fontSize={"xl"} fontWeight={600} color={"gray.700"}>
        Summary
      </Text>
      <SkeletonText
        isLoaded={!isLoading}
        noOfLines={3}
        skeletonHeight={3}
        spacing={3}
      >
        <Text fontSize={"lg"} color={"gray.600"}>
          {data?.event.note}
        </Text>
      </SkeletonText>

      <Flex direction={"column"} gap={6}>
        <Text fontSize={"xl"} fontWeight={600} color={"gray.700"}>
          Feedback
        </Text>
        <SkeletonList isLoaded={!isLoading} listSize={4}>
          <List gap={3}>
            {data?.event.individual_feedback
              ?.sort(sortByInterestLevel)
              .map((feedback: any) => (
                <ListItem key={feedback.id} p={0} pb={3}>
                  <Card py={4} px={3}>
                    <FeedbackListItem
                      feedback={{
                        ...feedback,
                        type: data.event.type,
                        agent: data.event.agent,
                        date_of: data.event.date_of,
                      }}
                    />
                  </Card>
                </ListItem>
              ))}
          </List>
        </SkeletonList>
      </Flex>
    </Flex>
  );
}
