import { forwardRef, type ComponentProps } from "react";
import { Link, useSearchParams } from "react-router-dom";

export const LinkPreserveSearch = forwardRef(
  (
    {
      to,
      children,
      keyWhitelist,
      ...props
    }: ComponentProps<typeof Link> & { keyWhitelist?: string[] },
    ref: any,
  ) => {
    const [searchParams] = useSearchParams();

    const searchString = keyWhitelist
      ? keyWhitelist
          .reduce((acc, key) => {
            const value = searchParams.get(key);
            if (value) {
              acc.append(key, value);
            }
            return acc;
          }, new URLSearchParams())
          .toString()
      : searchParams.toString();

    return (
      <Link
        {...props}
        ref={ref}
        to={
          !to
            ? ""
            : (searchString && !to ? "./" : to) +
              (searchString ? `?${searchString}` : "")
        }
      >
        {children}
      </Link>
    );
  },
);

LinkPreserveSearch.displayName = "LinkPreserveSearch";
