import type { ReactNode } from "react";
import { Box, Grid, GridItem, type As } from "@chakra-ui/react";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";

export type Stats = {
  id: string;
  value: number | string | undefined | null;
  label: string;
  dynamicSizing?: boolean;
};
type StatsGridProps<T extends Stats[]> = {
  stats?: T;
  isLoading?: boolean;
  columnsLarge?: number;
  columnsSmall?: number;
  hasBorders?: boolean;
  hasIcons?: boolean;
  renderBanner?: (stat: T[number]) => ReactNode;
};

export function StatsGrid<T extends Stats[]>({
  stats,
  isLoading,
  columnsLarge = 3,
  columnsSmall = 2,
  hasBorders = false,
  hasIcons = true,
  renderBanner,
}: StatsGridProps<T>) {
  const generateTemplateColumns = (
    stats: T | undefined,
    columnsCount: number,
  ) => {
    if (!stats?.length) return `repeat(${columnsCount}, 1fr)`;
    const dynamicColumns =
      stats
        ?.map((stat) => (stat.dynamicSizing ? "auto" : "1fr"))
        .slice(0, columnsCount) || Array(columnsCount).fill("1fr");

    return dynamicColumns.join(" ");
  };

  const templateColumnsBase = generateTemplateColumns(stats, columnsSmall);
  const templateColumnsLg = generateTemplateColumns(stats, columnsLarge);

  return (
    <SkeletonGrid
      isLoaded={!isLoading}
      gridSize={4}
      gap={6}
      templateColumns={{
        base: templateColumnsBase,
        lg: templateColumnsLg,
      }}
      skeleton={
        <StatsCardSkeleton hasBorders={hasBorders} hasIcons={hasIcons} />
      }
    >
      <Grid
        templateColumns={{
          base: templateColumnsBase,
          lg: templateColumnsLg,
        }}
        gap={6}
      >
        {stats?.map((stat) =>
          stat.value === 0 ? null : (
            <GridItem key={stat.id} w={"100%"}>
              {hasBorders ? (
                <Box
                  p={4}
                  border={"1px solid"}
                  borderColor={"gray.200"}
                  borderRadius={"lg"}
                  display={"flex"}
                  mx={[6, 0]}
                  flexDir={"column"}
                  gap={4}
                >
                  <StatsCard
                    stat={stat}
                    icon={hasIcons ? (BarChartIcon as As) : undefined}
                  />
                  {renderBanner ? renderBanner(stat) : null}
                </Box>
              ) : (
                <Box display={"flex"} flexDir={"column"} gap={4}>
                  <StatsCard
                    stat={stat}
                    icon={hasIcons ? (BarChartIcon as As) : undefined}
                  />
                  {renderBanner ? renderBanner(stat) : null}
                </Box>
              )}
            </GridItem>
          ),
        )}
      </Grid>
    </SkeletonGrid>
  );
}
