import { useEffect, useState, type ReactNode } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Icon,
  IconButton,
  Image,
  Show,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import rexRealEstateLogo from "assets/a-logo.png";
import HamburgerMenuIcon from "assets/icons/medium/hamburger-menu.svg?react";
import poweredByRexHomeLogo from "assets/powered-by-rex-home.png";
import { useAuth } from "contexts/auth-provider";
import { derivePortalFromUrl } from "utils/derive-portal-from-url";

export const TOP_NAVIGATION_BAR_HEIGHT = 16;

type TopNavigationBarProps = {
  leftNavigationMenu: ReactNode;
};
export function TopNavigationBar({
  leftNavigationMenu,
}: TopNavigationBarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const { propertyId } = useParams();
  const [currentLocation, setCurrentLocation] = useState<string>();
  const location = useLocation();
  const property = user?.related?.[
    derivePortalFromUrl() as keyof typeof user.related
  ]?.find((property) => property.id === propertyId);

  const [isPrint] = useMediaQuery("print");

  useEffect(() => {
    if (currentLocation !== location.pathname) {
      setCurrentLocation(location.pathname);
      onClose();
    }
  }, [currentLocation, location.pathname, setCurrentLocation, onClose]);

  if (isPrint) {
    return null;
  }

  return (
    <>
      <Box
        h={TOP_NAVIGATION_BAR_HEIGHT}
        flex={1}
        w={"100%"}
        bg={"gray.900"}
        pos={"fixed"}
        top={0}
        left={0}
        zIndex={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={6}
      >
        <Image src={rexRealEstateLogo} alt={"Rex Real Estate"} w={"100px"} />
        <Show above={"lg"}>
          <Image
            ml={"auto"}
            src={poweredByRexHomeLogo}
            alt={"Powered By Rex Home"}
            w={"186px"}
          />
        </Show>
        <Show below={"lg"}>
          <IconButton
            variant={"ghost"}
            colorScheme={"white"}
            aria-label={"Menu Button"}
            fontSize={"20px"}
            onClick={onOpen}
            icon={<Icon as={HamburgerMenuIcon} color={"white"} />}
          />
        </Show>
      </Box>
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerContent bg={"indigo.50"}>
          <DrawerCloseButton size={"lg"} />
          <DrawerHeader
            borderBottomWidth={"1px"}
            borderBottomColor={"gray.100"}
          >
            <Text fontSize={"2xl"} fontWeight={700}>
              {property?.address.short_address}
            </Text>
          </DrawerHeader>
          <DrawerBody p={0}>{leftNavigationMenu}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
