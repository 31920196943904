export function upperFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function titleCase(input: string): string {
  return (
    input
      // Split the string by underscores or spaces.
      .split(/_| /)
      .map(
        (word) =>
          // Capitalize the first letter of each word and convert the rest to lower case.
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      )
      // Combine the words into a single string with spaces.
      .join(" ")
  );
}

/**
 * Converts a number of bytes into a human readable string with appropriate units
 * @param bytes - The number of bytes to convert
 * @param decimals - Number of decimal places to show (default: 2)
 * @returns A formatted string like "1.78 GB" or "23.45 MB"
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
  if (bytes === 0) return "0 B";

  const k = 1024;
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  // Find the appropriate unit by calculating how many times we can divide by 1024
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Convert to the unit and round to specified decimal places
  const value = bytes / Math.pow(k, i);

  // Handle special case for bytes (no decimals needed)
  if (i === 0) {
    return `${Math.round(value)} ${units[i]}`;
  }

  return `${value.toFixed(decimals)} ${units[i]}`;
}

export const UNKNOWN_VALUE_MAP = "???";
