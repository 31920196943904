import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  type ReactNode,
} from "react";
import { Center, Spinner } from "@chakra-ui/react";
import NiceModal from "@ebay/nice-modal-react";
import { useQuery } from "@tanstack/react-query";
import { GenerateDevTokenModal } from "components/dev/generate-dev-token-modal";
import { sessionModel, type SessionData } from "contexts/session";
import type { Role } from "types/roles";
import { getAuthToken } from "utils/auth";

export type AuthContextType = {
  user?: SessionData;
  isLandlord: boolean;
  isTenant: boolean;
  isVendor: boolean;
  isAgent: boolean;
  isAgentManager: boolean;
  hasRole: (role: Role) => boolean;
};

const AuthContext = createContext<AuthContextType>({
  isLandlord: false,
  isTenant: false,
  isVendor: false,
  isAgent: false,
  isAgentManager: false,
  hasRole: () => false,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

function Unauthorised() {
  const isDev = import.meta.env.DEV;

  useEffect(() => {
    if (isDev) {
      NiceModal.show(GenerateDevTokenModal);
    }
  }, [isDev]);

  return <div>Not Authorised</div>;
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data: sessionData } = useQuery(
    sessionModel.queries.read(getAuthToken()),
  );

  const contextValue = useMemo(() => {
    const hasRole = (role: Role) => sessionData?.roles.includes(role) || false;

    return {
      user: sessionData,
      hasRole,
      isVendor: hasRole("vendor"),
      isTenant: hasRole("tenant"),
      isLandlord: hasRole("landlord"),
      isAgent: hasRole("agent"),
      isAgentManager: hasRole("agent_manager"),
    };
  }, [sessionData]);

  if (!sessionData && !!getAuthToken()) {
    return (
      <Center w={"100vw"} h={"100vh"}>
        <Spinner size={"xl"} />
      </Center>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function RequireAuthRole({
  anyOf,
  children,
}: {
  anyOf?: Role[];
  children: ReactNode;
}) {
  const auth = useAuth();
  const token = getAuthToken();

  if (!token && import.meta.env.DEV) {
    return <Unauthorised />;
  }

  if (anyOf && !anyOf.some((role) => auth.hasRole(role))) {
    return <Unauthorised />;
  }

  return children;
}
