import dayjs from "dayjs";
import { PageCard } from "components/page-card";
import { SimpleGridTable } from "components/simple-grid-table";
import type { Tenancy } from "types/tenancy";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { UNKNOWN_VALUE_MAP } from "utils/string";

type TenancyDetailsProps = {
  tenancy?: Tenancy;
};

const frequencyMap = {
  weekly: "week",
  fortnightly: "fortnight",
  monthly: "month",
  quarterly: "quarter",
  annually: "year",
};

export function TenancyDetails({ tenancy }: TenancyDetailsProps) {
  const dateFormat = (date: string | null | undefined) =>
    date ? dayjs(date).format(dateFormats.dayMonthYear) : "–";

  const sortedTenants = tenancy?.tenants.sort((a, b) => {
    if (a.role_id === "primary") return -1;
    if (b.role_id === "primary") return 1;
    return 0;
  });

  return (
    <PageCard heading={"Tenancy details"}>
      <SimpleGridTable
        items={[
          {
            label: "Lease start",
            value: dateFormat(tenancy?.agreement_start_date),
          },
          {
            label: "Last payment",
            value: UNKNOWN_VALUE_MAP,
          },
          {
            label: "Lease end",
            value: dateFormat(tenancy?.agreement_end_date),
          },
          {
            label: "Bond held",
            value: formatMoney(tenancy?.security_deposit_amount),
          },
          {
            label: "Rent",
            value:
              formatMoney(tenancy?.rent_amount) +
              ` per ${
                tenancy?.frequency_id ? frequencyMap[tenancy?.frequency_id] : ""
              }`,
          },
          {
            label: "Tenants",
            value: sortedTenants
              ?.map(
                (t) =>
                  t.contact.display_name +
                  (t.role_id === "primary" ? ` (primary)` : ""),
              )
              .join(", "),
          },
        ]}
      />
    </PageCard>
  );
}
