export const VENDOR_PORTAL_PATHS = {
  DEFAULT: "/vendor/:propertyId",
  OVERVIEW: "",
  EVENTS: "events",
  EVENT: "events/:eventId",
  FEEDBACK: "feedback",
  PERFORMANCE: "performance",
  OFFERS: "offers",
  MARKETING: "marketing",
  DOCUMENTS: "documents",
} as const;
