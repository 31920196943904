import { useSearchParams } from "react-router-dom";
import { SelectInput, type OptionsGroup } from "components/inputs/select-input";
import { useAuth } from "contexts/auth-provider";

export function PropertySelector() {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { property_id } = Object.fromEntries(searchParams.entries());

  const options: OptionsGroup[] = [
    {
      label: { id: "properties", value: "Properties" },
      options: [{ value: "all", label: "All properties" }].concat(
        auth.user?.related?.landlord_properties.map((property) => ({
          value: property.id,
          label: property.address.short_address_address_address,
        })) || [],
      ),
    },
  ];

  return (
    <SelectInput
      menuButtonProps={{
        borderWidth: 1,
        borderColor: "gray.200",
        _hover: { bg: "gray.200" },
      }}
      optionGroups={options}
      value={
        options[0].options.find((o) => o.value === (property_id || "all"))
          ?.value
      }
      onChange={(v) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (v !== "all") {
          newSearchParams.set("property_id", v);
        } else {
          newSearchParams.delete("property_id");
        }
        setSearchParams(newSearchParams);
      }}
    />
  );
}
