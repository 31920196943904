import { faker } from "@faker-js/faker";
import type { Stats } from "components/stats-grid";
import type { ListingCategories } from "modules/vendor-portal/types/listing";
import { mockProperty, type Property } from "types/property";
import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

export type EnquirySourceStat = {
  id: string;
  label: string;
  value: number;
};

export enum CampaignStatus {
  Active = "active",
  Inactive = "inactive",
  Pending = "pending",
}

type EngagementStat = {
  clicks: string;
  impressions: string;
  time: string;
};

export type MarketingCampaign = {
  id: string;
  source_title: string;
  status: CampaignStatus;
  type: string;
  primary_stats: {
    total: PortalStats[];
    last7days?: PortalStats[];
    last30days?: PortalStats[];
  };
  secondary_stats: {
    total: {
      id: string;
      title: string;
      data: PortalStats[];
    }[];
    last7days?: {
      id: string;
      title: string;
      data: PortalStats[];
    }[];
    last30days?: {
      id: string;
      title: string;
      data: PortalStats[];
    }[];
  };
  engagement_stats?: EngagementStat[];
};

export type ListingPerformance = {
  id: string;
  listing_category_id: ListingCategories | null;
  property: Property;
  performance_stats: Stats[];
  enquiry_stats: {
    sources: EnquirySourceStat[];
  };
  campaigns: MarketingCampaign[];
};

export type PerformanceData = {
  listing: ListingPerformance;
};

export type PerformanceApiResponse = {
  data: Array<{
    id: string;
    category: {
      id: string;
      label: string;
    };
    status: {
      id: string;
      label: string;
    };
    summary: {
      count_of_enquiries: {
        value: number;
      };
      count_of_enquiries_by_source: {
        value: Array<{
          id: string;
          label: string;
          value: number;
        }>;
      };
      most_common_enquiry_source: {
        value: {
          id: string;
          label: string;
        };
      };
    };
    performance: {
      stats: Array<PerformanceStat>;
    };
  }>;
};

type StatMetadata = {
  key: string;
  label: string;
  section: string | null;
  importance: string;
  aggregate_method: "sum" | "percentage";
};

type PerformanceStat = {
  id: string;
  type: string;
  format: {
    id: string;
    meta: StatMetadata[];
  };
  profile: {
    name: string;
  };
  value: Record<string, Record<string, number>>;
};

enum DateRange {
  SEVEN_DAYS = "7days",
  THIRTY_DAYS = "30days",
  TOTAL = "total",
}

/*
 Example dailyStatsData shape:
    {
      "2025-03-17": {
        "listingEvents:pageView": 310,
        "listingEvents:expandMap": 984,
        ...etc
      },
      "2025-03-18": {
        "listingEvents:pageView": 242,
        "listingEvents:expandMap": 148,
        ...etc
      },
      ..etc
    }
 */

function filterDataByDateRange(
  dailyStatsData: Record<string, Record<string, number>>,
  dateRange: DateRange,
  meta: StatMetadata[],
): Record<string, number> {
  // Grab all the dates from the data object keys
  const dailyStatsDates = Object.keys(dailyStatsData).sort();

  if (dailyStatsDates.length === 0) {
    return {};
  }

  const today = new Date();

  const dateRangeMap: Record<DateRange, number> = {
    "7days": new Date().setDate(today.getDate() - 7),
    "30days": new Date().setDate(today.getDate() - 30),
    total: 0,
  };

  // Filter the dates to only those that are after or equal to the dateRangeMap date
  const filteredDates = dailyStatsDates.filter(
    (date) => new Date(date).getTime() >= dateRangeMap[dateRange],
  );

  // If no dates match the filter, return empty object
  if (filteredDates.length === 0) {
    return {};
  }

  // Get the data for the filtered dates
  const filteredDatesData = filteredDates.map((date) => {
    return dailyStatsData[date];
  });

  // Sum up the stats for each key value pair for the filtered dates
  const aggregatedData = filteredDatesData.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if (acc[key] !== undefined) {
        acc[key] += curr[key];
      } else {
        acc[key] = curr[key];
      }
    });
    return acc;
  }, {});

  // Get all the stat keys that need to be aggregated as percentage values
  const percentageKeys = meta
    .filter((m) => m.aggregate_method === "percentage")
    .map((m) => m.key);

  // Some portals ie Domain will return stats as percentage values
  // As such we need to calculate the aggregate percentage values after we have summed the values
  // This is done by dividing the total summed value by the number of days in the date range
  percentageKeys.forEach((key) => {
    aggregatedData[key] = aggregatedData[key] / filteredDates.length;
  });

  // Return a single object consisting of key value pairs for each stat where
  // the value is the aggregate value for the date range
  return aggregatedData;
}

function parsePerformanceData(
  apiResponse: PerformanceApiResponse,
): PerformanceData {
  const { summary, performance, id, category } = apiResponse.data[0];

  // Parse performance stats
  const performanceStats = [
    {
      id: "enquiries",
      value: summary.count_of_enquiries.value,
      label: "Enquiries",
    },
    {
      id: "most-common-source",
      value: summary.most_common_enquiry_source.value.label,
      label: "Highest enquiry source",
    },
  ];

  return {
    listing: {
      id,
      listing_category_id: category.id as ListingCategories,
      property: mockProperty(),
      performance_stats: performanceStats,
      enquiry_stats: {
        sources: summary.count_of_enquiries_by_source.value,
      },
      campaigns: parsePortalProfileStats(performance.stats),
    },
  };
}

function parsePortalProfileStats(stats: PerformanceStat[]) {
  return stats.map((stat) => {
    const baseStats = {
      id: stat.format.id,
      source_title: stat.profile.name,
      status: CampaignStatus.Active,
      type: stat.type,
    };

    const primaryStatsMeta = stat.format.meta.filter(
      (m) => m.importance === "primary",
    );

    if (stat.type === "summary") {
      return {
        ...baseStats,
        primary_stats: {
          total: mapStatsWithData(primaryStatsMeta, stat.value.summary),
        },
        secondary_stats: {
          total: groupStatsBySection(stat.format.meta, stat.value.summary),
        },
        engagement_stats: [],
      };
    }

    const aggregatedStats = {
      total: filterDataByDateRange(
        stat.value,
        DateRange.TOTAL,
        stat.format.meta,
      ),
      last7days: filterDataByDateRange(
        stat.value,
        DateRange.SEVEN_DAYS,
        stat.format.meta,
      ),
      last30days: filterDataByDateRange(
        stat.value,
        DateRange.THIRTY_DAYS,
        stat.format.meta,
      ),
    };

    return {
      ...baseStats,
      primary_stats: {
        total: mapStatsWithData(primaryStatsMeta, aggregatedStats.total),
        last7days: mapStatsWithData(
          primaryStatsMeta,
          aggregatedStats.last7days,
        ),
        last30days: mapStatsWithData(
          primaryStatsMeta,
          aggregatedStats.last30days,
        ),
      },
      secondary_stats: {
        total: groupStatsBySection(stat.format.meta, aggregatedStats.total),
        last7days: groupStatsBySection(
          stat.format.meta,
          aggregatedStats.last7days,
        ),
        last30days: groupStatsBySection(
          stat.format.meta,
          aggregatedStats.last30days,
        ),
      },
    };
  });
}

type PortalStats = Stats & {
  aggregateMethod: StatMetadata["aggregate_method"];
};

function mapStatsWithData(
  meta: StatMetadata[],
  data: Record<string, number>,
): PortalStats[] {
  return meta.map((m) => ({
    id: m.key,
    label: m.label,
    value: data[m.key] || 0,
    aggregateMethod: m.aggregate_method,
  }));
}

// Helper function to group stats by section
function groupStatsBySection(
  meta: StatMetadata[],
  data: Record<string, any>,
): Array<{ id: string; title: string; data: PortalStats[] }> {
  // Get all secondary stats with their sections
  const secondaryStats = meta.filter(
    (m: StatMetadata) => m.importance === "secondary",
  );

  const sections = new Set<string>(
    secondaryStats
      .filter((s) => s.section !== null)
      .map((s) => s.section as string),
  );

  return Array.from(sections).map((section: string) => ({
    id: section.toLowerCase().replace(/\s+/g, "_"),
    title: section,
    data: secondaryStats
      .filter((i) => i.section === section)
      .map((s) => ({
        id: s.key,
        label: s.label,
        value: data[s.key] || 0,
        aggregateMethod: s.aggregate_method,
      })),
  }));
}

// Update the performanceModel to use the parser
export const performanceModel = createModel("performance", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: async () => {
        const response =
          await apiClient.get<PerformanceApiResponse>(`/performance`);
        return parsePerformanceData(response);
      },
    }),
  },
});

export const mockListingPerformanceResponse = {
  data: [
    {
      id: "9e584279-8936-4fcd-aae5-9537c153541c",
      category: {
        id: "residential",
        label: "Residential",
      },
      status: {
        id: "current",
        label: "current",
      },
      sale_or_rent: {
        id: "rental",
        label: "Rent",
      },
      date_listed: null,
      summary: {
        days_since_listed: {
          value: faker.number.int(60),
          type: {
            id: "int",
            label: "Int",
            nullable: true,
          },
        },
        count_of_attendees_attending_more_than_once: {
          value: faker.number.int(10),
          type: {
            id: "int",
            label: "Int",
            nullable: false,
          },
        },
        count_of_enquiries: {
          value: faker.number.int(10),
          type: {
            id: "int",
            label: "Int",
            nullable: false,
          },
        },
        count_of_enquiries_by_source: {
          value: [
            {
              id: "204",
              label: "Board",
              value: faker.number.int(10),
            },
            {
              id: "26859",
              label: "Animi Random Enquiry Source",
              value: faker.number.int(10),
            },
            {
              id: "26858",
              label: "Officiis Random Enquiry Source",
              value: faker.number.int(10),
            },
            {
              id: "190",
              label: "Bus Stop",
              value: faker.number.int(10),
            },
            {
              id: "193",
              label: "Telemarketing",
              value: faker.number.int(10),
            },
          ],
          type: {
            id: "array<Stat<int>>",
            label: "Array of stat objects with int values",
            nullable: false,
          },
        },
        count_of_feedback_by_interest_level: {
          value: [
            {
              id: "hot",
              label: "Hot",
              value: faker.number.int(10),
            },
            {
              id: "warm",
              label: "Warm",
              value: faker.number.int(10),
            },
            {
              id: "cold",
              label: "Cold",
              value: faker.number.int(10),
            },
            {
              id: "unknown",
              label: "Unknown",
              value: faker.number.int(10),
            },
          ],
          type: {
            id: "array<Stat<int>>",
            label: "Array of stat objects with int values",
            nullable: false,
          },
        },
        count_of_inspections_and_open_homes: {
          value: faker.number.int(10),
          type: {
            id: "int",
            label: "Int",
            nullable: false,
          },
        },
        count_of_unique_attendees: {
          value: faker.number.int(10),
          type: {
            id: "int",
            label: "Int",
            nullable: false,
          },
        },
        list_of_price_indications: {
          value: [36253, 82831, 203641],
          type: {
            id: "array<int>",
            label: "Array of ints",
            nullable: false,
          },
        },
        list_of_offer_amounts: {
          value: [1499732, 924304, 226004],
          type: {
            id: "array<int>",
            label: "Array of ints",
            nullable: false,
          },
        },
        most_common_enquiry_source: {
          value: {
            id: "204",
            label: "Board",
          },
          type: {
            id: "enum",
            label: "Enum",
            nullable: true,
          },
        },
      },
      performance: {
        stats: [
          {
            id: "9e584279-27f8-4ca5-82bf-ca62008ec2a3",
            type: "daily",
            format: {
              id: "au_reaapi",
              meta: [
                {
                  key: "listingEvents:pageView",
                  label: "Property page views",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:searchResultPhotoView",
                  label: "Search result photo views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:expandMap",
                  label: "Expand map",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:videoView",
                  label: "Video views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:propertyDetailPhotoView",
                  label: "Photo views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:floorplanView",
                  label: "Floorplan views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:virtualTourView",
                  label: "Virtual tour views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:3dTourView",
                  label: "3D tour views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:emailEnquiry",
                  label: "Email enquiries",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:revealedAgentPhoneNumber",
                  label: "Agent phone number reveals",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:rentalAppliedOnline",
                  label: "Applied for rental online",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:appliedForInspection",
                  label: "Applied for inspection",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:savedInspectionTime",
                  label: "Inspection time saved",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:savedAuctionTime",
                  label: "Auction time saved",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:listingSaved",
                  label: "Property saved",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:sendToFriend",
                  label: "Send to friend",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:viewStatementOfInformation",
                  label: "Statement of information views",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "listingEvents:searchResultsPageImpression",
                  label: "Search results page impression",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "audienceSourcePageView:mobile",
                  label: "Mobile",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "audienceSourcePageView:desktop",
                  label: "Desktop",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "audienceSourcePageView:other",
                  label: "Other",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "audienceSourcePageView:tablet",
                  label: "Tablet",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "audienceSourcePageView:app",
                  label: "App",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
              ],
            },
            profile: {
              name: "realestate.com.au 579",
            },
            value: {
              "2025-03-17": {
                "listingEvents:pageView": 310,
                "listingEvents:expandMap": 984,
                "listingEvents:videoView": 978,
                "listingEvents:3dTourView": 717,
                "audienceSourcePageView:app": 160,
                "listingEvents:emailEnquiry": 712,
                "listingEvents:listingSaved": 237,
                "listingEvents:sendToFriend": 393,
                "listingEvents:floorplanView": 721,
                "audienceSourcePageView:other": 19,
                "audienceSourcePageView:mobile": 748,
                "audienceSourcePageView:tablet": 627,
                "listingEvents:virtualTourView": 16,
                "audienceSourcePageView:desktop": 858,
                "listingEvents:savedAuctionTime": 622,
                "listingEvents:rentalAppliedOnline": 211,
                "listingEvents:savedInspectionTime": 984,
                "listingEvents:appliedForInspection": 71,
                "listingEvents:searchResultPhotoView": 748,
                "listingEvents:propertyDetailPhotoView": 225,
                "listingEvents:revealedAgentPhoneNumber": 226,
                "listingEvents:viewStatementOfInformation": 158,
                "listingEvents:searchResultsPageImpression": 287,
              },
              "2025-02-30": {
                "listingEvents:pageView": 242,
                "listingEvents:expandMap": 148,
                "listingEvents:videoView": 832,
                "listingEvents:3dTourView": 546,
                "audienceSourcePageView:app": 998,
                "listingEvents:emailEnquiry": 819,
                "listingEvents:listingSaved": 604,
                "listingEvents:sendToFriend": 436,
                "listingEvents:floorplanView": 5,
                "audienceSourcePageView:other": 370,
                "audienceSourcePageView:mobile": 696,
                "audienceSourcePageView:tablet": 602,
                "listingEvents:virtualTourView": 493,
                "audienceSourcePageView:desktop": 499,
                "listingEvents:savedAuctionTime": 782,
                "listingEvents:rentalAppliedOnline": 917,
                "listingEvents:savedInspectionTime": 967,
                "listingEvents:appliedForInspection": 369,
                "listingEvents:searchResultPhotoView": 138,
                "listingEvents:propertyDetailPhotoView": 193,
                "listingEvents:revealedAgentPhoneNumber": 370,
                "listingEvents:viewStatementOfInformation": 64,
                "listingEvents:searchResultsPageImpression": 954,
              },
            },
          },
          {
            id: "9e584279-2bdd-490b-b1b5-ad044832a313",
            type: "daily",
            format: {
              id: "au_domainapi",
              meta: [
                {
                  key: "totalListingViews",
                  label: "Property page views",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalShortlisted",
                  label: "Property saved",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalEnquiries",
                  label: "Enquiries",
                  section: null,
                  importance: "unspecified",
                  aggregate_method: "sum",
                },
                {
                  key: "totalEmailToFriend",
                  label: "Send to friend",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalPhotoViews",
                  label: "Photo views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalPhotoGalleryViews",
                  label: "Photo gallery views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalFloorplanViews",
                  label: "Floorplan views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalMapViews",
                  label: "Map views",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalVideoViews",
                  label: "Video views",
                  section: "Photos and Media",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalEbrochureViews",
                  label: "E-brochure views",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalAgentDetailViews",
                  label: "Agent details reveals",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalPrinted",
                  label: "Property page printed",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalAgentPhoneNumberReveals",
                  label: "Agent phone number reveals",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalSharedViaSocialMedia",
                  label: "Shared via social media",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalInspectionTimeSaving",
                  label: "Inspection time saved",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "totalCallToAgentFromMobile",
                  label: "Call to agent from mobile",
                  section: "Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "percentageWebsiteViews",
                  label: "Desktop",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "percentage",
                },
                {
                  key: "percentageMobileSiteViews",
                  label: "Mobile",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "percentage",
                },
                {
                  key: "percentageSmartPhoneViews",
                  label: "App",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "percentage",
                },
                {
                  key: "percentageTabletViews",
                  label: "Tablet",
                  section: "Audience Source",
                  importance: "secondary",
                  aggregate_method: "percentage",
                },
              ],
            },
            profile: {
              name: "Domain 585",
            },
            value: {
              "2024-03-03": {
                totalPrinted: 600,
                totalMapViews: 156,
                totalEnquiries: 562,
                totalPhotoViews: 780,
                totalVideoViews: 455,
                totalShortlisted: 338,
                totalListingViews: 303,
                totalEmailToFriend: 590,
                totalEbrochureViews: 355,
                totalFloorplanViews: 476,
                percentageTabletViews: 10,
                totalAgentDetailViews: 621,
                percentageWebsiteViews: 50,
                totalPhotoGalleryViews: 695,
                percentageMobileSiteViews: 30,
                percentageSmartPhoneViews: 10,
                totalInspectionTimeSaving: 4,
                totalSharedViaSocialMedia: 932,
                totalCallToAgentFromMobile: 840,
                totalAgentPhoneNumberReveals: 989,
              },
              "2024-03-04": {
                totalPrinted: 404,
                totalMapViews: 19,
                totalEnquiries: 4,
                totalPhotoViews: 795,
                totalVideoViews: 604,
                totalShortlisted: 747,
                totalListingViews: 388,
                totalEmailToFriend: 454,
                totalEbrochureViews: 563,
                totalFloorplanViews: 139,
                percentageTabletViews: 10,
                totalAgentDetailViews: 140,
                percentageWebsiteViews: 50,
                totalPhotoGalleryViews: 364,
                percentageMobileSiteViews: 30,
                percentageSmartPhoneViews: 10,
                totalInspectionTimeSaving: 63,
                totalSharedViaSocialMedia: 434,
                totalCallToAgentFromMobile: 747,
                totalAgentPhoneNumberReveals: 77,
              },
            },
          },
          {
            id: "9e584279-2e83-408d-82c9-3cf2812d131d",
            type: "summary",
            format: {
              id: "reach",
              meta: [
                {
                  key: "clicks",
                  label: "Ad Clicks",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "impressions",
                  label: "Ad Shown (Impressions)",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_photo_gallery_downloads",
                  label: "Photo Gallery Downloads",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_enquiry_submission",
                  label: "Enquiry Submissions",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_phone_clicks",
                  label: "Phone Button Clicks",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_email_clicks",
                  label: "Email Button Clicks",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_add_inspection_to_calendar",
                  label: "Inspection Time Saved",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_add_auction_to_calendar",
                  label: "Auction Time Saved",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_image_slides",
                  label: "Image Slides",
                  section: "Landing Page Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_video_plays",
                  label: "Video Plays",
                  section: "Landing Page Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_start_filling_enquiry_form",
                  label: "Started Filling Enquiry Form",
                  section: "Landing Page Conversions",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_scroll_90_percent",
                  label: "Scroll down 90% of the page",
                  section: "Landing Page Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_open_description",
                  label: "Open Full Description",
                  section: "Landing Page Engagement",
                  importance: "secondary",
                  aggregate_method: "sum",
                },
                {
                  key: "lp_page_views",
                  label: "Landing Page Engagement",
                  section: null,
                  importance: "primary",
                  aggregate_method: "sum",
                },
              ],
            },
            profile: {
              name: "Reach",
            },
            value: {
              summary: {
                clicks: 155,
                impressions: 814,
                lp_page_views: 690,
                lp_video_plays: 836,
                lp_email_clicks: 942,
                lp_image_slides: 768,
                lp_phone_clicks: 58,
                lp_open_description: 735,
                lp_scroll_90_percent: 498,
                lp_enquiry_submission: 608,
                lp_add_auction_to_calendar: 164,
                lp_photo_gallery_downloads: 735,
                lp_add_inspection_to_calendar: 249,
                lp_start_filling_enquiry_form: 713,
              },
            },
          },
        ],
      },
    },
  ],
};
