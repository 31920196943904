import type { MutationOptions, QueryOptions } from "@tanstack/react-query";
import { mapValues } from "utils/collection";

export function createQueries<
  TQueryFn extends (...args: any[]) => QueryOptions,
>(key: string, queries?: Record<string, TQueryFn>) {
  if (!queries) return queries;
  return mapValues(queries, (q) => (...args: Parameters<TQueryFn>) => {
    const options = q(...args);
    return {
      ...options,
      queryKey: [key, ...(options.queryKey || [])],
    } as ReturnType<TQueryFn>;
  });
}

export function createModel<
  TQueries extends Record<string, (...args: any[]) => QueryOptions>,
  TMutations extends Record<
    string,
    (...args: any[]) => MutationOptions<any, any, any, any>
  >,
>(
  key: string,
  queriesAndMutations: { queries?: TQueries; mutations?: TMutations },
) {
  return {
    queries: createQueries(key, queriesAndMutations.queries),
    mutations: queriesAndMutations.mutations,
  } as unknown as {
    queries: TQueries;
    mutations: TMutations;
  };
}
