import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  List,
  ListItem,
  Show,
  Skeleton,
  SkeletonText,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import {
  ImageGallery,
  MobileImageGallery,
} from "components/image-gallery/image-gallery";
import { PortalSelector } from "components/portal-selector";
import { Table } from "components/table/table";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import { ApproveMarketingModal } from "modules/vendor-portal/screens/marketing/components/approve-marketing-modal";
import { SendFeedbackModal } from "modules/vendor-portal/screens/marketing/components/send-feedback-modal";
import { marketingScreenModel } from "modules/vendor-portal/screens/marketing/models/marketing";
import {
  ListingAdvertType,
  ListingLinkType,
} from "modules/vendor-portal/types/listing";
import { dateFormats } from "utils/date";

const COLUMNS = [
  {
    label: "Provider",
    id: "provider_name",
  },
  {
    label: "Item",
    id: "product_name",
  },
  {
    label: "Date",
    id: "booking_date",
    formatValue: (value: string) =>
      dayjs(value).format(dateFormats.dayMonthYear),
  },
  {
    label: "Invoice ID",
    id: "_invoice_id",
  },
  {
    label: "Price",
    id: "price_inc_tax",
  },
];

const LINKS_MAP = {
  [ListingLinkType.VideoLink]: "video link",
  [ListingLinkType.VirtualTour]: "virtual tour",
  [ListingLinkType.InteractiveFloorPlan]: "interactive floorplan",
  [ListingLinkType.MiniWeb]: "mini web",
  [ListingLinkType.ExternalLink]: "external link",
};
export function MarketingScreen() {
  const {
    isOpen: approveIsOpen,
    onOpen: approveOnOpen,
    onClose: approveOnClose,
  } = useDisclosure();

  const {
    isOpen: sendFeedbackIsOpen,
    onOpen: sendFeedbackOnOpen,
    onClose: sendFeedbackOnClose,
  } = useDisclosure();

  const { data, isLoading, isFetching } = useQuery(
    marketingScreenModel.queries.read(),
  );

  const defaultListingAdvert = data?.listing?._related?.listing_adverts?.find(
    (i) => i.advert_type === ListingAdvertType.Internet,
  );

  const sortedImages =
    data?.listing?._related?.listing_images?.sort(
      (a, b) => (a.priority || 0) - (b.priority || 0),
    ) ?? [];

  const sortedFloorplans =
    data?.listing?._related?.listing_floorplans?.sort(
      (a, b) => (a.priority || 0) - (b.priority || 0),
    ) ?? [];

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>

      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={8}
      >
        <Heading
          fontSize={{ base: "2xl", md: "2xl" }}
          fontWeight={700}
          as={"h1"}
        >
          Marketing
        </Heading>

        <Heading fontSize={{ base: "xl", md: "xl" }} fontWeight={600}>
          Marketing schedule
        </Heading>

        <Box
          p={0}
          border={"1px solid"}
          borderColor={"gray.200"}
          borderRadius={"8px"}
          overflow={"hidden"}
        >
          <Table
            isLoading={isLoading}
            isFetching={isFetching}
            columns={COLUMNS}
            rows={data?.listing?._related?.vpa_campaign?._lineitems ?? []}
            emptyProps={{
              emptyMessage: "No documents found.",
              emptyDesc: "Please check back later.",
            }}
          />
        </Box>

        <HStack justifyContent={"space-between"} flexWrap={"wrap"} gap={4}>
          <VStack alignItems={"flex-start"}>
            <Heading fontSize={{ base: "xl", md: "xl" }} fontWeight={600}>
              Marketing material
            </Heading>
            <Text fontSize={"sm"} fontWeight={500} color={"gray.600"}>
              These text and photographs will be used for any marketing purposes
              published for the general public
            </Text>
          </VStack>
          <HStack>
            <Button
              variant={"solid"}
              bg={"gray.100"}
              color={"gray.700"}
              _hover={{ bg: "gray.200" }}
              onClick={sendFeedbackOnOpen}
            >
              Send feedback
            </Button>
            <Button colorScheme={"gray"} onClick={approveOnOpen}>
              Approve
            </Button>
          </HStack>
        </HStack>

        <Card p={6}>
          <VStack gap={3} alignItems={"flex-start"}>
            <Text fontWeight={700}>Links</Text>
            <SkeletonList
              isLoaded={!isLoading}
              listSize={4}
              w={"100%"}
              skeleton={<Skeleton h={"16px"} />}
            >
              <List spacing={3}>
                {data?.listing?._related?.listing_links?.map((link) => (
                  <ListItem key={link._id}>
                    <Text fontSize={"sm"} fontWeight={600}>
                      {LINKS_MAP[link.link_type]}
                    </Text>
                    <Link
                      key={link._id}
                      href={link.link_url}
                      isExternal
                      color={"blue.600"}
                      fontSize={"lg"}
                      fontWeight={500}
                    >
                      {link.link_url}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </SkeletonList>
          </VStack>
        </Card>

        <Card p={6}>
          <VStack gap={3} alignItems={"flex-start"}>
            <Text fontWeight={700}>Header</Text>
            <SkeletonText
              isLoaded={!isLoading}
              w={"100%"}
              noOfLines={2}
              spacing={"3"}
              skeletonHeight={"2"}
            >
              <Text>{defaultListingAdvert?.advert_heading}</Text>
            </SkeletonText>
          </VStack>
        </Card>

        <Card p={6}>
          <VStack gap={3} alignItems={"flex-start"}>
            <Text fontWeight={700}>Description</Text>
            <SkeletonText
              isLoaded={!isLoading}
              w={"100%"}
              noOfLines={8}
              spacing={"3"}
              skeletonHeight={"2"}
            >
              <Text whiteSpace={"pre-line"}>
                {defaultListingAdvert?.advert_body}
              </Text>
            </SkeletonText>
          </VStack>
        </Card>

        <Card p={6}>
          <VStack gap={3} alignItems={"flex-start"}>
            <Text fontWeight={700}>Imagery</Text>
            <Skeleton isLoaded={!isLoading}>
              <Show above={"md"}>
                <ImageGallery images={sortedImages} />
              </Show>
              <Show below={"md"}>
                <MobileImageGallery images={sortedImages} />
              </Show>
            </Skeleton>
          </VStack>
        </Card>

        <Card p={6}>
          <VStack gap={3} alignItems={"flex-start"}>
            <Text fontWeight={700}>Floor plans</Text>
            <Skeleton isLoaded={!isLoading}>
              <Show above={"md"}>
                <ImageGallery images={sortedFloorplans} />
              </Show>
              <Show below={"md"}>
                <MobileImageGallery images={sortedFloorplans} />
              </Show>
            </Skeleton>
          </VStack>
        </Card>

        <ApproveMarketingModal
          isOpen={approveIsOpen}
          onClose={approveOnClose}
        />
        <SendFeedbackModal
          isOpen={sendFeedbackIsOpen}
          onClose={sendFeedbackOnClose}
        />
      </Flex>
    </Box>
  );
}
