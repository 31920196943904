import type { Options as KyOptions } from "ky";
import { apiPrefix, kyClient } from "lib/ky";
import { getAuthToken } from "utils/auth";

async function makeKyRequest<R>({
  method,
  url,
  json,
  searchParams,
}: {
  method: string;
  url: string;
  json?: KyOptions["json"];
  searchParams?: KyOptions["searchParams"];
}) {
  url = url[0] === "/" ? url.replace(/^\//, "") : url;
  return (await kyClient(url, {
    method,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    searchParams,
    json,
  }).json()) as R;
}

export const apiClient = {
  get: <R>(url: string, searchParams?: KyOptions["searchParams"]) =>
    makeKyRequest<R>({ method: "GET", url, searchParams }),
  post: <R>(url: string, json: KyOptions["json"]) =>
    makeKyRequest<R>({ method: "POST", url, json }),
  put: <R>(url: string, json: KyOptions["json"]) =>
    makeKyRequest<R>({ method: "PUT", url, json }),
  patch: <R>(url: string, json: KyOptions["json"]) =>
    makeKyRequest<R>({ method: "PATCH", url, json }),
  delete: <R>(url: string, json?: KyOptions["json"]) =>
    makeKyRequest<R>({ method: "DELETE", url, json }),
  upload: async <R>(
    url: string,
    file: File,
    body: Record<string, any> = {},
  ) => {
    url = url[0] === "/" ? url.replace(/^\//, "") : url;

    const formData = new FormData();
    formData.append("file", file);

    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });

    const response = await fetch(apiPrefix + "/" + url, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(
        errorData.message || `Upload failed with status: ${response.status}`,
      );
    }

    return (await response.json()) as R;
  },
};
