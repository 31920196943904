import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  Icon,
  Show,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";
import {
  ResponsiveCards,
  type ResponsiveCard,
} from "components/responsive-cards";
import { StatsGrid, type Stats } from "components/stats-grid";
import { tenancyOverviewModel } from "modules/tenant-portal/models/overview";
import { AgencyDetails } from "modules/tenant-portal/screens/overview/components/agency-details";
import { TenantBannerImage } from "modules/tenant-portal/screens/overview/components/banner-image/tenant-banner-image";
import { TenancyDetails } from "modules/tenant-portal/screens/overview/components/tenancy-details";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";

export function OverviewScreen() {
  const { propertyId } = useParams();
  const { data, isLoading } = useQuery(
    tenancyOverviewModel.queries.read(propertyId),
  );

  const tenancy = data?.tenancy;
  const rentPosition = tenancy?.rent_position;

  const cards: ResponsiveCard[] = [
    {
      label: "Tenancy Details",
      element: <TenancyDetails tenancy={tenancy} />,
    },
    {
      label: "Agency details",
      element: <AgencyDetails agency={data?.agency} />,
    },
  ];

  const dateFormat = (date: string | null | undefined) =>
    date ? dayjs(date).format(dateFormats.dayMonthYear) : "–";

  const stats = [
    {
      id: "effective_pay_to_date",
      label: "Effective pay to Date",
      value: dateFormat(rentPosition?.paid_to_date),
    },
    {
      id: "paid_to_period",
      label: "Paid to period",
      value: `${dateFormat(
        rentPosition?.fully_paid_period_start_date,
      )} - ${dateFormat(rentPosition?.fully_paid_period_end_date)}${
        rentPosition?.fully_paid_period_surplus
          ? ` + ${formatMoney(rentPosition?.fully_paid_period_surplus)}`
          : ""
      }`,
    },
    {
      id: "rent_arrears",
      label: "Rent arrears",
      value: formatMoney(tenancy?.rent_arrears?.amount),
    },
    {
      id: "next_payment",
      label: "Next payment",
      value: formatMoney(tenancy?.rent_amount),
    },
  ] as const satisfies Stats[];

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>
      <Flex direction={"column"} gap={8}>
        <TenantBannerImage isLoading={isLoading} property={data?.property} />
        <StatsGrid
          stats={stats}
          columnsLarge={2}
          columnsSmall={1}
          hasBorders
          isLoading={isLoading}
          renderBanner={(stat) => {
            if (stat.id === "rent_arrears" && tenancy?.rent_arrears?.days) {
              return (
                <Alert status={"error"}>
                  <AlertIcon />
                  <AlertTitle>
                    {tenancy?.rent_arrears?.days} day
                    {tenancy?.rent_arrears?.days > 1 ? "s" : ""} in arrears
                  </AlertTitle>
                </Alert>
              );
            }

            if (stat.id === "rent_arrears" && !tenancy?.rent_arrears?.days) {
              return (
                <Alert status={"success"}>
                  <AlertIcon />
                  <AlertTitle>You&apos;re up to date</AlertTitle>
                </Alert>
              );
            }

            if (stat.id === "next_payment") {
              return (
                <Alert status={"info"}>
                  <AlertIcon />
                  <AlertTitle>
                    Due on{" "}
                    {dayjs(tenancy?.next_rent_due_date).format(
                      dateFormats.dayMonthYear,
                    )}
                  </AlertTitle>
                </Alert>
              );
            }
          }}
        />
        <ResponsiveCards cards={cards} />
      </Flex>
    </Box>
  );
}
