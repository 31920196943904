import { Badge, Flex, Text } from "@chakra-ui/react";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";

export function getFeedbackHeaderText(
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry,
) {
  if (feedback.type.id === "agent_vendor") {
    return feedback.agent.name;
  }

  if (feedback.type.id === "price_reduction") {
    // Since price reduction details are not in the new structure, we return a placeholder
    return "Price change";
  }

  if (["enquiry", "followup"].includes(feedback.type.id)) {
    // For enquiry and followup, we use attendees if available
    if ("attendees" in feedback && feedback.attendees?.length) {
      return feedback.attendees
        .map((attendee) => attendee.contact.name)
        .join(", ");
    }
    return feedback.agent.name;
  }

  if (
    feedback.type.id === "inspection" &&
    "attendees" in feedback &&
    feedback.attendees?.length
  ) {
    return feedback.attendees
      .map((attendee) => attendee.contact.name)
      .join(", ");
  }

  if (
    feedback.type.id === "ofi" &&
    "attendees" in feedback &&
    feedback.attendees?.length
  ) {
    return feedback.attendees[0].contact.name;
  }
}

type FeedbackHeaderProps = {
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry;
  isNew?: boolean;
};

export function FeedbackHeader({ feedback }: FeedbackHeaderProps) {
  return (
    <Flex direction={"row"} align={"center"} gap={2}>
      <Text textStyle={"large"} fontWeight={"semibold"} color={"gray.700"}>
        {getFeedbackHeaderText(feedback)}
      </Text>
      {"is_new" in feedback && feedback.is_new ? (
        <Badge colorScheme={"badgeLightBlue"}>NEW</Badge>
      ) : null}
    </Flex>
  );
}
