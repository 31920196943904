import {
  Box,
  Button,
  chakra,
  Heading,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import InfoIcon from "assets/icons/medium/info.svg?react";

export function HelpPopover({
  image,
  title,
  description,
}: {
  image: string;
  title: string;
  description: string;
}) {
  return (
    <Popover placement={"right-end"} arrowSize={8} closeOnEsc>
      <PopoverTrigger>
        <chakra.span
          p={2}
          mb={-1}
          mt={-1}
          cursor={"pointer"}
          _hover={{ opacity: 0.7 }}
        >
          <Icon
            as={InfoIcon}
            color={"gray.600"}
            boxSize={"12px"}
            cursor={"pointer"}
          />
        </chakra.span>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={"400px"} shadow={"xl"}>
          <PopoverArrow />
          <Box p={4} borderBottomWidth={"1px"} borderColor={"gray.100"}>
            <Heading as={"h5"} fontSize={"lg"} fontWeight={"medium"}>
              {title}
            </Heading>
          </Box>
          <Box p={4}>
            <Image src={image} alt={title} boxSize={"100%"} mb={4} />
            <Text
              fontSize={"md"}
              lineHeight={1.4}
              fontWeight={"normal"}
              color={"gray.600"}
            >
              {description}
            </Text>
          </Box>
          <Box p={4} borderTopWidth={"1px"} borderColor={"gray.100"}>
            <Button
              as={PopoverCloseButton}
              variant={"secondary"}
              colorScheme={"gray"}
              _hover={{ bg: "gray.200" }}
              bg={"gray.100"}
              pos={"relative"}
              fontSize={"lg"}
              w={"100%"}
              top={0}
              right={0}
            >
              Got it
            </Button>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
