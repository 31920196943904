import { faker } from "@faker-js/faker";
import type { Stats } from "components/stats-grid";
import { interestLevels } from "modules/vendor-portal/screens/overview/models/overview";
import type {
  FeedbackType,
  OpenHomeFeedback,
} from "modules/vendor-portal/types/feedback";
import {
  FeedbackTypeId,
  FeedbackTypeText,
} from "modules/vendor-portal/types/feedback";
import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

export type OpenHomeData = {
  event: OpenHomeFeedback;
  stats: Stats[];
};

export const openHomeDetailsModel = createModel("openhome", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: () => apiClient.get<OpenHomeData>(`/openhome`),
    }),
  },
});

function mockTime(
  hourRange = { min: 0, max: 23 },
  minuteRange = { min: 0, max: 59 },
) {
  return `${faker.number.int(hourRange)}:${faker.number.int(minuteRange)}:00`;
}

function mockNote() {
  return faker.datatype.boolean()
    ? faker.lorem.paragraphs({ min: 1, max: 3 })
    : `${faker.lorem.paragraph({
        min: 1,
        max: 3,
      })}\n\n${faker.lorem.paragraph({ min: 1, max: 5 })}`;
}
function mockOpenHomeFeedback(feedbackType: FeedbackType): OpenHomeFeedback {
  return {
    id: faker.string.uuid(),
    type: feedbackType,
    open_home_event: {
      id: faker.string.uuid(),
      calendar_event_id: faker.datatype.boolean() ? faker.string.uuid() : null,
    },
    date_of: faker.date.recent({ days: 10 }).toDateString(),
    date_time_start: mockTime({ min: 10, max: 10 }, { min: 0, max: 0 }),
    date_time_finish: mockTime({ min: 10, max: 13 }, { min: 0, max: 59 }),
    is_new: faker.datatype.boolean(),
    number_of_people: faker.number.int({ min: 1, max: 100 }),
    attendees: [],
    note: mockNote(),
    agent: {
      id: faker.string.uuid(),
      name: faker.person.firstName() + " " + faker.person.lastName(),
    },
    avg_price_indication: faker.number.int({ min: 200000, max: 10000000 }),
    individual_feedback: [...Array(faker.number.int({ min: 1, max: 6 }))].map(
      () => ({
        id: faker.string.uuid(),
        name: faker.person.firstName() + " " + faker.person.lastName(),
        attendees: [
          {
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
            ofi_visits: faker.number.int({ min: 1, max: 5 }),
          },
        ],
        price_indication: faker.number.int({
          min: 200000,
          max: 10000000,
        }),
        enquiry_source: null,
        interest_level: faker.datatype.boolean()
          ? null
          : interestLevels[faker.number.int({ min: 0, max: 2 })],
        note: mockNote(),
      }),
    ),
  };
}
export const mockOpenHomeDetailsResponse = {
  event: mockOpenHomeFeedback({
    id: FeedbackTypeId.OFI,
    text: FeedbackTypeText.OFI,
  }),
  stats: [
    {
      id: "attendees",
      value: faker.number.int(30),
      label: "Attendees",
    },
    {
      id: "multiple-inspections",
      value: faker.number.int(100),
      label: "Buyers who have inspected twice",
    },
    {
      id: "avg-price-indication",
      value: faker.number.int(100),
      label: "Average price indication",
    },
    {
      id: "hot-buyers",
      value: faker.number.int(100),
      label: "Hot buyers",
    },
    {
      id: "warm-buyers",
      value: faker.number.int(100),
      label: "Warm buyers",
    },
    {
      id: "cold-buyers",
      value: faker.number.int(100),
      label: "Cold buyers",
    },
  ],
};
