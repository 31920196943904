import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { cssVar } from "@chakra-ui/theme-tools";

const $bg = cssVar("menu-bg");

export const menuTheme = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
).defineMultiStyleConfig({
  baseStyle: {
    button: {},
    list: {
      boxShadow: "lg",
    },
    groupTitle: {
      fontSize: "sm",
      fontWeight: 700,
      color: "gray.400",
      padding: "6px 16px",
      margin: 0,
    },
    item: {
      fontSize: "lg",
      fontWeight: 400,
      color: "gray.700",
      padding: "6px 16px",
      transitionDuration: false,
      _focus: {
        [$bg.variable]: "colors.gray.100",
      },
      _active: {
        [$bg.variable]: "colors.gray.200",
      },
    },
  },
});
