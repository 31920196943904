import { useState } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, HStack, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { Page } from "components/page";
import { StatsGrid } from "components/stats-grid";
import { Table, type TableColumn } from "components/table/table";
import { PropertySelector } from "modules/landlord-portal/components/property-selector";
import {
  financialsModel,
  getStatementDownload,
} from "modules/landlord-portal/models/financials";
import { FinancialBarChart } from "modules/landlord-portal/screens/financial/components/financial-bar-chart/financial-bar-chart";
import { FinancialsDateRange } from "modules/landlord-portal/screens/financial/components/financials-date-range";
import type { Statement } from "types/statement";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";

const columns: TableColumn<Statement>[] = [
  {
    id: "created_at",
    label: "Issued",
    width: "150px",
    formatValue: (_v: undefined, row: Statement) =>
      dayjs.unix(row.created_at).format(dateFormats.dayMonthYear),
  },
  {
    id: "display_name",
    label: "Details",
  },
  {
    label: "Period",
    formatValue: (_v: undefined, row: Statement) =>
      [
        dayjs(row.statement_from).format(dateFormats.dayMonthYear),
        dayjs(row.statement_to).format(dateFormats.dayMonthYear),
      ]
        .filter(Boolean)
        .join(" — "),
  },
  {
    id: "reference",
    label: "Reference",
  },
];

export function FinancialsScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [statementDownloadLoadingId, setStatementDownloadLoadingId] = useState<
    string | null
  >(null);

  const { data, isLoading, isFetching } = useQuery(
    financialsModel.queries.read(searchParams),
  );

  return (
    <Page
      heading={"Financials"}
      rightElement={
        <HStack flexWrap={"wrap"}>
          <FinancialsDateRange />
          <PropertySelector />
        </HStack>
      }
    >
      <StatsGrid
        isLoading={isLoading}
        columnsLarge={4}
        columnsSmall={2}
        stats={
          data?.summary
            ? [
                {
                  id: "money_in",
                  label: "Money in",
                  value: formatMoney(data?.summary.money_in),
                },
                {
                  id: "money_out",
                  label: "Money out",
                  value: formatMoney(data?.summary.money_out),
                },
                {
                  id: "net_balance",
                  label: "Net balance",
                  value: formatMoney(data?.summary.net_balance),
                },
                {
                  id: "reserved_funds",
                  label: "Reserved funds",
                  value: formatMoney(data?.summary.reserved_funds),
                },
              ]
            : []
        }
      />
      <FinancialBarChart chartData={data?.chart_data} isLoading={isLoading} />
      <Flex gap={4} direction={"column"}>
        <Flex direction={"row"} justify={"space-between"} flex={1}>
          <HStack gap={3}>
            <Heading fontSize={"xl"} as={"h2"}>
              Statements
            </Heading>
          </HStack>
          <FinancialsDateRange />
        </Flex>
        <Box
          bg={"white"}
          borderRadius={"md"}
          overflow={"hidden"}
          borderWidth={1}
        >
          <Table
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            rows={data?.statements.rows ?? []}
            meta={data?.statements.pagination}
            setPage={(page) =>
              setSearchParams((p) => {
                p.set("page", String(page));
                return p;
              })
            }
            customRowCell={(row) => (
              <Button
                colorScheme={"gray"}
                variant={"outline"}
                isDisabled={statementDownloadLoadingId === row.id}
                onClick={async () => {
                  setStatementDownloadLoadingId(row.id);
                  const url = await getStatementDownload(row);
                  setStatementDownloadLoadingId(null);
                  window.open(url);
                }}
                px={0}
              >
                {statementDownloadLoadingId === row.id ? (
                  <Spinner boxSize={4} />
                ) : (
                  <DownloadIcon />
                )}
              </Button>
            )}
            emptyProps={{
              emptyMessage: "No statements found.",
              emptyDesc: "Please check back later.",
            }}
          />
        </Box>
      </Flex>
    </Page>
  );
}
