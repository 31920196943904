import { feedbackTypeLabels } from "modules/vendor-portal/components/feedback-type-tag";
import { feedbackTypes } from "modules/vendor-portal/screens/feedback/models/feedback";
import type {
  AgentVendorFeedback,
  EnquiryFeedback,
  Feedback,
  FollowUpFeedback,
  IndividualOpenHomeFeedback,
  OpenHomeFeedback,
  PriceReductionFeedback,
  PrivateInspectionFeedback,
} from "modules/vendor-portal/types/feedback";
import { upperFirst } from "utils/string";

type FeedbackType =
  | "ofi"
  | "enquiry"
  | "inspection"
  | "followup"
  | "agent_vendor"
  | "price_reduction";

type FeedbackMapping = {
  [key in FeedbackType]: (feedback: Feedback) => string;
};

type FeedbackWithInterestLevel =
  | EnquiryFeedback
  | PrivateInspectionFeedback
  | OpenHomeFeedback
  | FollowUpFeedback;

export enum FeedbackGroupByOption {
  Name = "name",
  InterestLevel = "interest-level",
  FeedbackType = "feedback-type",
  Date = "date",
}

function groupByFeedbackType(feedback: Feedback[]) {
  return feedbackTypes.map((feedbackType) => ({
    label: feedbackTypeLabels[feedbackType.id],
    feedback: feedback.filter((i) => i.type.id === feedbackType.id),
  }));
}

function groupByInterestLevel(feedback: Feedback[]) {
  const levels = ["hot", "warm", "cold", null];

  return levels.map((interestLevel) => ({
    label: interestLevel ? upperFirst(interestLevel) : "Neutral",
    feedback: (feedback as Array<FeedbackWithInterestLevel>).filter((i) =>
      interestLevel
        ? i.interest_level?.id === interestLevel
        : i.interest_level === interestLevel,
    ),
  }));
}

function groupByDate(feedback: Feedback[]) {
  const allDates = feedback.map((i) => i.date_of);
  const uniqueDates = [...new Set(allDates)];
  return uniqueDates
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .map((date) => ({
      label: date,
      feedback: feedback.filter((i) => i.date_of === date),
    }));
}

const typeToNameProperty: FeedbackMapping = {
  ofi: (i) => (i as IndividualOpenHomeFeedback).attendees[0].name,
  enquiry: (i) => (i as EnquiryFeedback).related_contacts[0].name,
  inspection: (i) => (i as PrivateInspectionFeedback).attendees[0].name,
  followup: (i) => (i as FollowUpFeedback).related_contacts[0].name,
  agent_vendor: (i) => (i as AgentVendorFeedback).agent.name,
  price_reduction: (i) => (i as PriceReductionFeedback).agent.name,
};

function groupByName(feedback: Feedback[]) {
  const allNames = feedback.map((i) => {
    const getName = typeToNameProperty[i.type.id];
    return getName ? getName(i) : null;
  });
  const uniqueNames = [...new Set(allNames.filter((i) => i !== null))];
  return uniqueNames.map((name) => ({
    label: name,
    feedback: feedback.filter((i) => typeToNameProperty[i.type.id](i) === name),
  }));
}

export function groupFeedback(
  feedback: Feedback[],
  groupBy: FeedbackGroupByOption,
) {
  switch (groupBy) {
    case FeedbackGroupByOption.Name:
      return groupByName(feedback);
    case FeedbackGroupByOption.InterestLevel:
      return groupByInterestLevel(feedback);
    case FeedbackGroupByOption.FeedbackType:
      return groupByFeedbackType(feedback);
    case FeedbackGroupByOption.Date:
      return groupByDate(feedback);
  }
}
