import { CalendarIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CardRecord } from "components/cards/card-record";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import { formatAPIEventDateTimeTitle } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card";
import { EventCardSkeleton } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card-skeleton";
import { OpenHomeEventFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/open-home-event-footer";
import { PrivateInspectionEventFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/private-inspection-event-footer";
import type { APIListing } from "modules/vendor-portal/types/api-listing";

type UpcomingEventsProps = {
  events: NonNullable<APIListing["events"]>;
  isLoading: boolean;
};
export function UpcomingEvents({ events, isLoading }: UpcomingEventsProps) {
  return (
    <Card h={"100%"} variant={{ base: "unstyled", md: "outline" }}>
      <CardHeader
        display={{ base: "none", md: "flex" }}
        flexDir={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={6}
        pb={0}
      >
        <Heading fontSize={"xl"}>Upcoming Events</Heading>
        <Link to={"events"}>
          <Button variant={"solid"} colorScheme={"gray"}>
            View All
          </Button>
        </Link>
      </CardHeader>
      <CardBody p={0} pt={1} pb={2}>
        <SkeletonList isLoaded={!isLoading} skeleton={<EventCardSkeleton />}>
          {events.slice(0, 3).map((event) => (
            <CardRecord
              key={event.id}
              heading={formatAPIEventDateTimeTitle(event)}
              leftElement={<FeedbackIcon icon={CalendarIcon} />}
              style={{ border: "none" }}
            >
              {event.type.id === "inspection" ? (
                <PrivateInspectionEventFooter event={event} />
              ) : null}
              {event.type.id === "open_home" ? (
                <OpenHomeEventFooter event={event} />
              ) : null}
            </CardRecord>
          ))}
        </SkeletonList>
      </CardBody>
      <CardFooter display={{ base: "flex", md: "none" }} mt={8}>
        <Link to={"events"} style={{ width: "100%" }}>
          <Button variant={"solid"} colorScheme={"gray"} w={"100%"}>
            View All
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
}
