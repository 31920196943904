import { useState } from "react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  Flex,
  Icon,
  List,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import CommentIcon from "assets/icons/medium/comment.svg?react";
import { CardRecord } from "components/cards/card-record";
import {
  MultiSelectInput,
  type MenuOption,
} from "components/inputs/multi-select-input";
import { ShowMoreLessButton } from "components/show-more-less-button";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import { formatEventDateTimeTitle } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card";
import { EventCardSkeleton } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card-skeleton";
import { EventFeedbackInset } from "modules/vendor-portal/screens/overview/components/open-home-events/event-feedback-inset";
import { OpenHomeEventFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/open-home-event-footer";
import { PrivateInspectionEventFooter } from "modules/vendor-portal/screens/overview/components/open-home-events/footer/private-inspection-event-footer";
import { LinkedEventCard } from "modules/vendor-portal/screens/overview/components/open-home-events/linked-event-card";
import type {
  EventsScreenData,
  ViewingEvent,
} from "modules/vendor-portal/types/events";
import type { OpenHomeFeedback } from "modules/vendor-portal/types/feedback";

const FEEDBACK_OPTIONS: MenuOption[] = [
  { value: "inspection", label: "Private inspection" },
  { value: "ofi", label: "Open home" },
];
const DEFAULT_FILTERS = FEEDBACK_OPTIONS.map((x) => x.value);

type EventMobileLayoutProps = {
  listing?: EventsScreenData;
  isLoading: boolean;
};
export function EventMobileLayout({
  listing,
  isLoading,
}: EventMobileLayoutProps) {
  const [filters, setFilter] = useState<string[]>(DEFAULT_FILTERS);

  function filterByEventType(event: ViewingEvent) {
    return filters.includes(event.feedback.type.id);
  }

  const [showAllPastEvents, setShowAllPastEvents] = useState(false);
  const pastEventsToShow = showAllPastEvents
    ? listing?.events.past
    : listing?.events.past.slice(0, 3);

  const [showAllUpcomingEvents, setShowAllUpcomingEvents] = useState(false);
  const upcomingEventsToShow = showAllUpcomingEvents
    ? listing?.events.upcoming
    : listing?.events.upcoming.slice(0, 3);

  return (
    <Tabs variant={"soft-rounded"}>
      <TabList overflowX={"scroll"}>
        <Tab whiteSpace={"nowrap"}>Past events</Tab>
        <Tab whiteSpace={"nowrap"}>Upcoming events</Tab>
      </TabList>

      <MultiSelectInput
        placeholder={"Event type"}
        options={FEEDBACK_OPTIONS}
        value={filters}
        menuLabel={"Filter by"}
        onChange={setFilter}
        menuButtonProps={{
          mt: 8,
          mb: 3,
          w: "100%",
        }}
      />

      <TabPanels>
        <TabPanel>
          <SkeletonList
            isLoaded={!isLoading}
            skeleton={<EventCardSkeleton />}
            spacing={4}
            listSize={4}
          >
            <List spacing={4}>
              {pastEventsToShow?.filter(filterByEventType).map((event) => (
                <ListItem key={event.id}>
                  <LinkedEventCard event={event} variant={"unstyled"}>
                    {event.feedback.type.id === "inspection" ? (
                      <PrivateInspectionEventFooter event={event}>
                        <Flex dir={"row"} align={"center"} gap={1}>
                          <Icon as={CommentIcon} boxSize={"12px"} />
                          <FooterText text={`1`} />
                        </Flex>
                      </PrivateInspectionEventFooter>
                    ) : null}
                    {event.feedback.type.id === "ofi" ? (
                      <OpenHomeEventFooter event={event}>
                        {(event.feedback as OpenHomeFeedback)
                          .individual_feedback.length > 0 ? (
                          <Flex dir={"row"} align={"center"} gap={1}>
                            <Icon as={CommentIcon} boxSize={"12px"} />

                            <FooterText
                              text={`${
                                (event.feedback as OpenHomeFeedback)
                                  .individual_feedback.length
                              }`}
                            />
                          </Flex>
                        ) : null}
                      </OpenHomeEventFooter>
                    ) : null}
                    <EventFeedbackInset feedback={event.feedback} />
                  </LinkedEventCard>
                </ListItem>
              ))}
              <ShowMoreLessButton
                showAll={showAllPastEvents}
                setShowAll={setShowAllPastEvents}
              />
            </List>
          </SkeletonList>
        </TabPanel>
        <TabPanel>
          <SkeletonList
            isLoaded={!isLoading}
            skeleton={<EventCardSkeleton />}
            spacing={4}
            listSize={4}
          >
            <List spacing={4}>
              {upcomingEventsToShow?.filter(filterByEventType).map((event) => (
                <ListItem key={event.id}>
                  <CardRecord
                    heading={formatEventDateTimeTitle(event)}
                    leftElement={<FeedbackIcon icon={CalendarIcon} />}
                    variant={"unstyled"}
                  >
                    {event.feedback.type.id === "inspection" ? (
                      <PrivateInspectionEventFooter event={event} />
                    ) : null}
                    {event.feedback.type.id === "ofi" ? (
                      <OpenHomeEventFooter event={event} />
                    ) : null}
                  </CardRecord>
                </ListItem>
              ))}
              <ShowMoreLessButton
                showAll={showAllUpcomingEvents}
                setShowAll={setShowAllUpcomingEvents}
              />
            </List>
          </SkeletonList>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
