import { faker } from "@faker-js/faker";
import { mockAgencyContacts } from "contexts/session";
import { uniqueArray } from "lib/enforce-unique";
import type { APIListing } from "modules/vendor-portal/types/api-listing";
import {
  FeedbackTypeId,
  FeedbackTypeText,
  InterestLevelId,
  InterestLevelText,
} from "modules/vendor-portal/types/feedback";
import {
  ListingCategories,
  type Listing,
} from "modules/vendor-portal/types/listing";
import type { Image } from "types/image";
import { mockListingImages, mockProperty } from "types/property";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type OverviewData = {
  listing: Listing;
};

export const overviewModel = createModel("overview", {
  queries: {
    read: (listingId?: string) => ({
      queryKey: ["read", listingId],
      queryFn: () =>
        apiClient.get<APIListing>(
          `/portal/listings/${listingId}?include=${[
            "address",
            "documents",
            "agency_contacts",
            "property{details}",
            "marketing{adverts,image,images,floorplans,links,campaign.entries}",
            "applicant_feedback{entries{agent,attendees}}",
            "agent_updates{entries{agent},last_3_entries{agent}}",
            "offers{applicants}",
            `summary{${[
              "days_since_listed",
              "count_of_attendees_attending_more_than_once",
              "count_of_enquiries",
              "count_of_enquiries_by_source",
              "count_of_feedback_by_interest_level",
              "count_of_inspections_and_open_homes",
              "count_of_unique_attendees",
              "list_of_price_indications",
              "list_of_offer_amounts",
              "most_common_enquiry_source",
            ].join(",")}}`,
            "performance{stats}",
            "events{feedback{type,agent,attendees}}",
          ].join(",")}`,
        ),
      enabled: !!listingId,
      keepPreviousData: true,
    }),
  },
});

export function getHighestResolutionImageSource(
  image: Image | null | undefined,
): Image["sources"][number] | null {
  return (
    image?.sources.reduce((max, source) =>
      source.width > max.width ? source : max,
    ) || null
  );
}

export const feedbackTypes: { id: FeedbackTypeId; text: FeedbackTypeText }[] = [
  { id: FeedbackTypeId.Enquiry, text: FeedbackTypeText.Enquiry },
  { id: FeedbackTypeId.FollowUp, text: FeedbackTypeText.FollowUp },
  { id: FeedbackTypeId.Inspection, text: FeedbackTypeText.Inspection },
  { id: FeedbackTypeId.OFI, text: FeedbackTypeText.OFI },
  { id: FeedbackTypeId.PriceReduction, text: FeedbackTypeText.PriceReduction },
  { id: FeedbackTypeId.AgentVendor, text: FeedbackTypeText.AgentVendor },
];

export const interestLevels: {
  id: InterestLevelId;
  text: InterestLevelText;
}[] = [
  { id: InterestLevelId.Cold, text: InterestLevelText.Cold },
  { id: InterestLevelId.Warm, text: InterestLevelText.Warm },
  { id: InterestLevelId.Hot, text: InterestLevelText.Hot },
];

const mockAdvertisePriceAs = faker.number.int({ min: 200000, max: 10000000 });

export const mockOverviewResponse = (listingId: string) => {
  return {
    listing: {
      id: listingId,
      listing_category_id: ListingCategories.CommercialSale,
      price_advertise_as: mockAdvertisePriceAs,
      property: mockProperty(),
      _related: {
        listing_images: Array.from({ length: 3 }).map((_i, idx) => ({
          priority: `${idx + 1}`,
          id: faker.string.uuid(),
          thumbnails: {
            "800x600": {
              url: `${mockListingImages[idx]}`,
            },
          },
          url: `${mockListingImages[idx]}`,
        })),
      },
      agent_updates: faker.datatype.boolean()
        ? []
        : [1, 2, 3, 4].map(() => ({
            id: faker.string.uuid(),
            type: {
              id: FeedbackTypeId.AgentVendor,
              text: FeedbackTypeText.AgentVendor,
            },
            agent: {
              id: mockAgencyContacts[0].id,
              name: mockAgencyContacts[0].name,
              profile_image: {
                url: mockAgencyContacts[0].imageUrl,
              },
            },
            note: uniqueArray([
              "This past week has been quite exciting! We opened your home to fifteen lovely groups who were very impressed with the space and charm it offers. I'm thrilled to share that we've received three promising offers that reflect the desirability of your home. The online buzz has been fantastic too, showing just how much people are loving what they see.",
              "We had a fantastic turnout at the open homes this week, with many attendees expressing their admiration for the property's modern amenities and beautiful garden. The warmth of the community really shone through, and it was a pleasure to see potential buyers feeling right at home. Your property is creating quite a stir, and several visitors have already asked for private tours to take a closer look.",
              "This week, your home was the stage for 18 enthusiastic groups exploring every corner with interest. We're in a great position with two offers already on the table, both very close to our asking price. It's clear that the efforts we've put into showcasing your home are resonating well with buyers, as evidenced by the steady stream of new enquiries and continued interest.",
              "It was a vibrant week at your property, with numerous families and couples exploring the home during our open houses. Their reactions to the space were heartening, particularly their admiration for the sunlit living room and cozy nook by the fireplace. We also saw a couple of offers come in that reflect a genuine appreciation for the value of your home.",
              "This week, we opened your doors to the community and were thrilled to see the turnout. Many visitors expressed a real connection with the home, especially noting how well the outdoor space complements the interior. I've received several follow-up calls and emails expressing interest, and I'm excited to discuss these in more detail with you soon",
              "Our recent open houses have brought quite a crowd, sparking lively discussions and interest. Two offers have been placed this week, and they are very promising. The enthusiasm for your home is palpable, and it's clear that the charm and location are resonating well with potential buyers. We're on a great path, and I look forward to bringing more good news shortly.",
              "It's been a slower week, and I was hoping for more buzz, but the people who came by were genuinely considering their options. I've got a few ideas up my sleeve to boost our visibility—starting with a feature in our next newsletter and a push on our listings across all platforms. I'm here to keep pushing until we find the right buyer, and I'm confident we'll see more interest soon.",
            ]),
            is_new: faker.datatype.boolean(),
            date_of: faker.date.recent({ days: 10 }).toDateString(),
          })),
      feedback: [...Array(15)].map(() => ({
        id: faker.string.uuid(),
        date_of: faker.date.recent({ days: 10 }).toDateString(),
        agent: {
          id: faker.string.uuid(),
          name: faker.person.firstName() + " " + faker.person.lastName(),
          profile_image: { url: "https://picsum.photos/1280/400" },
        },
        type: feedbackTypes[faker.number.int({ min: 0, max: 4 })],
        related_contacts: [
          {
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
            ofi_visits: faker.number.int({ min: 1, max: 5 }),
          },
          {
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
            ofi_visits: faker.number.int({ min: 1, max: 5 }),
          },
          {
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
            ofi_visits: faker.number.int({ min: 1, max: 5 }),
          },
        ],
        attendees: [
          {
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
            ofi_visits: faker.number.int({ min: 1, max: 5 }),
          },
          {
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
            ofi_visits: faker.number.int({ min: 1, max: 5 }),
          },
        ],
        number_of_people: faker.number.int({ min: 1, max: 5 }),
        open_home_event: {
          id: faker.string.uuid(),
          calendar_event_id: faker.datatype.boolean()
            ? faker.string.uuid()
            : null,
        },
        note: uniqueArray([
          "Loved the build quality of the property",
          "Was a bit concerned about the amount of maintenance required",
          "Young professional working nearby, found the location ideal",
          "Loved the amenities in the property",
          "Loved the location, but found the property to be a bit small",
          "Based on the advertised price they were keen to submit an offer as soon as possible",
        ]),
        is_new: faker.datatype.boolean(),
        price_indication: faker.number.int({ min: 200000, max: 10000000 }),
        price_previous_match: faker.number.int({ min: 200000, max: 250000 }),
        price_new_match: faker.number.int({ min: 255000, max: 300000 }),
        enquiry_source: {
          id: faker.string.uuid(),
          text: faker.lorem.words({ min: 1, max: 2 }),
        },
        interest_level: faker.datatype.boolean()
          ? null
          : interestLevels[faker.number.int({ min: 0, max: 2 })],
      })),
      stats: [
        {
          id: "total_attendees",
          value: faker.number.int({ min: 0, max: 100 }),
          label: "Total attendees",
        },
        {
          id: "total_private_inspections",
          value: faker.number.int({ min: 0, max: 30 }),
          label: "Private inspections",
        },
        {
          id: "total_open_homes",
          value: faker.number.int({ min: 0, max: 30 }),
          label: "Open homes",
        },
        {
          id: "days_since_listing",
          value: faker.number.int({ min: 0, max: 100 }),
          label: "Days since listing the property",
        },
        {
          id: "total_enquiries",
          value: faker.number.int({ min: 0, max: 30 }),
          label: "Total enquiries",
        },
        {
          id: "total_inspection_open_homes",
          value: faker.number.int({ min: 0, max: 30 }),
          label: "Total inspections & open homes",
        },

        {
          id: "duplicate_inspections",
          value: faker.number.int({ min: 0, max: 30 }),
          label: "Who have attended twice",
        },
        {
          id: "total_offers",
          value: faker.number.int({ min: 0, max: 30 }),
          label: "Total offers",
        },
      ],
      events: {
        recent: [...Array(6)].map(() => ({
          id: faker.string.uuid(),
          date_of: faker.date.recent({ days: 10 }).toDateString(),
          date_time_start: `${faker.number.int({
            min: 0,
            max: 23,
          })}:${faker.number.int({ min: 0, max: 59 })}:00`,
          date_time_finish: `${faker.number.int({
            min: 0,
            max: 23,
          })}:${faker.number.int({ min: 0, max: 59 })}:00`,
          agents: [...Array(faker.number.int({ min: 1, max: 2 }))].map(() => ({
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
          })),
          type: feedbackTypes[faker.number.int({ min: 2, max: 3 })],
          attendees_count: faker.number.int({ min: 1, max: 150 }),
          calendar_event_id: faker.datatype.boolean()
            ? faker.string.uuid()
            : null,
          feedback: {
            id: faker.string.uuid(),
            date_of: faker.date.recent({ days: 10 }).toDateString(),
            agent: {
              id: faker.string.uuid(),
              name: faker.person.firstName() + " " + faker.person.lastName(),
            },
            type: feedbackTypes[faker.number.int({ min: 2, max: 3 })],
            related_contacts: [
              {
                id: faker.string.uuid(),
                name: faker.person.firstName() + " " + faker.person.lastName(),
                ofi_visits: faker.number.int({ min: 1, max: 5 }),
              },
            ],
            attendees: [
              {
                id: faker.string.uuid(),
                name: faker.person.firstName() + " " + faker.person.lastName(),
                ofi_visits: faker.number.int({ min: 1, max: 5 }),
              },
              {
                id: faker.string.uuid(),
                name: faker.person.firstName() + " " + faker.person.lastName(),
                ofi_visits: faker.number.int({ min: 1, max: 5 }),
              },
            ],
            note: faker.datatype.boolean()
              ? faker.lorem.paragraphs({ min: 1, max: 3 })
              : `${faker.lorem.paragraph({
                  min: 1,
                  max: 3,
                })}\n\n${faker.lorem.paragraph({ min: 1, max: 5 })}`,
            is_new: false,
            number_of_people: 1,
            price_indication: faker.number.int({ min: 200000, max: 10000000 }),
            price_previous_match: 0,
            price_new_match: 0,
            enquiry_source: null,
            interest_level: faker.datatype.boolean()
              ? null
              : interestLevels[faker.number.int({ min: 0, max: 2 })],
          },
        })),
        upcoming: [...Array(6)].map(() => ({
          id: faker.string.uuid(),
          date_of: faker.date.recent({ days: 10 }).toDateString(),
          date_time_start: `${faker.number.int({
            min: 0,
            max: 23,
          })}:${faker.number.int({ min: 0, max: 59 })}:00`,
          date_time_finish: `${faker.number.int({
            min: 0,
            max: 23,
          })}:${faker.number.int({ min: 0, max: 59 })}:00`,
          agents: [...Array(faker.number.int({ min: 1, max: 2 }))].map(() => ({
            id: faker.string.uuid(),
            name: faker.person.firstName() + " " + faker.person.lastName(),
          })),
          type: feedbackTypes[faker.number.int({ min: 2, max: 3 })],
          attendees_count: faker.number.int({ min: 1, max: 150 }),
          calendar_event_id: faker.datatype.boolean()
            ? faker.string.uuid()
            : null,
          feedback: {
            id: faker.string.uuid(),
            date_of: faker.date.recent({ days: 10 }).toDateString(),
            agent: {
              id: faker.string.uuid(),
              name: faker.person.firstName() + " " + faker.person.lastName(),
            },
            type: feedbackTypes[faker.number.int({ min: 2, max: 3 })],
            related_contacts: [
              {
                id: faker.string.uuid(),
                name: faker.person.firstName() + " " + faker.person.lastName(),
                ofi_visits: faker.number.int({ min: 1, max: 5 }),
              },
            ],
            attendees: [
              {
                id: faker.string.uuid(),
                name: faker.person.firstName() + " " + faker.person.lastName(),
                ofi_visits: faker.number.int({ min: 1, max: 5 }),
              },
              {
                id: faker.string.uuid(),
                name: faker.person.firstName() + " " + faker.person.lastName(),
                ofi_visits: faker.number.int({ min: 1, max: 5 }),
              },
            ],
            note: faker.datatype.boolean()
              ? faker.lorem.paragraphs({ min: 1, max: 3 })
              : `${faker.lorem.paragraph({
                  min: 1,
                  max: 3,
                })}\n\n${faker.lorem.paragraph({ min: 1, max: 5 })}`,
            is_new: false,
            number_of_people: 1,
            price_indication: faker.number.int({ min: 200000, max: 10000000 }),
            price_previous_match: 0,
            price_new_match: 0,
            enquiry_source: null,
            interest_level: faker.datatype.boolean()
              ? null
              : interestLevels[faker.number.int({ min: 0, max: 2 })],
          },
        })),
      },
      interest_level_stats: [
        {
          id: "hot",
          label: "Hot",
          value: faker.number.int({ min: 5, max: 12 }),
        },
        {
          id: "warm",
          label: "Warm",
          value: faker.number.int({ min: 5, max: 10 }),
        },
        {
          id: "cold",
          label: "Cold",
          value: faker.number.int({ min: 5, max: 10 }),
        },
        {
          id: "neutral",
          label: "Neutral",
          value: faker.number.int({ min: 5, max: 10 }),
        },
      ],
      price_indications: [...Array(faker.number.int({ min: 2, max: 3 }))].map(
        () =>
          faker.number.int({
            min: mockAdvertisePriceAs - 100000,
            max: mockAdvertisePriceAs + 100000,
          }),
      ),
      performance_stats: [
        {
          id: "total_enquiries",
          value: faker.number.int({ min: 1, max: 50 }),
          label: "Enquiries",
        },
        {
          id: "newsletters_sent",
          value: faker.number.int({ min: 1, max: 300 }),
          label: "Newsletters sent",
        },
      ],
      campaigns: [
        {
          id: "reach",
          source_title: "Reach",
          url: "https://www.example.com",
          primary_stats: {
            total: [
              {
                id: "ad_clicks",
                value: faker.number.int({ min: 1, max: 100 }),
                label: "Ad clicks",
              },
            ],
          },
        },
        {
          id: "domain",
          source_title: "Domain",
          url: "https://www.example.com",
          primary_stats: {
            total: [
              {
                id: "ad_clicks",
                value: faker.number.int({ min: 1, max: 100 }),
                label: "Ad clicks",
              },
            ],
          },
        },
        {
          id: "rea",
          source_title: "realestate.com.au",
          url: "https://www.example.com",
          primary_stats: {
            total: [
              {
                id: "ad_clicks",
                value: faker.number.int({ min: 1, max: 100 }),
                label: "Ad clicks",
              },
            ],
          },
        },
      ],
    },
  };
};
