import { useBreakpoint } from "@chakra-ui/react";
import dayjs from "dayjs";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { FeedbackTypeTag } from "modules/vendor-portal/components/feedback-type-tag";
import { InterestLevelBadge } from "modules/vendor-portal/components/interest-level-badge";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";
import { dateFormats } from "utils/date";

type EnquiryFooterProps = {
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry;
};

export function EnquiryFooter({ feedback }: EnquiryFooterProps) {
  const breakpoint = useBreakpoint({ ssr: false });
  const isMobile = breakpoint === "base" || breakpoint === "sm";

  return (
    <CardRecordMeta>
      <FeedbackTypeTag
        feedbackType={feedback.type}
        enquirySource={
          "enquiry_source" in feedback ? feedback.enquiry_source : null
        }
      />

      {"interest_level" in feedback && feedback.interest_level ? (
        <InterestLevelBadge interestLevel={feedback.interest_level} />
      ) : null}

      {"date_of" in feedback && (
        <FooterText
          text={dayjs(feedback.date_of).format(dateFormats.dayMonthYear)}
        />
      )}

      {isMobile ? null : <FooterText text={`Agent ${feedback.agent.name}`} />}
    </CardRecordMeta>
  );
}
