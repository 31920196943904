import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

export const FONT_WEIGHT_DESCRIPTORS = {
  100: "Thin",
  200: "Extra Light",
  300: "Light",
  400: "Regular",
  500: "Medium",
  600: "Semi Bold",
  700: "Bold",
  800: "Extra Bold",
  900: "Black",
};

export type Font = {
  id: string;
  name: string;
  file_name: string;
  size: number;
  weight: number;
  style?: string;
  created_at: string;
};

export type FontFamily = {
  id: string;
  name: string;
  fonts: {
    id: string;
    name: string;
    weight: string;
    style: string;
  }[];
};

export type FontsResponse = {
  data: Font[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
};

export type FontFamiliesResponse = {
  data: FontFamily[];
  links: {
    first: string;
    last: string;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
};

export type FontUploadResponse = {
  data: Font;
};

export const fontModel = createModel("fonts", {
  queries: {
    list: () => ({
      queryKey: ["fonts", "list"],
      queryFn: async (): Promise<FontsResponse> => {
        const response = await apiClient.get<FontsResponse>("accounts/fonts");
        return response;
      },
    }),
    listFontFamilies: () => ({
      queryKey: ["fonts", "families"],
      queryFn: async (): Promise<FontFamiliesResponse> => {
        const response = await apiClient.get<FontFamiliesResponse>(
          "accounts/font-families",
        );
        return response;
      },
    }),
  },
  mutations: {
    upload: () => ({
      mutationFn: async ({
        file,
        name,
        weight,
      }: {
        file: File;
        name: string;
        weight: string;
      }) => {
        const response = await apiClient.upload<FontUploadResponse>(
          "accounts/fonts",
          file,
          { name, weight },
        );
        return response;
      },
    }),
    delete: () => ({
      mutationFn: async ({ id }: { id: string }) => {
        await apiClient.delete(`accounts/fonts/${id}`);
      },
    }),
  },
});
