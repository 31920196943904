import ky from "ky";

export const apiPrefix = import.meta.env.VITE_API_ORIGIN;
export const kyClient = ky.create({
  prefixUrl: apiPrefix,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
