import {
  mockComplianceEntry,
  type ComplianceEntry,
} from "types/compliance-entry";
import { mockContact, type Contact } from "types/contact";
import { mockFile, type File } from "types/file";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type HandbookData = {
  documents: File[];
  emergency_contacts: Contact[];
  manuals: File[];
  compliance_entries: ComplianceEntry[];
};

export const tenancyHandbookModel = createModel("tenancyHandbook", {
  queries: {
    read: (propertyId?: string) => ({
      queryKey: ["read", propertyId],
      queryFn: () => {
        return apiClient.get<HandbookData>(`/tenant/${propertyId}/handbook`);
      },
      enabled: !!propertyId,
      keepPreviousData: true,
    }),
  },
});

export const mockHandbookResponse = () => {
  return {
    documents: Array.from({ length: 9 }).map(() => mockFile()),
    emergency_contacts: Array.from({ length: 3 }).map(() => mockContact()),
    manuals: Array.from({ length: 3 }).map(() => mockFile()),
    compliance_entries: Array.from({ length: 3 }).map(() =>
      mockComplianceEntry(),
    ),
  };
};
