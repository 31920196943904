import { faker } from "@faker-js/faker";
import dayjs from "dayjs";
import { fakerDateUnix } from "lib/faker";
import type { DateString } from "types/api";
import { mockFile, type File } from "types/file";
import type { LandlordFinancialsSummary } from "types/landlord-financials";
import type { PaginationMeta } from "types/pagination";
import {
  StatementStatus,
  StatementType,
  type Statement,
} from "types/statement";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type FinancialsData = {
  summary: LandlordFinancialsSummary;
  chart_data: { money_in: number; money_out: number; date: DateString }[];
  statements: {
    rows: Statement[];
    pagination: PaginationMeta;
  };
};

export const financialsModel = createModel("financialsModel", {
  queries: {
    read: (searchParams: URLSearchParams) => ({
      queryKey: ["read", searchParams.toString()],
      queryFn: () => {
        const paramsString = new URLSearchParams(searchParams).toString();
        return apiClient.get<FinancialsData>(
          `/landlord/financials?${paramsString}`,
        );
      },
      keepPreviousData: true,
    }),
  },
});

export async function getStatementDownload(statement: Statement) {
  const { download_url } = await apiClient.get<File>(
    `/landlord/financials/statement-download/${statement.id}`,
  );

  return download_url;
}

export const mockFinancialsResponse: (
  searchParams: URLSearchParams,
) => FinancialsData = (searchParams) => {
  const page = Number(searchParams.get("page") || 1);
  return {
    chart_data: Array.from({ length: 6 }).map((_, idx) => ({
      money_in: faker.number.int({ min: 2300, max: 2500 }),
      money_out: faker.number.int({ min: 1900, max: 2100 }),
      date: dayjs().startOf("month").add(idx, "month").format("YYYY-MM-DD"),
    })),
    summary: {
      period_start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      period_end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
      opening_balance: faker.number.int({ min: 2000, max: 10000 }),
      money_in: faker.number.int({ min: 100, max: 1000 }),
      money_out: faker.number.int({ min: 100, max: 1000 }),
      reserved_funds: faker.number.int({ min: 100, max: 1000 }),
      net_balance: faker.number.int({ min: 100, max: 1000 }),
      bills_outstanding: faker.number.int({ min: 100, max: 1000 }),
    },
    statements: {
      rows: Array.from({ length: 10 }).map(() => {
        return {
          id: faker.string.uuid(),
          created_at: fakerDateUnix(),
          reference: `REF-${faker.number.int({ min: 1000, max: 9999 })}`,
          updated_at: fakerDateUnix(),
          statement_from: dayjs().startOf("month").format("YYYY-MM-DD"),
          statement_to: dayjs().endOf("month").format("YYYY-MM-DD"),
          type_id: faker.helpers.arrayElement([
            StatementType.PeriodicOwnership,
            StatementType.YearlyOwnership,
          ]),
          display_name: faker.lorem.sentence(),
          status_id: faker.helpers.arrayElement([
            StatementStatus.Issued,
            StatementStatus.Void,
          ]),
          status_reason: faker.lorem.sentence(),
        };
      }),
      pagination: {
        current_page: page,
        last_page: 2,
        per_page: 10,
        total: 20,
      },
    },
  };
};

export function mockFinancialsStatementDownloadResponse(): File {
  return mockFile();
}
