import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { MaintenanceList } from "components/maintenance-list/maintenance-list";
import { Page } from "components/page";
import { tenancyMaintenanceModel } from "modules/tenant-portal/models/maintenance";

export function MaintenanceScreen() {
  const { propertyId } = useParams();
  const { data, isLoading } = useQuery(
    tenancyMaintenanceModel.queries.read(propertyId),
  );

  return (
    <Page
      heading={"Maintenance"}
      rightElement={
        <Link to={"./request"}>
          <Button size={["sm", "md"]} colorScheme={"gray"} px={6} h={"36px"}>
            Request Maintenance
          </Button>
        </Link>
      }
    >
      <Alert
        status={"info"}
        alignItems={"flex-start"}
        bg={"white"}
        shadow={"md"}
      >
        <AlertIcon mt={1} />
        <Flex direction={"column"}>
          <AlertTitle>Need help with something else?</AlertTitle>
          <AlertDescription>
            If you have a general enquiry about your lease, give us a call or
            send through an email.
          </AlertDescription>
        </Flex>
      </Alert>

      <MaintenanceList
        isLoading={isLoading}
        maintenanceTasks={data?.maintenanceTasks ?? []}
      />
    </Page>
  );
}
