import { useEffect, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";

// How long to wait after a new resource request
// completes before setting the page as rendered
const TIME_TO_WAIT_AFTER_REQUEST_COMPLETES = 2000;

export function PageRenderListener() {
  const [isRendered, setIsRendered] = useState(false);

  const [isPrint] = useMediaQuery("print");

  useEffect(() => {
    if (!isPrint) return;
    replaceMediaQueriesOnPage();
    let timeoutId: NodeJS.Timeout;
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if ((entry as PerformanceResourceTiming).responseEnd) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          timeoutId = setTimeout(() => {
            replaceMediaQueriesOnPage();
            setIsRendered(true);
          }, TIME_TO_WAIT_AFTER_REQUEST_COMPLETES);
        }
      });
    });

    observer.observe({ type: "resource", buffered: true });

    return () => {
      observer.disconnect();
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isPrint]);

  return isRendered ? <div className={"print-ready"} /> : null;
}

function replaceMediaQueriesOnPage() {
  // Get all style sheets on the page
  const styleSheets = document.styleSheets;

  Array.from(styleSheets).forEach((sheet) => {
    // Check if this is a same-origin stylesheet
    if (sheet.href === null || sheet.href.startsWith(window.location.origin)) {
      try {
        const rules = sheet.cssRules as any;
        for (let i = 0; i < rules.length; i++) {
          if (rules[i].constructor.name === "CSSMediaRule" && rules[i].media) {
            const mediaText = rules[i].media.mediaText;
            if (mediaText.includes("screen and")) {
              // Create a new media query with 'all' instead of 'screen'
              const newMediaText = mediaText.replaceAll(
                "screen and",
                "all and",
              );

              // Create a new rule with the updated media query
              let newRuleText = `@media ${newMediaText} {`;
              for (let j = 0; j < rules[i].cssRules.length; j++) {
                newRuleText += rules[i].cssRules[j].cssText;
              }
              newRuleText += "}";

              // Insert the new rule
              sheet.insertRule(newRuleText, rules.length);

              // Delete the old rule
              sheet.deleteRule(i);

              // Adjust the index as we've removed a rule
              i--;
            }
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          "Cannot read or modify the stylesheet rules. Possibly a CORS issue:",
          e,
        );
      }
    }
  });
}
