import { useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  chakra,
  Image,
  ScaleFade,
  Text,
  useBoolean,
  useToast,
  VStack,
  type BoxProps,
  type ImageProps,
  type TextProps,
} from "@chakra-ui/react";

type SingleImageUploadInput = {
  onChange: (file: File | null) => void;
  disabled?: boolean;
  boxProps?: BoxProps;
  imageProps?: ImageProps;
  placeholderProps?: TextProps;
  name: string;
  value?: string;
  onImageUpload?: (file: File) => Promise<string>;
};

export function SingleImageUploadInput({
  disabled,
  onChange,
  placeholderProps,
  boxProps,
  imageProps,
  name,
  value,
  onImageUpload,
}: SingleImageUploadInput) {
  const toast = useToast();
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(value);
  const [isUploading, setIsUploading] = useBoolean(false);
  const [isDragOver, setIsDragOver] = useBoolean(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const processFile = async (file: File | null) => {
    setUploadedFile(file);

    if (file && onImageUpload) {
      setIsUploading.on();
      try {
        const imageUrl = await onImageUpload(file);
        setPreviewUrl(imageUrl);
        onChange(file);
      } catch (error) {
        toast({
          title: "Error uploading image",
          description: "Please try again",
          status: "error",
        });
        Bugsnag.notify(error as Error);
        setUploadedFile(null);
      } finally {
        setIsUploading.off();
      }
    } else if (file) {
      setPreviewUrl(URL.createObjectURL(file));
      onChange(file);
    } else {
      setPreviewUrl(undefined);
      onChange(null);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    processFile(file);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver.off();
    const file = event.dataTransfer.files?.[0];
    processFile(file);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isDragOver) return;
    setIsDragOver.on();
  };

  const handleDragLeave = () => {
    setIsDragOver.off();
  };

  return (
    <Box
      borderRadius={"lg"}
      borderWidth={"1px"}
      p={6}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      cursor={disabled || isUploading ? "not-allowed" : "pointer"}
      onDragEnter={handleDragOver}
      pos={"relative"}
      role={"group"}
      maxW={"400px"}
      aspectRatio={16 / 9}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      onDragLeave={handleDragLeave}
      bg={disabled ? "gray.50" : isDragOver ? "blue.100" : "transparent"}
      borderColor={isDragOver ? "blue.500" : undefined}
      opacity={isUploading ? 0.7 : 1}
      {...boxProps}
    >
      {uploadedFile || previewUrl ? (
        <>
          <ScaleFade initialScale={0.9} in={true} style={{ width: "100%" }}>
            <Box maxW={"400px"}>
              <Image
                w={"100%"}
                data-peer
                objectFit={"contain"}
                src={previewUrl}
                {...imageProps}
              />
            </Box>
          </ScaleFade>
          <Button
            display={"none"}
            _peerHover={{ display: "flex" }}
            _groupHover={{ display: "flex" }}
            _hover={{ display: "flex" }}
            bg={"gray.50"}
            variant={"ghost"}
            color={"red.500"}
            size={"sm"}
            leftIcon={<DeleteIcon mr={-1} />}
            shadow={"0 2px 3px 0 rgba(30,30,35,0.15)"}
            pos={"absolute"}
            bottom={6}
            left={0}
            right={0}
            ml={"auto"}
            mr={"auto"}
            w={"80px"}
            onClick={() => {
              setUploadedFile(null);
              setPreviewUrl(undefined);
              onChange(null);
              fileInputRef.current?.click();
            }}
            isDisabled={isUploading}
          >
            {"Replace"}
          </Button>
        </>
      ) : null}
      {!uploadedFile && !previewUrl ? (
        <Center
          as={chakra.label}
          htmlFor={name}
          overflow={"hidden"}
          pos={"relative"}
          cursor={disabled || isUploading ? "not-allowed" : "pointer"}
          flexDir={"column"}
        >
          <Center w={"100%"} py={12}>
            <VStack>
              <AddIcon />
              <Text
                fontSize={"md"}
                align={"center"}
                color={"gray.600"}
                fontWeight={"medium"}
                // @ts-expect-error-next-line
                style={{ textWrap: "balance" }}
                {...placeholderProps}
              >
                {isUploading
                  ? "Uploading..."
                  : "Click to upload or drag and drop"}
              </Text>
            </VStack>
          </Center>
        </Center>
      ) : null}
      <chakra.input
        type={"file"}
        style={{ display: "none" }}
        id={name}
        name={name}
        disabled={disabled || isUploading}
        accept={".jpg,.jpeg,.png,.gif"}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Box>
  );
}
