import { useState } from "react";
import {
  Box,
  Card,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  ListItem,
  Show,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import {
  MultiSelectInput,
  type MenuOption,
} from "components/inputs/multi-select-input";
import { SelectInput } from "components/inputs/select-input";
import { PortalSelector } from "components/portal-selector";
import { ShowMoreLessButton } from "components/show-more-less-button";
import { TotalCountBadge } from "components/total-count-badge";
import { useWhichWordFactory } from "modules/vendor-portal/hooks/use-which-words";
import { feedbackScreenModel } from "modules/vendor-portal/screens/feedback/models/feedback";
import {
  FeedbackGroupByOption,
  groupFeedback,
} from "modules/vendor-portal/screens/feedback/utils";
import { FeedbackListItem } from "modules/vendor-portal/screens/overview/components/feedback/feedback-list-item";
import { ListingPerformance } from "modules/vendor-portal/screens/overview/components/listing-performance/listing-performance";
import { PriceIndication } from "modules/vendor-portal/screens/overview/components/price-indication/price-indication";
import type { Feedback } from "modules/vendor-portal/types/feedback";

const FEEDBACK_OPTIONS: MenuOption[] = [
  { value: "enquiry", label: "Enquiry" },
  { value: "inspection", label: "inspection" },
  { value: "ofi", label: "Open home" },
  { value: "agent_vendor", label: "Agent update" },
  { value: "price_reduction", label: "Price reduction" },
  { value: "followup", label: "Follow-up" },
];

type FeedbackOptionsGroup = {
  label: { id: string; value: string };
  options: MenuOption[];
};

const GROUP_BY_OPTIONS: FeedbackOptionsGroup[] = [
  {
    label: { id: "group-by", value: "Group by" },
    options: [
      { value: FeedbackGroupByOption.Name, label: "Name" },
      { value: FeedbackGroupByOption.InterestLevel, label: "Interest level" },
      { value: FeedbackGroupByOption.FeedbackType, label: "Feedback type" },
      { value: FeedbackGroupByOption.Date, label: "Date" },
    ],
  },
];

const DEFAULT_GROUP_BY_OPTION = GROUP_BY_OPTIONS[0].options[0].value;
const DEFAULT_FILTERS = FEEDBACK_OPTIONS.map((x) => x.value);

export function FeedbackScreen() {
  const { data, isLoading } = useQuery(feedbackScreenModel.queries.read());
  const [filters, setFilter] = useState<string[]>(DEFAULT_FILTERS);
  const [groupByValue, setGroupByValue] = useState<string>(
    DEFAULT_GROUP_BY_OPTION,
  );
  const filteredFeedback =
    data?.listing.feedback.filter((i) => filters.includes(i.type.id)) ?? [];
  const groupedFeedback = groupFeedback(
    filteredFeedback,
    groupByValue as FeedbackGroupByOption,
  );
  const [showAllFeedback, setShowAllFeedback] = useState(false);
  const feedbackToShow = showAllFeedback
    ? groupedFeedback
    : groupedFeedback.slice(0, 3);

  const ww = useWhichWordFactory();

  const localisedFeedbackOptions = FEEDBACK_OPTIONS.map((option) => {
    return {
      ...option,
      label: ww(option.label),
    };
  });

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>

      <Flex
        direction={"column"}
        gap={{ base: 8, md: 8 }}
        p={{ base: 4, md: 0 }}
      >
        <Heading fontSize={"2xl"} fontWeight={700} as={"h1"}>
          Feedback
        </Heading>

        <Flex direction={"row"} wrap={"wrap"} gap={{ base: 14, md: 6 }}>
          <Box flexGrow={1} minW={{ base: "100%", sm: "300px" }} minH={"342px"}>
            <ListingPerformance
              interestLevelStats={data?.listing.interest_level_stats}
              isLoading={isLoading}
            />
          </Box>
          <Box
            flexGrow={1}
            minW={{ base: "100%", sm: "300px" }}
            minH={{ base: 0, md: "342px" }}
          >
            <PriceIndication
              priceAdvertiseAs={data?.listing.price_advertise_as ?? 0}
              priceIndications={data?.listing.price_indications ?? []}
              isLoading={isLoading}
            />
          </Box>
        </Flex>

        <Flex
          direction={{ base: "column", sm: "row" }}
          align={{ base: "flex-start", sm: "center" }}
          w={"100%"}
          gap={{ base: 4, sm: 0 }}
          justify={"space-between"}
        >
          <HStack spacing={3}>
            <Heading
              fontSize={"xl"}
              fontWeight={600}
              lineHeight={"28px"}
              whiteSpace={"nowrap"}
            >
              Feedback Notes
            </Heading>
            <TotalCountBadge count={filteredFeedback?.length} />
          </HStack>
          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={{ base: 4, sm: 3 }}
            w={"100%"}
            justify={{ base: "space-around", sm: "flex-end" }}
          >
            <MultiSelectInput
              placeholder={"Feedback type"}
              options={localisedFeedbackOptions}
              value={filters}
              menuLabel={"Filter by"}
              onChange={setFilter}
            />
            <SelectInput
              placeholder={"Select group"}
              optionGroups={GROUP_BY_OPTIONS}
              value={groupByValue}
              selectionPrefix={"Group by: "}
              onChange={(value) => setGroupByValue(value)}
            />
          </Flex>
        </Flex>

        <List spacing={4}>
          {feedbackToShow.map(({ label, feedback }) => {
            if (feedback.length === 0) return null;
            return (
              <ListItem key={label}>
                <Flex direction={"column"} gap={3}>
                  <Text
                    fontSize={"xl"}
                    fontWeight={400}
                    color={"gray.600"}
                    mt={3}
                  >
                    {label}
                  </Text>
                  <Divider mb={3} />
                  {feedback
                    .sort((a, b) => Number(b.is_new) - Number(a.is_new))
                    .map((feedback: Feedback) => (
                      <Card
                        key={feedback.id}
                        pt={3}
                        pr={10}
                        pb={4}
                        pl={3}
                        variant={{ base: "unstyled", md: "outline" }}
                        style={{ breakInside: "avoid-page" }}
                      >
                        <FeedbackListItem feedback={feedback} />
                      </Card>
                    ))}
                </Flex>
              </ListItem>
            );
          })}
          <ShowMoreLessButton
            showAll={showAllFeedback}
            setShowAll={setShowAllFeedback}
          />
        </List>
      </Flex>
    </Box>
  );
}
