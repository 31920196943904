import Bugsnag from "@bugsnag/js";
import { show as showModal } from "@ebay/nice-modal-react";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { ErrorModal } from "components/errors/components/error-modal";

type Meta = {
  /** Whether to show an error modal.
   * If you want to do your own error handling
   * UI for a query, you should probably set this
   * to false.
   * **/
  showErrorModal?: boolean;
};

declare module "@tanstack/react-query" {
  type Register = {
    queryMeta: Meta;
    mutationMeta: Meta;
  };
}

export const mutationCache = new MutationCache({
  onError: (error, variables, context, mutation) => {
    Bugsnag.notify(error as Error);
    if (mutation?.meta?.showErrorModal === false) return;
    showModal(ErrorModal, { error: error as Error });
  },
});

export const queryCache = new QueryCache({
  onError: (error, query) => {
    Bugsnag.notify(error as Error);
    if (query?.meta?.showErrorModal === false) return;
    showModal(ErrorModal, { error: error as Error });
  },
});

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: 0,
      retry: false,
    },
  },
});
