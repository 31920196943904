export function get(
  data: Record<string, unknown>,
  keys: string,
  defaultValue?: string,
): string | undefined {
  const value = keys
    .split(".")
    .reduce(
      (value, key) => (value as Record<string, unknown>)?.[key],
      data as unknown,
    );

  return value !== undefined ? (value as string) : defaultValue;
}

export function groupBy<InputArrayItem>(
  arr: InputArrayItem[],
  key: keyof InputArrayItem,
): Record<keyof InputArrayItem, InputArrayItem[]> {
  return arr.reduce(
    (accumulator, val) => {
      const groupedKey = val[key] as keyof InputArrayItem;
      if (!accumulator[groupedKey]) {
        accumulator[groupedKey] = [];
      }
      accumulator[groupedKey].push(val);
      return accumulator;
    },
    {} as Record<keyof InputArrayItem, InputArrayItem[]>,
  );
}

export function map<InputValue, OutputValue>(
  collection: InputValue[] | Record<string, InputValue>,
  func: (item: InputValue) => OutputValue,
): OutputValue[] {
  if (Array.isArray(collection)) {
    return collection.map(func);
  } else {
    return Object.keys(collection).map((key) => func(collection[key]));
  }
}

export function mapValues<
  KeyType extends string,
  ValueTypeInput,
  ValueTypeOutput,
>(
  obj: Record<KeyType, ValueTypeInput>,
  func: (value: ValueTypeInput) => ValueTypeOutput,
): Record<KeyType, ValueTypeOutput> {
  const result = {} as Record<KeyType, ValueTypeOutput>;
  (Object.keys(obj) as KeyType[]).forEach((key) => {
    result[key] = func(obj[key]);
  });
  return result;
}

export function upsert<T extends { id: string }>(arr: T[], newData: T) {
  let isOld = false;
  const newArr = arr.map((prev) => {
    if (prev.id === newData.id) {
      isOld = true;
      return newData;
    }
    return prev;
  });

  return isOld ? newArr : [...arr, newData];
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const result = {};
  for (const key of keys) {
    if (obj[key] !== undefined) {
      Object.assign(result, { [key]: obj[key] });
    }
  }
  return result as Pick<T, K>;
}
