import { Code, Text } from "@chakra-ui/react";
import { FetchClientError } from "errors/fetch-client-error";
import { FetchServerError } from "errors/fetch-server-error";
import { map } from "utils/collection";

export function ErrorContent({ error }: { error: Error }) {
  const isUnexpectedError = !(error instanceof FetchClientError);

  const message =
    (error instanceof FetchClientError || error instanceof FetchServerError) &&
    error.body?.errors &&
    Object.keys(error.body?.errors).length > 1
      ? map(error.body?.errors, (e) => `• ${e}`).join("\n\n")
      : error.message;

  return isUnexpectedError ? (
    <>
      <Text>An unexpected error occurred with the following message:</Text>
      <Code
        display={"block"}
        whiteSpace={"pre-wrap"}
        p={"4"}
        my={"4"}
        colorScheme={"red"}
      >
        {error?.name}: {error?.message ? error.message : "Something went wrong"}
      </Code>
      <Text>
        Please try again. If you continue to see this error, please reach out to
        our support team and include the above message.
      </Text>
    </>
  ) : (
    <Text whiteSpace={"pre-wrap"}>
      {message || "An unknown error occurred, please try again"}
    </Text>
  );
}
