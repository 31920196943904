import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { DateRangeInput } from "components/inputs/date-range-input";

export function FinancialsDateRange() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { period_start_date, period_end_date } = Object.fromEntries(
    searchParams.entries(),
  );

  const dateValue = [
    period_start_date
      ? dayjs(period_start_date).toDate()
      : dayjs().startOf("month").toDate(),
    period_start_date && period_end_date
      ? dayjs(period_end_date).toDate()
      : dayjs().endOf("month").toDate(),
  ].filter(Boolean) as Date[];

  const [inProgressValue, setInProgressValue] = useState<Date[]>(dateValue);

  useEffect(() => {
    const startDate = inProgressValue[0];
    const endDate = inProgressValue[1];
    if (startDate && endDate) {
      const startDateString = dayjs(startDate).format("YYYY-MM-DD");
      const endDateString = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("period_start_date", startDateString);
      if (endDate) {
        newSearchParams.set("period_end_date", endDateString);
      } else {
        newSearchParams.delete("period_end_date");
      }

      setSearchParams(newSearchParams);
    }
  }, [inProgressValue, searchParams, setSearchParams]);

  function onChangeDatePeriods(dates: Date[]) {
    setInProgressValue(dates);
  }

  return (
    <DateRangeInput value={inProgressValue} onChange={onChangeDatePeriods} />
  );
}
