import { useState, type ReactNode } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

type CardRecordInsetDescriptionProps = {
  showMoreElement?:
    | ReactNode
    | ((props: { isTruncated: boolean }) => ReactNode);
  description: string;
  color: string;
};
export function CardRecordInsetDescription({
  showMoreElement,
  description,
  color,
}: CardRecordInsetDescriptionProps) {
  const [isTruncated, setIsTruncated] = useState(true);

  return (
    <Box
      display={"flex"}
      flexDir={"row"}
      alignItems={"center"}
      gap={4}
      w={"100%"}
    >
      <Box borderRadius={"4px"} minW={1} h={"100%"} bgColor={color} />
      <Flex direction={"column"} gap={2} overflowX={"hidden"} w={"100%"}>
        <Flex
          direction={"column"}
          align={"flex-start"}
          gap={isTruncated ? 0 : 2}
          justify={"space-between"}
        >
          <Text
            textStyle={"large"}
            color={"gray.600"}
            noOfLines={isTruncated ? [3, 1] : undefined}
          >
            {description}
          </Text>
          {isTruncated ? (
            <Box>
              <Button
                size={"md"}
                variant={"link"}
                color={"blue.600"}
                onClick={() => setIsTruncated(!isTruncated)}
              >
                Show more
              </Button>
            </Box>
          ) : null}
        </Flex>
        {!isTruncated ? (
          <Box>
            {typeof showMoreElement === "function"
              ? showMoreElement({ isTruncated })
              : showMoreElement}
            <Button
              size={"md"}
              variant={"link"}
              mt={2}
              color={"blue.600"}
              onClick={() => setIsTruncated(!isTruncated)}
            >
              Show less
            </Button>
          </Box>
        ) : null}
      </Flex>
    </Box>
  );
}
