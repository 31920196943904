import type { ReactNode } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  IconButton,
  Show,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import BackArrowIcon from "assets/icons/medium/back-arrow.svg?react";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";

type PageProps = {
  children?: ReactNode;
  heading?: string;
  rightElement?: ReactNode;
  showBackButton?: boolean;
  description?: ReactNode;
  wrapHeader?: boolean;
};

export function Page({
  heading,
  description,
  children,
  rightElement,
  showBackButton,
  wrapHeader,
}: PageProps) {
  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>

      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={10}
      >
        <Flex
          direction={["column", "row"]}
          justify={"space-between"}
          wrap={wrapHeader ? "wrap" : "nowrap"}
          rowGap={4}
        >
          <Flex direction={"row"} gap={2} align={"center"}>
            {showBackButton ? (
              <Link to={".."} relative={"path"}>
                <IconButton
                  mt={-2}
                  aria-label={"Back"}
                  color={"gray.700"}
                  variant={"ghost"}
                  icon={
                    <Icon as={BackArrowIcon} boxSize={"16px"} color={"#000"} />
                  }
                />
              </Link>
            ) : null}
            <Flex direction={"column"} gap={2} flex={1}>
              {heading ? (
                <Heading
                  size={{ base: "2xl", md: "lg" }}
                  fontWeight={700}
                  as={"h1"}
                >
                  {heading}
                </Heading>
              ) : null}
              {description ? (
                typeof description === "string" ? (
                  <Text flexBasis={"100%"} fontSize={"lg"}>
                    {description}
                  </Text>
                ) : (
                  description
                )
              ) : null}
            </Flex>
          </Flex>
          {rightElement}
        </Flex>
        {children}
      </Flex>
    </Box>
  );
}
