export const colors = {
  brand: {
    "50": "hsl(168,100%,95%)",
    "100": "hsl(170,77%,83%)",
    "200": "hsl(172,67%,70%)",
    "300": "hsl(174,59%,56%)",
    "400": "hsl(177,52%,46%)",
    "500": "hsl(179,51%,39%)",
    "600": "hsl(181,47%,33%)",
    "700": "hsl(183,42%,27%)",
    "800": "hsl(185,40%,23%)",
    "900": "hsl(186,40%,19%)",
  },
  gray: {
    "50": "hsl(204,45%,98%)",
    "100": "hsl(210,38%,95%)",
    "200": "hsl(214,32%,91%)",
    "300": "hsl(211,25%,84%)",
    "400": "hsl(214,20%,69%)",
    "500": "hsl(220,3%,80%)",
    "600": "hsl(218,17%,35%)",
    "700": "hsl(218,23%,23%)", // #2D3748
    "800": "hsl(210,5%,33%)",
    "900": "hsl(230, 21%, 11%)",
  },
  slate: {
    "50": "hsl(240,27%,98%)",
    "100": "hsl(252,29%,97%)",
    "200": "hsl(240,20%,92%)",
    "300": "hsl(240,15%,87%)",
    "400": "hsl(243,14%,73%)",
    "500": "hsl(243,11%,58%)",
    "600": "hsl(248,13%,42%)",
    "700": "hsl(249,15%,33%)",
    "800": "hsl(249,20%,25%)",
    "900": "hsl(249,35%,15%)",
  },
  green: {
    "50": "#F0FFF4",
  },
  orange: {
    "50": "hsl(40, 100%, 97%)",
    "100": "hsl(38, 96%, 90%)",
    "800": "hsl(14,61%,30%)",
  },
  indigo: {
    50: "#F5F8FE",
    100: "#E7EDFD",
    200: "#D3DFFD",
    300: "#BECFF8",
    400: "#96AFEF",
    500: "#708FDE",
    600: "#496ABD",
    700: "#375698",
    800: "#23417F",
    900: "#0F2C65",
  },
  badgeLightBlue: {
    "100": "#EBF8FF",
    "800": "#4299E1",
  },
  badgeBlue: {
    "100": "#BEE3F8",
    "800": "#2C5282",
  },
  badgeOrange: {
    "100": "#FEEBCB",
    "800": "#DD6B20",
  },
  badgeRed: {
    "100": "#FED7D7",
    "800": "#E53E3E",
  },
  badgeGreen: {
    "100": "#C6F6D5",
    "800": "#25855A",
  },
  percentageBar: {
    "100": "#CBD5E0",
    "500": "#2D3748",
  },
  switch: {
    "100": "#CBD5E0",
    "500": "#2D3748",
  },
  chartLinePink: "#d973a6",
  chartLineBlue: "#4769bd",
  chartAxisGrey: "#8aa0ae",
};
