import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Show, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import CommentIcon from "assets/icons/medium/comment.svg?react";
import { CardRecord } from "components/cards/card-record";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { Empty } from "components/empty";
import {
  ImageGallery,
  MobileImageGallery,
} from "components/image-gallery/image-gallery";
import { getLongDateTimeStringForInspectionTask } from "components/inspections-list/inspections-list";
import { Page } from "components/page";
import { landlordInspectionsModel } from "modules/landlord-portal/models/inspections";
import { InspectionType } from "types/inspection-task";
import { UNKNOWN_VALUE_MAP } from "utils/string";

const typeTextMap = {
  [InspectionType.RoutineInspection]: "Routine inspection",
  [InspectionType.EntryInspection]: "Entry inspection",
  [InspectionType.ExitInspection]: "Exit inspection",
};

export function InspectionDetailsScreen() {
  const [searchParams] = useSearchParams();
  const { inspectionId } = useParams();

  const { data } = useQuery(
    landlordInspectionsModel.queries.list(searchParams),
  );

  const inspection = data?.inspectionTasks?.find(
    (inspection) => inspection.id === inspectionId,
  );

  const inspectionReportUrl = inspection?.details.inspection_report?.url;

  return (
    <Page
      heading={
        inspection
          ? `${typeTextMap[inspection.details.type_id]} at ${inspection.property
              ?.short_address}`
          : ""
      }
      rightElement={
        inspectionReportUrl ? (
          <Button
            flexGrow={[1, 0]}
            flexShrink={0}
            as={Link}
            to={inspectionReportUrl}
            size={"md"}
            colorScheme={"gray"}
            leftIcon={<DownloadIcon />}
          >
            Download Report
          </Button>
        ) : null
      }
      description={
        inspection ? (
          <CardRecordMeta>
            <Text fontSize={"md"}>
              {getLongDateTimeStringForInspectionTask(inspection)}
            </Text>
            <Text fontSize={"md"}>
              Inspected by {inspection?.managed_by?.display_name}
            </Text>
            <Text fontSize={"md"}>REF {UNKNOWN_VALUE_MAP}</Text>
          </CardRecordMeta>
        ) : null
      }
      showBackButton
    >
      <Flex direction={"column"} gap={4}>
        <Heading fontSize={"xl"}>Feedback</Heading>
        {inspection?.details.cover_summary ? (
          <CardRecord
            leftElement={
              <Box pl={4} pr={2} pt={1}>
                <CommentIcon />
              </Box>
            }
            heading={inspection?.managed_by?.display_name}
          >
            <Text>{inspection?.details.cover_summary}</Text>
          </CardRecord>
        ) : (
          <Empty emptyMessage={"No inspection summary available"} />
        )}
      </Flex>

      <Flex direction={"column"} gap={4}>
        <Heading fontSize={"xl"}>Photos</Heading>

        {(inspection?.details.images?.length || 0) > 0 ? (
          <>
            <Show above={"md"}>
              <ImageGallery images={inspection?.details.images ?? []} />
            </Show>
            <Show below={"md"}>
              <MobileImageGallery images={inspection?.details.images ?? []} />
            </Show>
          </>
        ) : (
          <Empty emptyMessage={"No photos available"} />
        )}
      </Flex>
    </Page>
  );
}
