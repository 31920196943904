import type { ReactNode } from "react";
import { Box, Show, useBreakpointValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Footer } from "components/footer";
import { PageRenderListener } from "components/page-render-listener";
import {
  TOP_NAVIGATION_BAR_HEIGHT,
  TopNavigationBar,
} from "components/top-navigation-bar";

type AppLayoutProps = {
  leftNavigationMenu: ReactNode;
};
export function AppLayout({ leftNavigationMenu }: AppLayoutProps) {
  const margin = useBreakpointValue(
    { base: "0px", lg: "320px" },
    { ssr: false },
  );

  return (
    <Box>
      <TopNavigationBar leftNavigationMenu={leftNavigationMenu} />
      <Box display={"flex"} overflowX={"hidden"}>
        <Show above={"lg"}>{leftNavigationMenu}</Show>
        <Box
          w={"100%"}
          overflowX={"hidden"}
          ml={margin}
          mt={TOP_NAVIGATION_BAR_HEIGHT}
          p={{ base: 0, md: 10 }}
        >
          <Outlet />
          <Footer />
          <PageRenderListener />
        </Box>
      </Box>
    </Box>
  );
}
