import { Text, type TextProps } from "@chakra-ui/react";

type FooterTextProps = {
  text: string;
} & TextProps;
export function FooterText({ text, ...rest }: FooterTextProps) {
  return (
    <Text
      textStyle={"medium"}
      whiteSpace={"nowrap"}
      color={"gray.600"}
      {...rest}
    >
      {text}
    </Text>
  );
}
