import { Box, Image } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import rexRealEstateLogoDark from "assets/rex-real-estate-logo-dark.png";
import { CardRecord } from "components/cards/card-record";
import { Page } from "components/page";
import { SimpleGridTable } from "components/simple-grid-table";
import { landlordOverviewModel } from "modules/landlord-portal/models/overview";
import { AgencyDetails } from "modules/landlord-portal/screens/overview/components/agency-details";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { UNKNOWN_VALUE_MAP } from "utils/string";

const frequencyMap = {
  weekly: "week",
  fortnightly: "fortnight",
  monthly: "month",
  quarterly: "quarter",
  annually: "year",
};

export function OverviewPropertyDetailsScreen() {
  const { propertyId } = useParams();

  const { data } = useQuery(landlordOverviewModel.queries.read());

  const property = data?.properties?.find((p) => p.id === propertyId);

  const tenancy = property?.related.tenancies[0];

  const ownership = property?.related.ownership;

  const sortedTenants = tenancy?.tenants.sort((a, b) => {
    if (a.role_id === "primary") return -1;
    if (b.role_id === "primary") return 1;
    return 0;
  });

  const sortedOwners = ownership?.owners.sort((a, b) => {
    if (a.role_id === "primary") return -1;
    if (b.role_id === "primary") return 1;
    return 0;
  });

  return (
    <Page
      heading={`Details for ${property?.short_address}`}
      rightElement={
        <Box>
          <Image
            src={rexRealEstateLogoDark}
            alt={"Rex Real Estate"}
            w={"168px"}
            h={"auto"}
          />
        </Box>
      }
      description={`Managed by ${property?.related.ownership.agency_company_contact.display_name}`}
      showBackButton
    >
      <CardRecord heading={"Details"}>
        <SimpleGridTable
          items={[
            {
              label: "Lease type",
              value: UNKNOWN_VALUE_MAP,
            },
            {
              label: "Management agreement",
              value:
                dayjs(ownership?.agreement_start_date).format(
                  dateFormats.dayMonthYear,
                ) +
                " - " +
                (ownership?.agreement_end_date
                  ? dayjs(ownership?.agreement_end_date).format(
                      dateFormats.dayMonthYear,
                    )
                  : ""),
            },
            {
              label: "Rent",
              value:
                formatMoney(tenancy?.rent_amount) +
                ` per ${
                  tenancy?.frequency_id
                    ? frequencyMap[tenancy?.frequency_id]
                    : ""
                }`,
            },
            {
              label: "Owners",
              value: sortedOwners
                ?.map(
                  (t) =>
                    t.contact.display_name +
                    (t.role_id === "primary" ? ` (primary)` : ""),
                )
                .join(", "),
            },
            {
              label: "Tenants",
              value: sortedTenants
                ?.map(
                  (t) =>
                    t.contact.display_name +
                    (t.role_id === "primary" ? ` (primary)` : ""),
                )
                .join(", "),
            },
          ]}
        />
      </CardRecord>
      <AgencyDetails agency={ownership?.agency_company_contact} />
    </Page>
  );
}
