import { Box, Image } from "@chakra-ui/react";
import rexRealEstateLogoDark from "assets/rex-real-estate-logo-dark.png";
import { PropertyAttributes } from "components/property-banner-image/property-attributes";
import {
  getGreeting,
  PropertyBannerDetails,
} from "components/property-banner-image/property-banner-details";
import { useAuth } from "contexts/auth-provider";
import { RequestMaintenanceBox } from "modules/tenant-portal/screens/overview/components/banner-image/request-maintenance-box";
import type { Property } from "types/property";

type TenantBannerImageProps = {
  property?: Property;
  isLoading: boolean;
};
export function TenantBannerImage({
  property,
  isLoading,
}: TenantBannerImageProps) {
  const { user } = useAuth();
  const currentHour = new Date().getHours();
  const greeting = getGreeting(currentHour);

  return (
    <PropertyBannerDetails
      isLoading={isLoading}
      title={`${greeting}, ${user?.given_name}!`}
      subtitle={property?.full_address}
      leftElement={
        <PropertyAttributes
          propertyDetails={property?.details}
          propertyCategoryId={property?.property_category_id}
        />
      }
      topLeftElement={
        <Image
          src={rexRealEstateLogoDark}
          alt={"Rex Real Estate"}
          w={"168px"}
        />
      }
      rightElement={
        <Box alignSelf={"stretch"} flex={1} flexBasis={{ base: "100%", xl: 0 }}>
          <RequestMaintenanceBox />
        </Box>
      }
    />
  );
}
