import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  getLongDateTimeStringForInspectionTask,
  InspectionsList,
  triggerIcsDownload,
} from "components/inspections-list/inspections-list";
import { Page } from "components/page";
import { tenancyInspectionsModel } from "modules/tenant-portal/models/inspections";

export function InspectionsScreen() {
  const { propertyId } = useParams();
  const { data, isLoading } = useQuery(
    tenancyInspectionsModel.queries.read(propertyId),
  );

  const sortedInspections = [...(data?.inspectionTasks ?? [])].sort((a, b) =>
    dayjs(b.details.scheduled_at).diff(dayjs(a.details.scheduled_at)),
  );

  const upcomingInspection = [...sortedInspections]
    .reverse()
    .find((task) => dayjs(task.details.scheduled_at).isAfter(dayjs()));

  return (
    <Page heading={"Inspections"}>
      <InspectionsList
        inspectionTasks={sortedInspections}
        isLoading={isLoading}
        alerts={
          upcomingInspection ? (
            <Alert
              status={"info"}
              alignItems={"flex-start"}
              bg={"white"}
              shadow={"md"}
              justifyContent={"space-between"}
              flexDir={"row"}
            >
              <Flex direction={"row"}>
                <AlertIcon mt={1} />
                <Flex direction={"column"}>
                  <AlertTitle>You have an inspection coming up</AlertTitle>
                  <AlertDescription>
                    You have an upcoming inspection at{" "}
                    {getLongDateTimeStringForInspectionTask(upcomingInspection)}
                  </AlertDescription>
                </Flex>
              </Flex>
              <Button
                flexShrink={0}
                variant={"outline"}
                colorScheme={"gray"}
                onClick={() => triggerIcsDownload(upcomingInspection)}
              >
                Add to calendar
              </Button>
            </Alert>
          ) : null
        }
      />
    </Page>
  );
}
