import { Box, Show } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { PerformanceDesktopLayout } from "modules/vendor-portal/screens/performance/components/layout/performance-desktop-layout";
import { PerformanceMobileLayout } from "modules/vendor-portal/screens/performance/components/layout/performance-mobile-layout";
import { performanceModel } from "modules/vendor-portal/screens/performance/models/performance";

export function PerformanceScreen() {
  const { isLoading, data } = useQuery(performanceModel.queries.read());

  return (
    <Box p={0}>
      <Show above={"md"}>
        <PerformanceDesktopLayout isLoading={isLoading} data={data} />
      </Show>

      <Show below={"md"}>
        <PerformanceMobileLayout isLoading={isLoading} data={data} />
      </Show>
    </Box>
  );
}
