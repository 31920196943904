import type { ReactNode } from "react";
import { CalendarIcon } from "@chakra-ui/icons";
import { Button, type CardProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CardRecord } from "components/cards/card-record";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import { formatEventDateTimeTitle } from "modules/vendor-portal/screens/overview/components/open-home-events/event-card";
import type { ViewingEvent } from "modules/vendor-portal/types/events";

type LinkEventCardProps = {
  event: ViewingEvent;
  children?: ReactNode;
} & CardProps;
export function LinkedEventCard({
  event,
  children,
  ...rest
}: LinkEventCardProps) {
  return (
    <CardRecord
      heading={formatEventDateTimeTitle(event)}
      renderCallToActionButton={
        event.feedback.type.id === "ofi"
          ? (props) => (
              <Button as={Link} to={`${event.id}`} {...props}>
                View details
              </Button>
            )
          : undefined
      }
      leftElement={<FeedbackIcon icon={CalendarIcon} />}
      {...rest}
    >
      {children}
    </CardRecord>
  );
}
