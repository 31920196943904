import { Fragment, useMemo, type ReactNode } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
  type ButtonProps,
  type MenuProps,
} from "@chakra-ui/react";
import CheckIcon from "assets/icons/medium/check.svg?react";

export type MenuOption = { value: string; label: string };

export type OptionsGroup = {
  label: { id: string; value: string };
  options: { value: string; label: string }[];
};

type SelectInputProps = {
  placeholder?: string;
  selectionPrefix?: string;
  optionGroups: OptionsGroup[];
  onChange: (option: string, label: string) => void;
  value?: string | null;
  menuProps?: Omit<MenuProps, "children">;
  menuButtonProps?: ButtonProps;
  overlineText?: string;
  prefixIcon?: ReactNode;
};

export function SelectInput({
  placeholder = "",
  selectionPrefix = "",
  optionGroups,
  onChange,
  value = null,
  menuProps = {},
  menuButtonProps = {},
  overlineText,
  prefixIcon,
}: SelectInputProps) {
  const selectedLabel = useMemo(() => {
    for (const group of optionGroups) {
      const option = group.options.find((option) => option.value === value);
      if (option) {
        return option.label;
      }
    }
    return "";
  }, [optionGroups, value]);

  return (
    <Menu closeOnSelect={true} {...menuProps}>
      <MenuButton
        as={Button}
        h={"auto"}
        py={2}
        colorScheme={"gray"}
        variant={"outline"}
        fontSize={"md"}
        borderWidth={"2px"}
        borderColor={"rgba(0,0,0,0)"}
        _active={{
          bg: "indigo.100",
          borderColor: "indigo.600",
        }}
        _hover={{ bg: "indigo.100" }}
        {...menuButtonProps}
      >
        <VStack align={"flex-start"}>
          {overlineText ? (
            <Text fontSize={"md"} fontWeight={500} color={"gray.600"}>
              {overlineText}
            </Text>
          ) : null}
          <HStack w={"100%"}>
            <HStack justify={"flex-start"} w={"100%"} overflowX={"hidden"}>
              {prefixIcon ? prefixIcon : null}
              <Text
                lineHeight={"24px"}
                whiteSpace={"normal"}
                noOfLines={1}
                wordBreak={"break-all"}
              >
                {value
                  ? `${selectionPrefix} ${selectedLabel}`
                  : `${placeholder}`}
              </Text>
            </HStack>
            <ChevronDownIcon boxSize={"18px"} />
          </HStack>
        </VStack>
      </MenuButton>
      <MenuList>
        {optionGroups.map((group, idx) => (
          <Fragment key={group.label.id}>
            <MenuGroup
              key={group.label.id}
              title={group.label.value.toUpperCase()}
            >
              {group.options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  bg={value === option.value ? "indigo.100" : "inherit"}
                  _hover={{ bg: "indigo.100" }}
                  onClick={() => onChange(option.value, group.label.id)}
                >
                  <Flex
                    direction={"row"}
                    align={"center"}
                    justify={"space-between"}
                    flexGrow={1}
                  >
                    {option.label}
                    {value === option.value ? <CheckIcon /> : null}
                  </Flex>
                </MenuItem>
              ))}
            </MenuGroup>
            {idx < optionGroups.length - 1 ? <MenuDivider /> : null}
          </Fragment>
        ))}
      </MenuList>
    </Menu>
  );
}
