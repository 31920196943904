export const dateFormats = {
  dayMonth: "D MMM",
  dayMonthYear: "D MMM YYYY",
  weekdayDayYear: "ddd D MMM",
  time: "h:mma",
  monthYear: "MMM YYYY",
};

export const timeFormats = {
  hoursMinutes: "h:mma",
};

const dateTimeFormat = new Intl.DateTimeFormat("en-GB", {
  dateStyle: "long",
  timeStyle: "short",
});

export function getCurrentUtcDate() {
  const local = new Date();
  const offset = local.getTimezoneOffset();
  return new Date(local.getTime() + offset * 60000);
}

export function getElapsedTime(previousDate: Date) {
  const seconds = Math.floor((+getCurrentUtcDate() - +previousDate) / 1000);
  let interval = Math.floor(seconds / 3600);
  if (interval >= 24) {
    return dateTimeFormat.format(previousDate);
  }
  if (interval >= 1) {
    return interval + ` hour${interval > 1 ? "s" : ""} ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + ` minute${interval > 1 ? "s" : ""} ago`;
  }
  return (
    Math.floor(seconds) + ` second${Math.floor(seconds) > 1 ? "s" : ""} ago`
  );
}
