import type { Dispatch, SetStateAction } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Flex } from "@chakra-ui/react";

type ShowMoreLessButtonProps = {
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
};

export function ShowMoreLessButton({
  showAll,
  setShowAll,
}: ShowMoreLessButtonProps) {
  return (
    <Flex justify={{ base: "center", sm: "start" }} align={"center"} gap={2}>
      <Button
        variant={"link"}
        color={"grey.700"}
        fontSize={"lg"}
        fontWeight={600}
        lineHeight={"24px"}
        pl={{ base: 0, sm: 2 }}
        rightIcon={
          <ChevronDownIcon
            boxSize={6}
            color={"grey.700"}
            ml={-2}
            sx={{
              transition: "transform 0.3s ease-in-out",
              transform: showAll ? "rotate(180deg)" : "rotate(0)",
            }}
          />
        }
        onClick={() => setShowAll(!showAll)}
      >
        Show {showAll ? "less" : "more"}
      </Button>
    </Flex>
  );
}
