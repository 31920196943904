import { faker } from "@faker-js/faker";
import { uniqueArray } from "lib/enforce-unique";
import { interestLevels } from "modules/vendor-portal/screens/overview/models/overview";
import {
  FeedbackTypeId,
  FeedbackTypeText,
} from "modules/vendor-portal/types/feedback";
import {
  ListingCategories,
  type Listing,
} from "modules/vendor-portal/types/listing";
import { BuildAreaUnit, LandAreaUnit, mockListingImages } from "types/property";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type FeedbackScreenData = {
  listing: Listing;
};

export const feedbackScreenModel = createModel("feedback", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: () => apiClient.get<FeedbackScreenData>(`/feedback`),
    }),
  },
});

export const feedbackTypes: { id: FeedbackTypeId; text: FeedbackTypeText }[] = [
  { id: FeedbackTypeId.Enquiry, text: FeedbackTypeText.Enquiry },
  { id: FeedbackTypeId.FollowUp, text: FeedbackTypeText.FollowUp },
  { id: FeedbackTypeId.Inspection, text: FeedbackTypeText.Inspection },
  { id: FeedbackTypeId.OFI, text: FeedbackTypeText.OFI },
  { id: FeedbackTypeId.PriceReduction, text: FeedbackTypeText.PriceReduction },
  { id: FeedbackTypeId.AgentVendor, text: FeedbackTypeText.AgentVendor },
];

function mockProperty() {
  return {
    id: faker.string.uuid(),
    full_address: `${faker.location.streetAddress()}, ${faker.location.city()} ${faker.location.state()} ${faker.location.zipCode(
      "####",
    )}`,
    short_address: faker.location.streetAddress(),
    image_url: mockListingImages[0],
    details: {
      bedrooms: faker.number.int(30),
      bathrooms: faker.number.int(30),
      carports: faker.number.int(30),
      build_area: faker.number.float({ min: 100, max: 2000, multipleOf: 0.01 }),
      build_area_unit: BuildAreaUnit.SquareMeters,
      land_area: faker.number.float({ min: 100, max: 2000, multipleOf: 0.01 }),
      land_area_unit: LandAreaUnit.Hectares,
    },
  };
}

export function mockFeedback(contactName: string, agentName: string) {
  return {
    id: faker.string.uuid(),
    date_of: faker.date.recent({ days: 10 }).toDateString(),
    agent: {
      id: faker.string.uuid(),
      name: agentName,
    },
    type: feedbackTypes[faker.number.int({ min: 0, max: 5 })],
    related_contacts: [
      {
        id: faker.string.uuid(),
        name: contactName,
        ofi_visits: faker.number.int({ min: 1, max: 5 }),
      },
      {
        id: faker.string.uuid(),
        name: faker.person.firstName() + " " + faker.person.lastName(),
        ofi_visits: faker.number.int({ min: 1, max: 5 }),
      },
      {
        id: faker.string.uuid(),
        name: faker.person.firstName() + " " + faker.person.lastName(),
        ofi_visits: faker.number.int({ min: 1, max: 5 }),
      },
    ],
    attendees: [
      {
        id: faker.string.uuid(),
        name: contactName,
        ofi_visits: faker.number.int({ min: 1, max: 5 }),
      },
      {
        id: faker.string.uuid(),
        name: faker.person.firstName() + " " + faker.person.lastName(),
        ofi_visits: faker.number.int({ min: 1, max: 5 }),
      },
    ],
    number_of_people: faker.number.int({ min: 1, max: 5 }),
    open_home_event: {
      id: faker.string.uuid(),
      calendar_event_id: faker.datatype.boolean() ? faker.string.uuid() : null,
    },
    note: uniqueArray([
      "Loved the build quality of the property",
      "Was a bit concerned about the amount of maintenance required",
      "Young professional working nearby, found the location ideal",
      "Loved the amenities in the property",
      "Loved the location, but found the property to be a bit small",
      "Based on the advertised price they were keen to submit an offer as soon as possible",
    ]),
    is_new: faker.datatype.boolean(),
    price_indication: faker.number.int({ min: 700000, max: 900000 }),
    price_previous_match: faker.number.int({ min: 700000, max: 900000 }),
    price_new_match: faker.number.int({ min: 700000, max: 900000 }),
    enquiry_source: {
      id: faker.string.uuid(),
      text: faker.lorem.words({ min: 1, max: 2 }),
    },
    interest_level: faker.datatype.boolean()
      ? null
      : interestLevels[faker.number.int({ min: 0, max: 2 })],
  };
}

const mockAdvertisePriceAs = faker.number.int({ min: 900000, max: 900000 });
const mockAgentName = faker.person.firstName() + " " + faker.person.lastName();
export const mockFeedbackResponse = {
  listing: {
    id: faker.string.uuid(),
    listing_category_id: ListingCategories.CommercialSale,
    price_advertise_as: mockAdvertisePriceAs,
    property: mockProperty(),
    feedback: [...Array(30)].map(() => {
      const contactName =
        faker.person.firstName() + " " + faker.person.lastName();
      return mockFeedback(contactName, mockAgentName);
    }),
    stats: [
      {
        id: faker.string.uuid(),
        value: faker.number.int(30),
        label: "Days since listing the property",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Total enquiries",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Total inspections & open homes",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Total attendees",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Buyers who have inspected twice",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Total offers",
      },
    ],
    interest_level_stats: [
      {
        id: "hot",
        label: "Hot",
        value: faker.number.int({ min: 0, max: 100 }),
      },
      {
        id: "warm",
        label: "Warm",
        value: faker.number.int({ min: 0, max: 100 }),
      },
      {
        id: "cold",
        label: "Cold",
        value: faker.number.int({ min: 0, max: 100 }),
      },
      {
        id: "neutral",
        label: "Neutral",
        value: faker.number.int({ min: 0, max: 100 }),
      },
    ],
    price_indications: [...Array(faker.number.int({ min: 2, max: 3 }))].map(
      () =>
        faker.number.int({
          min: mockAdvertisePriceAs - 100000,
          max: mockAdvertisePriceAs + 100000,
        }),
    ),
  },
};
