import type { ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react";

type CardRecordInfoTableProps = {
  rows: { label: string; value: ReactNode }[];
};
export function CardRecordInfoTable({ rows }: CardRecordInfoTableProps) {
  return (
    <Box display={"flex"} flexDir={"column"} gap={2}>
      {rows.map((row) => (
        <Box
          key={row.label}
          display={"flex"}
          flexDir={"row"}
          justifyContent={"space-between"}
        >
          <Box flex={1}>
            <Text fontSize={"lg"} fontWeight={"medium"}>
              {row.label}
            </Text>
          </Box>
          <Box flex={1}>
            {typeof row.value === "string" ? (
              <Text fontSize={"lg"}>{row.value}</Text>
            ) : (
              row.value ?? <Text fontSize={"lg"}>–</Text>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
