import type { ComponentType, SVGProps } from "react";
import { Box, Flex, Show, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ContactItem } from "components/left-navigation-menu/components/contact-item";
import { LeftNavigationMenuItem } from "components/left-navigation-menu/components/left-navigation-menu-item";
import { PortalSelector } from "components/portal-selector";
import { TOP_NAVIGATION_BAR_HEIGHT } from "components/top-navigation-bar";
import { useAuth } from "contexts/auth-provider";
import { derivePortalFromUrl } from "utils/derive-portal-from-url";

export type MenuItem = {
  label: string;
  path: string;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
};

export function LeftNavigationMenu({ items }: { items: MenuItem[] }) {
  const { user } = useAuth();
  const { propertyId } = useParams();

  const property = user?.related?.[
    derivePortalFromUrl() as keyof typeof user.related
  ]?.find((property) => property.id === propertyId);

  return (
    <>
      <Box
        w={{ base: "100%", lg: "320px" }}
        bg={"indigo.50"}
        pos={"fixed"}
        top={0}
        bottom={0}
        p={{ base: 4, md: 6 }}
        pt={{ base: 4, md: 10 }}
        mt={TOP_NAVIGATION_BAR_HEIGHT}
        display={"flex"}
        flexDir={"column"}
        alignItems={"space-between"}
        overflowY={{ base: "scroll", lg: "visible" }}
        zIndex={999}
        gap={6}
      >
        <Box>
          <Show above={"md"}>
            <Flex direction={"column"} w={"100%"} mb={"40px"}>
              <PortalSelector
                overlineText={"Property"}
                menuButtonProps={{
                  variant: "ghost",
                  fontSize: "2xl",
                  w: "100%",
                  textAlign: "left",
                }}
              />
            </Flex>
          </Show>
          <VStack spacing={1}>
            {items.map((menu) => (
              <LeftNavigationMenuItem
                path={menu.path as string}
                label={menu.label}
                key={menu.path}
                Icon={menu.Icon}
                property={property}
              />
            ))}
          </VStack>
        </Box>
        {property?.agency_contacts?.length ? (
          <VStack
            spacing={2}
            mt={"auto"}
            borderTop={"1px solid #ECE7DE"}
            gap={4}
            pt={4}
            justifyContent={"flex-start"}
          >
            <Box w={"100%"}>
              <Text fontSize={"14px"} fontWeight={500} color={"gray.600"}>
                Contact
              </Text>
            </Box>
            <VStack w={"100%"} gap={4}>
              {property?.agency_contacts?.map((contact: any) => (
                <ContactItem key={contact.id} agent={contact} />
              ))}
            </VStack>
          </VStack>
        ) : null}
      </Box>
    </>
  );
}
