import type { ReactNode } from "react";
import { Box, SkeletonText, Text } from "@chakra-ui/react";
import { GradientMaskedImage } from "components/gradient-masked-image";

type PropertyBannerDetailsProps = {
  isLoading: boolean;
  title: string;
  subtitle?: string;
  rightElement?: ReactNode;
  topLeftElement?: ReactNode;
  leftElement?: ReactNode;
  backgroundImage?: string | null;
};
export function PropertyBannerDetails({
  isLoading,
  title,
  subtitle,
  backgroundImage,
  topLeftElement,
  leftElement,
  rightElement,
}: PropertyBannerDetailsProps) {
  const inner = (
    <Box
      p={[6, 12]}
      display={"flex"}
      flexDir={"row"}
      rowGap={6}
      flexWrap={"wrap"}
      columnGap={4}
    >
      <Box
        flex={1}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"space-between"}
        rowGap={6}
      >
        {topLeftElement ? <Box flex={1}>{topLeftElement}</Box> : null}
        <Box display={"flex"} flexDir={"row"} flex={1} alignItems={"flex-end"}>
          <Box flex={1}>
            <SkeletonText
              noOfLines={1}
              skeletonHeight={"30px"}
              maxW={isLoading ? "300px" : "100%"}
              isLoaded={!isLoading}
              color={backgroundImage ? "white" : undefined}
            >
              <Text
                fontSize={{ base: "6xl", sm: "7xl" }}
                fontWeight={{ base: 800, md: 700 }}
                lineHeight={{ base: "30px", sm: "40px" }}
              >
                {title}
              </Text>
            </SkeletonText>
            <Box
              display={"flex"}
              flexDir={"row"}
              rowGap={"32px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={"24px"}
            >
              <SkeletonText
                noOfLines={2}
                skeletonHeight={"18px"}
                maxW={isLoading ? "300px" : { base: "100%", md: "80%" }}
                isLoaded={!isLoading}
                color={backgroundImage ? "white" : undefined}
              >
                <Box display={"flex"} flexDir={"column"} gap={"8px"}>
                  <Text fontSize={{ base: "md", lg: "lg" }} fontWeight={700}>
                    {subtitle}
                  </Text>
                  <Box
                    display={"flex"}
                    dir={"row"}
                    rowGap={2}
                    columnGap={4}
                    flexWrap={"wrap"}
                  >
                    {leftElement}
                  </Box>
                </Box>
              </SkeletonText>
            </Box>
          </Box>
        </Box>
      </Box>
      {rightElement}
    </Box>
  );

  return (
    <Box
      minH={"320px"}
      w={"100%"}
      borderRadius={["none", "none", "lg"]}
      overflow={"hidden"}
      bg={"indigo.100"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={backgroundImage ? "flex-end" : "stretch"}
    >
      {backgroundImage ? (
        <GradientMaskedImage imageUrl={backgroundImage}>
          {inner}
        </GradientMaskedImage>
      ) : (
        inner
      )}
    </Box>
  );
}

export function getGreeting(currentHour: number): string {
  if (currentHour >= 4 && currentHour < 12) return "Good morning";
  if (currentHour >= 12 && currentHour < 18) return "Hello";
  return "Good evening";
}
