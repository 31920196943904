import { Badge } from "@chakra-ui/react";
import type { EnumValue } from "modules/vendor-portal/types/api-listing";

export const colorMap = {
  cold: "badgeBlue",
  warm: "badgeOrange",
  hot: "badgeRed",
  neutral: "gray",
} as const;

type InterestLevelBadgeProps = {
  interestLevel: EnumValue;
};
export function InterestLevelBadge({ interestLevel }: InterestLevelBadgeProps) {
  return (
    <Badge colorScheme={colorMap[interestLevel.id as keyof typeof colorMap]}>
      {interestLevel.label}
    </Badge>
  );
}
