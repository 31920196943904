import { Text, useBreakpoint } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { CardRecordMeta } from "components/cards/card-record-meta";
import { FeedbackTypeTag } from "modules/vendor-portal/components/feedback-type-tag";
import { InterestLevelBadge } from "modules/vendor-portal/components/interest-level-badge";
import { FooterText } from "modules/vendor-portal/screens/overview/components/feedback/footer/footer-text";
import type {
  APIListingAgentUpdateEntry,
  APIListingApplicantFeedbackEntry,
  APIListingEventFeedbackEntry,
} from "modules/vendor-portal/types/api-listing";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { numberToOrdinal } from "utils/format-ordinal-number";

type OpenHomeFooterProps = {
  feedback:
    | APIListingApplicantFeedbackEntry
    | APIListingAgentUpdateEntry
    | APIListingEventFeedbackEntry;
};

export function OpenHomeFooter({ feedback }: OpenHomeFooterProps) {
  const breakpoint = useBreakpoint({ ssr: false });
  const isMobile = breakpoint === "base" || breakpoint === "sm";

  return (
    <CardRecordMeta>
      <FeedbackTypeTag feedbackType={feedback.type} />

      {"interest_level" in feedback && feedback.interest_level ? (
        <InterestLevelBadge interestLevel={feedback.interest_level} />
      ) : null}

      {isMobile ||
      !("price_indication" in feedback) ||
      !feedback.price_indication ? null : (
        <FooterText
          text={`Price indication ${formatMoney(feedback.price_indication)}`}
        />
      )}

      {"attendees" in feedback &&
      feedback.attendees &&
      feedback.attendees.length === 0
        ? null
        : "attendees" in feedback &&
          feedback.attendees &&
          feedback.attendees[0] && (
            <FooterText
              text={`${numberToOrdinal(
                feedback.attendees[0].times_inspected_running_total,
              )} inspection`}
            />
          )}

      {"date_of" in feedback && (
        <FooterText
          text={dayjs(feedback.date_of).format(dateFormats.dayMonthYear)}
        />
      )}

      {isMobile ? null : <FooterText text={`Agent ${feedback.agent.name}`} />}

      {"event" in feedback && feedback.event ? (
        <Link to={`../events/${feedback.event.id}`}>
          <Text
            color={"blue.600"}
            fontSize={"md"}
            fontWeight={500}
            lineHeight={"20px"}
          >
            View event details
          </Text>
        </Link>
      ) : null}
    </CardRecordMeta>
  );
}
