import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Show,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { PortalSelector } from "components/portal-selector";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { EventDesktopLayout } from "modules/vendor-portal/screens/events/components/layout/event-desktop-layout";
import { EventMobileLayout } from "modules/vendor-portal/screens/events/components/layout/event-mobile-layout";
import { eventsModel } from "modules/vendor-portal/screens/events/models/events";

export function EventsScreen() {
  const { isLoading, data } = useQuery(eventsModel.queries.read());

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>

      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={8}
      >
        <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700} as={"h1"}>
          Events
        </Heading>

        <SkeletonGrid
          isLoaded={!isLoading}
          gridSize={4}
          skeleton={<StatsCardSkeleton />}
        >
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
            templateRows={{ base: "repeat(2, 1fr)", lg: "repeat(1, 1fr)" }}
            gap={6}
          >
            {data?.listing.stats.map((stat) => (
              <GridItem key={stat.id} w={"100%"}>
                <StatsCard stat={stat} icon={BarChartIcon} />
              </GridItem>
            ))}
          </Grid>
        </SkeletonGrid>

        <Show above={"sm"}>
          <EventDesktopLayout isLoading={isLoading} listing={data?.listing} />
        </Show>

        <Show below={"sm"}>
          <EventMobileLayout isLoading={isLoading} listing={data?.listing} />
        </Show>
      </Flex>
    </Box>
  );
}
