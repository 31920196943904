import { faker } from "@faker-js/faker";
import { uniqueArray } from "lib/enforce-unique";
import {
  feedbackTypes,
  interestLevels,
} from "modules/vendor-portal/screens/overview/models/overview";
import type {
  EventsScreenData,
  ViewingEvent,
} from "modules/vendor-portal/types/events";
import type {
  FeedbackType,
  OpenHomeFeedback,
  PrivateInspectionFeedback,
} from "modules/vendor-portal/types/feedback";
import { ListingCategories } from "modules/vendor-portal/types/listing";
import { mockProperty } from "types/property";
import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

export type EventsData = {
  listing: EventsScreenData;
};

export const eventsModel = createModel("events", {
  queries: {
    read: () => ({
      queryKey: ["read"],
      queryFn: () => apiClient.get<EventsData>(`/events`),
    }),
  },
});

function mockAgents(size = 1) {
  return [...Array(size)].map(() => ({
    id: faker.string.uuid(),
    name: faker.person.firstName() + " " + faker.person.lastName(),
  }));
}

function mockTime() {
  return `${faker.number.int({
    min: 0,
    max: 23,
  })}:${faker.number.int({ min: 0, max: 59 })}:00`;
}

function mockNote() {
  return uniqueArray([
    "Great turnout despite the weather",
    "Lots of attendees for this open home, overall interest was very high with several offers submitted",
    "Highly attended open house, vibrant interest and multiple bids received",
    "Despite the rain, strong turnout with significant engagement and inquiries",
    "Excellent attendance; visitors showed strong interest, leading to multiple offers",
    "Impressive attendance with sustained interest and multiple competitive offers made",
  ]);
}

function mockViewingEvent(feedbackType: FeedbackType): ViewingEvent {
  return {
    id: faker.string.uuid(),
    date_of: faker.date.recent({ days: 10 }).toDateString(),
    date_time_start: mockTime(),
    date_time_finish: mockTime(),
    agents: mockAgents(faker.number.int({ min: 1, max: 2 })),
    attendees_count: faker.number.int({ min: 1, max: 150 }),
    calendar_event_id: faker.datatype.boolean() ? faker.string.uuid() : null,
    feedback:
      feedbackType.id === "ofi"
        ? mockOpenHomeFeedback(feedbackType)
        : mockPrivateInspectionFeedback(feedbackType),
  };
}
function mockPrivateInspectionFeedback(
  feedbackType: FeedbackType,
): PrivateInspectionFeedback {
  return {
    id: faker.string.uuid(),
    type: feedbackType,
    date_of: faker.date.recent({ days: 10 }).toDateString(),
    is_new: faker.datatype.boolean(),
    note: mockNote(),
    agent: {
      id: faker.string.uuid(),
      name: faker.person.firstName() + " " + faker.person.lastName(),
    },
    enquiry_source: null,
    interest_level: interestLevels[faker.number.int({ min: 0, max: 2 })],
    number_of_people: faker.number.int({ min: 1, max: 100 }),
    attendees: [],
    price_indication: faker.number.int({ min: 200000, max: 10000000 }),
  };
}
function mockOpenHomeFeedback(feedbackType: FeedbackType): OpenHomeFeedback {
  return {
    id: faker.string.uuid(),
    type: feedbackType,
    open_home_event: {
      id: faker.string.uuid(),
      calendar_event_id: faker.datatype.boolean() ? faker.string.uuid() : null,
    },
    date_of: faker.date.recent({ days: 10 }).toDateString(),
    date_time_start: mockTime(),
    date_time_finish: mockTime(),
    is_new: faker.datatype.boolean(),
    number_of_people: faker.number.int({ min: 1, max: 100 }),
    attendees: [],
    note: mockNote(),
    agent: {
      id: faker.string.uuid(),
      name: faker.person.firstName() + " " + faker.person.lastName(),
    },
    avg_price_indication: faker.number.int({ min: 200000, max: 10000000 }),
    individual_feedback: [...Array(faker.number.int({ min: 1, max: 6 }))].map(
      () => ({
        id: faker.string.uuid(),
        name: faker.person.firstName() + " " + faker.person.lastName(),
        price_indication: faker.number.int({
          min: 200000,
          max: 10000000,
        }),
        enquiry_source: null,
        interest_level: faker.datatype.boolean()
          ? null
          : interestLevels[faker.number.int({ min: 0, max: 2 })],
        note: mockNote(),
      }),
    ),
  };
}
export const mockEventsResponse = {
  listing: {
    id: faker.string.uuid(),
    listing_category_id: ListingCategories.CommercialSale,
    property: mockProperty(),
    stats: [
      {
        id: faker.string.uuid(),
        value: faker.number.int(30),
        label: "Total attendees",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Total private inspections",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Total open homes",
      },
      {
        id: faker.string.uuid(),
        value: faker.number.int(100),
        label: "Buyers who have inspected twice",
      },
    ],
    events: {
      past: [...Array(6)].map(() => {
        const feedbackType =
          feedbackTypes[faker.number.int({ min: 2, max: 3 })];
        return mockViewingEvent(feedbackType);
      }),
      upcoming: [...Array(6)].map(() => {
        const feedbackType =
          feedbackTypes[faker.number.int({ min: 2, max: 3 })];
        return mockViewingEvent(feedbackType);
      }),
    },
  },
};
