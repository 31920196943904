import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import BathIcon from "assets/icons/medium/bath.svg?react";
import BedIcon from "assets/icons/medium/bed.svg?react";
import BuildAreaIcon from "assets/icons/medium/build-area.svg?react";
import CarportIcon from "assets/icons/medium/car.svg?react";
import LandAreaIcon from "assets/icons/medium/land-area.svg?react";
import type { APIListing } from "modules/vendor-portal/types/api-listing";
import {
  BuildAreaUnit,
  LandAreaUnit,
  PropertyCategories,
} from "types/property";

function getAreaUnit(value: string): string {
  if (
    value === BuildAreaUnit.SquareMeters ||
    value === LandAreaUnit.SquareMeters
  ) {
    return String.fromCharCode(0x33a1);
  }
  return value;
}
type PropertyAttributesProps = {
  propertyDetails?: APIListing["property"];
  propertyCategoryId?: PropertyCategories | null;
};
export function PropertyAttributes({
  propertyDetails,
  propertyCategoryId,
}: PropertyAttributesProps) {
  const shouldDisplayAreaAttributes =
    propertyCategoryId === PropertyCategories.Commercial;

  return (
    <Flex
      wrap={"wrap"}
      direction={"row"}
      align={"center"}
      columnGap={"24px"}
      rowGap={"6px"}
    >
      <Flex direction={"row"} align={"center"} columnGap={3}>
        {shouldDisplayAreaAttributes ? (
          <>
            <Box
              display={propertyDetails?.build_area ? "flex" : "none"}
              flexDir={"row"}
              alignItems={"center"}
              gap={"4px"}
            >
              <Icon as={BuildAreaIcon} />
              <Text fontSize={"md"} fontWeight={600}>
                {`${propertyDetails?.build_area}${propertyDetails?.build_area_unit?.label}`}
              </Text>
            </Box>
            <Box
              display={propertyDetails?.land_area ? "flex" : "none"}
              flexDir={"row"}
              alignItems={"center"}
              gap={"4px"}
            >
              <Icon as={LandAreaIcon} />
              <Text fontSize={"md"} fontWeight={600}>
                {`${propertyDetails?.land_area}${propertyDetails?.land_area_unit?.label}`}
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Box
              display={propertyDetails?.bedrooms ? "flex" : "none"}
              flexDir={"row"}
              alignItems={"center"}
              gap={"4px"}
            >
              <Icon as={BedIcon} />

              <Text fontSize={"md"} fontWeight={600}>
                {propertyDetails?.bedrooms}
              </Text>
            </Box>
            <Box
              display={propertyDetails?.bathrooms ? "flex" : "none"}
              flexDir={"row"}
              alignItems={"center"}
              gap={"4px"}
            >
              <Icon as={BathIcon} />

              <Text fontSize={"md"} fontWeight={600}>
                {propertyDetails?.bathrooms}
              </Text>
            </Box>
          </>
        )}

        <Box
          display={propertyDetails?.car_spaces ? "flex" : "none"}
          flexDir={"row"}
          alignItems={"center"}
          gap={"4px"}
        >
          <Icon as={CarportIcon} />

          <Text fontSize={"md"} fontWeight={600}>
            {propertyDetails?.car_spaces}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
