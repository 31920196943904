import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { SelectInput } from "components/inputs/select-input";
import { fontModel } from "modules/agency-settings/models/font-model";

type UploadFontModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const fontWeightOptions = [
  { value: "100", label: "Thin (100)" },
  { value: "200", label: "Extra Light (200)" },
  { value: "300", label: "Light (300)" },
  { value: "400", label: "Regular (400)" },
  { value: "500", label: "Medium (500)" },
  { value: "600", label: "Semi Bold (600)" },
  { value: "700", label: "Bold (700)" },
  { value: "800", label: "Extra Bold (800)" },
  { value: "900", label: "Black (900)" },
];

const fontWeightOptionGroup = {
  label: { id: "weights", value: "Weights" },
  options: fontWeightOptions,
};

export function UploadFontModal({ isOpen, onClose }: UploadFontModalProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const form = useForm({
    defaultValues: {
      name: "",
      weight: "400", // Default to Regular weight
    },
  });

  const uploadFontMutation = useMutation({
    ...fontModel.mutations.upload(),
    onSuccess: () => {
      toast({
        title: "Font uploaded",
        description: "Your font has been uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      form.reset();
      setSelectedFile(null);

      // Explicitly invalidate the fonts list query
      queryClient.invalidateQueries({ queryKey: ["fonts", "list"] });
    },
    onError: (error: Error) => {
      toast({
        title: "Upload failed",
        description:
          error.message ||
          "There was an error uploading your font. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  async function onSubmit(values: { name: string; weight: string }) {
    if (!selectedFile) {
      toast({
        title: "No file selected",
        description: "Please select a font file to upload.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await uploadFontMutation.mutateAsync({
        file: selectedFile,
        name: values.name,
        weight: values.weight,
      });
    } catch (error) {
      // Error will be handled by onError callback
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent>
        <Form form={form} id={"upload-font"} onSubmit={onSubmit}>
          <ModalHeader px={6} fontSize={"xl"}>
            Upload font
          </ModalHeader>

          <ModalCloseButton right={6} />

          <ModalBody px={6}>
            <Flex direction={"column"} gap={4} mb={8}>
              <Text>
                Before uploading fonts, you acknowledge that you have the
                appropriate licensing rights.
              </Text>
              <Text>File must be TTF, OTF, EOT or WOFF format.</Text>
            </Flex>

            <FormField
              name={"name"}
              label={"Font family name"}
              inputProps={{ placeholder: "e.g. Open Sans" }}
              Input={Input}
              required
            />

            <Box mt={4}>
              <FormField
                name={"weight"}
                label={"Font weight"}
                Input={SelectInput}
                required
                inputProps={{
                  placeholder: "Select weight",
                  menuButtonProps: {
                    borderWidth: "1px",
                    borderColor: "gray.200",
                    fontWeight: "normal",
                  },
                  optionGroups: [fontWeightOptionGroup],
                }}
              />
            </Box>

            <Box mt={4}>
              <FormLabel>Font File</FormLabel>
              <Input
                type={"file"}
                accept={".ttf,.otf,.eot,.woff"}
                onChange={handleFileChange}
                p={1}
              />
              {selectedFile && (
                <Text mt={2} fontSize={"sm"}>
                  Selected: {selectedFile.name} (
                  {Math.round(selectedFile.size / 1024)} KB)
                </Text>
              )}
            </Box>
          </ModalBody>

          <ModalFooter border={"none"} pt={0} px={6}>
            <HStack spacing={3}>
              <Button variant={"ghost"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant={"solid"}
                type={"submit"}
                form={"upload-font"}
                colorScheme={"gray"}
                isLoading={uploadFontMutation.isPending}
                isDisabled={uploadFontMutation.isPending || !selectedFile}
              >
                Upload Font
              </Button>
            </HStack>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
