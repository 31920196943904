import { Box, Button, Icon, Image, Show, Skeleton } from "@chakra-ui/react";
import rexRealEstateLogo from "assets/a-logo.png";
import PlayIcon from "assets/icons/medium/play.svg?react";
import { PropertyAttributes } from "components/property-banner-image/property-attributes";
import {
  getGreeting,
  PropertyBannerDetails,
} from "components/property-banner-image/property-banner-details";
import { useAuth } from "contexts/auth-provider";
import { getHighestResolutionImageSource } from "modules/vendor-portal/screens/overview/models/overview";
import type { APIListing } from "modules/vendor-portal/types/api-listing";
import { PropertyCategories } from "types/property";

type VendorBannerImageProps = {
  listing?: APIListing;
  isLoading: boolean;
  onOpen: () => void;
};
export function VendorBannerImage({
  listing,
  isLoading,
  onOpen,
}: VendorBannerImageProps) {
  const { user } = useAuth();
  const currentHour = new Date().getHours();
  const greeting = getGreeting(currentHour);

  return (
    <>
      <PropertyBannerDetails
        isLoading={isLoading}
        title={`${greeting}, ${user?.given_name}!`}
        subtitle={listing?.address?.full_address}
        leftElement={
          <>
            <PropertyAttributes
              propertyDetails={listing?.property}
              propertyCategoryId={PropertyCategories.Residential} // TODO: not hard coded
            />
            <Button
              onClick={() => onOpen()}
              rightIcon={<Icon as={PlayIcon} />}
              colorScheme={"white"}
              variant={"outline"}
              size={"xs"}
            >
              Play presentation
            </Button>
          </>
        }
        backgroundImage={
          getHighestResolutionImageSource(listing?.marketing?.image)?.url
        }
        rightElement={
          <Show above={"md"}>
            <Box flex={1} display={"flex"}>
              <Skeleton
                h={"40px"}
                isLoaded={!isLoading}
                alignSelf={"flex-end"}
                ml={"auto"}
              >
                <Image
                  w={"100px"}
                  objectFit={"contain"}
                  src={rexRealEstateLogo}
                  alt={"agency logo"}
                />
              </Skeleton>
            </Box>
          </Show>
        }
      />
    </>
  );
}
