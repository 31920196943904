import type { DateString } from "types/api";
import type { Contact } from "types/contact";
import type { Property } from "types/property";

export enum TaskStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum TaskPriority {
  Preventative = "preventative",
  Normal = "normal",
  Urgent = "urgent",
  Emergency = "emergency",
}

export type Task<Details> = {
  id: string;
  summary: string;
  description: string | null;
  status_id: TaskStatus;
  priority_id: TaskPriority;
  due_date: DateString | null;
  follow_up_date: DateString | null;
  managed_by?: Contact | null;
  closed_at: DateString | null;
  created_at: DateString;
  updated_at: DateString;
  property?: Property;
  details: Details;
};
