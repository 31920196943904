import { Flex, Text } from "@chakra-ui/react";
import { generateSingularLabel } from "components/stats-card";

type PresentationStatProps = {
  value: number | string | null | undefined;
  label: string;
  count: number;
};
export function PresentationStat({
  value,
  label,
  count,
}: PresentationStatProps) {
  const widthClass = count <= 2 ? "48%" : count === 4 ? "48%" : "30%";

  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      gap={3}
      p={{ base: "20px", sm: "20px", lg: "50px" }}
      w={{ base: "100%", sm: "30%", md: widthClass }}
    >
      <Text
        fontSize={{ base: "48px", md: "72px" }}
        color={"gray.700"}
        fontWeight={800}
      >
        {value}
      </Text>
      <Text
        fontSize={{ base: "xl", md: "2xl" }}
        color={"gray.700"}
        fontWeight={400}
        textAlign={"center"}
      >
        {generateSingularLabel(label, value as number)}
      </Text>
    </Flex>
  );
}
