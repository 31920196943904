import type { ReactNode } from "react";
import { Box, type BoxProps } from "@chakra-ui/react";

type GradientMaskedImageProps = {
  imageUrl?: string | null;
  children?: ReactNode;
  style?: BoxProps;
  bgGradient?: boolean;
  gradientStyle?: BoxProps;
};
export function GradientMaskedImage({
  imageUrl,
  children,
  style,
  bgGradient = true,
  gradientStyle,
}: GradientMaskedImageProps) {
  return (
    <Box
      bgImage={imageUrl ?? ""}
      bgRepeat={"no-repeat"}
      bgPosition={"center"}
      bgSize={"cover"}
      w={"100%"}
      h={"100%"}
      flex={1}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"flex-end"}
      overflow={"hidden"}
      {...style}
    >
      <Box
        h={"250px"}
        bgGradient={
          bgGradient
            ? "linear(to-b, rgba(0,0,0,0), rgba(43,43,43,1))"
            : undefined
        }
        display={"flex"}
        flexDir={"column"}
        justifyContent={"flex-end"}
        {...gradientStyle}
      >
        {children}
      </Box>
    </Box>
  );
}
