import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm, type FieldValues } from "react-hook-form";
import { Form } from "components/form";
import { useAuth } from "contexts/auth-provider";
import { marketingScreenModel } from "modules/vendor-portal/screens/marketing/models/marketing";

const FORM_NAME = "marketing-feedback-form";

type SendFeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function SendFeedbackModal({ isOpen, onClose }: SendFeedbackModalProps) {
  const { user } = useAuth();
  const form = useForm();
  const mutation = useMutation(marketingScreenModel.mutations.sendFeedback());

  function onSubmit(values: FieldValues) {
    mutation.mutate({ userId: user?.remote_id, feedback: values.feedback });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={6} fontSize={"xl"}>
          Send feedback
        </ModalHeader>

        <ModalCloseButton right={6} />

        <ModalBody px={6}>
          <VStack gap={4}>
            <Text>
              Thank you for your feedback. We will be in contact shortly to
              discuss any changes.
            </Text>

            <Form id={FORM_NAME} onSubmit={onSubmit} form={form}>
              <FormControl isInvalid={!!form.formState.errors.feedback}>
                <Controller
                  name={"feedback"}
                  control={form.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder={"Please type your feedback here"}
                    />
                  )}
                />
                <FormErrorMessage>*Required</FormErrorMessage>
              </FormControl>
            </Form>

            <Collapse in={mutation.isSuccess}>
              <HStack>
                <CheckCircleIcon color={"green.500"} />
                <Text color={"green.500"} fontWeight={600}>
                  Feedback sent successfully
                </Text>
              </HStack>
            </Collapse>
          </VStack>
        </ModalBody>

        <ModalFooter border={"none"} pt={0} px={6}>
          <Button variant={"ghost"} onClick={onClose}>
            Cancel
          </Button>
          <Button
            type={"submit"}
            form={FORM_NAME}
            variant={"solid"}
            colorScheme={"gray"}
            isLoading={mutation.isPending}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
