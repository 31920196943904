import type { ComponentPropsWithRef, ComponentType, ReactNode } from "react";
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import type { RegisterOptions } from "react-hook-form";
import { useController, useFormContext } from "react-hook-form";

type FormFieldProps<P extends ComponentType<any>> = {
  name: string;
  label?: ReactNode;
  Input: P;
  inputProps?: Omit<ComponentPropsWithRef<P>, "onChange" | "value" | "name">;
} & RegisterOptions;

export function FormField<P extends ComponentType<any>>({
  label,
  Input,
  inputProps,
  name,
  required,
  ...registerOptions
}: FormFieldProps<P>) {
  const { control } = useFormContext();

  const labelWithFallback =
    label ||
    name.replace(/_|\./g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

  const requiredMessage =
    typeof required === "string"
      ? required
      : required
        ? `${labelWithFallback} is required`
        : required;

  const { field, fieldState } = useController({
    control,
    name,
    rules: { required: requiredMessage },
  });

  const props = {
    ...field,
    ...registerOptions,
    ...(Input === Checkbox ? { isChecked: field.value } : {}),
    ...inputProps,
  } as ComponentPropsWithRef<P>;

  return (
    <FormControl isInvalid={!!fieldState.error}>
      {label ? (
        <FormLabel
          htmlFor={name}
          display={"flex"}
          me={"0"}
          fontWeight={"medium"}
          alignItems={"center"}
          fontSize={"lg"}
        >
          {label} {!required && <Text color={"gray.600"}>— optional</Text>}
        </FormLabel>
      ) : null}
      <Input {...props} />
      {fieldState.error?.message ? (
        <FormErrorMessage>
          {fieldState.error?.message as string}
        </FormErrorMessage>
      ) : null}
    </FormControl>
  );
}
