import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import {
  getLongDateTimeStringForInspectionTask,
  InspectionsList,
  triggerIcsDownload,
} from "components/inspections-list/inspections-list";
import { Page } from "components/page";
import { PropertySelector } from "modules/landlord-portal/components/property-selector";
import { landlordInspectionsModel } from "modules/landlord-portal/models/inspections";
import { InspectionType } from "types/inspection-task";

const typeTextMap = {
  [InspectionType.RoutineInspection]: "a routine inspection",
  [InspectionType.EntryInspection]: "an entry inspection",
  [InspectionType.ExitInspection]: "an exit inspection",
};

export function InspectionsScreen() {
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useQuery(
    landlordInspectionsModel.queries.list(searchParams),
  );

  // Sort by newest first
  const sortedInspections = [...(data?.inspectionTasks ?? [])].sort((a, b) =>
    dayjs(b.details.scheduled_at).diff(dayjs(a.details.scheduled_at)),
  );

  const upcomingInspections = [...sortedInspections]
    .reverse()
    .filter((task) => dayjs(task.details.scheduled_at).isAfter(dayjs()))
    .slice(0, 3);

  return (
    <Page heading={"Inspections"} rightElement={<PropertySelector />}>
      <InspectionsList
        isLoading={isLoading}
        inspectionTasks={sortedInspections}
        alerts={
          upcomingInspections.length ? (
            <VStack gap={4}>
              {upcomingInspections.map((upcomingInspection) => (
                <Alert
                  key={upcomingInspection.id}
                  status={"info"}
                  alignItems={"flex-start"}
                  bg={"white"}
                  shadow={"md"}
                  justifyContent={"space-between"}
                  flexDir={"row"}
                >
                  <Flex direction={"row"}>
                    <AlertIcon mt={1} />
                    <Flex direction={"column"}>
                      <AlertTitle>
                        Your Property Manager is conducting{" "}
                        {typeTextMap[upcomingInspection.details.type_id]}{" "}
                        inspection for{" "}
                        {upcomingInspection.property?.short_address} soon
                      </AlertTitle>
                      <AlertDescription>
                        Inspection is happening at{" "}
                        <Text display={"inline"} fontWeight={"semibold"}>
                          {getLongDateTimeStringForInspectionTask(
                            upcomingInspection,
                          )}
                        </Text>
                      </AlertDescription>
                    </Flex>
                  </Flex>
                  <Button
                    flexShrink={0}
                    variant={"outline"}
                    colorScheme={"gray"}
                    onClick={() => triggerIcsDownload(upcomingInspection)}
                  >
                    Add to calendar
                  </Button>
                </Alert>
              ))}
            </VStack>
          ) : null
        }
      />
    </Page>
  );
}
