import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  type ButtonProps,
  type MenuProps,
} from "@chakra-ui/react";

export type MenuOption = { value: string; label: string };

type MultiSelectInputProps = {
  placeholder: string;
  menuLabel?: string;
  options: MenuOption[];
  value: string[];
  onChange: (x: string[]) => void;
  menuProps?: Omit<MenuProps, "children">;
  menuButtonProps?: ButtonProps;
};
export function MultiSelectInput({
  placeholder,
  menuLabel,
  options,
  value,
  onChange,
  menuProps = {},
  menuButtonProps = {},
}: MultiSelectInputProps) {
  const handleCheckboxChange = (selectedValue: string) => {
    const updatedValues = value.includes(selectedValue)
      ? value.filter((v) => v !== selectedValue)
      : [...value, selectedValue];

    onChange(updatedValues);
  };

  return (
    <Menu closeOnSelect={false} {...menuProps}>
      <MenuButton
        as={Button}
        variant={"outline"}
        colorScheme={"gray"}
        rightIcon={<ChevronDownIcon />}
        {...menuButtonProps}
      >
        {placeholder}
      </MenuButton>
      <MenuList>
        <MenuGroup title={menuLabel?.toUpperCase() ?? ""}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              as={Checkbox}
              value={option.value}
              colorScheme={"indigo"}
              size={"lg"}
              onChange={() => handleCheckboxChange(option.value)}
              defaultChecked={value.includes(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
