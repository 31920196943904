import {
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  type As,
} from "@chakra-ui/react";
import DomainLogo from "assets/icons/medium/domain-logo.svg?react";
import ReachLogo from "assets/icons/medium/reach-logo.svg?react";
import REALogo from "assets/icons/medium/realestate-dot-com-logo.svg?react";
import { CampaignSnapshot } from "modules/vendor-portal/screens/performance/components/campaign-snapshot";
import {
  CampaignStatus,
  type MarketingCampaign,
} from "modules/vendor-portal/screens/performance/models/performance";

export const LOGO_MAP: { [key: string]: As } = {
  au_reaapi: REALogo,
  reach: ReachLogo,
  au_domainapi: DomainLogo,
};

const INACTIVE_REACH_CAMPAIGN: MarketingCampaign = {
  id: "reach",
  source_title: "Reach",
  type: "summary",
  status: CampaignStatus.Inactive,
  primary_stats: {
    total: [],
    last7days: [],
    last30days: [],
  },
  secondary_stats: {
    total: [],
  },
  engagement_stats: [],
};

type CampaignTabsProps = {
  campaigns?: MarketingCampaign[];
  isLoading: boolean;
};
export function CampaignTabs({ campaigns, isLoading }: CampaignTabsProps) {
  const hasReachCampaign = campaigns?.find(
    (campaign) => campaign.id === "reach",
  );
  const portalCampaigns = [
    ...(campaigns ?? []),
    ...(!hasReachCampaign ? [INACTIVE_REACH_CAMPAIGN] : []),
  ];
  return (
    <Tabs size={"md"} variant={"closedPanel"}>
      <TabList>
        {portalCampaigns?.map((campaign) => (
          <Tab key={campaign.id}>
            <HStack>
              <Icon as={LOGO_MAP[campaign.id]} boxSize={"20px"} />
              <Text>{campaign.source_title}</Text>
            </HStack>
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {portalCampaigns?.map((campaign) => (
          <TabPanel key={campaign.id}>
            <CampaignSnapshot campaign={campaign} isLoading={isLoading} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
