import { apiClient } from "utils/api-client";
import { createModel } from "utils/models";

type ImageResource = {
  id: string;
  name: string;
  path: string;
  mime_type: string;
  size: number;
  scale: number;
  url: string;
  created_at: string;
  updated_at: string;
};

type FontResource = {
  id: string;
  name: string;
  path: string;
  mime_type: string;
  size: number;
  url: string;
  created_at: string;
  updated_at: string;
};

export type AccountBrandingResponse = {
  data: AccountBranding;
};

export type AccountBranding = {
  primary_logo: ImageResource | null;
  secondary_logo: ImageResource | null;
  accent_color: string;
  navigation_color: string;
  heading_font: FontResource | null;
  body_font: FontResource | null;
};

export type AccountBrandingUpdateData = {
  primary_logo_id?: string | null;
  secondary_logo_id?: string | null;
  accent_color?: string;
  navigation_color?: string;
  heading_font_id?: string | null;
  body_font_id?: string | null;
};

export type ImageUploadResponse = {
  data: ImageResource;
};

export const accountBrandingModel = createModel("accountBranding", {
  queries: {
    get: () => ({
      queryKey: ["accountBranding"],
      queryFn: async () => {
        const response =
          await apiClient.get<AccountBrandingResponse>("accounts/branding");
        return response.data;
      },
    }),
  },
  mutations: {
    update: () => ({
      mutationKey: ["accountBranding", "update"],
      mutationFn: async (data: AccountBrandingUpdateData) => {
        const response = await apiClient.patch<AccountBrandingResponse>(
          "accounts/branding",
          data,
        );
        return response.data;
      },
    }),
    uploadImage: () => ({
      mutationKey: ["accountBranding", "uploadImage"],
      mutationFn: async (params: { file: File; scale?: number }) => {
        const { file, scale = 1 } = params;
        const response = await apiClient.upload<ImageUploadResponse>(
          "accounts/images",
          file,
          { scale },
        );
        return response;
      },
    }),
  },
});
