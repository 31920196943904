import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({});

const commonStyles = {
  container: {
    height: "max-content",
    lineHeight: "24px",
    size: 16,
    borderRadius: "md",
    borderLeftWidth: 7,
  },
  title: {
    fontWeight: "bold",
    color: "gray.900",
  },
  icon: {
    width: 5,
    height: 5,
    marginLeft: "-4px",
  },
};

const successStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "green.50",
    borderColor: "green.700",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

const errorStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "red.50",
    borderColor: "red.600",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

const warningStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "orange.50",
    borderColor: "orange.700",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

const infoStatusVariant = definePartsStyle({
  container: {
    ...commonStyles.container,
    background: "blue.50",
    borderColor: "blue.700",
    boxShadow: "none",
  },
  title: commonStyles.title,
  icon: commonStyles.icon,
});

export const alertTheme = defineMultiStyleConfig({
  baseStyle,

  variants: {
    subtle: ({ status }) => {
      if (status === "error") return errorStatusVariant;
      else if (status === "warning") return warningStatusVariant;
      else if (status === "success") return successStatusVariant;
      else return infoStatusVariant;
    },
  },
});
