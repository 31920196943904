import { defineStyleConfig } from "@chakra-ui/styled-system";

export const buttonTheme = defineStyleConfig({
  variants: {
    solid: (props) => {
      const { colorScheme: c } = props;

      const brightness = c === "gray" ? 700 : 500;

      return {
        color: `#FFF`,
        bg: `${c}.${brightness}`,
        _hover: {
          bg: `${c}.${brightness - 100}`,
          _disabled: {
            bg: `${c}.${brightness}`,
          },
        },
        _active: { bg: `${c}.${brightness}` },
        _expanded: {
          bg: `${c}.${brightness - 100}`,
        },
      };
    },
    ghost: {
      color: "gray.900",
      _hover: {
        bg: "gray.100",
        _disabled: {
          bg: "none",
        },
      },
      _active: { bg: "gray.200" },
    },
    group: {
      bg: "#FFF",
      color: "gray.700",
      border: "1px solid",
      borderColor: "gray.200",
      fontSize: "md",
      fontWeight: 600,
      lineHeight: "20px",
      _hover: {
        bg: "gray.200",
      },
      _active: {
        bg: "gray.700",
        color: "#FFF",
        border: "1px solid",
        borderColor: "gray.700",
      },
    },
  },
  defaultProps: {
    colorScheme: "brand",
  },
});
