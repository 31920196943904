import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button,
  Collapse,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "contexts/auth-provider";
import { marketingScreenModel } from "modules/vendor-portal/screens/marketing/models/marketing";

type ApproveMarketingModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function ApproveMarketingModal({
  isOpen,
  onClose,
}: ApproveMarketingModalProps) {
  const { user } = useAuth();
  const mutation = useMutation(
    marketingScreenModel.mutations.approveMarketingMaterial(),
  );

  function onSubmit() {
    mutation.mutate({ userId: user?.remote_id });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={6} fontSize={"xl"}>
          Approve marketing material
        </ModalHeader>

        <ModalCloseButton right={6} />

        <ModalBody px={6}>
          <VStack gap={4}>
            <Text>
              You acknowledge that you have reviewed all material content and
              are approving the allowed use for any portals, websites and
              general marketing for the public domain.
            </Text>
            <Collapse in={mutation.isSuccess}>
              <HStack>
                <CheckCircleIcon color={"green.500"} />
                <Text color={"green.500"} fontWeight={600}>
                  Approval sent successfully
                </Text>
              </HStack>
            </Collapse>
          </VStack>
        </ModalBody>

        <ModalFooter border={"none"} pt={0} px={6}>
          <Button variant={"ghost"} onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button
            variant={"solid"}
            colorScheme={"gray"}
            onClick={onSubmit}
            isLoading={mutation.isPending}
            isDisabled={mutation.isSuccess}
          >
            Approve
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
