import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Page } from "components/page";
import { Table } from "components/table/table";
import { TotalCountBadge } from "components/total-count-badge";
import {
  landlordOverviewModel,
  type OverviewData,
} from "modules/landlord-portal/models/overview";
import { LandlordBannerImage } from "modules/landlord-portal/screens/overview/components/banner-image/landlord-banner-image";
import { PropertyCell } from "modules/landlord-portal/screens/overview/components/property-cell";
import { StatusCell } from "modules/landlord-portal/screens/overview/components/status-cell";
import { VacancyStatus } from "types/property";
import { PaymentFrequency } from "types/tenancy";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { UNKNOWN_VALUE_MAP } from "utils/string";

const paymentFrequencyLabelMap = {
  [PaymentFrequency.Weekly]: "p/w",
  [PaymentFrequency.Fortnightly]: "p/f",
  [PaymentFrequency.Monthly]: "p/m",
  [PaymentFrequency.Quarterly]: "p/q",
  [PaymentFrequency.Annually]: "p/a",
};

export function OverviewScreen() {
  const { data, isLoading } = useQuery(landlordOverviewModel.queries.read());
  return (
    <Page>
      <Flex direction={"column"} gap={8}>
        <LandlordBannerImage
          isLoading={isLoading}
          summary={data?.summary}
          properties={data?.properties}
        />
        <Alert
          status={"info"}
          alignItems={"flex-start"}
          bg={"white"}
          shadow={"md"}
          justifyContent={"space-between"}
          flexDir={"row"}
        >
          <Flex direction={"row"}>
            <AlertIcon mt={1} />
            <Flex direction={"column"}>
              <AlertTitle>
                Your end of month statement is now available
              </AlertTitle>
              <AlertDescription>{UNKNOWN_VALUE_MAP}</AlertDescription>
            </Flex>
          </Flex>
          <Flex gap={2}>
            <Button
              flexShrink={0}
              variant={"outline"}
              colorScheme={"gray"}
              onClick={() => null}
            >
              Go to statements
            </Button>
            <Button
              flexShrink={0}
              variant={"outline"}
              colorScheme={"gray"}
              onClick={() => null}
              bg={"gray.100"}
            >
              Download
            </Button>
          </Flex>
        </Alert>
        <HStack gap={3}>
          <Heading fontSize={"xl"} as={"h2"}>
            My properties
          </Heading>
          <TotalCountBadge count={data?.properties.length} />
        </HStack>
        <Box
          bg={"white"}
          borderRadius={"md"}
          overflow={"hidden"}
          borderWidth={1}
        >
          <Table<OverviewData["properties"][number]>
            isLoading={isLoading}
            onRowClick={() => null}
            isFetching={isLoading}
            getRowHref={(row) => `/landlord/${row.id}`}
            columns={[
              {
                label: "Property",
                Cell: PropertyCell,
                width: "400px",
              },
              { label: "Status", id: "vacancy_status_id", Cell: StatusCell },
              {
                label: "Agreement",
                formatValue: (_v, row) =>
                  row.vacancy_status_id === VacancyStatus.Occupied
                    ? `${dayjs(
                        row.related.tenancies[0].agreement_start_date,
                      ).format(dateFormats.dayMonthYear)} — ${dayjs(
                        row.related.tenancies[0].agreement_end_date,
                      ).format(dateFormats.dayMonthYear)}`
                    : "–",
              },
              {
                label: "Rent",
                id: "amount",
                formatValue: (_v, row) =>
                  `${formatMoney(row.related.tenancies[0].rent_amount)} ${
                    paymentFrequencyLabelMap[
                      row.related.tenancies[0].frequency_id
                    ]
                  }`,
              },
              {
                label: "Paid to",
                id: "amount",
                formatValue: (_v, row) =>
                  dayjs(row.related.tenancies[0].paid_to_date).format(
                    dateFormats.dayMonthYear,
                  ),
              },
            ]}
            rows={data?.properties ?? []}
            emptyProps={{
              emptyMessage: "No managed properties found.",
              emptyDesc: "Please check back later.",
            }}
          />
        </Box>
      </Flex>
    </Page>
  );
}
