import { faker } from "@faker-js/faker";
import ReachLogo from "assets/icons/medium/reach-logo.svg";

export type Contact = {
  id: string;
  display_name: string;
  given_name: string;
  middle_name: string | null;
  family_name: string;
  primary_address?: string;
  primary_phone?: string;
  primary_email?: string;
  logo?: {
    url: string;
  };
};

// Mock contact using faker to generate data
export function mockContact(): Contact {
  return {
    id: faker.string.uuid(),
    display_name: faker.person.firstName() + " " + faker.person.lastName(),
    given_name: faker.person.firstName(),
    middle_name: faker.person.firstName(),
    family_name: faker.person.lastName(),
    primary_address: faker.location.streetAddress(),
    primary_phone: faker.phone.number(),
    primary_email: faker.internet.email(),
    logo: {
      url: ReachLogo,
    },
  };
}
