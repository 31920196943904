import CalendarIcon from "assets/icons/medium/calendar.svg?react";
import ComputerIcon from "assets/icons/medium/computer.svg?react";
import DisbursementsIcon from "assets/icons/medium/disbursements.svg?react";
import FileIcon from "assets/icons/medium/file.svg?react";
import HomeIcon from "assets/icons/medium/house.svg?react";
import ListingAlternativeIcon from "assets/icons/medium/listing-alternative.svg?react";
import PeopleIcon from "assets/icons/medium/people.svg?react";
import type { MenuItem } from "components/left-navigation-menu/components/left-navigation-menu";
import { LeftNavigationMenu } from "components/left-navigation-menu/components/left-navigation-menu";
import { VENDOR_PORTAL_PATHS } from "modules/vendor-portal/paths";

const menuItems: MenuItem[] = [
  {
    label: "Overview",
    path: VENDOR_PORTAL_PATHS.OVERVIEW,
    Icon: HomeIcon,
  },
  {
    label: "Events",
    path: VENDOR_PORTAL_PATHS.EVENTS,
    Icon: CalendarIcon,
  },
  {
    label: "Feedback",
    path: VENDOR_PORTAL_PATHS.FEEDBACK,
    Icon: PeopleIcon,
  },
  {
    label: "Performance",
    path: VENDOR_PORTAL_PATHS.PERFORMANCE,
    Icon: ComputerIcon,
  },
  {
    label: "Offers",
    path: VENDOR_PORTAL_PATHS.OFFERS,
    Icon: DisbursementsIcon,
  },
  {
    label: "Marketing",
    path: VENDOR_PORTAL_PATHS.MARKETING,
    Icon: ListingAlternativeIcon,
  },
  {
    label: "Documents",
    path: VENDOR_PORTAL_PATHS.DOCUMENTS,
    Icon: FileIcon,
  },
];
export function LeftNavigation() {
  return <LeftNavigationMenu items={menuItems} />;
}
