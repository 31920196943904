import type { DateString, Timestamps } from "types/api";

export enum StatementType {
  PeriodicOwnership = "periodic_ownership",
  YearlyOwnership = "yearly_ownership",
  PeriodicAgency = "periodic_agency",
  YearlyAgency = "yearly_agency",
}

export enum StatementStatus {
  Issued = "issued",
  Void = "void",
}

export type Statement = {
  id: string;
  statement_from: DateString;
  statement_to: DateString;
  type_id: StatementType;
  display_name: string;
  status_id: StatementStatus;
  status_reason?: string;
  voided_at?: DateString;
  reference: string;
} & Timestamps;
