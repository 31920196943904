import type { ReactElement, ReactNode } from "react";
import { cloneElement } from "react";
import { Skeleton, Stack, type StackProps } from "@chakra-ui/react";

type SkeletonListProps = {
  isLoaded: boolean;
  listSize?: number;
  skeleton?: ReactElement;
  children: ReactNode;
} & StackProps;
export function SkeletonList({
  isLoaded,
  listSize = 3,
  spacing = 4,
  skeleton,
  children,
  ...rest
}: SkeletonListProps) {
  if (isLoaded) return children;
  return (
    <Stack spacing={spacing} {...rest}>
      {Array.from({ length: listSize }).map((_, index) =>
        skeleton ? (
          cloneElement(skeleton, { key: index })
        ) : (
          <Skeleton h={"40px"} key={index} w={"100%"} />
        ),
      )}
    </Stack>
  );
}
