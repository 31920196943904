import {
  Box,
  Button,
  Circle,
  Flex,
  Icon,
  Image,
  ListItem,
  Tag,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import SpannerIcon from "assets/icons/medium/spanner.svg?react";
import { CardRecord } from "components/cards/card-record";
import { CardRecordInfoTable } from "components/cards/card-record-info-table";
import { CardRecordInsetDescription } from "components/cards/card-record-inset-description";
import { CardRecordMeta } from "components/cards/card-record-meta";
import {
  MaintenanceTaskStatus,
  type MaintenanceTask,
} from "types/maintenance-task";
import { TaskPriority } from "types/task";
import { dateFormats } from "utils/date";
import { titleCase, UNKNOWN_VALUE_MAP, upperFirst } from "utils/string";

const priorityColorMap = {
  [TaskPriority.Preventative]: "gray.600",
  [TaskPriority.Normal]: "green.600",
  [TaskPriority.Urgent]: "orange.600",
  [TaskPriority.Emergency]: "red.600",
};

const statusColorMap = {
  [MaintenanceTaskStatus.JobLogged]: "gray",
  [MaintenanceTaskStatus.QuotesRequested]: "gray",
  [MaintenanceTaskStatus.QuotesIssued]: "gray",
  [MaintenanceTaskStatus.QuotesReceived]: "gray",
  [MaintenanceTaskStatus.QuotesApproved]: "green",
  [MaintenanceTaskStatus.WorkOrderIssued]: "green",
  [MaintenanceTaskStatus.WorkOrderAssigned]: "green",
  [MaintenanceTaskStatus.WorkFinished]: "green",
};

export function MaintenanceTaskListItem({
  task,
  setCurrentOpenTaskId,
}: {
  task: MaintenanceTask;
  setCurrentOpenTaskId: (taskId: string) => void;
}) {
  return (
    <ListItem>
      <CardRecord
        heading={task.summary}
        leftElement={
          <Box
            borderRadius={"lg"}
            w={["90px", "160px"]}
            aspectRatio={4 / 3}
            bg={"gray.100"}
            alignItems={"center"}
            justifyContent={"center"}
            overflow={"hidden"}
            flexBasis={["100%", "auto"]}
            display={"flex"}
          >
            {(task.details?.images?.length || 0) > 0 ? (
              <Image
                src={task.details!.images![0].url}
                alt={"Maintenance task image"}
                objectFit={"cover"}
                w={"100%"}
                h={"100%"}
              />
            ) : (
              <Icon as={SpannerIcon} w={10} h={10} color={"gray.300"} />
            )}
          </Box>
        }
      >
        <CardRecordMeta>
          <Tag
            colorScheme={statusColorMap[task.details.status_id]}
            fontSize={"sm"}
            fontWeight={600}
            lineHeight={"16px"}
            py={0.5}
            px={2}
            whiteSpace={"nowrap"}
          >
            {titleCase(task.details.status_id)}
          </Tag>
          <Flex direction={"row"} align={"center"} gap={1}>
            <Circle size={2} bg={priorityColorMap[task.priority_id]} />
            <Text
              fontSize={"md"}
              fontWeight={"medium"}
              color={priorityColorMap[task.priority_id]}
            >
              {upperFirst(task.priority_id)} priority
            </Text>
          </Flex>
          <Text fontSize={"md"}>
            Last updated{" "}
            {dayjs(task.updated_at).format(dateFormats.dayMonthYear)}
          </Text>
        </CardRecordMeta>
        <CardRecordInsetDescription
          description={task.description ?? "–"}
          color={"gray.300"}
          showMoreElement={
            <CardRecordInfoTable
              rows={[
                { label: "Reference number", value: "1234" },
                {
                  label: "Requested on",
                  value: task.created_at
                    ? dayjs(task.created_at).format(dateFormats.dayMonthYear)
                    : null,
                },
                { label: "Tradesperson", value: UNKNOWN_VALUE_MAP },
                { label: "Entry authorisation", value: UNKNOWN_VALUE_MAP },
                {
                  label: "Images",
                  value: (
                    <Flex direction={"row"} gap={1}>
                      <Text fontSize={"lg"}>
                        {task.details?.images?.length ?? 0} image
                        {task.details?.images?.length === 1 ? "" : "s"}
                      </Text>
                      <Button
                        variant={"link"}
                        fontSize={"lg"}
                        color={"blue.600"}
                        onClick={() => setCurrentOpenTaskId(task.id)}
                      >
                        View
                      </Button>
                    </Flex>
                  ),
                },
              ]}
            />
          }
        />
      </CardRecord>
    </ListItem>
  );
}
