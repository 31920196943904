let token: string | null = null;

export function initialiseAuthToken() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get("token")) {
    sessionStorage.setItem("token", urlParams.get("token") as string);
    setAuthToken(urlParams.get("token") as string);
    return;
  }
  setAuthToken(sessionStorage.getItem("token") || null);
}

export function getAuthToken() {
  return token;
}

export function setAuthToken(newToken: string | null) {
  token = newToken;
}
