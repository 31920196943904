import { Box, Button, HStack, Text } from "@chakra-ui/react";
import type { FieldValues, UseFormReturn } from "react-hook-form";

export function SaveCancelBar<Values extends FieldValues>({
  form,
  isLoading,
}: {
  form: UseFormReturn<Values>;
  isLoading?: boolean;
}) {
  return (
    <Box
      pos={"fixed"}
      w={"100%"}
      bottom={0}
      left={0}
      right={0}
      bg={"white"}
      zIndex={999}
      display={"flex"}
      justifyContent={"flex-end"}
      shadow={"0 -10px 15px -5px rgba(0,0,0,0.1)"}
      py={[4, 4, 6]}
      px={12}
    >
      <HStack spacing={"2"} justifyContent={"flex-end"}>
        <Text color={"gray.400"} fontSize={"md"} mr={4}>
          You have unsaved changes
        </Text>
        <Button
          colorScheme={"gray"}
          variant={"ghost"}
          onClick={() => form.reset()}
        >
          Cancel
        </Button>
        <Button type={"submit"} colorScheme={"gray"} isLoading={isLoading}>
          Submit
        </Button>
      </HStack>
    </Box>
  );
}
