import { Flex, Progress, Text } from "@chakra-ui/react";

export function PercentageBar({
  percentage,
  label,
  count = null,
}: {
  percentage: number;
  label: string;
  count?: number | null;
}) {
  return (
    <Flex direction={"column"} gap={1}>
      <Text fontSize={"md"} fontWeight={500} color={"gray.700"}>
        {label}
      </Text>

      <Flex
        direction={"row"}
        align={"center"}
        justify={"space-between"}
        gap={5}
      >
        <Progress
          value={percentage}
          h={"8px"}
          borderRadius={"4px"}
          colorScheme={"percentageBar"}
          flexGrow={1}
        />
        <Text fontSize={"md"} fontWeight={500} color={"gray.700"} minW={"32px"}>
          {count ? `${count} ` : `${percentage}%`}
        </Text>
      </Flex>
    </Flex>
  );
}
