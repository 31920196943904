import { Flex, Text } from "@chakra-ui/react";

export function Footer() {
  return (
    <Flex align={"center"} justify={"center"} pt={8} pb={{ base: 8, md: 0 }}>
      <Text fontSize={"sm"} fontWeight={400} color={"gray.600"}>
        Powered by Rex Home
      </Text>
    </Flex>
  );
}
