import type { ReactNode } from "react";
import { Button, Stack } from "@chakra-ui/react";

type Option = {
  label: ReactNode;
  value: string;
  backgroundColor?: string;
};

type ToggleInputProps = {
  value: string;
  onChange: (value: string) => void;
  options: Option[];
};

export function ToggleInput({ value, onChange, options }: ToggleInputProps) {
  return (
    <Stack flexDir={"row"} justifyContent={"center"} flexGrow={1} gap={0}>
      {options.map((option, index) => {
        const isSelected = value === option.value;
        const isFirstChild = index === 0;
        const isLastChild = index === options.length - 1;

        const bg = option.backgroundColor ?? "indigo.200";

        return (
          <Button
            variant={"outlined"}
            size={"sm"}
            key={option.value}
            onClick={() => onChange(option.value)}
            bg={isSelected ? bg : "transparent"}
            borderRadius={0}
            borderTopLeftRadius={isFirstChild ? "lg" : 0}
            borderBottomLeftRadius={isFirstChild ? "lg" : 0}
            borderTopRightRadius={isLastChild ? "lg" : 0}
            borderBottomRightRadius={isLastChild ? "lg" : 0}
            ml={isFirstChild ? 0 : "-1px"} // Overlap borders between adjacent buttons
            borderWidth={1}
            borderColor={isSelected ? "gray.800" : undefined}
            borderLeftColor={
              options[index - 1]?.value === value ? "gray.800" : undefined
            }
            fontWeight={"600"}
            flexGrow={1}
          >
            {option.label}
          </Button>
        );
      })}
    </Stack>
  );
}
