import { AppLayout } from "components/app-layout";
import { RequireAuthRole } from "contexts/auth-provider";
import type { Route } from "lib/react-router-dom";
import { LeftNavigation } from "modules/landlord-portal/components/left-navigation";
import { LANDLORD_PORTAL_PATHS } from "modules/landlord-portal/paths";
import { DocumentsScreen } from "modules/landlord-portal/screens/documents/documents-screen";
import { FinancialsScreen } from "modules/landlord-portal/screens/financial/financials-screen";
import { InspectionDetailsScreen } from "modules/landlord-portal/screens/inspections/inspection-details-screen";
import { InspectionsScreen } from "modules/landlord-portal/screens/inspections/inspections-screen";
import { MaintenanceScreen } from "modules/landlord-portal/screens/maintenance/maintenance-screen";
import { OverviewPropertyDetailsScreen } from "modules/landlord-portal/screens/overview/overview-property-details-screen";
import { OverviewScreen } from "modules/landlord-portal/screens/overview/overview-screen";

export const LANDLORD_PORTAL_ROUTES: Route[] = [
  {
    path: LANDLORD_PORTAL_PATHS.DEFAULT,
    handle: {
      title: "Landlord",
    },
    element: (
      <RequireAuthRole anyOf={["landlord", "agent", "agent_manager"]}>
        <AppLayout leftNavigationMenu={<LeftNavigation />} />
      </RequireAuthRole>
    ),
    children: [
      {
        index: true,
        Component: OverviewScreen,
        handle: {
          title: "Overview",
        },
      },
      {
        path: LANDLORD_PORTAL_PATHS.OVERVIEW_PROPERTY_DETAILS,
        Component: OverviewPropertyDetailsScreen,
        handle: {
          title: "Property Details",
        },
      },
      {
        path: LANDLORD_PORTAL_PATHS.MAINTENANCE,
        Component: MaintenanceScreen,
        handle: {
          title: "Maintenance",
        },
      },
      {
        path: LANDLORD_PORTAL_PATHS.INSPECTIONS,
        Component: InspectionsScreen,
        handle: {
          title: "Inspections",
        },
      },
      {
        path: LANDLORD_PORTAL_PATHS.INSPECTION_DETAILS,
        Component: InspectionDetailsScreen,
        handle: {
          title: "Inspection Details Screen",
        },
      },
      {
        path: LANDLORD_PORTAL_PATHS.FINANCIALS,
        Component: FinancialsScreen,
        handle: {
          title: "Financials",
        },
      },
      {
        path: LANDLORD_PORTAL_PATHS.DOCUMENTS,
        Component: DocumentsScreen,
        handle: {
          title: "Documents",
        },
      },
    ],
  },
];
