import type { ReactNode } from "react";
import { Card, Flex, Text, type CardProps } from "@chakra-ui/react";
import dayjs from "dayjs";
import CalendarIcon from "assets/icons/medium/calendar.svg?react";
import { FeedbackIcon } from "modules/vendor-portal/screens/overview/components/feedback/feedback-icon";
import type { APIListingEvent } from "modules/vendor-portal/types/events";
import { dateFormats, timeFormats } from "utils/date";

export function formatEventDateTimeTitle({
  date_of,
  date_time_start,
  date_time_finish,
}: {
  date_of: string;
  date_time_start: string;
  date_time_finish: string;
}) {
  return `${dayjs(date_of).format(dateFormats.weekdayDayYear)}, ${dayjs(
    `2000-01-01 ${date_time_start}`,
  ).format(timeFormats.hoursMinutes)} - ${dayjs(
    `2000-01-01 ${date_time_finish}`,
  ).format(timeFormats.hoursMinutes)}`;
}

export function formatAPIEventDateTimeTitle(event: APIListingEvent) {
  return `${dayjs(event.starts_at).format(dateFormats.weekdayDayYear)}, ${dayjs(
    event.starts_at,
  ).format(timeFormats.hoursMinutes)} - ${dayjs(event.ends_at).format(
    timeFormats.hoursMinutes,
  )}`;
}

type EventCardProps = {
  title: string;
  children?: ReactNode;
} & CardProps;
export function EventCard({ title, children, ...rest }: EventCardProps) {
  return (
    <Card
      display={"flex"}
      flexDir={"row"}
      gap={2}
      pt={4}
      pr={{ base: 0, md: 10 }}
      pb={4}
      pl={{ base: 0, md: 3 }}
      variant={{ base: "unstyled", md: "outline" }}
      {...rest}
    >
      <FeedbackIcon icon={CalendarIcon} />

      <Flex direction={"column"} gap={2} flexGrow={1} overflowX={"hidden"}>
        <Text textStyle={"large"} fontWeight={"semibold"} color={"gray.700"}>
          {title}
        </Text>
        {children}
      </Flex>
    </Card>
  );
}
