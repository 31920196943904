import { useState } from "react";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  List,
  ListItem,
  Show,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import BarChartIcon from "assets/icons/medium/bar-chart.svg?react";
import PropertyIcon from "assets/icons/medium/property.svg?react";
import { SelectInput, type MenuOption } from "components/inputs/select-input";
import { PortalSelector } from "components/portal-selector";
import { SkeletonGrid } from "components/skeleton-grid";
import { StatsCard } from "components/stats-card";
import { StatsCardSkeleton } from "components/stats-card-skeleton";
import { TotalCountBadge } from "components/total-count-badge";
import { SkeletonList } from "modules/vendor-portal/components/skeleton-list";
import { OfferListItem } from "modules/vendor-portal/screens/offers/components/offer-list-item";
import {
  offersScreenModel,
  type Contract,
} from "modules/vendor-portal/screens/offers/models/offers";

export enum OffersSortByOption {
  Highest = "highest",
  Lowest = "lowest",
  Recent = "recent",
}

type OffersOptionsGroup = {
  label: { id: string; value: string };
  options: MenuOption[];
};

const SORT_BY_OPTIONS: OffersOptionsGroup[] = [
  {
    label: { id: "sort-by", value: "Sort by" },
    options: [
      { value: OffersSortByOption.Highest, label: "Highest offer" },
      { value: OffersSortByOption.Lowest, label: "Lowest offer" },
      { value: OffersSortByOption.Recent, label: "Most recent" },
    ],
  },
];
const DEFAULT_SORT_BY_OPTION = SORT_BY_OPTIONS[0].options[0].value;

function sortOffers(offers: Contract[], sortByValue: string) {
  switch (sortByValue) {
    case OffersSortByOption.Highest:
      return offers.sort(
        (a, b) =>
          (b.detail_sale_price_or_lease_pa ?? 0) -
          (a.detail_sale_price_or_lease_pa ?? 0),
      );
    case OffersSortByOption.Lowest:
      return offers.sort(
        (a, b) =>
          (a.detail_sale_price_or_lease_pa ?? 0) -
          (b.detail_sale_price_or_lease_pa ?? 0),
      );
    case OffersSortByOption.Recent:
      return offers.sort(
        (a, b) =>
          new Date(b.date_actual_fallen ?? b.date_actual_offer).getTime() -
          new Date(a.date_actual_fallen ?? a.date_actual_offer).getTime(),
      );
  }
}

export function OffersScreen() {
  const { data, isLoading } = useQuery(offersScreenModel.queries.read());
  const [sortByValue, setSortByValue] = useState<string>(
    DEFAULT_SORT_BY_OPTION,
  );
  const primaryAcceptedContract = data?.listing.contracts?.find(
    (i) => i._is_primary_accepted,
  );
  const sortableContracts =
    data?.listing.contracts?.filter((i) => !i._is_primary_accepted) ?? [];

  return (
    <Box p={0}>
      <Show below={"sm"}>
        <Box borderBottomWidth={"1px"} borderBottomColor={"gray.100"}>
          <Center>
            <PortalSelector
              prefixIcon={<Icon as={PropertyIcon} boxSize={"12px"} />}
            />
          </Center>
        </Box>
      </Show>

      <Flex
        direction={"column"}
        p={{ base: 4, md: 0 }}
        pt={{ base: 4, md: 2 }}
        gap={8}
      >
        <Heading size={{ base: "2xl", md: "lg" }} fontWeight={700} as={"h1"}>
          Offers
        </Heading>
        <SkeletonGrid
          isLoaded={!isLoading}
          gridSize={4}
          skeleton={<StatsCardSkeleton />}
        >
          <Grid
            templateColumns={{
              base: "repeat(2, 1fr)",
              lg: `repeat(${data?.listing.stats?.length ?? 4}, 1fr)`,
            }}
            templateRows={{ base: "repeat(2, 1fr)", lg: "repeat(1, 1fr)" }}
            gap={6}
          >
            {data?.listing.stats?.map((stat) => (
              <GridItem key={stat.id} w={"100%"}>
                <StatsCard stat={stat} icon={BarChartIcon} />
              </GridItem>
            ))}
          </Grid>
        </SkeletonGrid>

        <Flex
          direction={{ base: "column", md: "row" }}
          justify={"space-between"}
          gap={4}
        >
          <HStack gap={3}>
            <Heading fontSize={"xl"} as={"h2"}>
              All submitted offers
            </Heading>
            <TotalCountBadge count={data?.listing.contracts?.length} />
          </HStack>
          <SelectInput
            optionGroups={SORT_BY_OPTIONS}
            value={sortByValue}
            onChange={(value) => setSortByValue(value)}
          />
        </Flex>

        <SkeletonList
          isLoaded={!isLoading}
          listSize={4}
          skeleton={<Skeleton h={"119px"} />}
        >
          <List spacing={"12px"}>
            {primaryAcceptedContract && (
              <ListItem key={primaryAcceptedContract.id}>
                <OfferListItem contract={primaryAcceptedContract} />
              </ListItem>
            )}
            {sortOffers(sortableContracts, sortByValue)?.map((contract) => {
              return (
                <ListItem key={contract.id}>
                  <OfferListItem contract={contract} />
                </ListItem>
              );
            })}
          </List>
        </SkeletonList>
      </Flex>
    </Box>
  );
}
