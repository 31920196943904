import {
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import PriorityEmergencyIcon from "assets/icons/medium/priority-emergency.svg?react";
import PriorityLowIcon from "assets/icons/medium/priority-low.svg?react";
import PriorityNormalIcon from "assets/icons/medium/priority-normal.svg?react";
import PriorityHighIcon from "assets/icons/medium/priority-urgent.svg?react";
import { CardRecord } from "components/cards/card-record";
import { Form } from "components/form";
import { FormField } from "components/form-field";
import { MultiUploadImage } from "components/inputs/multi-image-upload-input";
import { ToggleInput } from "components/inputs/toggle-input";
import { Page } from "components/page";
import { EmergencyFields } from "modules/tenant-portal/screens/request-maintenance/components/emergency-fields";
import { TaskPriority } from "types/task";
import { UNKNOWN_VALUE_MAP } from "utils/string";

export function RequestMaintenanceScreen() {
  const form = useForm({
    defaultValues: {
      access_authorisation: "true",
      summary: "",
      description: "",
      allow_access_for: "",
      images: [],
      priority_id: "",
      send_request_as_well: "",
    },
  });

  const doesAllowAccess = form.watch("access_authorisation") === "true";
  const priorityId = form.watch("priority_id");
  const sendRequestAsWell = form.watch("send_request_as_well");
  const isAllDisabled =
    priorityId === TaskPriority.Emergency && !sendRequestAsWell;

  return (
    <Page heading={"Request Maintenance"} showBackButton>
      <Form
        id={"request-maintenance"}
        form={form}
        onSubmit={() => alert(UNKNOWN_VALUE_MAP)}
      >
        <Flex direction={"column"} gap={6}>
          <CardRecord py={8}>
            <Flex direction={["column", "column", "row"]} gap={6}>
              <Flex direction={"column"} gap={2} flex={1}>
                <Heading size={"md"} fontWeight={"bold"}>
                  Details
                </Heading>
                <Text color={"gray.600"}>
                  Tell us as much information as you can about the problem so
                  that we know how to help
                </Text>
              </Flex>
              <Flex flex={2} direction={"column"} gap={4}>
                <FormField
                  name={"summary"}
                  label={"Summary"}
                  Input={Input}
                  disabled={isAllDisabled}
                  required
                />
                <FormField
                  name={"description"}
                  label={"Detailed description"}
                  disabled={isAllDisabled}
                  required
                  Input={Textarea}
                />
                <FormField
                  name={"priority_id"}
                  label={"How urgent is this issue?"}
                  required
                  Input={ToggleInput}
                  inputProps={{
                    options: [
                      {
                        label: (
                          <Flex direction={"row"} gap={1} align={"center"}>
                            <Icon as={PriorityLowIcon} boxSize={4} />
                            Low
                          </Flex>
                        ),
                        value: TaskPriority.Preventative,
                        backgroundColor: "gray.200",
                      },
                      {
                        label: (
                          <Flex direction={"row"} gap={1} align={"center"}>
                            <Icon as={PriorityNormalIcon} boxSize={4} />
                            Normal
                          </Flex>
                        ),
                        value: TaskPriority.Normal,
                        backgroundColor: "green.50",
                      },
                      {
                        label: (
                          <Flex direction={"row"} gap={1} align={"center"}>
                            <Icon as={PriorityHighIcon} boxSize={4} />
                            Urgent
                          </Flex>
                        ),
                        value: TaskPriority.Urgent,
                        backgroundColor: "orange.50",
                      },
                      {
                        label: (
                          <Flex direction={"row"} gap={1} align={"center"}>
                            <Icon as={PriorityEmergencyIcon} boxSize={4} />
                            Emergency
                          </Flex>
                        ),
                        value: TaskPriority.Emergency,
                        backgroundColor: "red.100",
                      },
                    ],
                  }}
                />
                {priorityId === TaskPriority.Emergency ? (
                  <EmergencyFields />
                ) : null}
                <FormField
                  name={"access_authorisation"}
                  label={"Access authorisation"}
                  Input={RadioGroup}
                  disabled={isAllDisabled}
                  required
                  inputProps={{
                    colorScheme: "blue",
                    children: (
                      <Stack spacing={2} direction={"column"}>
                        <Radio value={"true"} isDisabled={isAllDisabled}>
                          Yes, I authorise someone else to access the property
                          if no one is home
                        </Radio>
                        <Radio value={"false"} isDisabled={isAllDisabled}>
                          No, I do not authorise someone else to access the
                          property if no one is home
                        </Radio>
                      </Stack>
                    ),
                  }}
                />
                {priorityId === TaskPriority.Emergency ? (
                  <FormField
                    name={"notify_tradesman_contacted"}
                    Input={Checkbox}
                    disabled={isAllDisabled}
                    inputProps={{
                      children:
                        "Let my Property Manager know that I’ve contacted a tradesman",
                    }}
                  />
                ) : null}
                {doesAllowAccess ? (
                  <FormField
                    name={"allow_access_for"}
                    disabled={isAllDisabled}
                    label={"Allow access for"}
                    Input={Select}
                    inputProps={{
                      children: [
                        <option key={1} value={"1"}>
                          Property Manager
                        </option>,
                      ],
                    }}
                  />
                ) : null}
              </Flex>
            </Flex>
          </CardRecord>
          <CardRecord py={8}>
            <Flex direction={["column", "column", "row"]} gap={6}>
              <Flex direction={"column"} gap={2} flex={1}>
                <Heading size={"md"} fontWeight={"bold"}>
                  Attachments
                </Heading>
                <Text color={"gray.600"}>
                  By adding photos or videos, you can help us understand the
                  problem better and we can get to a solution quicker
                </Text>
              </Flex>
              <Flex flex={2}>
                <FormField
                  name={"images"}
                  disabled={isAllDisabled}
                  label={"Images"}
                  Input={MultiUploadImage}
                />
              </Flex>
            </Flex>
          </CardRecord>
          <ButtonGroup justifyContent={"flex-end"}>
            <Button
              variant={"outline"}
              colorScheme={"gray"}
              onClick={() => {
                form.reset();
                window.scroll(0, 0);
              }}
            >
              Cancel
            </Button>
            <Button
              type={"submit"}
              isLoading={form.formState.isSubmitting}
              colorScheme={"gray"}
              variant={"solid"}
              isDisabled={isAllDisabled}
            >
              Submit request
            </Button>
          </ButtonGroup>
        </Flex>
      </Form>
    </Page>
  );
}
