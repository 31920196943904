import { useCallback } from "react";
import { useAuth } from "contexts/auth-provider";

type WordMap = {
  [key: string]: {
    isAUS: string;
    isEU?: string;
    isAE?: string;
  };
};

const REGIONAL_WORD_VARIANT_MAP = {
  memorandum: { isEU: "Memorandum", isAUS: "Advice" },
  memo: { isEU: "Memo", isAUS: "Advice" },
  valued: { isEU: "Valued", isAUS: "Appraised", isAE: "Valued" },
  valuation: { isEU: "Valuation", isAUS: "Appraisal", isAE: "Valuation" },
  valuations: { isEU: "Valuations", isAUS: "Appraisals", isAE: "Valuations" },
  valuation_type_rent: { isEU: "Let", isAUS: "Rent / Lease", isAE: "Let" },
  contract: { isEU: "Offer", isAUS: "Contract" },
  contracts: { isEU: "Offers", isAUS: "Contracts" },
  buyer: { isEU: "Applicant", isAUS: "Buyer", isAE: "Applicant" },
  unconditional: { isEU: "Exchanged", isAUS: "Unconditional" },
  "expected unconditional": {
    isEU: "Expected exchange",
    isAUS: "Expected unconditional",
  },
  "verified unconditional": {
    isEU: "Verified exchange",
    isAUS: "Verified unconditional",
  },
  "expected settlement": {
    isEU: "Expected completion",
    isAUS: "Expected settlement",
  },
  "verified settled": {
    isEU: "Verified completed",
    isAUS: "Verified settled",
  },
  settled: { isEU: "Completed", isAUS: "Settled" },
  leased: { isEU: "Let", isAUS: "Leased" },
  GST: { isEU: "VAT", isAUS: "GST" },
  letting: { isEU: "Letting", isAUS: "Rental" },
  fallen: { isEU: "fall through", isAUS: "fallen" },
  inspection: {
    isAE: "Viewing",
    isEU: "Viewing",
    isAUS: "Private inspection",
  },
};

type Word = keyof typeof REGIONAL_WORD_VARIANT_MAP;

export type WhichWord = (word: Word) => string;

export function useWhichWordFactory() {
  const { user } = useAuth();
  const region = user?.region;

  const whichWordFactory = useCallback(
    (word: string | Word) => {
      const currentWord =
        (REGIONAL_WORD_VARIANT_MAP[word as Word] as WordMap[string] | string) ??
        word;

      if (typeof currentWord === "string" || !currentWord) {
        return currentWord;
      }

      // Defaults to AUS when the region doesn't have the word for it
      // So if AUS has the same wording on the other region no need to declare it
      let regionWord = currentWord.isAUS;

      if (region === "eu" && currentWord.isEU) {
        regionWord = currentWord.isEU;
      }

      if (region === "ae" && currentWord.isAE) {
        regionWord = currentWord.isAE;
      }

      return regionWord;
    },
    [region],
  );

  return whichWordFactory;
}

export function useWhichWord(word: Word) {
  const whichWord = useWhichWordFactory();
  return whichWord(word);
}
