import {
  mockComplianceEntry,
  type ComplianceEntry,
} from "types/compliance-entry";
import { mockFile, type File } from "types/file";
import { apiClient } from "utils/api-client/index";
import { createModel } from "utils/models";

export type HandbookData = {
  documents: File[];
  manuals: File[];
  compliance_entries: ComplianceEntry[];
};

export const landlordDocumentsModel = createModel("landlordDocuments", {
  queries: {
    read: (searchParams: URLSearchParams) => ({
      queryKey: ["read", searchParams.toString()],
      queryFn: () => {
        return apiClient.get<HandbookData>(
          `/landlord/documents?${searchParams.toString()}`,
        );
      },
      keepPreviousData: true,
    }),
  },
});

export const mockLandlordDocumentsResponse = () => {
  return {
    documents: Array.from({ length: 9 }).map(() => mockFile()),
    manuals: Array.from({ length: 3 }).map(() => mockFile()),
    compliance_entries: Array.from({ length: 3 }).map(() =>
      mockComplianceEntry(),
    ),
  };
};
