import { Box, Icon, type As } from "@chakra-ui/react";

type FeedbackIconProps = {
  icon: As;
};
export function FeedbackIcon({ icon }: FeedbackIconProps) {
  return (
    <Box
      minW={{ base: "16px", md: "40px" }}
      h={"24px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Icon as={icon} color={"gray.700"} boxSize={"16px"} />
    </Box>
  );
}
