import { Divider, Flex, HStack, Text } from "@chakra-ui/react";
import type { PriceIndicationRange } from "modules/vendor-portal/types/price-indication";
import { formatMoney } from "utils/format-money";

type MinimalPriceIndicationProps = {
  priceRange: PriceIndicationRange;
};
export function MinimalPriceIndication({
  priceRange,
}: MinimalPriceIndicationProps) {
  return (
    <Flex direction={"column"} gap={5} w={"100%"}>
      <HStack gap={{ base: 2, sm: 4 }} justifyContent={"space-between"}>
        <Text
          fontSize={{ base: "md", sm: "lg" }}
          fontWeight={700}
          color={"gray.700"}
        >
          {`${formatMoney(priceRange.minValue)}`}
        </Text>
        <Text
          fontSize={{ base: "2xl", sm: "5xl" }}
          fontWeight={700}
          color={"gray.700"}
        >
          {`${formatMoney(priceRange.averageValue)}`}
        </Text>
        <Text
          fontSize={{ base: "md", sm: "lg" }}
          fontWeight={700}
          color={"gray.700"}
        >
          {`${formatMoney(priceRange.maxValue)}`}
        </Text>
      </HStack>

      <HStack gap={{ base: 2, sm: 4 }} justifyContent={"space-between"}>
        <Text fontSize={"lg"} color={"gray.600"}>
          Lowest
        </Text>
        <Divider borderColor={"#2D3748"} />
        <Text fontSize={"lg"} color={"gray.600"} fontWeight={600}>
          Average
        </Text>
        <Divider borderColor={"#2D3748"} />
        <Text fontSize={"lg"} color={"gray.600"}>
          Highest
        </Text>
      </HStack>
    </Flex>
  );
}
