import type { ReactNode } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

export type EmptyProps = {
  emptyMessage: string;
  emptyDesc?: string;
  addLabel?: string;
  onAdd?: () => void;
  icon?: ReactNode;
};

export function Empty({
  emptyMessage,
  emptyDesc,
  addLabel,
  onAdd,
  icon = null,
}: EmptyProps) {
  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      h={300}
      bg={"gray.50"}
      gap={6}
    >
      {icon}
      <Box>
        <Text fontSize={"lg"} fontWeight={"bold"} color={"gray.600"}>
          {emptyMessage}
        </Text>
        {emptyDesc ? (
          <Text fontWeight={"medium"} mt={2} color={"gray.600"}>
            {emptyDesc}
          </Text>
        ) : null}
        {addLabel && (
          <Button leftIcon={<AddIcon />} mt={5} onClick={onAdd}>
            {addLabel}
          </Button>
        )}
      </Box>
    </Flex>
  );
}
