import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { CardRecord } from "components/cards/card-record";
import { Page } from "components/page";
import { SimpleGridTable } from "components/simple-grid-table";
import { StatsGrid } from "components/stats-grid";
import { Table } from "components/table/table";
import { apiPrefix } from "lib/ky";
import { tenancyPaymentsModel } from "modules/tenant-portal/models/payments";
import { generateRentPositionStats } from "modules/tenant-portal/utils/stats";
import type { TenancyPaymentHistoryItem } from "types/tenancy-payment-history-item";
import { dateFormats } from "utils/date";
import { formatMoney } from "utils/format-money";
import { UNKNOWN_VALUE_MAP } from "utils/string";

export function PaymentsScreen() {
  const { propertyId } = useParams();
  const { data, isLoading } = useQuery(
    tenancyPaymentsModel.queries.read(propertyId),
  );

  const stats = generateRentPositionStats(data?.tenancy);

  return (
    <Page heading={"Payments"}>
      <StatsGrid stats={stats} isLoading={isLoading} columnsLarge={4} />
      <CardRecord
        heading={"Bank details"}
        pl={{ base: 0, md: 6 }}
        pb={6}
        pt={6}
      >
        <SimpleGridTable
          items={[
            {
              label: "Account name",
              value: data?.bank_account.account_name,
            },
            {
              label: "Account number",
              value: data?.bank_account.account_number,
            },
            {
              label: "Branch code",
              value: data?.bank_account.branch_code,
            },
            {
              label: "Tenancy reference number",
              value: UNKNOWN_VALUE_MAP,
            },
          ]}
        />
      </CardRecord>
      <Flex direction={"column"} gap={6}>
        <Flex direction={"row"} justify={"space-between"} align={"center"}>
          <Heading fontSize={"lg"} as={"h2"}>
            Payments history
          </Heading>
          <Link
            to={apiPrefix + `/tenant/${propertyId}/payments/download`}
            download={`Payment History for ${data?.property.short_address}.csv`}
          >
            <Button
              variant={"outline"}
              colorScheme={"gray"}
              leftIcon={<DownloadIcon />}
              size={"sm"}
            >
              Download payment history
            </Button>
          </Link>
        </Flex>
        <Box
          bg={"white"}
          borderRadius={"md"}
          overflow={"hidden"}
          borderWidth={1}
        >
          <Table<TenancyPaymentHistoryItem>
            isLoading={isLoading}
            isFetching={isLoading}
            columns={[
              {
                label: "Received on",
                width: "200px",
                id: "date",
                formatValue: (v) => dayjs(v).format(dateFormats.dayMonthYear),
              },
              { label: "Details", id: "description" },
              { label: "Payment", id: "sub_description" },
              { label: "Amount", id: "amount", formatValue: formatMoney },
            ]}
            rows={data?.payment_history_items ?? []}
            emptyProps={{
              emptyMessage: "No payment history found.",
              emptyDesc: "Please check back later.",
            }}
          />
        </Box>
      </Flex>
    </Page>
  );
}
