import { useRef, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  chakra,
  GridItem,
  Image,
  ScaleFade,
  SimpleGrid,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import DeleteIcon from "assets/icons/medium/delete.svg?react";

type MultiImageUploadInputProps = {
  onChange: (files: File[]) => void;
  disabled?: boolean;
};

export function MultiUploadImage({
  disabled,
  onChange,
}: MultiImageUploadInputProps) {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isDragOver, setIsDragOver] = useBoolean(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    const files = [...uploadedFiles, ...newFiles];
    setUploadedFiles(files);
    onChange(files);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver.off();
    const newFiles = Array.from(event.dataTransfer.files || []);
    const files = [...uploadedFiles, ...newFiles];
    setUploadedFiles(files);
    onChange(files);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isDragOver) return;
    setIsDragOver.on();
  };

  const handleDragLeave = () => {
    setIsDragOver.off();
  };

  return (
    <Box
      borderRadius={"lg"}
      borderWidth={"1px"}
      p={4}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      cursor={disabled ? "not-allowed" : "pointer"}
      onDragEnter={handleDragOver}
      onDragLeave={handleDragLeave}
      bg={disabled ? "gray.50" : isDragOver ? "blue.100" : "transparent"}
      borderColor={isDragOver ? "blue.500" : undefined}
    >
      <SimpleGrid
        columns={3}
        gap={4}
        pointerEvents={isDragOver ? "none" : "unset"}
      >
        {uploadedFiles.map((file, index) => (
          <GridItem
            key={file.name}
            aspectRatio={4 / 3}
            borderRadius={"lg"}
            bg={"gray.100"}
            overflow={"hidden"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            pos={"relative"}
          >
            <ScaleFade initialScale={0.9} in={true}>
              <Box>
                <Image
                  w={"100%"}
                  data-peer
                  objectFit={"cover"}
                  aspectRatio={4 / 3}
                  src={URL.createObjectURL(file)}
                  alt={`Uploaded ${index}`}
                />
                <Button
                  display={"none"}
                  _peerHover={{ display: "flex" }}
                  _hover={{ display: "flex" }}
                  bg={"gray.50"}
                  variant={"ghost"}
                  color={"red.500"}
                  size={"sm"}
                  leftIcon={<DeleteIcon />}
                  shadow={"0 2px 3px 0 rgba(30,30,35,0.15)"}
                  pos={"absolute"}
                  bottom={2}
                  left={0}
                  right={0}
                  ml={"auto"}
                  mr={"auto"}
                  w={"80px"}
                  onClick={() => {
                    setUploadedFiles((f) => f.filter((f, i) => i !== index));
                  }}
                >
                  Remove
                </Button>
              </Box>
            </ScaleFade>
          </GridItem>
        ))}
        {uploadedFiles.length ? (
          <GridItem>
            <Button
              w={"100%"}
              aspectRatio={4 / 3}
              borderWidth={2}
              borderStyle={"dashed"}
              h={"unset"}
              as={chakra.label}
              colorScheme={"blue"}
              cursor={"pointer"}
              bg={"transparent"}
              color={"gray.700"}
              _hover={{
                bg: "blue.100",
                borderColor: "blue.500",
                color: "blue.500",
              }}
              htmlFor={"file"}
            >
              <AddIcon boxSize={6} color={"currentColor"} />
            </Button>
          </GridItem>
        ) : null}
      </SimpleGrid>
      {!uploadedFiles.length ? (
        <Center
          as={chakra.label}
          htmlFor={"file"}
          overflow={"hidden"}
          pos={"relative"}
          cursor={disabled ? "not-allowed" : "pointer"}
          flexDir={"column"}
        >
          <Center w={"100%"} py={12}>
            <VStack>
              <AddIcon />
              <Text fontSize={"lg"} fontWeight={"medium"} color={"gray.600"}>
                Click to upload or drag and drop
              </Text>
            </VStack>
          </Center>
        </Center>
      ) : null}
      <chakra.input
        type={"file"}
        style={{ display: "none" }}
        id={"file"}
        name={"file"}
        disabled={disabled}
        multiple
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Box>
  );
}
