import type { ReactNode } from "react";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { PageCard } from "components/page-card";

export function PageCardAgencySettings({
  children,
  heading,
  description,
}: {
  children: ReactNode;
  heading: string;
  description: string;
}) {
  return (
    <PageCard>
      <Flex direction={["column", "row"]} gap={8}>
        <Flex direction={"column"} gap={2} flex={1}>
          <Heading fontSize={"lg"} as={"h3"} fontWeight={"bold"}>
            {heading}
          </Heading>
          <Text fontSize={"md"} color={"gray.600"}>
            {description}
          </Text>
        </Flex>
        <Flex direction={"column"} flex={3}>
          {children}
        </Flex>
      </Flex>
    </PageCard>
  );
}
